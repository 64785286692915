import {
  Currency,
  DurationUnitEnum,
  FinancePeriodEnum,
  WagerActivityTimeFilter,
  WagerTypeEnum,
} from '../api/interfaces';

export const toMaxPrecision = (value: number, decimals: number) => {
  return value.toFixed(decimals).replace(/\.?0+$/, '');
};

export const formatCurrency = (amount: number, currency?: Currency) => {
  if (currency) return amount.toLocaleString(undefined, { style: 'currency', currency: currency });

  return amount.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

export const formatCurrencySymbol = (currency: Currency) => {
  switch (currency) {
    case 'AUD':
      return 'A$';
    case 'CNY':
      return '¥';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'HKD':
      return 'HK$';
    case 'JPY':
      return '¥';
    case 'USD':
      return '$';
  }

  return currency;
};

export const formatWagerType = (type: WagerTypeEnum) => {
  switch (type) {
    case WagerTypeEnum.Rise:
      return 'Up';
    case WagerTypeEnum.Fall:
      return 'Down';
  }
};

export const formatCurrencyPair = (left: Currency, right: Currency) => {
  return `${left} / ${right}`;
};

export const formatDurationUnit = (quantity: number, unit: DurationUnitEnum, short?: boolean) => {
  if (quantity === 1) {
    switch (unit) {
      case DurationUnitEnum.Ticks:
        return 'tick';
      case DurationUnitEnum.Minutes:
        return short ? 'min' : 'minute';
    }
  }

  switch (unit) {
    case DurationUnitEnum.Ticks:
      return 'ticks';
    case DurationUnitEnum.Minutes:
      return short ? 'mins' : 'minutes';
  }
};

export const rawDateToIso = (year: string, month: string, day: string) => {
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

export const formatTime = (timestamp: string) => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Pad single-digit values with leading zeros
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};

export const minutesToDhms = (minutes: number | undefined) => {
  if (minutes !== undefined) {
    const d = Math.floor(minutes / (60 * 24));
    const h = Math.floor((minutes % (60 * 24)) / 60);
    const m = Math.floor(minutes % 60);
    return `${d}d, ${h}h, ${m}m`;
  }
};

export const formatDate = (
  dateString: string | number,
  includeDayName: boolean = true,
  shortMonth: boolean = false
) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };

  if (includeDayName) {
    options.weekday = 'long';
  }

  if (shortMonth) {
    options.month = 'short';
  }

  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
};

export const formatPlayerFullName = (name: string, surname: string) => {
  var fullName = `${name} ${surname}`;

  return fullName
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const calculateDateFromToday = async (duration: WagerActivityTimeFilter) => {
  const today = new Date();
  const durationMap: { [key: string]: () => Date } = {
    '24 Hours': () => new Date(today.getTime() - 24 * 60 * 60 * 1000),
    '3 Days': () => new Date(today.getTime() - 3 * 24 * 60 * 60 * 1000),
    '7 Days': () => new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000),
    '14 Days': () => new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000),
    '1 Month': () => new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()),
    '3 Months': () => new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()),
    '6 Months': () => new Date(today.getFullYear(), today.getMonth() - 6, today.getDate()),
    '1 Year': () => new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()),
    // MTD is the first day of the current month
    MTD: () => new Date(today.getFullYear(), today.getMonth(), 1),
    // YTD is the first day of the current year
    YTD: () => new Date(today.getFullYear(), 0, 1),
    All: () => new Date(0),
  };

  if (duration in durationMap) {
    const calculatedDate = durationMap[duration]();
    return calculatedDate.toISOString();
  } else {
    throw new Error('Invalid duration');
  }
};

export const financePeriodFormatter = (duration: WagerActivityTimeFilter): FinancePeriodEnum => {
  switch (duration) {
    case '24 Hours':
      return FinancePeriodEnum.Day;
    case '7 Days':
      return FinancePeriodEnum.Week;
    case '1 Month':
      return FinancePeriodEnum.Month;
    case '6 Months':
      return FinancePeriodEnum.Months;
    case '1 Year':
      return FinancePeriodEnum.Year;
    case 'MTD':
      return FinancePeriodEnum.MTD;
    case 'YTD':
      return FinancePeriodEnum.YTD;

    default:
      throw new Error('Invalid duration');
  }
};

export const generateUniqSerial = () => {
  return 'xxxx-xxxx-xxx-xxxx'.replace(/[x]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getCurrentDate = () => {
  const currentDate: Date = new Date();
  const year: number = currentDate.getFullYear();
  const month: number = currentDate.getMonth() + 1;
  const day: number = currentDate.getDate();

  const formattedMonth: string = month < 10 ? '0' + month : month.toString();
  const formattedDay: string = day < 10 ? '0' + day : day.toString();

  const formattedDate: string = `${year}-${formattedMonth}-${formattedDay}`;
  return formattedDate;
};

export const formatDateSubString = (inputDate: string): string => {
  if (inputDate) {
    const datePart: string = inputDate.substring(0, 10);
    return datePart;
  }
  return '';
};
