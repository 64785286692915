import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => {
  return {
    gameIcon: {
      height: '37px',
      width: '37px',
      [`@media (max-width: 360px)`]: { height: '32px', width: '32px' },
      [`@media (max-width: 340px)`]: { height: '28px', width: '28px' },
    },
    overBoxTextContainer: {
      width: '100%',
      backgroundImage: `${theme.other.gradientGreen}`,
      padding: '12px',
    },
    paperContainer: {
      width: '190px',
      height: '100%',
      minHeight: '281px',
      padding: '12px 10px',
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: { width: '45vw' },
      position: 'relative',
    },
  };
});

export { useStyles };
