import { Center, Divider, Group, Image, Paper, Text, Overlay, Stack, Box } from '@mantine/core';
import { FavoriteGame, Game, GameCategoryEnum, GameVolatilityEnum, MarketEnabledEnum } from '../../api/interfaces';
import SVG from 'react-inlinesvg';

import { ReactComponent as IconRightTriangle } from '../../assets/icons/icon-play-right-triangle.svg';
import { ReactComponent as IconVolatilityFast } from '../../assets/icons/icon-game-volatility-fast.svg';
import { ReactComponent as IconVolatilitySlow } from '../../assets/icons/icon-game-volatility-slow.svg';

import { ReactComponent as IconCategoryForex } from '../../assets/icons/icon-game-category-bigbills.svg';
import { ReactComponent as IconCategoryMetals } from '../../assets/icons/icon-game-category-minermadness.svg';
import { ReactComponent as IconCategoryCrypto } from '../../assets/icons/icon-game-category-nonstop.svg';
import { ReactComponent as IconCategorySocial } from '../../assets/icons/icon-game-category-social.svg';

import { ReactComponent as IconStarRating } from '../../assets/icons/icon-play-star.svg';
import { toMaxPrecision } from '../../utils/formatters';
import { FavoriteMarketSelect } from '../../components/game/marketButton';

export const GameCardMulti = (props: {
  game: Game;
  selected: number | null;
  favoriteGames: FavoriteGame[];
  setSelected: any;
}) => {
  return (
    <>
      <Group spacing={80}>
        <Box sx={{ width: '240px' }}>
          <Group sx={{ display: 'flex', alignItems: 'center' }}>
            <Image
              sx={{ height: '80px', width: '80px', display: 'flex', alignItems: 'center' }}
              src={props.game.imageGame}
              radius="md"
              styles={{ image: { boxShadow: '0px 0px 8px rgba(0, 202, 251, 0.75)' } }}
            />
            <Stack>
              <Text size="md" weight="bold" sx={{ lineHeight: 1, [`@media (max-width: 380px)`]: { fontSize: '14px' } }}>
                {props.game.name}
              </Text>
              <Group>
                <GameVolatilityIndicator game={props.game} />
                <GameCategoryIndicator game={props.game} />
                <GameRatingIndicator game={props.game} />
              </Group>
            </Stack>
          </Group>
        </Box>
        <Group>
          {props.game.markets?.map((market) => {
            const marketFound = props.favoriteGames.some((game) => game.marketId === market.id);
            if (marketFound) {
              return (
                <Box
                  sx={{
                    borderRadius: '10px',
                    width: '80px',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'linear-gradient(to bottom, rgba(0, 202, 251, 0.7) 0%, rgba(11, 94, 171, 0.7) 50%);',
                  }}
                  key={market.id}
                >
                  <Text>
                    Game <br /> Added
                  </Text>
                </Box>
              );
            } else {
              return <FavoriteMarketSelect key={market.id} market={market} game={props.game} />;
            }
          })}
        </Group>
      </Group>
      <Divider size={2} />
    </>
  );
};

const GameVolatilityIndicator = (props: { game: Game }) => {
  if (props.game.volatility === GameVolatilityEnum.Fast) {
    return (
      <Group direction="column" spacing={0} align="center">
        <Center sx={{ height: '28px' }}>
          <IconVolatilityFast style={{ color: '#FFF' }} width={32} height={20} />
        </Center>
        <Text size="xs" weight="bold" sx={{ color: props.game.color }}>
          Fast
        </Text>
      </Group>
    );
  } else if (props.game.volatility === GameVolatilityEnum.Slow) {
    return (
      <Group direction="column" spacing={0} align="center">
        <Center sx={{ height: '28px' }}>
          <IconVolatilitySlow style={{ color: '#FFF' }} width={32} height={20} />
        </Center>
        <Text size="xs" weight="bold" sx={{ color: props.game.color }}>
          Slow
        </Text>
      </Group>
    );
  }

  return null;
};

const GameCategoryIndicator = (props: { game: Game }) => {
  if (props.game.category === GameCategoryEnum.BigBills) {
    return (
      <Group direction="column" spacing={0} align="center">
        <Center sx={{ height: '28px' }}>
          <IconCategoryForex color="#FFF" width={26} height={26} />
        </Center>
        <Text size="xs" weight="bold" sx={{ color: props.game.color }}>
          Forex
        </Text>
      </Group>
    );
  } else if (props.game.category === GameCategoryEnum.MinerMadness) {
    return (
      <Group direction="column" spacing={0} align="center">
        <Center sx={{ height: '28px' }}>
          <IconCategoryMetals color="#FFF" width={26} height={26} />
        </Center>
        <Text size="xs" weight="bold" sx={{ color: props.game.color }}>
          Metal
        </Text>
      </Group>
    );
  } else if (props.game.category === GameCategoryEnum.NonStopGame) {
    return (
      <Group direction="column" spacing={0} align="center">
        <Center sx={{ height: '28px' }}>
          <IconCategoryCrypto color="#FFF" width={26} height={26} />
        </Center>
        <Text size="xs" weight="bold" sx={{ color: props.game.color }}>
          Crypto
        </Text>
      </Group>
    );
  } else if (props.game.category === GameCategoryEnum.SocialFrenzy) {
    //not used in initial launch
    return (
      <Group direction="column" spacing={0} align="center">
        <Center sx={{ height: '28px' }}>
          <IconCategorySocial color="#FFF" width={26} height={26} />
        </Center>
        <Text size="xs" weight="bold" sx={{ color: props.game.color }}>
          Social
        </Text>
      </Group>
    );
  }

  return null;
};

const GameRatingIndicator = (props: { game: Game }) => {
  return (
    <Group direction="column" spacing={0} align="center">
      <Center sx={{ height: '28px' }}>
        <IconStarRating color="#FFF" width={26} height={26} />
      </Center>
      <Text size="xs" weight="bold" sx={{ color: props.game.color }}>
        {toMaxPrecision(props.game.rating, 1)}
      </Text>
    </Group>
  );
};
