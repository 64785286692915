import React, { createContext, useContext, useState, ReactNode } from 'react';
import { BatchRenderer } from './BatchRenderer';

interface BatchRenderingContextProps {
  children: ReactNode;
}

interface BatchRenderingContextValue {
  componentsToRender: (() => void)[];
  registerComponentForBatchRendering: (component: () => void) => void;
  unregisterComponentForBatchRendering: (component: () => void) => void;
}

const BatchRenderingContext = createContext<BatchRenderingContextValue | undefined>(undefined);

export const BatchRenderingProvider = ({ children }: BatchRenderingContextProps) => {
  const [componentsToRender, setComponentsToRender] = useState<(() => void)[]>([]);

  const registerComponentForBatchRendering = (component: () => void) => {
    setComponentsToRender((prev) => [...prev, component]);
  };

  const unregisterComponentForBatchRendering = (component: () => void) => {
    setComponentsToRender((prev) => prev.filter((c) => c !== component));
  };

  const value: BatchRenderingContextValue = {
    componentsToRender,
    registerComponentForBatchRendering,
    unregisterComponentForBatchRendering,
  };

  return (
    <BatchRenderingContext.Provider value={value}>
      {children} <BatchRenderer />
    </BatchRenderingContext.Provider>
  );
};

export const useBatchRendering = () => {
  const context = useContext(BatchRenderingContext);
  if (context === undefined) {
    throw new Error('useBatchRendering must be used within a BatchRenderingProvider');
  }
  return context;
};
