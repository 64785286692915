import { Anchor, createStyles, Group, Image, Text, useMantineTheme, Header, Stack } from '@mantine/core';
import logo from '../../assets/fxbet-logo.svg';
import { ReactComponent as IconDepositFunds } from '../../assets/icons/icon-deposit-funds.svg';
import { ReactComponent as IconEuro } from '../../assets/icons/icon-currency-euro.svg';
import { Link, useLocation } from 'react-router-dom';
import { formatCurrency } from '../../utils/formatters';
import { useStore } from '../../state/state';

import { ReactComponent as IconProfileBlue } from '../../assets/icons/icon-profile-blue.svg';

import { useStyles } from './styles';
import { useMediaQuery, useWindowScroll } from '@mantine/hooks';
import { AccountMenu } from './AccountMenu';

const CustomHeader = () => {
  const headerMode = useStore((state) => state.headerMode);
  const { classes } = useStyles();
  const mobileView = useMediaQuery(`(max-width: ${useMantineTheme().breakpoints.sm}px)`);
  const auth = useStore((state) => state.auth);
  const [scroll] = useWindowScroll();
  const location = useLocation();
  const signupUrl = `/signup${location.search}`;

  if (auth.isAuthenticated && headerMode === 'play') {
    return (
      <Header height={60} className={classes.header} sx={{ zIndex: 300 }}>
        <Stack justify="center" sx={{ height: '100%' }}>
          <Group position="apart" sx={{ padding: '6px 8px' }}>
            {mobileView && (
              <>
                <Link to="/play">
                  <Image width={44} src={logo} />
                </Link>
                <UserBalance mobileView={mobileView} />
                <DepositOrStopLoss />
              </>
            )}
            {!mobileView && (
              <>
                <Link to="/play">
                  <Image width={102} src={logo} />
                </Link>
                <Group>
                  <UserBalance mobileView={mobileView} />
                  <DepositOrStopLoss />
                  <AccountMenu />
                </Group>
              </>
            )}
          </Group>
        </Stack>
      </Header>
    );
  } else {
    return (
      <Header height={60} className={scroll.y > 1 ? classes.header : classes.headerNoAuth} sx={{ zIndex: 300 }}>
        <Group position="apart" sx={{ padding: '6px 8px' }}>
          <Link to="/">
            <Image width={102} src={logo} />
          </Link>
          {!auth.isAuthenticated && (
            <Group spacing={42} align="flex-end">
              <Anchor component={Link} to={signupUrl} weight="bold" sx={(theme) => ({ color: theme.other.green })}>
                Sign Up
              </Anchor>
              <Anchor component={Link} to="/login" weight="bold" sx={(theme) => ({ color: theme.other.blue })}>
                Login <IconProfileBlue height={28} />
              </Anchor>
            </Group>
          )}
        </Group>
      </Header>
    );
  }
};

const UserBalance = ({ mobileView }: { mobileView: boolean }) => {
  const balance = useStore((state) => state.balance);
  const theme = useMantineTheme();
  const userRole = useStore((state) => state.auth.user?.role);

  if (!balance || userRole == 'Sales' || userRole == 'SuperAdmin') return null;

  return (
    <>
      <Group align="baseline" spacing={8}>
        {!mobileView && (
          <Text size="xs" mr={4} sx={{ alignSelf: 'center' }}>
            Account
            <br />
            balance
          </Text>
        )}
        <IconEuro style={{ height: '20px', color: theme.white }} />
        <Text
          weight="bold"
          sx={{
            fontSize: '30px',
            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
              fontSize: '24px',
            },
          }}
        >
          {formatCurrency(balance.balance)}
        </Text>
      </Group>
    </>
  );
};

const useDepositStyles = createStyles((theme) => ({
  icon: {
    height: '27px',
    width: 'auto',
    color: theme.white,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      height: '24px',
    },
  },
}));

const DepositOrStopLoss = () => {
  const balance = useStore((state) => state.balance);
  const userRole = useStore((state) => state.auth.user?.role);
  const { classes } = useDepositStyles();

  if (!balance || userRole === 'Sales' || userRole === 'SuperAdmin') return null;

  return (
    <div>
      {balance.stopLoss ? (
        <button>
          <div>Stop Loss</div>
          <IconDepositFunds className={classes.icon} />
        </button>
      ) : (
        <Anchor component={Link} to="/wallet/deposit">
          <Group spacing={8}>
            <Text size="xs" align="right">
              Deposit
              <br />
              Funds
            </Text>
            <IconDepositFunds className={classes.icon} />
          </Group>
        </Anchor>
      )}
    </div>
  );
};

export { CustomHeader };
