import { GameVolatilityEnum } from '../../api/interfaces';
import { Button, createStyles } from '@mantine/core';

import { ReactComponent as IconFast } from '../../assets/icons/icon-game-volatility-fast.svg';
import { ReactComponent as IconSlow } from '../../assets/icons/icon-game-volatility-slow.svg';

const useStyles = createStyles((theme, params: { selected: boolean }) => ({
  volatilityButton: {
    width: '190px',
    height: '50px',
    fontSize: '12px',
    border: 'none',
    padding: '0 20px 0 16px',
    background: params.selected ? theme.other.gradientPurple : theme.other.gradientBlue,
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: { height: '40px', width: 'auto', flexGrow: 1 },
    [`@media (max-width: 390px)`]: { fontSize: '10px' },
    [`@media (max-width: 340px)`]: { fontSize: '9px' },
  },
  svg: {
    width: '38px',
    height: '23px',
    color: '#FFF',
    [`@media (max-width: 460px)`]: { width: '31px', flexGrow: 1 },
    [`@media (max-width: 390px)`]: { width: '24px', flexGrow: 1 },
  }
}));

export const VolatilityButton = (props: { volatility: GameVolatilityEnum; selected: boolean; onClick: () => void }) => {
  const { classes } = useStyles({ selected: props.selected });

  switch (props.volatility) {
    case GameVolatilityEnum.Fast:
      return (
        <Button
          className={classes.volatilityButton}
          radius="lg"
          leftIcon={<IconFast className={classes.svg} />}
          onClick={props.onClick}
          styles={{ inner: { justifyContent: 'space-between' }, label: { fontWeight: 'normal', lineHeight: 1.3 } }}
        >
          FAST &amp; FUN
          <br />
          Agile gameplay
        </Button>
      );
    case GameVolatilityEnum.Slow:
      return (
        <Button
          className={classes.volatilityButton}
          radius="lg"
          leftIcon={<IconSlow className={classes.svg} />}
          onClick={props.onClick}
          styles={{ inner: { justifyContent: 'space-between' }, label: { fontWeight: 'normal', lineHeight: 1.3 } }}
        >
          SLOW &amp; SERIOUS
          <br />
          Steady gameplay
        </Button>
      );
  }
};
