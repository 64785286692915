import { HubConnectionBuilder } from '@microsoft/signalr';
import { host } from '../host';
import { findMarket, useMarketStore, useStore } from '../state/state';
import {
  Currency,
  DurationUnitEnum,
  Game,
  MarketEnabledEnum,
  MarketOpenEnum,
  Player,
  PlayerUpdatedReasons,
  Tick,
  WagerStateEnum,
  WagerTypeEnum,
} from './interfaces';
import { WagerCompleteNotification } from '../utils/wagerCompleteNotification';

export class PlayerHub {
  private static instance: PlayerHub;
  private constructor() {
    const auth = useStore.getState().auth;

    if (auth.isAuthenticated && auth.token?.accessToken) {
      const connection = new HubConnectionBuilder()
        .withUrl(`${host}/playerhub`, {
          accessTokenFactory: () => useStore.getState().auth.token?.accessToken ?? '', //we retrieve state fresh in the accessTokenFactory in case we've rotated tokens
          withCredentials: false,
        })
        .withAutomaticReconnect()
        .build();

      connection.on('initGames', (games: Game[]) => {
        useStore.getState().setGames(
          games.map((x) => ({
            ...x,
            iconAction: `${host}${x.iconAction}`,
            iconGame: `${host}${x.iconGame}`,
            imageBackground: `${host}${x.imageBackground}`,
            imageGame: `${host}${x.imageGame}`,
          }))
        );
      });

      connection.on('initMarketState', (marketId: number, history: Tick[]) => {
        console.log(`initMarketState`);
        const state = useStore.getState();
        const market = findMarket(state.games, marketId);

        if (!market) return;
        const setTicks = useMarketStore(market).getState().setTicks;

        console.log(`${market.currencyPair} init with ${history.length} ticks`);
        const now = new Date().getTime();
        if (history.length > 0) {
          history[history.length - 1].localTimestamp = now;
        }
        for (let i = history.length - 2; i >= 0; i--) {
          history[i].localTimestamp = history[i + 1].localTimestamp - (history[i + 1].timestamp - history[i].timestamp);
        }

        setTicks(history);
      });

      connection.on('updateTickState', (marketId: number, tick: Tick) => {
        const state = useStore.getState();
        const market = findMarket(state.games, marketId);
        if (!market) return;
        const addTick = useMarketStore(market).getState().addTick;

        tick.localTimestamp = new Date().getTime();
        addTick(tick);
      });

      connection.on('updateTrendState', (marketId: number, tick: Tick) => {
        const state = useStore.getState();
        const market = findMarket(state.games, marketId);
        if (!market) return;
        const setTrendTick = useMarketStore(market).getState().setTrendTick;

        tick.localTimestamp = new Date().getTime();
        setTrendTick(tick);
      });

      connection.on('updateMarketState', (marketId: number, enabled: MarketEnabledEnum, open: MarketOpenEnum) => {
        const state = useStore.getState();
        state.updateMarket(marketId, (market) => {
          market.enabled = enabled;
          market.open = open;
        });
      });

      connection.on('updateMarketOdds', (marketId: number, forOdds: number, againstOdds: number) => {
        const state = useStore.getState();
        state.updateMarket(marketId, (market) => {
          market.forOdds = forOdds;
          market.againstOdds = againstOdds;
        });
      });

      connection.on('updatePlayerBalance', (balance: number, stopLoss: number) => {
        useStore.getState().updateBalance((current) => {
          current.balance = balance;
          current.stopLoss = stopLoss;
          current.currency = 'EUR';
        });
      });

      connection.on(
        'updateWagerState',
        (
          marketId: number,
          wagerState: WagerStateEnum,
          openingValue: number,
          closingValue: number | null,
          currency: string,
          staked: number,
          won: number | null
        ) => {
          const state = useStore.getState();
          const market = findMarket(state.games, marketId);
          const game = state.games.find((game) => {
            let flag = false;
            game.markets.forEach((m) => {
              if (market && m.id === market.id) {
                flag = true;
              }
            });
            return flag;
          });
          if (!market) return;
          const updateWager = useMarketStore(market).getState().updateWager;

          updateWager((wager) => {
            wager.state = wagerState;
            wager.openingValue = openingValue;
            wager.closingValue = closingValue ?? undefined;
            wager.won = won ?? undefined;
          });
          if (game) {
            WagerCompleteNotification(game, market, wagerState);
          }
        }
      );

      connection.on(
        'updateWagerPlaced',
        (
          marketId: number,
          type: WagerTypeEnum,
          durationUnit: DurationUnitEnum,
          durationValue: number,
          openingValue: number,
          openingTimestamp: number,
          currency: Currency,
          stake: number
        ) => {
          const state = useStore.getState();
          const market = findMarket(state.games, marketId);
          if (!market) return;
          const setWager = useMarketStore(market).getState().setWager;
          setWager({
            state: WagerStateEnum.Active,
            type: type,
            duration: {
              unit: durationUnit,
              value: durationValue,
            },
            openingValue: openingValue,
            openingTimestamp: openingTimestamp,
            currency: currency,
            staked: stake,
            marketId: marketId,
          });
        }
      );

      connection.on('updatePlayer', (player: Player, reason: PlayerUpdatedReasons) => {
        const state = useStore.getState();
        state.setPlayer(player);
      });

      useStore.getState().setHub(connection);

      connection.start();
    }
  }

  public static getInstance(): PlayerHub {
    if (!PlayerHub.instance) {
      PlayerHub.instance = new PlayerHub();
    }

    return PlayerHub.instance;
  }
}
