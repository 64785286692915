export interface ApiResponse<T, U extends string | number> {
  success: boolean;
  result: T;
  error: U;
}

export enum EmptyErrorEnum {
  UserBanned = 'UserBanned',
}

export enum GameCategoryEnum {
  NonStopGame = 'NonStopGame',
  BigBills = 'BigBills',
  MinerMadness = 'MinerMadness',
  SocialFrenzy = 'SocialFrenzy',
}

export enum GameVolatilityEnum {
  Fast = 'Fast',
  Slow = 'Slow',
}

export enum GameThemeEnum {
  Light = 'Light',
  Dark = 'Dark',
}

export enum MarketEnabledEnum {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}

export enum MarketOpenEnum {
  Open = 'Open',
  Closed = 'Closed',
  Full = 'Full',
}

export enum DurationUnitEnum {
  Ticks = 'Ticks',
  Minutes = 'Minutes',
}

export enum WagerTypeEnum {
  Rise = 'Rise',
  Fall = 'Fall',
}

export enum WagerStateEnum {
  Pending = 'Pending', //pending is a fake value, set when placing a wager whilst awaiting confirmation from backend
  Active = 'Active',
  Won = 'Won',
  Lost = 'Lost',
  Draw = 'Draw',
  Cancelled = 'Cancelled',
}

export enum FinancePeriodEnum {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Months = 'Months',
  Year = 'Year',
  MTD = 'MTD',
  YTD = 'YTD',
}

export interface FinancePeriodData {
  winnings: number;
  deposits: number;
  withdrawals: number;
  losses: number;
  spend: number;
  profit: number;
}

export interface FinancePeriod {
  period: FinancePeriodData;
  periodBefore: FinancePeriodData;
}

export interface Game {
  id: number;
  name: string;
  description: string;
  category: GameCategoryEnum;
  volatility: GameVolatilityEnum;
  theme: GameThemeEnum;
  rating: number;
  color: string;
  gradient: string;
  imageGame: string;
  imageBackground: string;
  iconGame: string;
  iconAction: string;
  maxActiveBets: number;
  markets: Market[];
}

export interface EditableGame extends Game {
  markets: EditableMarket[];
  isEditable: boolean;
}

export interface UserTransactions {
  id: number;
  userName: string;
  userId: string;
  userIban: string;
  currency: Currency;
  amount: number;
  type: string;
  initiatedAt: Date;
  processedAt: Date;
  completedAt: Date;
  status: string;
}

export interface GameState {
  durationUnit: DurationUnitEnum;
  durationValue: number;
  wagerType: WagerTypeEnum;
  stake: number;
}

export interface Market {
  id: number;
  currencyPair: CurrencyPair;
  wagerCurrency: Currency;
  minWager: number;
  maxWager: number;
  maxActiveBets: number;
  forOdds: number;
  againstOdds: number;
  durations: Duration[];
  enabled: MarketEnabledEnum;
  open: MarketOpenEnum;
}

export interface EditableMarket extends Market {
  isEditable: boolean;
}

export interface Duration {
  unit: DurationUnitEnum;
  value: number;
}

export interface Tick {
  timestamp: number;
  localTimestamp: number;
  value: number;
}

export interface Wager {
  marketId: number;
  duration: Duration;
  type: WagerTypeEnum;
  currency: Currency;
  staked: number;

  state: WagerStateEnum;
  openingTimestamp?: number;
  closingTimestamp?: number;

  openingValue?: number;
  closingValue?: number;
  won?: number;
}

export interface WagerHistory extends Wager {
  currencyPair: string;
  game: Game;
  duration: Duration;
  completedAt: string;
  placedAt: string;
  userFirstName: string;
  userLastName: string;
  userId: string;
}

export interface LiveWager extends WagerHistory {
  wagerId: number;
  userId: string;
  gameCategory: GameCategoryEnum;
  gameName: string;
}
export interface Player {
  firstName: string;
  lastName: string;
  email: string;
  birthDate: string;
  registeredDate: string;
  gender: 'Undisclosed' | 'Male' | 'Female';
  language: string;
  country: string;
  isVerified: boolean;
  iban: string;
  currency: string;
  phoneNumber: string;
  selfExcludedUntil?: number; //unix timestamp milliseconds
  selfExclusionCause: 'None' | 'Losses' | 'Wagers' | 'SessionDuration';
  depositSelfExcludedUntil?: number; //unix timestamp milliseconds
  quickStats: QuickStats;

  limits: Limits;
  role: string;
}

export interface QuickStats {
  totalPlayed: number;
  wins: number;
  losses: number;
  draws: number;
  favoriteGame: string;
}
export interface Limits {
  selfExclusionPeriodHours: number;

  depositLimitDaily?: number;
  depositLimitWeekly?: number;
  depositLimitMonthly?: number;

  lossLimitDaily?: number;
  lossLimitWeekly?: number;
  lossLimitMonthly?: number;

  wagerLimit?: number;
  wagerLimitDaily?: number;
  wagerLimitWeekly?: number;
  wagerLimitMonthly?: number;

  sessionLimitHoursDaily?: number;
  sessionLimitHoursWeekly?: number;
  sessionLimitDaysMonthly?: number;
}

export interface PlayTime {
  lastDeposit: Date;
  minutesLast24Hours: number;
  minutesLast7Days: number;
  minutesLast30Days: number;
  minutesTotal: number;
  lastActive?: Date;
  firstSession?: Date;
}

export interface AccountInsight {
  insightPeriod: 'Day' | 'Week' | 'Month' | 'Months' | 'Year' | 'MTD' | 'YTD' | 'Ever';
  insightsData: InsightsData;
}

export interface InsightsData {
  totalActive: number;
  totalNew: number;
  totalDeactivated: number;
  incomplete: number;
  totalVerified: number;
  totalFlagged: number;
  totalFrozen: number;
  totalIdle: number;
  noDeposit: number;
  withResponsibleGaming: number;
  modeCountry: string;
  modeGender: string;
  modeAge: number;
}

export interface BalanceSummary {
  deposits: number;
  wins: number;
  losses: number;
  withdrawals: number;
}

export interface InitiatePagoraTopupResponse {
  url: string;
}

export interface MergedDataItem {
  transactionDate: string;
  items: HistoryActivity[];
}

export interface MapHisotryDataItem {
  transactionDate: string;
  items: WagerHistory[];
}

export interface MapGameDataItem {
  gameName: string;
  gameColor: string;
  market: MarketStatistics[];
}

export interface HistoryActivity {
  userId?: string;
  firstName?: string;
  lastName?: string;
  type:
    | 'Wager'
    | 'WithdrawCompleted'
    | 'WithdrawPending'
    | 'WithdrawProcessing'
    | 'WithdrawFailed'
    | 'Deposit'
    | 'Wins'
    | 'Losses'
    | 'Refund';
  currency: Currency;
  adjustment: number;
  label: string;
  gameIcon?: string;
  timestamp: string;
  currencyPair?: string;
  wagerType?: string;
}

export type Currency =
  | 'AUD'
  | 'BTC'
  | 'CNY'
  | 'ETH'
  | 'EUR'
  | 'GBP'
  | 'HKD'
  | 'JPY'
  | 'USD'
  | 'XAG'
  | 'XAU'
  | 'XPT'
  | 'XRP';

export type CurrencyPair = `${Currency}/${Currency}`;

export type PlayerUpdatedReasons =
  | 'PlayerUpdated'
  | 'PlayerLimitsUpdated'
  | 'PlayerSelfExcluded'
  | 'PlayerDepositSelfExcluded';

export type Gender = 'Undisclosed' | 'Male' | 'Female';

export interface PlayerOverview {
  playerActivity: {
    threeDays: number;
    sevenDays: number;
    fourteenDays: number;
    twentyOneDays: number;
    oneMonth: number;
    twoMonths: number;
    threeMonths: number;
    sixMonths: number;
    oneYear: number;
  };
  closedAccounts: {
    sevenDays: number;
    fourteenDays: number;
  };
  newSignUps: {
    threeDays: number;
    sevenDays: number;
    fourteenDays: number;
    twentyOneDays: number;
    oneMonth: number;
    twoMonths: number;
    threeMonths: number;
    sixMonths: number;
    oneYear: number;
  };
  dropInSignUps: {
    sevenDays: number;
    fourteenDays: number;
  };
}

export enum PlayerSearchFilterEnum {
  Name = 'Name',
  Deposited = 'Deposited',
  Spent = 'Spent',
  Won = 'Won',
  Lost = 'Lost',
  Withdrawn = 'Withdrawn',
  FlaggedAndFrozen = 'FlaggedAndFrozen',
  Closed = 'Closed',
  Banned = 'Banned',
  LastOnline = 'LastOnline',
}

export interface PlayerSearchParams {
  duration?: Date;
  searchTerm?: string;
  filter?: PlayerSearchFilterEnum;
  isDesc?: boolean;
}

export type ReportFilter =
  | 'All'
  | 'New'
  | 'Active'
  | 'Incomplete'
  | 'NoDeposit'
  | 'Flagged'
  | 'Frozen'
  | 'Idle'
  | 'Deactivated';

export type ReportProfitRange = 'All' | 'profit' | 'loss' | 'OverGrandProfit' | 'OverGrandLoss';

export interface AccountReportParams {
  duration?: Date;
  profit?: ReportProfitRange;
  filter?: ReportFilter;
  countryCode?: string;
  isDesc?: boolean;
}

export interface PlayerAccount {
  id: string;
  firstName: string;
  lastName: string;
  lastOnline: number;
  email: string;
  country: Country;
  currency: Currency;
  deposit: number;
  spent: number;
  won: number;
  lost: number;
  profit: number;
  isVerified: boolean;
  withdraw: number;
  isFlagged: boolean;
  isFrozen: boolean;
  isBanned: boolean;
  isClosed: boolean;
  winCount: number;
  lostCount: number;
  wagerCount: number;
  playtime: PlayTime;
  registeredDate: Date;
  role: string;
}

export interface PlayerAccountOverview {
  id: string;
  firstName: string;
  lastName: string;
  lastLogin: string;
  isIdle: boolean;
  email: string;
  emailConfirmed: boolean;
  country: string;
  deposit: number;
  depositCount: number;
  won: number;
  lost: number;
  profit: number;
  phoneNumber: string;
  isVerified: boolean;
  isFlagged: boolean;
  isBanned: boolean;
  isClosed: boolean;
  wagerCount: number;
  registeredDate: string;
  age: number;
  playtime: PlayTime;
  quickStats: QuickStats;
}

export type WagerActivityTimeFilter =
  | '24 Hours'
  | '3 Days'
  | '7 Days'
  | '14 Days'
  | '1 Month'
  | '3 Months'
  | '6 Months'
  | '1 Year'
  | 'MTD'
  | 'YTD'
  | 'All';

export type WagerActivityGameFilter = 'All Games' | '24/7 Games' | 'Big Bills' | 'Miners Madness' | 'Social Frenzy';

export interface BettingHistory {
  date: string;
  data: BettingHistoryData[];
}

export interface BettingHistoryData {
  name: string;
  time: string;
  category: string;
  gameName: string;
  currency: string;
  ticks: string;
  bet: string;
  betAmount: string;
  playerWinStatus: string;
}

export interface MarketStatistics {
  category: WagerActivityGameFilter;
  color: string;
  currency: Currency;
  currencyPair: Currency;
  gameName: string;
  lastWager: Date;
  marketId: number;
  fxBetProfit: number;
  totalBets: number;
  totalBetsCount: number;
  totalDraws: number;
  totalDrawsCount: number;
  totalLosses: number;
  totalLossesCount: number;
  totalWins: number;
  totalWinsCount: number;
  FXBetProfit: number;
}

export interface MarketStatisticsCounts {
  totalBetsCount: number;
  totalBets: number;
  totalWins: number;
  totalWinsCount: number;
  totalLosses: number;
  totalLossesCount: number;
}

export interface PlayerData {
  id: string;
  playtime: PlayTime;
  wagerCount: number;
  won: number;
  profit: number;
  winCount: number;
  lost: number;
  lostCount: number;
  drawCount: number;
  deposit: number;
  depositCount: number;
  lastDeposit: number;
  spent: number;
  withdraw: number;
  balance: number;
  email: string;
  firstName: string;
  lastName: string;
  country: string;
  city: string;
  birthDate: string;
  gender: string;
  language: string;
  selfExclusionCause: string;
  isVerified: boolean;
  isFlagged: boolean;
  isFrozen: boolean;
  isBanned: boolean;
  isClosed: boolean;
  streetAddress: string;
  countryCode: string;
  phoneNumber: string;
  profilePictureKey: string;
  currency: Currency;
  pendingWithdrawals: UserTransactions[];
  registeredDate: string;
  limits: Limits;
  role: string;
  userNotes: UserNote[];
}

export interface UserNote {
  id?: number;
  note: string;
  createdDate: string;
}
export interface WeekCount {
  week: number | string;
  count: number;
}

export interface Countries {
  code: string;
  name: string;
  playersAllowed: boolean;
  minAge: number;
}

export interface PlayeStat {
  activeAccounts: number;
  interactivePlayers: number;
  newSignUps: number;
  incompleteSignUps: number;
  deactivated: number;
}

export interface IPlayerStats {
  period: PlayeStat;
  periodBefore: PlayeStat;
}

export interface ModalHandler {
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export interface DateRangeRequest {
  from: Date;
  to: Date;
}

export interface PolicyChange {
  id: number;
  policyName: string;
  policyLink: string;
  dateUpdated: Date;
}

export interface Multiview {
  multiview: boolean | undefined;
  layout: string | undefined;
}

export interface FavoriteGame {
  marketId: number;
  game: string;
  currencyPair: CurrencyPair;
}

export interface Country {
  code: string;
  minAge: number;
  name: string;
  playersAllowed: boolean;
}

export interface UserMin {
  country?: Country;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
}

export interface PlayerLink {
  player: UserMin;
  salesAdmin?: UserMin;
}

export interface UserSalesManagementFilter {
  filter: 'All' | 'Unallocated' | 'Allocated' | 'Admin';
  adminId?: string;
  page: number;
  pageSize: number;
  countryCode?: string;
}

export interface PaginatedData {
  data: any[];
  totalPages: number;
}

export interface AssignSalesAdmin {
  AdminId: string;
  UserId: string;
}

export interface GenerateReferralRequest {
  SalesUserId: string;
}

export interface SalesAdmins {
  id: string;
  referralCode: string;
  firstName: string;
  lastName: string;
}

export interface referralCode {
  referralCode: string;
  salesUser: PlayerData;
}
//TODO: Clean up on backend and align with frontend
export interface referralCodeParent {
  referralCode: referralCode;
}

export interface UserNotes {
  userId: string;
  note: string;
}
