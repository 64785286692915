import { Tooltip, Text } from '@mantine/core';

const ToolTip = ({ text, size }: { text: string; size?: number }) => {
  return (
    <Tooltip label={text} withArrow>
      <Text
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: `#1971c2`,
          borderRadius: '50%',
          height: `${size ? size + 'px' : '24px'}`,
          width: `${size ? size + 'px' : '24px'}`,
        }}
      >
        &#8505;
      </Text>
    </Tooltip>
  );
};

export default ToolTip;
