import { Button, ButtonProps } from '@mantine/core';

export type PurpleButtonProps = Omit<ButtonProps<'button'>, 'size' | 'radius'>;

export const PurpleButton = (props: PurpleButtonProps) => {
  return (
    <Button
      {...props}
      size="lg"
      radius="lg"
      styles={(theme) => {
        let styles = props.styles ?? {};
        if (typeof styles === 'function') {
          styles = styles(theme);
        }
        return {
          ...styles,
          root: {
            height: '50px',
            width: '150px',
            border: 'none',
            background: theme.other.gradientPurple,
            padding: '0 14px',
            ...(styles.root ?? {}),
          },
        };
      }}
    >
      {props.children}
    </Button>
  );
};
