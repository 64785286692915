import { Text, Container, List, Anchor, Title, useMantineTheme, Stack } from '@mantine/core';
import { useStyles } from '../legalPages/styles';
import UnstyledMailtoLink from '../../components/mailToLink/unstyledMailToLink';
import Glossary from './privacyGlossary';

const PrivacyPolicy = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <Container py="50px">
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title id="privacy_policy" order={1} my={theme.spacing.lg}>
          FXBet Privacy Policy
        </Title>
        <Glossary />
      </Stack>
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title order={2} mt={theme.spacing.xl}>
          Privacy Policy
        </Title>
        <Text component="p">
          We, at FxBet (the “Company”, “we” or “us”), respect your privacy and are committed to protect the privacy of
          our users (“user” or “you”). The Controller is FxBet N.V., Landhuis Groot Kwartier, Groot Kwartierweg 12,
          Willemstad, Curaçao. Contact can be made by email to{' '}
          <UnstyledMailtoLink email="privacy@fxbet.co" subject="Hello" body="I'm reaching out to inquire about..." />.
        </Text>
        <Text component="p">
          We have created this Privacy Policy to inform you about how we manage, collect and use your information
          through your use of our websites, which provide information with regards to betting games and the gambling
          industry (collectively: the “Services”).
        </Text>
        <Text component="p">
          We encourage you to read the Privacy Policy carefully and use it to make informed decisions. You have the
          right to complain to your applicable data protection authority, but please contact us in the first instance if
          you have any concerns. In this Privacy Policy you will read about:
        </Text>
      </Stack>
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title id="info_type" order={2} mt={theme.spacing.xl}>
          What Type of Information We Collect
        </Title>
        <Text component="p">
          The first type of information is un-identified and non-identifiable information pertaining to a user(s), which
          may be made available or gathered via your use of the Services and interaction with the website (“Non-personal
          Information”). We are not able to identify the user from the Non-personal Information that is collected.
        </Text>
        <Text component="p">
          In order to enhance the functionality of the Services and to provide you with a better user experience, we
          collect technical information transmitted by your computer (through the use of third party analytical
          cookies), including certain software and hardware information (e.g. the type of browser and operating system
          your device uses, language preference, access time and the domain name of the website from which you linked to
          the Services; etc.).
        </Text>
        <Text component="p">
          We also collect information about your use of the Services, such as user activity (e.g. pages viewed, the
          amount of time spent on particular pages, online browsing, clicks, actions, etc.). This information is
          collected, amongst other things, for research and analytics purposes about your use of the Services.
        </Text>
        <Text component="p">
          We aggregate, anonymize or de-identify the information collected by the Services or via other means so that
          the information cannot, on its own, personally identify you. Our use and disclosure of such aggregated or
          de-identified information is not subject to any restrictions under this Privacy Policy, and we may disclose it
          to others without limitation and for any purpose.
        </Text>
        <Text component="p">
          The second type of information is individually identifiable information, namely information that identifies an
          individual or may with reasonable effort identify an individual (“Personal Information”). This may be
          collected when you subscribe for our email newsletters by providing us with your email address on the website
          or when you post a comment including your name and email address in response to one of our news pieces.
        </Text>
        <Text component="p">
          We may also collect some of your Personal Information if you use our “Live Chat” feature on the website,
          depending on the level of information you provide and the nature of your query. We may also collect
          information such as your name, email address and interests/preferences if you register on our membership page
          and submit your preferences.
        </Text>
        <Text component="p">
          We may also collect specific types of connection details and information with regard to your device and
          hardware, such as IP addresses.
        </Text>
        <Text component="p">
          If we combine Personal Information with Non-personal Information, the combined information will be treated as
          Personal Information for as long as it remains combined.
        </Text>
      </Stack>
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title id="cookies" order={2} mt={theme.spacing.xl}>
          Cookies
        </Title>
        <Text component="p">
          We use cookies and other technologies or methods of web analysis to gather, store, and track certain
          information related to your visit to and activity through the Services.
        </Text>
        <Text component="p">
          A cookie is a piece of data stored on your computer containing information about your access to the website.
          We and our trusted partners may use such cookies for various different purposes. These purposes include
          allowing you to navigate between pages efficiently, enable automatic activation of certain features,
          remembering your preferences and making the interaction between you and our Services quicker and easier.
        </Text>
        <Text component="p">
          Our website uses different types of cookies: (i) 'session cookies' which are stored only temporarily during a
          browsing session in order to allow normal use of the system and are deleted from your device when the browser
          is closed; (ii) persistent cookies' which are read only by our website, saved on your computer for a fixed
          period and are not deleted when the browser is closed. Such cookies are used where we need to know who you are
          for repeat visits; (iii) 'third party cookies' which are set by other websites, for example by third party
          analytics companies who monitor and analyze your access to, and interaction with, our website.
        </Text>
        <Text component="p">
          You may remove the cookies by following the instructions of your web browser; however, if you choose to
          disable cookies, some features of our Service may not operate properly and your online experience may be
          limited. For additional information about cookies and how to disable cookies, we recommend the following
          website: www.allaboutcookies.org
        </Text>
        <Text component="p">
          In addition, our website may run widgets, beacons and other technologies which collect mainly Non-personal
          Information connected to your browsing tendencies. We use such technologies for routine operation, including
          for the collection of statistical data about your use of our Service and in order to adapt the Service to your
          personal preferences. The information collected through such tracking technologies by third parties on our
          behalf, help to provide technical diagnostics and analytic tools.
        </Text>
      </Stack>
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title id="collected_info_use" order={2} mt={theme.spacing.xl}>
          How We Use the Collected Information
        </Title>
        <Text component="p">
          We will only use your personal data when the law allows us to. Most commonly, we will use your personal data
          in the following circumstances:
        </Text>
        <Text component="ul">
          <Text component="li">
            Where it is necessary for our legitimate interests (or those of a third party) and your interests and
            fundamental rights do not override those interests.
          </Text>
          <Text component="li">
            Where you have given us your specific consent for processing your personal data as a legal basis. We will
            always obtain your consent before sending third party direct marketing communications to you via email. You
            have the right to withdraw consent to marketing at any time by contacting us.
          </Text>
        </Text>
        <Text component="p">
          We use Personal Information in the manners described in this Privacy Policy. In addition to the purposes
          referred to above, the information we collect, which may include your Personal Information, is used for the
          following legitimate interests and purposes (without overriding your interests and fundamental rights):
        </Text>
        <Text component="ul">
          <Text component="li">
            To provide our Services (e.g. enable you to share and post User Content; follow other users&apos; shared
            content; etc.);
          </Text>
          <Text component="li">
            To communicate with you and respond to you if you have a query relating to any of the information on the
            site and to keep you informed of our latest updates and email newsletters;
          </Text>
          <Text component="li">To support and troubleshoot our Services;</Text>
          <Text component="li">To develop our business and inform our business strategy;</Text>
          <Text component="li">
            Provide you with information relating to your membership within our membership area.
          </Text>
        </Text>
        <Text component="p">
          The type of personal information collected and the purposes listed above are necessary for our legitimate
          interests (to develop our products and services, grow our business, study how visitors use our services and
          inform our marketing strategy).
        </Text>
        <Text component="p">We use Non-personal Information for analytical purposes:</Text>
        <Text component="ul">
          <Text component="li">
            To conduct anonymous analytics in order to improve and customise our Services to your needs and interests;
          </Text>
          <Text component="li">To test changes to the Services and make improvements to their functionality.</Text>
        </Text>
      </Stack>
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title id="personal_info" order={2} mt={theme.spacing.xl}>
          Sharing of Personal Information
        </Title>
        <Text component="p">
          We do not trade, sell, or otherwise share your Personal Information with third-parties except as set out in
          this Privacy Policy. In addition to the different uses listed above, we may transfer or disclose Personal
          Information to other third parties as set out below:
        </Text>
        <List>
          <Text component="li">Companies in the same group as the Controller.</Text>
          <Text component="li">
            Third-party companies referred to above for analytics purposes and website performance. This data is
            non-identifiable personal data and anonymised in relation to IP addresses for visitors in the European
            Economic Area (“EEA”). External third parties may also include providers of marketing services to enable us
            to communicate with you by email (such as sending out our monthly newsletters).
          </Text>
          <Text component="li">
            Third parties in relation to the acquisition, sale or merger of the company, business or its assets in
            respect to prospective purchasers and/or vendors or on a business re-organisation.
          </Text>
          <Text component="li">Other third party marketing service providers that support website performance</Text>
        </List>
        <Text component="p">
          We ensure that all third parties have measures in place to protect the security of your Personal Information
          and process it in accordance with the law. We do not allow our third-party service providers to use your
          Personal Information for their own purposes and only allow them to process your Personal Information for
          specific purposes and in accordance with our instructions.
        </Text>
        <Text component="p">
          We may disclose Personal Information, or any information you submitted via the Service if we have a good faith
          belief that disclosure of such information is helpful or reasonably necessary to: (i) comply with any
          applicable law, regulation, legal process or governmental request; (ii) investigate, detect, prevent, or take
          action regarding illegal activities or other wrongdoing, suspected fraud or security issues; (iii) to
          establish or exercise our rights to defend against legal claims; (iv) prevent harm to the rights, property or
          safety of us, our users, yourself or any third party; or (v) for the purpose of collaborating with law
          enforcement agencies.
        </Text>
      </Stack>
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title id="third_party" order={2} mt={theme.spacing.xl}>
          Third-Party Collection of Information
        </Title>
        <Text component="p">
          Please keep in mind that this Privacy Policy does not apply to any third part's websites, services or
          applications, even if they are accessible, downloadable, or otherwise distributed through the Services. You
          should always review their privacy practices carefully before providing Personal Information to such third
          parties.
        </Text>
        <Text component="p">
          You are knowingly and voluntarily assuming all risks of using any third-party websites, services or
          applications.
        </Text>
      </Stack>
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title id="safeguard" order={2} mt={theme.spacing.xl}>
          How We Safeguard and Transfer Your Information?
        </Title>
        <Text component="p">
          We take great care in implementing and maintaining the security of the Services and your information. We
          employ industry standard procedures and policies to ensure the safety of our users' information and prevent
          unauthorized use of any such information including by using Secure Socket Layer (SSL) technology, to do so.
        </Text>
        <List>
          <Text component="li">
            Since we operate globally, it may be necessary to transfer your Personal Information to countries outside
            the European Union. The data protection and other laws of these countries may not be as comprehensive as
            those in the European Union − in these instances we will take steps to ensure that a similar level of
            protection is given to your Personal Information. These include the following:
          </Text>
          <Text component="li">
            Where we use certain service providers, we may adopt specific contracts approved by the European Commission
            which give Personal Information the same protection it has in Europe.
          </Text>
          <Text component="li">
            We will only transfer your Personal Information to countries that have been deemed to provide an adequate
            level of protection for personal data by the European Commission.
          </Text>
          <Text component="li">
            Where we use service providers located in the US, we may transfer data to them if they have signed up to the
            Privacy Shield which requires them to offer similar protection to personal data shared between Europe and
            the US.
          </Text>
        </List>
        <Text component="p">
          If you feel that your privacy was treated not in accordance with our policy, or if any person attempted to
          abuse our Services
        </Text>
      </Stack>
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title id="retain_information" order={2} mt={theme.spacing.xl}>
          How Long Do We Retain Collected Information?
        </Title>
        <Text component="p">
          We value your privacy and control over your Personal Information, and therefore you may, at any time, request
          to change and update it by emailing us at{' '}
          <UnstyledMailtoLink
            email="privacy@fxbet.co"
            subject="Hello"
            body="I'm reaching out to inquire about..."
            dark
          />
          . You can also request that we will correct errors or that we will erase your Personal Information (except for
          data that we are required to keep under applicable laws) by emailing us.
        </Text>
        <Text component="p">
          Please note that unless you instruct us otherwise we retain the information in accordance with our retention
          policy which you can request from us. The period of retention of your Personal Information is always balanced
          against the volume, nature and sensitivity of the Personal Information collected, the potential risk of harm
          from unauthorised use or disclosure of your Personal Information, the purposes for which we process your
          Personal Information and if those purposes may be achieved through other means and the relevant legal
          requirements. We aim to rectify, replenish or remove incomplete or inaccurate information promptly upon
          becoming aware of such error or inaccuracy.
        </Text>
      </Stack>
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title id="transaction" order={2} mt={theme.spacing.xl}>
          Corporate Transaction
        </Title>
        <Text component="p">
          We may share Information, including Personal Information, in the event of a corporate transaction (e.g. sale
          of a substantial part of our business, merger, consolidation or asset sale of an asset or transfer in the
          operation thereof) of the Company. In the event of the above, the acquiring company or transferee will assume
          the rights and obligations as described in this Privacy Policy.
        </Text>
      </Stack>
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title id="data_security" order={2} mt={theme.spacing.xl}>
          Data Security
        </Title>
        <Text component="p">
          We have put in place appropriate security measures to prevent your personal data from being accidentally lost,
          used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal
          data to those employees, agents, contractors and other third parties who have a business need to know. They
          will only process your personal data on our instructions and they are subject to a duty of confidentiality.
        </Text>
        <Text component="p">
          We have put in place procedures to deal with any suspected personal data breach and will notify you and any
          applicable regulator of a breach where we are legally required to do so.
        </Text>
      </Stack>
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title id="legal_rights" order={2} mt={theme.spacing.xl}>
          Your Legal Rights
        </Title>
        <Text component="p">
          Under certain circumstances, you have rights under data protection laws in relation to your personal data:
        </Text>
        <List mb={theme.spacing.md}>
          <Text component="li">Request access to your personal data.</Text>
          <Text component="li">Request correction of your personal data.</Text>
          <Text component="li">Request transfer of your personal data.</Text>
          <Text component="li">Object to processing of your personal data.</Text>
          <Text component="li">Request erasure of your personal data.</Text>
          <Text component="li">Request restriction of processing your personal data.</Text>
          <Text component="li">Right to withdraw consent.</Text>
        </List>
      </Stack>
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title id="no_fee" order={2}>
          No Fee Usually Required
        </Title>
        <Text component="p">
          No fee is required to exercise any of your rights in respect to your Personal Information that we collect.
          However, we reserve the right to charge a reasonable fee if we consider your request excessive, unfounded or
          of a repetitive nature and may refuse to comply with your request in such circumstances.
        </Text>
      </Stack>
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title id="info_needed" order={2}>
          What we May Need From You
        </Title>
        <Text component="p">
          We may need to request specific information from you to help us confirm your identity and ensure your right to
          access your personal data (or to exercise any of your other rights). This is a security measure to ensure that
          personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask
          you for further information in relation to your request to speed up our response.
        </Text>
      </Stack>
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title id="response_time" order={2}>
          Response Time Limit
        </Title>
        <Text component="p">
          We aim to respond to all legitimate requests within one month if you wish you to exercise any of your rights.
          We will notify you if your request is complex and requires longer than 1 month to provide a response. We will
          endeavour to keep you updated.
        </Text>
      </Stack>
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title id="minors" order={2} mt={theme.spacing.xl}>
          Minors
        </Title>
        <Text component="p">
          The Services are not designated to users under the age of 18. If you are under 18, you should not use the
          Services nor provide any Personal Information to us.
        </Text>
        <Text component="p">
          We reserve the right to access and verify any Personal Information collected from you. In the event that we
          become aware that a user under the age of 18 has shared any information, we will discard such information. If
          you have any reason to believe that a minor has shared any information with us, please contact us.
        </Text>
      </Stack>
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title id="contact" order={2} mt={theme.spacing.xl}>
          How to Contact Us
        </Title>
        <Text component="p">
          If you have any general questions about the Services use{' '}
          <UnstyledMailtoLink email="info@fxbet.co" subject="Hello" body="I'm reaching out to inquire about..." />.
          enquiries about the information that we collect about you and how we use it, please contact us at{' '}
          <UnstyledMailtoLink email="privacy@fxbet.co" subject="Hello" body="I'm reaching out to inquire about..." />.
        </Text>
      </Stack>
    </Container>
  );
};

export default PrivacyPolicy;
