import { host } from '../host';
import { useStore } from '../state/state';
import { ApiResponse, Game, Wager, MarketStatistics, Market } from './interfaces';
import fetch from './interceptor';

enum GetGamesErrorEnum {}

const getGames = async () => {
  try {
    const response = await fetch(`${host}/api/game`, {
      method: 'GET',
      mode: 'cors',
    });

    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<Game[], GetGamesErrorEnum>;
      
      const state = useStore.getState();
      if (json.success && (!state.games || state.games.length === 0)) {
        state.setGames(
          json.result.map((x) => ({
            ...x,
            iconAction: `${host}${x.iconAction}`,
            iconGame: `${host}${x.iconGame}`,
            imageBackground: `${host}${x.imageBackground}`,
            imageGame: `${host}${x.imageGame}`,
          }))
        );
      }
    }
  } catch {}
};

const placeWager = async (marketId: number, wager: Wager) => {
  try {
    const state = useStore.getState();

    const body = {
      marketId: marketId,
      durationUnit: wager.duration.unit,
      durationValue: wager.duration.value,
      type: wager.type,
      stake: wager.staked,
      currency: wager.currency,
    };
    const response = await fetch(`${host}/api/wager/new`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<void, GetGamesErrorEnum>;

      return json.success;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};

const getMarketStatistics = async (date: string) => {
  const state = useStore.getState();
  try {
    const response = await fetch(`${host}/api/game/stats${date}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<MarketStatistics[], GetGamesErrorEnum>;
      return json.result;
    } else {
      return 
    }
  } catch {
    return false
  }
};

const updateMarket = async (market: Market) => {
  const state = useStore.getState();
  try {
    const response = await fetch(`${host}/api/game/update-market`, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(market),
    });

    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<any, GetGamesErrorEnum>;
      return json.success;
    } else {
      return false
    }
  } catch {
    return false
  }
};

const updateGame = async (game: Game) => {
  const state = useStore.getState();
  try {
    const response = await fetch(`${host}/api/game/update-game`, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(game),
    });

    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<void, GetGamesErrorEnum>;
      return json.success;
    } else {
      return false
    }
  } catch {
    return false
  }
};

const toggleMarketStatus = async (marketId: number, status: boolean) => {
  const state = useStore.getState();
  try {
    const response = await fetch(`${host}/api/game/market-status`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({marketId: marketId, isDisabled: status}),
    });

    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<void, GetGamesErrorEnum>;
      return json.success;
    } else {
      return false
    }
  } catch {
    return false
  }
};

export { getGames, placeWager, getMarketStatistics, updateMarket, updateGame, toggleMarketStatus };
