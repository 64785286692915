import ReactGA4 from 'react-ga4';
import { googleAnalytics } from '../../utils/consts';

//Define the categories to make everything standard and aligns on reports
const analyticsEventCategories = {
  video: 'Video',
};

const InitializeGoogleAnalytics = () => {
  const tracking_id = googleAnalytics.trackingID;
  ReactGA4.initialize(tracking_id);
  console.log('Google Analytics initialized');
};

const TrackGoogleAnalyticsEvent = (category: string, action: string, eventLabel?: string) => {
  console.log('GA event = ', 'category :', category, ':', 'action :', action);
  if (eventLabel) {
    ReactGA4.event({
      category: category,
      action: action,
      label: eventLabel,
    });
  } else {
    ReactGA4.event({
      category: category,
      action: action,
    });
  }
};

const TrackGoogleAnalyticsPage = (path: string, title: string) => {
  console.log('GA pageview = ', 'path :', path, ':', 'title :', title);
  ReactGA4.send({ hitType: 'pageview', page: path, title: title });
};

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent, TrackGoogleAnalyticsPage, analyticsEventCategories };
