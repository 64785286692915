import { Paper, Divider, Group, Text, Center, useMantineTheme, createStyles } from '@mantine/core';
import { Game, Market, Wager, WagerTypeEnum } from '../../../../api/interfaces';
import { MarketDisplay } from '../../../../components/game/marketButton';

import { ReactComponent as IconDuration } from '../../../../assets/icons/icon-game-duration.svg';
import { ReactComponent as IconUp } from '../../../../assets/icons/icon-play-arrow-up.svg';
import { ReactComponent as IconDown } from '../../../../assets/icons/icon-play-arrow-down.svg';
import {
  formatCurrency,
  formatCurrencySymbol,
  formatDurationUnit,
  formatWagerType,
} from '../../../../utils/formatters';
import { useMediaQuery } from '@mantine/hooks';

const useStyles = createStyles((theme, params: { mobileView: boolean }) => {
  return {
    paperWager: {
      minWidth: params.mobileView ? '0' : '90px',
    },
  };
});

export const WagerSummary = (props: {
  game: Game;
  market: Market;
  wager: Wager;
  hideDivider?: boolean;
  spaceBetween?: boolean;
}) => {
  const stakeFontSize = props.wager.staked < 100 ? '36px' : props.wager.staked < 1000 ? '28px' : '22px';
  const mobileView = useMediaQuery(`(max-width: ${useMantineTheme().breakpoints.sm}px)`);
  const space = mobileView ? 4 : 12;
  const { classes } = useStyles({ mobileView: mobileView });

  if (!props.wager) return null;

  return (
    <>
      <Group
        noWrap
        position="right"
        spacing={space}
        style={{
          marginBottom: '10px',
          justifyContent: `${props.spaceBetween || mobileView ? 'space-between' : 'end'}`,
        }}
        align="stretch"
        mt="xs"
      >
        <Paper radius="md" p={4} className={classes.paperWager}>
          <Center style={{ height: '100%' }}>
            <MarketDisplay key={props.market.id} market={props.market} game={props.game} />
          </Center>
        </Paper>

        <Paper radius="md" p={4} className={classes.paperWager}>
          <Group position="center" align="center" noWrap spacing={space} style={{ height: '100%' }}>
            <IconDuration style={{ height: '24px', color: props.game.color }} />
            <Text size="md" weight="bold" align="center" sx={{ color: props.game.color, lineHeight: 1 }}>
              {props.wager.duration.value}
              <br />
              {formatDurationUnit(props.wager.duration.value, props.wager.duration.unit, true)}
            </Text>
          </Group>
        </Paper>

        <Paper radius="md" p={4} className={classes.paperWager}>
          <Group position="center" align="center" spacing={space} noWrap style={{ height: '100%' }}>
            {props.wager.type === WagerTypeEnum.Rise ? (
              <IconUp style={{ height: '24px', color: props.game.color }} />
            ) : props.wager.type === WagerTypeEnum.Fall ? (
              <IconDown style={{ height: '24px', color: props.game.color }} />
            ) : null}
            <Text size="md" weight="bold" transform="uppercase" sx={{ color: props.game.color }}>
              {formatWagerType(props.wager.type)}
            </Text>
          </Group>
        </Paper>

        <Paper radius="md" p={4} className={classes.paperWager}>
          <Center>
            <Group position="apart" spacing={space} noWrap>
              <Group position="center" direction="column" spacing={0}>
                <Text size="sm" weight="bold" sx={{ color: props.game.color, lineHeight: 1 }}>
                  bet
                </Text>
                <Text size="xl" weight="bold" sx={{ color: props.game.color, lineHeight: 1 }}>
                  {formatCurrencySymbol(props.wager.currency)}
                </Text>
              </Group>
              <Text
                size="md"
                weight="bold"
                align="center"
                sx={{ color: props.game.color, flexGrow: 1, fontSize: stakeFontSize }}
              >
                {formatCurrency(props.wager.staked)}
              </Text>
            </Group>
          </Center>
        </Paper>
      </Group>
    </>
  );
};
