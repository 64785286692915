import { Stack, Title, Image, Text, Group, Space } from '@mantine/core';
import { useStyles } from './styles';
import bets from '../../assets/icons/bets.svg';
import currency from '../../assets/icons/currency.svg';
import updown from '../../assets/icons/updown.svg';
import timeline from '../../assets/icons/timeline.svg';

import visa from '../../assets/images/visa.svg';
import mastercard from '../../assets/images/mastercard.svg';
import sepa from '../../assets/images/sepa.svg';
import { GreenButton } from '../buttons/GreenButton';
import { useNavigate, useLocation } from 'react-router-dom';

const LandingPageFooter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const signupUrl = `/signup${location.search}`;
  const { classes } = useStyles();

  return (
    <Stack>
      <Title order={1} align="center">
        How you WIN with FXBet!
      </Title>
      <Title order={2} align="center">
        A unique gaming experience!
      </Title>
      <Stack align="center">
        <Text align="center" style={{ maxWidth: '800px' }}>
          FXBet provides betting on fluctuation of currency rates. The bet is for the predicted outcome of exchange rate
          changes; if the market will go "up" or "down" compared to the rate when the bet is placed. GOOD LUCK!
        </Text>
        <Group direction="row" position="center">
          <Group direction="row" noWrap sx={{ maxWidth: '350px' }}>
            <Stack>
              <Title order={3} align="right">
                Choose your currencies!
              </Title>
              <Text align="right">Choose what currency pairs or commodities you wish to bet on.</Text>
            </Stack>
            <img src={currency} className={classes.reasonImage} alt=""></img>
          </Group>
          <Group direction="row" noWrap sx={{ maxWidth: '350px' }}>
            <img src={timeline} className={classes.reasonImage} alt=""></img>
            <Stack>
              <Title order={3}>Choose your timeline!</Title>
              <Text>
                Choose how many "tics" you want to play - the short movements up and down that indicate the rate change.
              </Text>
            </Stack>
          </Group>
        </Group>
        <Group direction="row" position="center">
          <Group direction="row" noWrap sx={{ maxWidth: '350px' }}>
            <Stack justify="flex-start">
              <Title order={3} align="right">
                Choose your bet!
              </Title>
              <Text align="right">Choose your stake - how much do you want to bet?</Text>
            </Stack>
            <img height="100%" src={bets} className={classes.reasonImage} alt=""></img>
          </Group>
          <Group direction="row" noWrap sx={{ maxWidth: '350px' }}>
            <img height="100%" src={updown} className={classes.reasonImage} alt=""></img>
            <Stack>
              <Title order={3}>Choose your position!</Title>
              <Text>
                Choose your position "Up" or "Down" - your outcome in comparison to the current rate when you place your
                bet.
              </Text>
            </Stack>
          </Group>
        </Group>
      </Stack>
      <Space />
      <Stack align="center" mb={32}>
        <GreenButton
          onClick={() => navigate(signupUrl)}
          styles={{
            root: { minWidth: 'fit-content' },
          }}
        >
          SIGN UP TO START WINNING
        </GreenButton>
        <Group position="center">
          <Image width={65} src={mastercard}></Image>
          <Image width={90} src={visa}></Image>
          <Image width={90} src={sepa}></Image>
        </Group>
        <div className={classes.bottomWrapper}>
          <Stack align="center" mt={32}>
            <Title order={2}>Need to get in touch with us?</Title>
            <GreenButton
              onClick={() => navigate('/contact-us')}
              styles={{
                root: { minWidth: 'fit-content' },
              }}
            >
              Contact Us
            </GreenButton>
            <Text className={classes.bottomText}>
              Reach out with questions & suggestions and we will get back to you soon.
            </Text>
          </Stack>
          {/* TODO: implement newsletter*/}
          {/* <Stack align="center" mt={32}>
            <Title order={2}>Join our newsletter for updates</Title>
            <div className={classes.dFlex}>
              <TextInput radius="md" size="lg" type="email" mr={16} placeholder="email address"></TextInput>
              <GreenButton
                onClick={joinNow}
                styles={{
                  root: { minWidth: 'fit-content' },
                }}
              >
                Join now
              </GreenButton>
            </div>

            <Text className={classes.bottomText}>
              Be first to hear about all our news & updates. Unsubscribe any time
            </Text>
          </Stack> */}
        </div>
      </Stack>
    </Stack>
  );
};

export default LandingPageFooter;
