import { useEffect, useState } from 'react';
import {
  calculateDateFromToday,
  formatDateSubString,
  formatPlayerFullName,
  generateUniqSerial,
  getCurrentDate,
} from '../../../utils/formatters';
import {
  AccountReportParams,
  Countries,
  PlayerAccountOverview,
  ReportFilter,
  ReportProfitRange,
  WagerActivityTimeFilter,
} from '../../../api/interfaces';
import { getAccountsReport } from '../../../api/backoffice';
import { Box, Button, Checkbox, Grid, Group, Select, Space, Stack, Table, Text, Title } from '@mantine/core';
import { downloadExcel } from 'react-export-table-to-excel';
import { mapKeyToTextPlayerFields } from '../../../utils/Pipes';
import { getCountries } from '../../../api/player';
import { useStyles } from '../styles';

const PlayerAccountReport = () => {
  const [activeFilterActivity, setActiveFilterActivity] = useState<WagerActivityTimeFilter>('7 Days');
  const [accountType, setAccountType] = useState<ReportFilter>('New');
  const [countryCode, setCountryCode] = useState<string | null>('All');
  const [countries, setCountries] = useState<Countries[]>([]);
  const [profit, setProfit] = useState<ReportProfitRange>('All');
  const [loadedData, setLoadedData] = useState<boolean>(true);
  const [playerData, setPlayerData] = useState<any[]>([
    {
      id: '',
      registeredDate: '',
      totalPlays: 0,
      profit: 0,
      name: '',
      lastOnline: '',
      won: 0,
      winCount: 0,
      email: 0,
      phoneNumber: '',
      lost: 0,
      lostCount: 0,
      country: 0,
      emailVerified: 0,
      deposit: '',
      depositCount: 0,
      age: 0,
      isVerified: '',
      drawCount: 0,
      favoriteGame: 0,
    },
  ]);
  const [tableData, setTableData] = useState<string[][]>([[]]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(['name', 'email']);
  const { classes } = useStyles();

  const toggleColumn = (label: string) => {
    if (selectedColumns.includes(label)) {
      setSelectedColumns(selectedColumns.filter((col) => col !== label));
    } else {
      setSelectedColumns([...selectedColumns, label]);
    }
  };

  const getPlayers = async () => {
    const date = new Date(await calculateDateFromToday(activeFilterActivity));
    let params: AccountReportParams = {
      duration: date,
      isDesc: false,
      profit: profit === 'All' ? undefined : profit,
      filter: accountType === 'All' ? undefined : accountType,
      countryCode: !countryCode || countryCode === 'All' ? undefined : countryCode,
    };
    const result = await getAccountsReport(params);
    if (Array.isArray(result) && result.length > 1) {
      setLoadedData(true);
      filterPlayerData(result);
    } else {
      setLoadedData(false);
    }
    setSelectedColumns(selectedColumns);
  };

  const filterPlayerData = (playerArray: PlayerAccountOverview[]) => {
    const filteredPlayers = playerArray.map((player) => {
      const filteredPlayer = {
        id: player.id,
        registeredDate: formatDateSubString(player.registeredDate),
        totalPlays: player.quickStats.totalPlayed,
        name: formatPlayerFullName(player.firstName, player.lastName),
        lastOnline: formatDateSubString(player?.lastLogin),
        won: player.won,
        winCount: player.quickStats.wins,
        email: player.email,
        phoneNumber: player.phoneNumber,
        lost: player.lost,
        lostCount: player.quickStats.losses,
        country: player.country,
        emailVerified: player.emailConfirmed ? 'Complete' : 'Incomplete',
        deposit: player.deposit,
        depositCount: player.depositCount,
        age: player.age,
        isVerified: player.isVerified ? 'Verified' : 'Not Verified',
        profit: player.profit,
        drawCount: player.quickStats.draws,
        favoriteGame: player.quickStats.favoriteGame,
        // Add other fields you want to keep here
      };
      return filteredPlayer;
    });
    setPlayerData(filteredPlayers);
  };

  const getCountryObject = async () => {
    const result = await getCountries();
    if (result && Array.isArray(result)) {
      setCountries(result);
    }
  };

  useEffect(() => {
    if (Array.isArray(playerData)) {
      setTableData(
        playerData.map((player) => {
          const rowData: { [key: string]: string } = {};
          selectedColumns.forEach((col) => {
            rowData[col] = player[col as keyof typeof player]?.toString();
          });
          return Object.values(rowData);
        })
      );
    }
  }, [selectedColumns, playerData, accountType, countryCode, profit]);

  useEffect(() => {
    getCountryObject();
  }, []);

  const DownloadReport = () => {
    downloadExcel({
      fileName: `Player Account Report_${getCurrentDate()}`,
      sheet: 'Player Account',
      tablePayload: {
        header: selectedColumns,
        body: tableData,
      },
    });
  };

  useEffect(() => {
    getPlayers();
  }, [activeFilterActivity, profit, accountType, countryCode]);

  return (
    <>
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Stack sx={{ width: '100%' }}>
          <Space h={16} />
          <Group noWrap>
            <Grid mr={12} grow sx={{ minWidth: '466px', maxWidth: '500px' }}>
              <Grid.Col span={6}>
                <Select
                  radius="md"
                  size="sm"
                  label={<Title order={3}>Select Time Span</Title>}
                  value={activeFilterActivity}
                  onChange={(e: WagerActivityTimeFilter) => setActiveFilterActivity(e)}
                  data={[
                    { value: '24 Hours', label: '24 Hours' },
                    { value: '3 Days', label: '3 Days' },
                    { value: '7 Days', label: '7 Days' },
                    { value: '14 Days', label: '14 Days' },
                    { value: '1 Month', label: '1 Month' },
                    { value: '3 Months', label: '3 Months' },
                    { value: '6 Months', label: '6 Months' },
                    { value: '1 Year', label: '1 Year' },
                    { value: 'MTD', label: 'MTD' },
                    { value: 'YTD', label: 'YTD' },
                    { value: 'All', label: 'All Time' },
                  ]}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Select
                  radius="md"
                  size="sm"
                  label={<Title order={3}> Select Country of Residence</Title>}
                  value={countryCode}
                  placeholder="All Countries"
                  onChange={(e: string | null) => setCountryCode(e)}
                  data={[
                    { value: 'All', label: 'All Countries' },
                    ...countries.map((x) => ({ key: x.code, value: x.code, label: x.name })),
                  ]}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Select
                  radius="md"
                  size="sm"
                  label={<Title order={3}>Select Account Type</Title>}
                  value={accountType}
                  onChange={(e: ReportFilter) => setAccountType(e)}
                  data={[
                    { value: 'New', label: 'New Accounts' },
                    { value: 'Active', label: 'Active Accounts' },
                    { value: 'Incomplete', label: 'Incomplete Sign Up' },
                    { value: 'NoDeposit', label: 'No Deposits Made' },
                    { value: 'Flagged', label: 'Flagged Accounts' },
                    { value: 'Frozen', label: 'Frozen Accounts' },
                    { value: 'Idle', label: 'Idle Accounts' },
                    { value: 'Deactivated', label: 'Deactivated Accounts' },
                  ]}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Select
                  radius="md"
                  size="sm"
                  label={<Title order={3}>Select Player Profit Range</Title>}
                  value={profit}
                  onChange={(e: ReportProfitRange) => setProfit(e)}
                  data={[
                    { value: 'All', label: 'All Players' },
                    { value: 'Profit', label: 'Profit' },
                    { value: 'Loss', label: 'Loss' },
                    { value: 'OverGrandProfit', label: 'Over 1000 Profit' },
                    { value: 'OverGrandLoss', label: 'Over 1000 Loss' },
                  ]}
                />
              </Grid.Col>
            </Grid>
            <Grid pl={16} sx={{ borderLeft: '3px solid #00cafb' }}>
              {playerData.length > 0 &&
                Object.keys(playerData[0]).map((key) => (
                  <Grid.Col span={3} key={JSON.stringify(key)}>
                    <Checkbox
                      checked={selectedColumns.includes(key)}
                      label={mapKeyToTextPlayerFields(key)}
                      onChange={() => toggleColumn(key)}
                    />
                  </Grid.Col>
                ))}
            </Grid>
          </Group>
        </Stack>
      </Box>
      <Space h={24} />
      <Text size="xs">“Active Accounts” excludes only deleted, deactivated or incomplete accounts.</Text>
      <Space h={24} />
      <Title order={3} mb="sm">
        Report Preview (Shows first 3 entries only)
      </Title>
      <Box className={classes.removeScroll} sx={{ width: '100%', maxWidth: 'calc(100vw - 214px)', overflowX: 'auto' }}>
        <Table>
          <thead className={classes.tableHeader}>
            <tr>
              {[...selectedColumns].map((col) => (
                <th key={JSON.stringify(col)}>
                  <Text>{mapKeyToTextPlayerFields(col)}</Text>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loadedData &&
              tableData.slice(0, 3).map((row, rowIndex) => (
                <tr key={generateUniqSerial()}>
                  {row.map((data, cellIndex) => (
                    <td style={{ minWidth: data?.length > 5 ? '230px' : '150px' }} key={cellIndex}>
                      <Text lineClamp={1}>{data}</Text>
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </Table>
        {!loadedData && (
          <Box mt={64} sx={{ width: '100%' }}>
            <Title align="center">No data available</Title>
          </Box>
        )}
      </Box>
      <Space h={32} />
      <Button radius="lg" onClick={DownloadReport}>
        Generate & Download Report
      </Button>
    </>
  );
};

export default PlayerAccountReport;
