import { MouseEvent } from 'react';

interface MailToLinkProps {
  email: string;
  subject?: string;
  body?: string;
  dark?: boolean;
}
const UnstyledMailtoLink = ({ email, subject = 'Contact FXBet', body = '', dark = true }: MailToLinkProps) => {
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const emailLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(emailLink, '_blank');
  };

  return (
    <a
      href={`mailto:${email}`}
      onClick={handleClick}
      style={{ textDecoration: 'none', color: dark ? 'rgb(176 220 255)' : 'rgb(102 176 235)' }}
    >
      {email}
    </a>
  );
};

export default UnstyledMailtoLink;
