import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => {
  return {
    container: {
      backgroundColor: theme.other.tabBackgroundColor,
      borderRadius: '20px',
      padding: '16px',
      height: '100%',
    },
    cardContainer: {
      border: `1px solid ${theme.other.blue}`,
      borderRadius: '20px',
      padding: '16px',
      height: '100%',
    },
    tableHeader: {
      borderTop: `2px solid ${theme.other.blue}`,
      borderBottom: `2px solid ${theme.other.blue}`,
    },
    indicatorContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: `#1971c2`,
      borderRadius: '50%',
      height: '32px',
      width: '32px',
    },
  };
});

export { useStyles };
