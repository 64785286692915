import { Box, Button, Divider, Group, Space, Stack, Title, Text } from '@mantine/core';
import { IPlayerStats, WagerActivityTimeFilter } from '../../../api/interfaces';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ActiveGradient, InActiveGradient } from '../../../utils/consts';
import { useStyles } from '../styles';
import { useStore } from '../../../state/state';
import { calculateDateFromToday } from '../../../utils/formatters';

const PlayerStats = ({
  activeFilterStats,
  playerStats,
  setActiveFilterStats,
}: {
  activeFilterStats: WagerActivityTimeFilter;
  playerStats: IPlayerStats | undefined;
  setActiveFilterStats: Dispatch<SetStateAction<WagerActivityTimeFilter>>;
}) => {
  const { classes } = useStyles();
  const filterButtons: WagerActivityTimeFilter[] = ['24 Hours', '7 Days', '1 Month', '6 Months', 'MTD', 'YTD'];
  const auth = useStore((state) => state.auth);
  const [downloadFileName, setDownloadFileName] = useState<string>('');

  useEffect(() => {
    calculateDateFromToday(activeFilterStats).then((date: string) => {
      const formattedDate = new Date(date);
      const startDate = `${formattedDate.getFullYear()}_${formattedDate.getMonth() + 1}_${formattedDate.getDate()}`;
      const endDate = new Date().toISOString().split('T')[0];
      setDownloadFileName(`Player_Stats_${startDate}_${endDate}`);
    });
  }, [activeFilterStats]);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Title align="left" sx={{ flexGrow: 1 }} order={1}>
          Player Statistics
        </Title>
      </Box>
      <Divider size={2} sx={{ margin: '24px 0px' }} />
      <Box sx={{ display: 'flex', margin: '24px 0px' }}>
        <Stack spacing="md">
          <Title align="left" order={2}>
            Choose time span
          </Title>
          {filterButtons.map((button, index) => (
            <Button
              key={index}
              onClick={() => setActiveFilterStats(button)}
              variant="gradient"
              radius="lg"
              gradient={activeFilterStats === button ? ActiveGradient : InActiveGradient}
              sx={{ height: '50px', width: '190px' }}
            >
              <Title order={3}>{button}</Title>
            </Button>
          ))}
        </Stack>
        <Box
          sx={{
            flexGrow: 1,
            marginLeft: '16px',
          }}
        >
          <Group grow sx={{ height: '100%' }}>
            <Box className={classes.container}>
              <Stack align="center">
                <Title order={2} align="center">
                  Total
                  <br />
                  Active Accounts
                </Title>
                <Space h={4}></Space>
                {playerStats && (
                  <>
                    <Title align="center">{playerStats?.period.activeAccounts}</Title>
                    <Space h={4} />
                    {percentageDifference(playerStats?.period.activeAccounts, playerStats?.periodBefore.activeAccounts)}
                    <Space h={4} />
                  </>
                )}
                <Text align="center">Compared to previous week</Text>
              </Stack>
            </Box>
            <Box className={classes.container}>
              <Stack align="center">
                <Title order={2} align="center">
                  Players Online &
                  <br />
                  Engaged with FXBet
                </Title>
                <Space h={4}></Space>
                {playerStats && (
                  <>
                    <Title align="center">{playerStats?.period.interactivePlayers}</Title>
                    <Space h={4} />
                    {percentageDifference(
                      playerStats?.period.interactivePlayers,
                      playerStats?.periodBefore.interactivePlayers
                    )}
                    <Space h={4} />
                  </>
                )}
                <Text align="center">Compared to previous week</Text>
              </Stack>
            </Box>
            <Box className={classes.container}>
              <Stack align="center">
                <Title order={2} align="center">
                  New Player
                  <br />
                  Sign-ups
                </Title>
                <Space h={4}></Space>
                {playerStats && (
                  <>
                    <Title align="center">{playerStats?.period.newSignUps}</Title>
                    <Space h={4} />
                    {percentageDifference(playerStats?.period.newSignUps, playerStats?.periodBefore.newSignUps)}
                    <Space h={4} />
                  </>
                )}
                <Text align="center">Compared to previous week</Text>
              </Stack>
            </Box>
            <Box className={classes.container}>
              <Stack align="center">
                <Title order={2} align="center">
                  Incomplete
                  <br />
                  Sign-ups
                </Title>
                <Space h={4}></Space>
                {playerStats && (
                  <>
                    <Title align="center">{playerStats?.period.incompleteSignUps}</Title>
                    <Space h={4} />
                    {percentageDifference(
                      playerStats?.period.incompleteSignUps,
                      playerStats?.periodBefore.incompleteSignUps
                    )}
                    <Space h={4} />
                  </>
                )}
                <Text align="center">Compared to previous week</Text>
              </Stack>
            </Box>
            <Box className={classes.container}>
              <Stack align="center">
                <Title order={2} align="center">
                  Accounts
                  <br />
                  Deactivated
                </Title>
                <Space h={4}></Space>
                {playerStats && (
                  <>
                    <Title align="center">{playerStats?.period.deactivated}</Title>
                    <Space h={4} />
                    {percentageDifference(playerStats?.period.deactivated, playerStats?.periodBefore.deactivated)}
                    <Space h={4} />
                  </>
                )}
                <Text align="center">Compared to previous week</Text>
              </Stack>
            </Box>
          </Group>
        </Box>
      </Box>
    </>
  );
};

const percentageDifference = (current: number, previous: number, reverseOrder?: boolean) => {
  const calculatePercentage = (currentValue: number, previousValue: number): number => {
    if (previousValue === 0) {
      return 0;
    }
    return ((currentValue - previousValue) / previousValue) * 100;
  };

  const percentageDifference = calculatePercentage(current, previous);

  if (reverseOrder) {
    return (
      <Title order={2} sx={{ color: `${percentageDifference > 0 ? '#eb6c35' : '#88c32c'}` }}>
        {percentageDifference.toFixed(1)} %
      </Title>
    );
  } else {
    return (
      <Title order={2} sx={{ color: `${percentageDifference > 0 ? '#88c32c' : '#eb6c35'}` }}>
        {percentageDifference.toFixed(1)} %
      </Title>
    );
  }
};

export default PlayerStats;
