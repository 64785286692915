import { useStore } from '../state/state';
const gameMusic = require('../assets/sounds/gamePlay.mp3');
const tickMove = require('../assets/sounds/tickMove.mp3');
const winner = require('../assets/sounds/winner.mp3');
const lost = require('../assets/sounds/lost.mp3');
const betPlacedSound = require('../assets/sounds/betPlaced.mp3');

export type SoundName = 'gameMusic' | 'tickMove' | 'winner' | 'lost' | 'betPlacedSound';
export interface Sound {
  name: SoundName;
  sound: HTMLAudioElement;
}

export class SoundManager {
  private static instance: SoundManager;
  public sounds = new Map<SoundName, HTMLAudioElement>([
    ['gameMusic', new Audio(gameMusic)],
    ['tickMove', new Audio(tickMove)],
    ['winner', new Audio(winner)],
    ['lost', new Audio(lost)],
    ['betPlacedSound', new Audio(betPlacedSound)],
  ]);

  private constructor() {
    this.listenToAudioEnabledFlag();
  }

  private listenToAudioEnabledFlag() {
    useStore.subscribe((store) => {
      this.toggleAudioEnabled(store.audioEnabled);
    });
  }

  private toggleAudioEnabled(enabled: boolean) {
    this.sounds.forEach((val, key) => {
      val.muted = !enabled;
      this.sounds.set(key, val);
    });
  }

  public static getInstance(): SoundManager {
    if (!SoundManager.instance) {
      SoundManager.instance = new SoundManager();
    }

    return SoundManager.instance;
  }

  public playSound(soundName: SoundName) {
    const foundSound = this.sounds.get(soundName);
    if (!foundSound) {
      throw new Error('Sound does not exist');
    }
    if (foundSound) {
      foundSound.play();
    }
  }

  public loopSound(soundName: SoundName) {
    const foundSound = this.sounds.get(soundName);
    if (!foundSound) {
      throw new Error('Sound does not exist');
    }
    if (foundSound) {
      foundSound.loop = true;
      this.playSound(soundName);
    }
  }

  public stopLoopSound(soundName: SoundName) {
    const foundSound = this.sounds.get(soundName);
    if (!foundSound) {
      throw new Error('Sound does not exist');
    }
    if (foundSound) {
      foundSound.loop = false;
    }
  }

  public stopSound(soundName: SoundName) {
    const foundSound = this.sounds.get(soundName);
    if (!foundSound) {
      throw new Error('Sound does not exist');
    }
    if (foundSound) {
      foundSound.pause();
    }
  }
}
