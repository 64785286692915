import { Token } from '../components/requireAuth';
import { host } from '../host';
import { useStore } from '../state/state';
import { default as fetchWithNotification } from './interceptor';
import jwt from 'jwt-decode';
import { EmptyErrorEnum } from './interfaces';
// @ts-ignore
import ReactRecaptcha3 from 'react-google-recaptcha3';

interface JwtResponse {
  access_token: string;
  refresh_token: string;
  token_type: string; //Bearer
  expires_in: number;
}

let tokenRefreshTimer: NodeJS.Timeout | null = null;

const login = async (email: string, password: string, token: string, onError: (error: string) => void, onLogin: () => void) => {
  try {
    const response = await fetch(`${host}/api/auth/oauth`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'X-Recaptcha-token': token || '',
      },
      body: new URLSearchParams({ grant_type: 'password', username: email, password: password }),
    });
    if (response.status === 401) {
      const json = await response.json();
      if(json === EmptyErrorEnum.UserBanned){
        onError('Account is currently frozen, Please contact support@fxbet.co.')
      } else {
        onError('Invalid username or password');
      }
    } else if (response.status !== 200) {
      onError('Unexpected login failure');
    } else {
      const json = (await response.json()) as JwtResponse;

      const expiration = Date.now() + json.expires_in * 1000;

      let role: '' | 'Sales' = '';
      if (json.access_token) {
        role = jwt<Token>(json.access_token).role || '';
      }
      useStore.getState().setAuth({
        isAuthenticated: true,
        token: {
          accessToken: json.access_token,
          refreshToken: json.refresh_token,
          expiresAt: expiration.valueOf(),
        },
        user: {
          role,
        },
      });
      scheduleTokenRefresh()
      onLogin();
    }
  } catch {
    onError('Unexpected login failure');
  }
};

const loginWithRefreshToken = async (refreshToken: string,token: string, onError: () => void, onLogin: () => void) => {
  try {
    const response = await fetchWithNotification(`${host}/api/auth/oauth`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'X-Recaptcha-token': token || '',
      },
      body: new URLSearchParams({ grant_type: 'refresh_token', refresh_token: refreshToken }),
    });

    if (response.status !== 200) {
      onError();
    } else {
      const json = (await response.json()) as JwtResponse;

      const expiration = Date.now() + json.expires_in * 1000;

      let role: '' | 'Sales' = '';
      if (json.access_token) {
        role = jwt<Token>(json.access_token).role || '';
      }
      useStore.getState().setAuth({
        isAuthenticated: true,
        token: {
          accessToken: json.access_token,
          refreshToken: json.refresh_token,
          expiresAt: expiration.valueOf(),
        },
        user: {
          role,
        },
      });
      scheduleTokenRefresh();
      localStorage.removeItem('policyChange');
      onLogin();
    }
  } catch {
    onError();
  }
};

const scheduleTokenRefresh = () => {

  if (tokenRefreshTimer) {
    clearTimeout(tokenRefreshTimer);
  }

  const state = useStore.getState();
  const refreshToken = state.auth.token?.refreshToken;
  const isAuthenticated = state?.auth?.isAuthenticated;
  const expiresIn = state.auth.token?.expiresAt;

  if (expiresIn && isAuthenticated && refreshToken) {
    const currentTime = Date.now();
    const timeUntilExpiry = expiresIn - currentTime;
    const refreshThreshold = 5 * 60 * 1000;
    const refreshInterval = Math.max(timeUntilExpiry - refreshThreshold, 0);

    tokenRefreshTimer = setTimeout(() => {
      if (isAuthenticated && refreshToken) {
        try {
          ReactRecaptcha3.getToken().then((token: string) => {
            loginWithRefreshToken(
              refreshToken,
              token,
              () => {
                signOut();
              },
              () => {
                console.log('Token refreshed successfully');
              }
            );
          });
        } catch (error) {
          console.log('Recaptcha not loaded');
        }
      }
    }, refreshInterval);
  }
};

const signOut = () => {

  // TODO: Add Token Invalidation API Call to mitigate session hacking
  if (tokenRefreshTimer) {
    clearTimeout(tokenRefreshTimer);
    tokenRefreshTimer = null;
  }
  localStorage.removeItem('fxbet-auth')
  window.location.replace('/login');
}

export { login, signOut, loginWithRefreshToken };
