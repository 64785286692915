import { Box, Divider, Grid, Group, Table, Title, Text, Space } from '@mantine/core';
import { useEffect, useState } from 'react';
import { LiveWager, MarketStatistics, WagerActivityTimeFilter } from '../../../api/interfaces';
import { useStyles } from '../styles';
import { formatCurrency, formatCurrencySymbol, formatDate, getCurrentDate } from '../../../utils/formatters';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { HubConnectionState } from '@microsoft/signalr';
import { useStore } from '../../../state/state';
import ToolTip from '../../../components/tooltip/tooltip';
import ExcelDownload from '../../../components/reporting/ExcelDownload';

const WagerInsights = ({
  marketStatistics,
  timeSpan,
}: {
  marketStatistics: MarketStatistics[];
  timeSpan: WagerActivityTimeFilter;
}) => {
  const { classes } = useStyles();
  const [combinedGames, setCombinedGames] = useState<MarketStatistics[]>();
  const [gamesSortedByMarketId, setGamesSortedByMarketId] = useState<MarketStatistics[]>();
  const [liveBets, setLiveBets] = useState<LiveWager[]>([]);
  const [newBet, SetNewBet] = useState<LiveWager>();
  const [selectedScatter, setSelectedScatter] = useState<MarketStatistics[]>([]);
  const hub = useStore((state) => state.hub);
  const auth = useStore((state) => state.auth);
  const fileName = `Game_Statistics_${getCurrentDate()}`;

  const combineData = (MarketStatistics: MarketStatistics[]) => {
    const combinedData: MarketStatistics[] = [];

    MarketStatistics.forEach((obj) => {
      const existingIndex = combinedData.findIndex((item) => item.gameName === obj.gameName);
      if (existingIndex !== -1) {
        combinedData[existingIndex].totalBets += obj.totalBets;
        combinedData[existingIndex].totalBetsCount += obj.totalBetsCount;
        combinedData[existingIndex].totalDraws += obj.totalDraws;
        combinedData[existingIndex].totalDrawsCount += obj.totalDrawsCount;
        combinedData[existingIndex].totalLosses += obj.totalLosses;
        combinedData[existingIndex].totalLossesCount += obj.totalLossesCount;
        combinedData[existingIndex].totalWins += obj.totalWins;
        combinedData[existingIndex].totalWinsCount += obj.totalWinsCount;
        combinedData[existingIndex].fxBetProfit += obj.fxBetProfit;
      } else {
        combinedData.push({ ...obj });
      }
      setCombinedGames(combinedData);
    });
  };

  const sortbyMostPlayedGames = (MarketStatistics: MarketStatistics[]) => {
    if (MarketStatistics) {
      const sortedList = [...MarketStatistics].sort((a, b) => b.totalBetsCount - a.totalBetsCount);
      return sortedList;
    }
  };

  const sortByMarketId = (MarketStatistics: MarketStatistics[]) => {
    const sortedList = [...MarketStatistics].sort((a, b) => Number(a.marketId) - Number(b.currencyPair));
    setGamesSortedByMarketId(sortedList);
  };

  const sortMakingALoss = (MarketStatistics: MarketStatistics[]) => {
    const sortedList = [...MarketStatistics].sort((a, b) => a.fxBetProfit - b.fxBetProfit);
    return sortedList;
  };

  useEffect(() => {
    if (combinedGames) {
      sortbyMostPlayedGames(combinedGames);
      sortMakingALoss(combinedGames);
    }
  }, [combinedGames]);

  const CustomTooltip = ({ active, payload }: { active?: boolean; payload?: any[] }) => {
    if (active && payload && payload.length) {
      const dataPoint = payload[0].payload;
      return (
        <Box sx={{}}>
          <Title order={2}>{dataPoint.gameName}</Title>
          <Text>
            <strong>Win Count:</strong> {dataPoint.totalWinsCount}
          </Text>
          <Text>
            <strong>Loss Count:</strong> {dataPoint.totalLossesCount}
          </Text>
        </Box>
      );
    }

    return null;
  };

  const CustomWinsLosesTooltip = ({ active, payload }: { active?: boolean; payload?: any[] }) => {
    if (active && payload && payload.length) {
      const dataPoint = payload[0].payload;
      return (
        <Box sx={{ background: '#033551', padding: '16px', borderRadius: '5px' }}>
          <Title order={2}>{dataPoint.currencyPair}</Title>
          <Text weight="bold">
            Wins:{' '}
            <span style={{ color: '#88c32c' }}>
              {formatCurrencySymbol(dataPoint.currency)}
              {dataPoint.totalWins}
            </span>
          </Text>
          <Text weight="bold">
            Losses:{' '}
            <span style={{ color: '#eb6c35' }}>
              {formatCurrencySymbol(dataPoint.currency)}
              {dataPoint.totalLosses}
            </span>
          </Text>
        </Box>
      );
    }
    return null;
  };

  const renderBarChart = (gameName: string) => {
    const games = [...marketStatistics]?.filter((x) => x.gameName === gameName);

    if (games.length > 0) {
      setSelectedScatter(games);
    }
  };

  const calculateEndpoint = (combinedGames: MarketStatistics[] | undefined) => {
    let maxX = 0;
    let maxY = 0;
    if (combinedGames) {
      maxX = Math.max(...combinedGames.map((entry) => entry.totalWinsCount));
      maxY = Math.max(...combinedGames.map((entry) => entry.totalLossesCount));
    }
    return { x: maxX, y: maxY + 1 };
  };

  useEffect(() => {
    combineData(marketStatistics);
    sortByMarketId(marketStatistics);
  }, [marketStatistics]);

  useEffect(() => {
    if (hub?.state === HubConnectionState.Connected) {
      try {
        hub.send('subscribeAllWagers');
        hub.on('updateAllWagers', (wager: LiveWager) => {
          SetNewBet(wager);
        });
      } catch {
        console.log('error calling hub method');
      }
    }
    return () => {
      if (hub?.state === HubConnectionState.Connected) {
        hub.off('updateAllWagers');
        hub.send('unsubscribeAllWagers');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hub, hub?.state]);

  useEffect(() => {
    if (newBet) {
      if (newBet.closingTimestamp) {
        setLiveBets((prevLiveBets) => prevLiveBets.filter((bet) => bet.wagerId !== newBet.wagerId));
      } else {
        setLiveBets((prevLiveBets) => [...prevLiveBets, newBet]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBet]);

  useEffect(() => {
    if (gamesSortedByMarketId) {
      renderBarChart(gamesSortedByMarketId[0]?.gameName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gamesSortedByMarketId]);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Title align="left" sx={{ flexGrow: 1 }} order={1}>
          Game Quick Insights
        </Title>
        {auth?.user?.role?.includes('SuperAdmin') && (
          <ExcelDownload
            content={combinedGames}
            defaultFields={[
              'gameName',
              'lastWager',
              'totalBetsCount',
              'totalWinsCount',
              'totalLossesCount',
              'totalWins',
              'totalLosses',
              'fxBetProfit',
            ]}
            fileName={fileName}
            headers={[
              'Game',
              'Last Bet Placed',
              'Total Bets',
              'Total Wins',
              'Total Losses',
              'Total Won',
              'Total Lost',
              'Profit',
            ]}
          />
        )}
      </Box>
      <Divider size={2} sx={{ margin: '24px 0px' }} />
      <Grid>
        <Grid.Col span={12}>
          <Group grow>
            <Group>
              <Box className={classes.container}>
                <Group sx={{ height: '100%' }}>
                  <Title order={2}>Total Games Available</Title>
                  <Title order={2}>{combinedGames?.length}</Title>
                </Group>
              </Box>
              <Box className={classes.container}>
                <Group sx={{ height: '100%' }}>
                  <Title order={2}>Games with Active Bets</Title>
                  <Title order={2}>{liveBets.length}</Title>
                </Group>
              </Box>
            </Group>
          </Group>
        </Grid.Col>
        <Grid.Col span={5}>
          <Box className={classes.container} sx={{ width: '100%' }}>
            <Title sx={{ flexGrow: 1 }} order={2}>
              Popular Games with MOST BETS PLACED
            </Title>
            <Space h="md" />
            <Table>
              <thead className={classes.tableHeader}>
                <tr>
                  <th>
                    <Text>Rank</Text>
                  </th>
                  <th>
                    <Text>Game</Text>
                  </th>
                  <th>
                    <Text align="center">Last Bet Placed</Text>
                  </th>
                  <th>
                    <Text align="center">Total Bets</Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {combinedGames &&
                  sortbyMostPlayedGames(combinedGames)
                    ?.slice(0, 5)
                    .map((game, index) => {
                      return (
                        <tr key={game.marketId}>
                          <td>
                            <Text>{index + 1}</Text>
                          </td>
                          <td>
                            <Text>{game.gameName}</Text>
                          </td>
                          <td align="center">
                            <Text>{game.lastWager ? formatDate(game.lastWager.toString(), false) : 'No Wagers'}</Text>
                          </td>
                          <td>
                            <Text align="center">{game.totalBetsCount}</Text>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </Box>
        </Grid.Col>
        <Grid.Col span={7}>
          <Box className={classes.container} sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex' }}>
              <Title sx={{ flexGrow: 1 }} order={2}>
                Games Making A Loss
              </Title>
            </Box>
            <Space h="md" />
            <Table>
              <thead className={classes.tableHeader}>
                <tr>
                  <th>
                    <Text>Rank</Text>
                  </th>
                  <th>
                    <Text>Game</Text>
                  </th>
                  <th>
                    <Text align="center">Player Wins vs Losses</Text>
                  </th>
                  <th>
                    <Text align="right">Player Winnings</Text>
                  </th>
                  <th>
                    <Text align="right">Player Losses</Text>
                  </th>
                  <th>
                    <Text align="right">FXBet Balance</Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {combinedGames &&
                  sortMakingALoss(combinedGames)
                    ?.slice(0, 5)
                    .map((game, index) => {
                      return (
                        <tr key={game.marketId}>
                          <td>
                            <Text>{index + 1}</Text>
                          </td>
                          <td>
                            <Text>{game.gameName}</Text>
                          </td>
                          <td>
                            <Text align="center">
                              {game.totalWinsCount} vs {game.totalLossesCount}
                            </Text>
                          </td>
                          <td>
                            <Text align="right" sx={{ color: '#88c32c' }}>
                              {formatCurrencySymbol(game.currency)}
                              {formatCurrency(game.totalWins)}
                            </Text>
                          </td>
                          <td>
                            <Text align="right" sx={{ color: '#eb6c35' }}>
                              {formatCurrencySymbol(game.currency)} {formatCurrency(game.totalLosses * -1)}
                            </Text>
                          </td>
                          <td>
                            <Text align="right" sx={{ color: game.fxBetProfit > 0 ? '#88c32c' : '#eb6c35' }}>
                              {formatCurrencySymbol(game.currency)}
                              {formatCurrency(game.fxBetProfit)}
                            </Text>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </Box>
        </Grid.Col>
        <Grid.Col span={6}>
          <Box className={classes.container} sx={{ width: '100%' }}>
            <Title order={2}>{`Games Wins vs Losses Over ${timeSpan}`}</Title>
            <Space h="xl" />
            <ResponsiveContainer width="100%" height={500}>
              <ScatterChart data={combinedGames}>
                <CartesianGrid />
                <XAxis
                  stroke="white"
                  label={{ value: 'Total Wins', offset: -5, position: 'insideBottom' }}
                  dataKey="totalWinsCount"
                  name="Win Count"
                  type="number"
                />
                <YAxis
                  stroke="white"
                  label={{ value: 'Total Losses', angle: -90, position: 'insideLeft' }}
                  dataKey="totalLossesCount"
                  name="Loss Count"
                  type="number"
                />
                <Tooltip content={<CustomTooltip />} />
                <ReferenceLine
                  strokeWidth={3}
                  segment={[{ x: 0, y: 0 }, calculateEndpoint(combinedGames)]}
                  stroke="red"
                  strokeDasharray="3 3"
                />
                <Scatter
                  onClick={(payload) => renderBarChart(payload.payload.gameName)}
                  name="Currency Pair"
                  cursor="pointer"
                  data={combinedGames}
                >
                  {combinedGames &&
                    combinedGames.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)}
                </Scatter>
              </ScatterChart>
            </ResponsiveContainer>
          </Box>
        </Grid.Col>
        <Grid.Col span={6}>
          <Box className={classes.container} sx={{ width: '100%' }}>
            <Group position="apart">
              <Title order={2}>{`Games Wins vs Losses Breakdown`}</Title>
              <ToolTip text={'Select a game from the scatter chart to view Win/Loss breakdown.'} />
            </Group>
            <Space h="xl" />
            <ResponsiveContainer width="100%" height={500}>
              <BarChart data={selectedScatter}>
                <CartesianGrid vertical={false} />
                <XAxis dataKey="currencyPair" stroke="white" />
                <Label value="Pages of my website" offset={-5} position="top" />
                <Label>Hong Kong Kicks</Label>
                <YAxis stroke="white" />
                <Tooltip content={<CustomWinsLosesTooltip />} />
                <Bar dataKey="totalWins" fill="#88c32c" />
                <Bar dataKey="totalLosses" fill="#eb6c35" />
              </BarChart>
            </ResponsiveContainer>
            <Text align="center">{selectedScatter[0]?.gameName}</Text>
          </Box>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default WagerInsights;
