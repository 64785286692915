import { host } from '../host';
import { useStore } from '../state/state';
import {
  ApiResponse,
  EmptyErrorEnum,
  PlayerAccount,
  DateRangeRequest,
  Wager,
  Limits,
  WagerHistory,
  PlayerSearchParams,
  UserTransactions,
  FinancePeriodEnum,
  FinancePeriod,
  HistoryActivity,
  IPlayerStats,
  PlayerAccountOverview,
  AccountReportParams,
  UserMin,
  AssignSalesAdmin,
  UserSalesManagementFilter,
  PaginatedData,
  UserNotes,
} from './interfaces';
import fetch from './interceptor';
import { getPlayer, updatePlayerLimits } from './player';

const getActiveWagers = async () => {
  try {
    const state = useStore.getState();

    const response = await fetch(`${host}/api/wager/active`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
      },
    });

    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<Wager[], EmptyErrorEnum>;

      return json.result;
    }
  } catch {}
};

const getWagerHistory = async (dateRangeRequest: DateRangeRequest) => {
  try {
    const state = useStore.getState();

    const response = await fetch(`${host}/api/wager/history`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
      },
      body: JSON.stringify(dateRangeRequest),
    });

    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<WagerHistory[], EmptyErrorEnum>;

      return json.result;
    }
  } catch (error) {
    console.error('Error fetching wager history:', error);
  }
};

const searchAccounts = async (params: PlayerSearchParams) => {
  try {
    const state = useStore.getState();

    const response = await fetch(`${host}/api/player/search`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    if (response.status === 200) {
      const json = (await response.json()) as PlayerAccount[];
      return json;
    }
  } catch {}
};

const getAccountsReport = async (params: AccountReportParams) => {
  try {
    const state = useStore.getState();

    const response = await fetch(`${host}/api/player/account-report`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    if (response.status === 200) {
      const json = (await response.json()) as PlayerAccountOverview[];
      return json;
    }
  } catch {}
};

const getPlayerStats = async (period: FinancePeriodEnum) => {
  try {
    const state = useStore.getState();

    const response = await fetch(`${host}/api/admin/players-summary/${period}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = (await response.json()) as IPlayerStats;
      return json;
    }
  } catch {}
};

const getPendingWithdrawals = async () => {
  try {
    const state = useStore.getState();

    const response = await fetch(`${host}/api/player/open-withdrawals`, {
      method: 'Get',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = (await response.json()) as UserTransactions[];
      state.setPendingWithdrawals(json);
      return json;
    }
  } catch {}
};

const getOperatorWithdrawals = async (id: string) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/player/operator-withdrawal`, {
      method: 'Post',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: id, amount: 50 }),
    });
    if (response.status === 200) {
      const json = await response.json();
      return json;
    }
  } catch {}
};

const completePendingWithdrawal = async (id: number) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/player/complete-withdrawal`, {
      method: 'Post',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ transactionId: id }),
    });
    if (response.status === 200) {
      const json = await response.json();
      return json.success;
    }
  } catch {}
};

const failPendingWithdrawal = async (id: number) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/player/fail-withdrawal`, {
      method: 'Post',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ transactionId: id }),
    });
    if (response.status === 200) {
      const json = await response.json();
      return json.success;
    }
  } catch {}
};

const financeSummary = async (period: FinancePeriodEnum) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/admin/finance-summary/${period}`, {
      method: 'Get',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = (await response.json()) as FinancePeriod;
      return json;
    }
  } catch {}
};

const getAllAccountHistory = async () => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/admin/accounts-history`, {
      method: 'Get',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = (await response.json()) as HistoryActivity[];
      return json;
    }
  } catch {}
};

const toggleFlaggedStatus = async (id: string, isFlagged: boolean) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/player/toggle-flagged`, {
      method: 'Post',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: id, isFlagged: isFlagged }),
    });
    if (response.status === 200) {
      const json = await response.json();
      return json.success;
    }
  } catch {}
};

const toggleAdminRole = async (id: string, isAdmin: boolean) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/admin/toggle-admin`, {
      method: 'Post',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: id, isAdmin: isAdmin }),
    });
    if (response.status === 200) {
      const json = await response.json();
      return json;
    }
  } catch {}
};

const toggleSuperAdminRole = async (id: string, isSuperAdmin: boolean) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/admin/toggle-super-admin`, {
      method: 'Post',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: id, isSuperAdmin: isSuperAdmin }),
    });
    if (response.status === 200) {
      const json = await response.json();
      return json.success;
    }
  } catch {}
};

const toggleBannedStatus = async (id: string, isBanned: boolean) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/player/toggle-banned`, {
      method: 'Post',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: id, isBanned: isBanned }),
    });
    if (response.status === 200) {
      const json = await response.json();
      return json.success;
    }
  } catch {}
};

const toggleVerifiedStatus = async (id: string, isVerified: boolean) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/player/toggle-verified`, {
      method: 'Post',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: id, isVerified: isVerified }),
    });
    if (response.status === 200) {
      const json = await response.json();
      return json.success;
    }
  } catch {}
};

const toggleClosedStatus = async (id: string) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/player/close-user-account/${id}`, {
      method: 'Post',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = await response.json();
      return json;
    }
  } catch {}
};

const toggleAllMarketStatus = async (areClosed: boolean) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/admin/toggle-all-markets`, {
      method: 'Post',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ areClosed: areClosed }),
    });
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch {}
};

// Get /api/admin/accounts-insight
const getAccountsInsight = async () => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/admin/accounts-insight`, {
      method: 'Get',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = await response.json();
      return json;
    } else {
      return false;
    }
  } catch {}
};

const getUsersForSalesManagement = async (request: UserSalesManagementFilter) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/admin/users-by-admin`, {
      method: 'Post',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });
    if (response.status === 200) {
      const json = (await response.json()) as PaginatedData;
      return json;
    }
  } catch {}
};

const assignSalesAdmin = async (request: AssignSalesAdmin) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/admin/assign-sales-admin`, {
      method: 'Post',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });
    if (response.status === 200) {
      const json = (await response.json()) as any;
      return json;
    }
  } catch {}
};

const getReferralCodeByID = async (id: string) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/admin/get-referral-code/${id}`, {
      method: 'Get',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      const text = await response.text();
      return text;
    }
  } catch {}
};

const getSalesAdminByReferralCode = async (id: string) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/admin/check-referral/${id}`, {
      method: 'Get',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = (await response.json()) as UserMin[];
      return json;
    }
  } catch {}
};

const getSalesAdminByUserId = async (id: string) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/admin/get-sales-admin/${id}`, {
      method: 'Get',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = (await response.json()) as UserMin;
      return json;
    }
  } catch {}
};

const addNoteToUser = async (request: UserNotes) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/admin/add-user-note`, {
      method: 'Post',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });
    if (response.status === 200) {
      const json = (await response.json()) as any;
      return json;
    }
  } catch {}
};

//TODO : connect to back office endpoint
const getPlayerActiveWagers = (id: string) => {
  return getActiveWagers();
};

//TODO : connect to back office endpoint
const getPlayerProfile = (id: string) => {
  return getPlayer();
};

//TODO : connect to back office endpoint
const updatePlayerLimitsAdmin = (id: string, limits: Limits) => {
  return updatePlayerLimits(limits);
};

export {
  getActiveWagers,
  getWagerHistory,
  searchAccounts,
  toggleAdminRole,
  toggleSuperAdminRole,
  getAllAccountHistory,
  getPlayerActiveWagers,
  getPlayerProfile,
  updatePlayerLimitsAdmin,
  getPendingWithdrawals,
  completePendingWithdrawal,
  failPendingWithdrawal,
  financeSummary,
  getAccountsInsight,
  toggleFlaggedStatus,
  toggleBannedStatus,
  toggleVerifiedStatus,
  getOperatorWithdrawals,
  toggleAllMarketStatus,
  getPlayerStats,
  getAccountsReport,
  toggleClosedStatus,
  getUsersForSalesManagement,
  assignSalesAdmin,
  getReferralCodeByID,
  getSalesAdminByReferralCode,
  getSalesAdminByUserId,
  addNoteToUser,
};
