import { Title, Text, Stack, createStyles, keyframes } from '@mantine/core';
import { Game, GameThemeEnum } from '../../../api/interfaces';

const loader = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

const useStyles = createStyles((theme, game: Game ) => ({
  chartLoaderWrapper: {
    backgroundColor: theme.fn.rgba(game.color, 0.7),
    margin: 'auto',
    position: 'absolute',
    right: 'calc(50% - 130px)',
    top: '10%',
    padding: '32px',
    width: '260px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.lg,
  },
  chartLoader: {
    border: `4px solid ${game.color} `,
    borderTop: `4px solid ${game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(game.theme, 0.8)}`,
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    animation: `${loader} 2s linear infinite`,
  },
  text: {
    color: `${game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(game.color, 0.8)} !important`,
  },
  chartError: {
    background: theme.other.darkBackground,
  },

}));

export const GameStateBubble = (props: {
  title: string;
  game: Game;
  subtext?: string;
  loader?: boolean;
  error?: boolean;
}) => {
  const { classes } = useStyles(props.game);
  return (
    <Stack className={`${classes.chartLoaderWrapper} ${props.error ? classes.chartError : ""}`}>
      {props.loader && <div className={classes.chartLoader}></div>}
      <Title className={`${props.error ? "" : classes.text} `} order={2} >{props.title}</Title>
      {props.subtext && <Text className={`${props.error ? "" : classes.text} `}>{props.subtext}</Text>}
    </Stack>
  );
};
