import { Title, Anchor, Text, List, Modal, Group, TextInput, Box } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useStore } from '../../state/state';
import { GreenButton } from '../buttons/GreenButton';
import { useEffect, useState } from 'react';
import { requestWithdrawal, updatePlayer } from '../../api/player';
import { verficiationDocumentation } from '../../utils/emailTemplates';
import { ReactComponent as IconEuro } from '../../assets/icons/icon-currency-euro.svg';

export enum WithdrawalModalEnum {
  Withdrawal = 'withdrawal',
  Requested = 'requested',
}

export const KYCandWithdraw = () => {
  const player = useStore((state) => state.player);
  const [KYCModalOpened, setKYCModalOpened] = useState(false);
  const [withdrawalModalState, setWithdrawalModalState] = useState<WithdrawalModalEnum>(WithdrawalModalEnum.Withdrawal);
  const [withdrawalModalOpened, setWithdrawalModalOpened] = useState(false);
  const balance = useStore((state) => state.balance);

  useEffect(() => {
    request.reset();
  }, [player]);

  const request = useForm({
    initialValues: {
      iban: `${player?.iban !== undefined ? player?.iban : ''}`,
      amount: 0,
    },

    validate: {
      iban: (value) => (/^[A-Z]{2}[0-9]{2}[A-Z0-9]{11}([A-Z0-9]?){0,16}$/.test(value) ? null : 'Invalid IBAN'),
      amount: (value) => (isInRange(value) ? null : 'Invalid Amount'),
    },
  });

  const isInRange = (value: number) => {
    if (balance !== undefined) {
      return value >= 20 && value <= balance.balance;
    }
    return false;
  };

  const saveChanges = (iban: string) => {
    updatePlayer({ iban: iban });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (request.validate().hasErrors === false) {
      var iban = request.getInputProps('iban').value;

      if (iban !== player?.iban) {
        saveChanges(iban);
      }

      requestWithdrawal(request.getInputProps('amount').value, iban).then(() => {
        request.reset();
        setWithdrawalModalState(WithdrawalModalEnum.Requested);
      });
    }
  };

  const closeWithdrawalModal = () => {
    setWithdrawalModalOpened(false);
    setWithdrawalModalState(WithdrawalModalEnum.Withdrawal);
  };

  return (
    <>
      <Modal
        overlayOpacity={0.5}
        centered
        title={<Title order={3}>Required Documents</Title>}
        opened={KYCModalOpened}
        onClose={() => setKYCModalOpened(false)}
        radius="lg"
        size="lg"
      >
        <List>
          <List.Item> A certified copy of your ID or Passport</List.Item>
          <List.Item> A recent copy of your utility bill for proof of address</List.Item>
        </List>
      </Modal>
      <Modal
        overlayOpacity={0.5}
        centered
        title={
          <Title order={3}>
            {withdrawalModalState === WithdrawalModalEnum.Requested ? 'Withdrawal Requested' : 'Request Withdrawal'}
          </Title>
        }
        opened={withdrawalModalOpened}
        onClose={() => closeWithdrawalModal()}
        radius="lg"
        size="lg"
      >
        <Box mb={16}>
          <Text size="sm" align="center">
            Minimum withdrawal amount is &euro;20
          </Text>
        </Box>

        {withdrawalModalState === WithdrawalModalEnum.Withdrawal ? (
          <form onSubmit={handleSubmit}>
            <Group align="center" grow direction="row" sx={{ height: '98px' }} mb="xs">
              <TextInput
                required
                label="IBAN"
                placeholder="IBAN number"
                sx={{ marginBottom: 'auto' }}
                radius="md"
                size="lg"
                {...request.getInputProps('iban')}
              />
              <TextInput
                required
                label="Amount"
                icon={<Title order={3}>{player?.currency ? player.currency : 'EUR'}</Title>}
                placeholder="Withdrawl amount"
                sx={{
                  marginBottom: 'auto',
                }}
                radius="md"
                size="lg"
                {...request.getInputProps('amount')}
              />
            </Group>
            <GreenButton
              styles={{
                root: { width: '100%' },
              }}
              type="submit"
            >
              Submit Request
            </GreenButton>
          </form>
        ) : (
          <Text>Your withdrawal request has been submitted funds are available in 1-2 business days.</Text>
        )}
      </Modal>
      <Title order={2}>Withdraw Funds from your FXBet Account</Title>
      {player?.isVerified ? (
        <>
          <Title order={3}>Request a withdrawl</Title>
          <Text>
            Initiate a SEPA standard bank transfer from FXBet to any IBAN bank account in participating EU and EFTA
            countries. Funds are available in 1-2 business days
          </Text>
        </>
      ) : (
        <>
          <Title order={3}>Verification</Title>
          <Text>
            In order to withdraw funds from your FXBet account, we will need to first verify your identity as part of
            our regulatory and licensing requirements.
          </Text>
          <Text>
            Please supply
            <Anchor
              variant="link"
              sx={{
                textDecoration: 'underline',
              }}
              onClick={() => setKYCModalOpened(true)}
              pl={8}
              pr={8}
            >
              these documents
            </Anchor>
            by clicking the button below.
          </Text>
        </>
      )}
      {player?.isVerified ? (
        <GreenButton
          onClick={() => setWithdrawalModalOpened(true)}
          styles={{
            root: { width: '100%' },
          }}
        >
          <Text>Request a withdrawal</Text>
        </GreenButton>
      ) : (
        <GreenButton
          styles={{
            root: { width: '100%' },
          }}
        >
          <Anchor
            underline={false}
            href={verficiationDocumentation(player?.firstName, player?.lastName, player?.email)}
          >
            <Text>Start Document Verification</Text>
          </Anchor>
        </GreenButton>
      )}
    </>
  );
};
