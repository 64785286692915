import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => {
  return {
    container: {
      backgroundColor: theme.other.tabBackgroundColor,
      borderRadius: '20px',
      padding: '16px 16px 32px',
    },
    tableHeader: {
      borderTop: `2px solid ${theme.other.blue}`,
      borderBottom: `2px solid ${theme.other.blue}`,
    },
    removeScroll: {
      msOverflowStyle: 'none' /* for Internet Explorer, Edge */,
      scrollbarWidth: 'none' /* for Firefox */,
      overflowY: 'scroll',

      '&::-webkit-scrollbar': {
        display: 'none' /* for Chrome, Safari, and Opera */,
      },
    },
  };
});

export { useStyles };
