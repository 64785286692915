import { Divider, Modal, Stack, Title, Text, Group, Space } from '@mantine/core';
import { UserTransactions } from '../../api/interfaces';
import { GreenButton } from '../buttons/GreenButton';
import { formatCurrency, formatCurrencySymbol, formatDate } from '../../utils/formatters';

interface ModalHandler {
  open: () => void;
  close: () => void;
  toggle: () => void;
}

const WithdrawalDetialModal = ({
  modalHandler,
  modalState,
  withdrawal,
}: {
  modalHandler: ModalHandler;
  modalState: boolean;
  withdrawal: UserTransactions;
}) => {
  return (
    <Modal
      opened={modalState}
      onClose={modalHandler.close}
      withCloseButton={false}
      centered={true}
      radius="lg"
      padding={32}
    >
      <Stack>
        <Title order={2} align="center">
          Withdrawal Request Details
        </Title>
        <Divider size={2} />
        <Group grow>
          <Text weight="bold" align="center">
            Username:
          </Text>
          <Text>{withdrawal.userName}</Text>
        </Group>
        <Group grow>
          <Text weight="bold" align="center">
            Amount:
          </Text>
          <Text>{`${formatCurrencySymbol(withdrawal.currency)} ${formatCurrency(withdrawal.amount)}`}</Text>
        </Group>
        <Group grow>
          <Text weight="bold" align="center">
            IBAN:
          </Text>
          <Text>{withdrawal.userIban}</Text>
        </Group>
        <Group grow>
          <Text weight="bold" align="center">
            Initiated At:
          </Text>
          <Text>{formatDate(withdrawal.initiatedAt.toString())}</Text>
        </Group>
        <Divider size={2} />
      </Stack>
      <Space h="lg" />
      <Group grow align="center">
        <GreenButton onClick={() => modalHandler.close()} type="submit">
          <Text weight="bold">View User Profile</Text>
        </GreenButton>
      </Group>
    </Modal>
  );
};

export default WithdrawalDetialModal;
