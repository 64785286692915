import { Textarea, Stack, Title, Container, Text, TextInput, Group } from '@mantine/core';
import { ProceedButton } from '../../components/buttons/ProceedButton';
import { sendContactUsMessage } from '../../api/contactUsApi';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../state/state';
import { useForm } from '@mantine/form';
import { PlayerHub } from '../../api/playerhub';
// @ts-ignore
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { verifyReCAPTCHA } from '../../api/recaptchAPI';

export const ContactUs = () => {
  const [messageSent, setMessageSent] = useState(false);
  const player = useStore((state) => state.player);
  const auth = useStore((state) => state.auth);
  const navigate = useNavigate();
  const setHeaderMode = useStore((state) => state.setHeaderMode);

  const sendMessage = () => {
    ReactRecaptcha3.getToken().then(async (token: any) => {
      if (request.validate().hasErrors === false) {
        ReactRecaptcha3.getToken().then(async (token: any) => {
          sendContactUsMessage(
            request.getInputProps('name').value,
            request.getInputProps('email').value,
            request.getInputProps('message').value,
            token
          ).then(() => setMessageSent(true));
        });
      } else {
        console.log('error in verification');
      }
    });
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      PlayerHub.getInstance();
      setHeaderMode('play');
    }
    if (player) {
      request.values.name = player ? `${player.firstName} ${player.lastName}` : '';
      request.values.email = player ? `${player.email}` : '';
    }
  }, [auth.isAuthenticated, player]);

  const navToPlay = () => {
    navigate('/play', { replace: true });
  };

  const request = useForm({
    initialValues: {
      name: player ? `${player.firstName} ${player.lastName}` : '',
      email: player ? `${player.email}` : '',
      message: '',
    },

    validate: {
      name: (value) => (value.length < 1 ? 'Name is required' : null),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      message: (value) =>
        value.length < 1 ? 'Message is required' : value.length > 300 ? 'Message is too long' : null,
    },
  });

  return (
    <Stack align="center">
      <Title order={1}>{messageSent ? 'Message sent' : 'Contact the FXBet team'}</Title>
      <Title order={3} align="center">
        {messageSent
          ? 'Our team will be in touch with you shortly'
          : 'Reach out to customer support for help, technical problems, suggestions or feedback.'}
      </Title>
      {!messageSent && (
        <Container sx={{ width: '100%' }}>
          {!auth.isAuthenticated && (
            <Group sx={{ height: '84px' }} align="top" grow>
              <TextInput
                required
                label="Full Name"
                placeholder="Enter your full name"
                {...request.getInputProps('name')}
              />
              <TextInput
                required
                label="Email"
                placeholder="Enter your email address"
                {...request.getInputProps('email')}
              />
            </Group>
          )}
          <Textarea
            mt={3}
            placeholder="Hi FXBet, "
            label="Tell us what's on your mind..."
            minRows={6}
            {...request.getInputProps('message')}
          />
          <Text align="right">Characters remaining {request.getInputProps('message').value.length} / 300</Text>
          <Group position="apart" mt="sm">
            <ProceedButton
              disabled={
                request.getInputProps('email').value === '' ||
                request.getInputProps('name').value === '' ||
                request.getInputProps('message').value === ''
              }
              type="submit"
              onClick={sendMessage}
              styles={{
                root: { minWidth: 'fit-content' },
              }}
            >
              Send message
            </ProceedButton>
          </Group>
        </Container>
      )}
      {messageSent && (
        <ProceedButton
          onClick={navToPlay}
          styles={{
            root: { minWidth: 'fit-content', marginTop: '16px' },
          }}
        >
          Choose a new game to play
        </ProceedButton>
      )}
    </Stack>
  );
};

export default ContactUs;
