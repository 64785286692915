import { Title, Text, useMantineTheme, Group, Anchor, Button } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Link } from 'react-router-dom';
import { Game, GameThemeEnum, Market, MarketOpenEnum, WagerStateEnum } from '../api/interfaces';
import slugify from 'slugify';
import { useMediaQuery } from '@mantine/hooks';
import { useMarketStore, useStore } from '../state/state';

export const WagerCompleteNotification = (game: Game, market: Market, won: WagerStateEnum) => {
  const isDark = (won === WagerStateEnum.Won && game.theme === GameThemeEnum.Dark);

  showNotification({
    color: `${won === WagerStateEnum.Won ? game.color : ''} !important`,
    icon: (
      <Link
        to={`/play/${slugify(game.name, { lower: true })}/${slugify(market.currencyPair, { lower: true })}`}
        style={{
          textDecoration: 'none',
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img src={game.iconGame} height="35px" width="35px" style={{ filter: 'invert(1)' }} />
      </Link>
    ),
    disallowClose: true,
    autoClose: 6000,
    message: <Content won={won} game={game} market={market} />,
    styles: (theme) => ({
      root: {
        ":not(:hover)": {
          ".mantine-Text-root, .mantine-Title-root": {
            color: isDark ? theme.fn.darken(game.color, 0.9) : theme.other.white,
          },
        },
        background: `linear-gradient(${won === WagerStateEnum.Won ? game.gradient : '#052130 44%, #000000 100%'})`,
        border: 'none',
        padding: '4px',
        width: 'auto',
        '&:hover': {
          background: `linear-gradient(180deg, #00213D 0%, #06061B 100%)`,
          color: `${theme.white} !important`,
        },
        [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
          position: 'fixed',
          width: '100vw',
          left: '0',
          bottom: '60px'
        },
        [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
          position: 'fixed',
          top: '11px',
          left: '58px',
          bottom: 'unset',
        },
        [`@media (min-width: ${theme.breakpoints.md}px)`]: {
          position: 'fixed',
          top: '11px',
          left: '118px',
          bottom: 'unset',
        },
      },
      icon: {
        background: 'none !important',
      },
    })
  });
};

const Message = (wagerResult: WagerStateEnum, game: Game) => {
  let message = ['', ''];
  const mobileView = useMediaQuery(`(max-width: ${useMantineTheme().breakpoints.xs}px)`);

  if (wagerResult === WagerStateEnum.Won) {
    message = ['CONGRATULATIONS!', 'You beat the odds'];
  }
  if (wagerResult === WagerStateEnum.Lost) {
    message = ['YOU LOST THIS ROUND', 'Better luck next time!'];
  }
  if (wagerResult === WagerStateEnum.Draw) {
    message = ['THE GAME IS A DRAW', 'Try again!'];
  }
  if (wagerResult === WagerStateEnum.Cancelled) {
    message = ['THE WAGER WAS CANCELLED', 'Try again!'];
  }

  return (
    <Group align='center' direction={`${mobileView ? 'column' : 'row'}`} spacing={mobileView ? 2 : 8} noWrap sx={{
      textWrap: 'nowrap'
    }}>
      <Title order={3}  >{message[0]}</Title>
      <Text> {message[1]}</Text>
    </Group>)
};

const Content = (props: { won: WagerStateEnum; game: Game; market: Market }) => {
  const { wagering, placeWager } = useMarketStore(props.market)(
    (state) => ({ wager: state.wager, wagering: state.wagering, placeWager: state.placeWager }),
  );
  const balance = useStore((state) => state.balance?.balance ?? 0);

  const disabled =
    wagering.stake > balance ||
    wagering.stake < props.market.minWager ||
    wagering.stake > props.market.maxWager ||
    props.market.open === MarketOpenEnum.Closed ||
    props.market.open === MarketOpenEnum.Full;

  return (
    <Group direction='row'>
      <Anchor underline={false} href={`/play/${slugify(props.game.name, { lower: true })}/${slugify(props.market.currencyPair, { lower: true })}`} sx={(theme) => ({
        textDecoration: 'none',
      })}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{
            display: 'flex',
            flex: '9',
            justifyContent: 'center',
            alignContent: 'center',
            flexDirection: 'row',
          }}>
            {Message(props.won, props.game)}
          </div>
          <div style={{ display: 'flex', flex: '1', justifyContent: 'center' }}>
            <img src={props.game.iconGame} height="35px" width="35px" style={{ filter: 'invert(1)' }} />
          </div>
        </div>
      </Anchor>
      <Button
        disabled={disabled}
        variant="filled"
        radius="lg"
        size="lg"
        sx={(theme) => ({
          height: '30px',
          backgroundColor: props.game.color,
          color: props.game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(props.game.color, 0.8),
          'svg g path': {
            fill: `${props.game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(props.game.color, 0.8)
              } !important`,
          },
          '&:hover': {
            backgroundColor: theme.fn.darken(props.game.color, 0.2),
          },
          '&:disabled': {
            background: `${theme.fn.rgba(props.game.color, 0.7)} !important`,
            color: `${theme.fn.lighten(props.game.color, 0.5)} !important`,
            'svg g path': {
              fill: `${theme.fn.lighten(props.game.color, 0.5)} !important`,
            },
          },
        })}
        onClick={() => placeWager()}
      >
        Repeat
      </Button>
    </Group>
  );
};
