import { Box, Button, Divider, Group, Stack, Table, Title, Text, Grid, Space } from '@mantine/core';
import { useStyles } from '../styles';
import { financePeriodFormatter, formatCurrency, formatCurrencySymbol, formatDate } from '../../../utils/formatters';
import { FinancePeriod, UserTransactions, WagerActivityTimeFilter } from '../../../api/interfaces';
import { completePendingWithdrawal, failPendingWithdrawal } from '../../../api/backoffice';
import { Dispatch, SetStateAction, useState } from 'react';
import { ActiveGradient, InActiveGradient } from '../../../utils/consts';
import WithdrawalDetialModal from '../../../components/modals/WithDrawalDetailModal';
import { useDisclosure } from '@mantine/hooks';
import { useStore } from '../../../state/state';

const PendingWithdrawals = ({
  pendingWithdrawals,
  timeSpan,
  financialSummary,
  setTimeSpan,
  getWithdrawals,
}: {
  pendingWithdrawals: UserTransactions[];
  timeSpan: WagerActivityTimeFilter;
  financialSummary: FinancePeriod;
  setTimeSpan: Dispatch<SetStateAction<WagerActivityTimeFilter>>;
  getWithdrawals: Dispatch<SetStateAction<void>>;
}) => {
  const { classes } = useStyles();
  const filterButtons: WagerActivityTimeFilter[] = ['24 Hours', '7 Days', '1 Month', '6 Months'];
  const [modalState, modalHandler] = useDisclosure(false);
  const [selectedItem, setSelectedItem] = useState<UserTransactions | null>(null);
  const auth = useStore((state) => state.auth);

  const onComplete = async (withdrawalId: number) => {
    const result = await completePendingWithdrawal(withdrawalId);
    if (result) {
      getWithdrawals();
    }
  };

  const onReject = async (withdrawalId: number) => {
    const result = await failPendingWithdrawal(withdrawalId);
    if (result) {
      getWithdrawals();
    }
  };

  const setDialogDetails = (transaction: UserTransactions) => {
    setSelectedItem(transaction);
    modalHandler.open();
  };

  const timePeriodPipe = (timespan: WagerActivityTimeFilter) => {
    if (timespan.includes('6 Months')) {
      return 'Months';
    } else {
      return financePeriodFormatter(timespan);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Title align="left" sx={{ flexGrow: 1 }} order={1}>
          Wallet Overview
        </Title>
      </Box>
      <Divider size={2} sx={{ margin: '24px 0px' }} />
      <Grid>
        <Grid.Col span={6}>
          <Box className={classes.container} sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Group grow position="apart" mt={4}>
              <Title order={2}>FXBet Balance</Title>
              <Box sx={{ display: 'flex', justifyContent: 'end', marginRight: '16px' }}>
                <Title order={2}>
                  {formatCurrencySymbol('EUR')}{' '}
                  {formatCurrency(financialSummary?.period.spend - financialSummary?.period.winnings)}
                </Title>
              </Box>
            </Group>
            <Space h="md" />
            <Divider size={2} mt={2} />
            <Space h="md" />
            <Group sx={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
              <Stack spacing="md">
                <Title align="center" order={2}>
                  Choose time span
                </Title>
                {filterButtons.map((button, index) => (
                  <Button
                    key={index}
                    onClick={() => setTimeSpan(button)}
                    variant="gradient"
                    radius="lg"
                    gradient={timeSpan === button ? ActiveGradient : InActiveGradient}
                    sx={{ height: '50px', width: '190px' }}
                  >
                    <Title order={3}>{button}</Title>
                  </Button>
                ))}
              </Stack>
              <Box sx={{ flex: 1, display: 'flex' }}>
                <Grid sx={{ width: '100%', height: '100%' }}>
                  <Grid.Col span={6}>
                    <Box className={classes.cardContainer}>
                      <Stack align="center" spacing={14}>
                        <Title order={2}>Total</Title>
                        <Title order={2}>FXBet Profits</Title>
                        <Title order={1} sx={{ margin: '32px 0px' }}>
                          {formatCurrencySymbol('EUR')} {formatCurrency(financialSummary.period.profit)}
                        </Title>
                        {percentageDifference(financialSummary.period.profit, financialSummary.periodBefore.profit)}
                        <Text align="center">Compared to previous {timePeriodPipe(timeSpan)}</Text>
                      </Stack>
                    </Box>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Box className={classes.cardContainer}>
                      <Stack align="center" spacing={14}>
                        <Title order={2}>Total</Title>
                        <Title order={2}>Player Spend</Title>
                        <Title order={1} sx={{ margin: '32px 0px' }}>
                          {formatCurrencySymbol('EUR')} {formatCurrency(financialSummary?.period.spend)}
                        </Title>
                        {percentageDifference(financialSummary.period.spend, financialSummary.periodBefore.spend)}
                        <Text align="center">Compared to previous {timePeriodPipe(timeSpan)}</Text>
                      </Stack>
                    </Box>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Box className={classes.cardContainer}>
                      <Stack align="center" spacing={14}>
                        <Title order={2}>Total</Title>
                        <Title order={2}>Player Winnings</Title>
                        <Title order={1} sx={{ margin: '32px 0px' }}>
                          {formatCurrencySymbol('EUR')} {formatCurrency(financialSummary?.period.winnings)}
                        </Title>
                        {percentageDifference(financialSummary.period.winnings, financialSummary.periodBefore.winnings)}
                        <Text align="center">Compared to previous {timePeriodPipe(timeSpan)}</Text>
                      </Stack>
                    </Box>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Box className={classes.cardContainer}>
                      <Stack align="center" spacing={14}>
                        <Title order={2}>Total</Title>
                        <Title order={2}>Player Losses</Title>
                        <Title order={1} sx={{ margin: '32px 0px' }}>
                          {formatCurrencySymbol('EUR')} {formatCurrency(financialSummary?.period.losses)}
                        </Title>
                        {percentageDifference(financialSummary.period.losses, financialSummary.periodBefore.losses)}
                        <Text align="center">Compared to previous {timePeriodPipe(timeSpan)}</Text>
                      </Stack>
                    </Box>
                  </Grid.Col>
                </Grid>
              </Box>
            </Group>
          </Box>
        </Grid.Col>
        <Grid.Col span={6}>
          <Box className={classes.container} sx={{ width: '100%' }}>
            <Group grow position="apart">
              <Title order={2}>PENDING Withdrawal Requests</Title>
              <Box sx={{ display: 'flex', justifyContent: 'end', marginRight: '16px' }}>
                <Title className={classes.indicatorContainer} order={2}>
                  {pendingWithdrawals.length}
                </Title>
              </Box>
            </Group>
            <Space h="md" />
            <Table>
              <thead className={classes.tableHeader}>
                <tr>
                  <th>
                    <Text>Player</Text>
                  </th>
                  <th>
                    <Text align="center">Date Requested</Text>
                  </th>
                  <th>
                    <Text align="center">Amount</Text>
                  </th>
                  <th>
                    <Text align="center">Actions</Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {pendingWithdrawals.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <Text>{item.userName}</Text>
                      </td>
                      <td>
                        <Text align="center">{formatDate(item.initiatedAt.toString(), false)}</Text>
                      </td>
                      <td>
                        <Text align="center">{`${formatCurrencySymbol(item.currency)} ${formatCurrency(
                          item.amount
                        )}`}</Text>
                      </td>
                      <td>
                        <Group align="center" grow>
                          <Button
                            onClick={() => onComplete(item.id)}
                            radius="lg"
                            variant="gradient"
                            gradient={{ from: '#D9E021', to: '#009245', deg: 180 }}
                          >
                            ✓
                          </Button>
                          <Button
                            onClick={() => onReject(item.id)}
                            radius="lg"
                            variant="gradient"
                            gradient={{ from: '#FF5353', to: '#630202', deg: 180 }}
                          >
                            X
                          </Button>
                          <Button
                            onClick={() => setDialogDetails(item)}
                            radius="lg"
                            variant="gradient"
                            gradient={{ from: '#2A94D9', to: '#15499D', deg: 180 }}
                          >
                            Details
                          </Button>
                        </Group>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Box>
        </Grid.Col>
      </Grid>
      {selectedItem && (
        <WithdrawalDetialModal modalHandler={modalHandler} modalState={modalState} withdrawal={selectedItem} />
      )}
    </>
  );
};

const percentageDifference = (current: number, previous: number) => {
  const calculatePercentage = (currentValue: number, previousValue: number): number => {
    if (previousValue === 0) {
      return 0;
    }
    return ((currentValue - previousValue) / previousValue) * 100;
  };

  const percentageDifference = calculatePercentage(current, previous);
  const difference = percentageDifference.toFixed(1);

  return (
    <Title order={2} sx={{ color: `${percentageDifference > 0 ? '#88c32c' : '#eb6c35'}` }}>
      {previous === 0 ? 'No previous data' : difference + '%'}
    </Title>
  );
};

export default PendingWithdrawals;
