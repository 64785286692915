import { Space, Title } from '@mantine/core';
import MasterControls from './master-controls/MasterControls';
import GameControl from './game-control/GameControl';
import { getGames, getMarketStatistics } from '../../api/game';
import { useEffect, useState } from 'react';
import { MarketStatistics, WagerActivityTimeFilter } from '../../api/interfaces';
import { useStore } from '../../state/state';
import { calculateDateFromToday } from '../../utils/formatters';
import GameStats from './game-stats/GameStats';

const GameControls = () => {
  const games = useStore((state) => state.games);
  const [activeFilter, setActiveFilter] = useState<WagerActivityTimeFilter>('24 Hours');
  const [marketStatistics, setMarketStatistics] = useState<MarketStatistics[]>([]);

  const getGameInsights = async () => {
    const date = await calculateDateFromToday(activeFilter);
    const marketStatistics = await getMarketStatistics('?since=' + date);
    if (marketStatistics) {
      setMarketStatistics(marketStatistics);
    }
  };

  useEffect(() => {
    if (!games || games.length === 0) {
      getGames();
    }
  }, [games]);

  useEffect(() => {
    getGameInsights();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter]);

  return (
    <>
      <Title align="center" order={1}>
        GAME Controls
      </Title>
      <Space h={32} />
      <GameStats activeFilter={activeFilter} setActiveFilter={setActiveFilter} marketStatistics={marketStatistics} />
      <Space h={32} />
      <MasterControls />
      <Space h={32} />
      <GameControl gamesList={games} />
      <Space h={32} />
    </>
  );
};

export default GameControls;
