import { Group, Box, Title, Text, Paper } from '@mantine/core';
import { Game, GameThemeEnum, Market, Wager } from '../../../../api/interfaces';
import { useMarketStore } from '../../../../state/state';
import { toMaxPrecision } from '../../../../utils/formatters';

export const WagerPosition = (props: { game: Game; market: Market; wager: Wager }) => {
  const ticks = useMarketStore(props.market)((state) => state.ticks);

  const openingValue = ticks.find((x) => x.timestamp === props.wager.openingTimestamp)?.value;
  const currentValue = props.wager.closingValue ?? ticks[ticks.length - 1]?.value;

  if (!openingValue || !currentValue) return null;

  return (
    <Group position="right">
      <Group
        noWrap
        position="apart"
        sx={(theme) => ({
          width: '390px',
          height: '60px',
          [`@media (max-width: ${theme.breakpoints.sm}px)`]: { width: '100%' },
        })}
      >
        <Box sx={{ alignSelf: 'start', paddingTop: '4px' }}>
          <Title order={3}>Current position</Title>
          <Text size="xs">The direction the feed is going</Text>
        </Box>
        <Paper
          radius="md"
          sx={(theme) => ({
            backgroundColor: props.game.color,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            height: '60px',
            width: '170px',
            padding: '4px 12px 0 20px',
            '.mantine-Text-root, .mantine-Title-root': {
              color: `${
                props.game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(props.game.color, 0.8)
              } !important`,
            },
          })}
        >
          <Group position="apart">
            <Text size="sm">from</Text>
            <Text size="lg" weight="bold">
              {toMaxPrecision(openingValue, 6)}
            </Text>
          </Group>
          <Group position="apart">
            <Text size="sm">to</Text>
            <Text size="lg" weight="bold">
              {toMaxPrecision(currentValue, 6)}
            </Text>
          </Group>
        </Paper>
      </Group>
    </Group>
  );
};
