import { Group, Title, Text, Paper, Center, Box, useMantineTheme } from '@mantine/core';
import { DurationUnitEnum, Game, GameThemeEnum, Market, Wager, WagerStateEnum } from '../../../../api/interfaces';
import { formatDurationUnit } from '../../../../utils/formatters';
import { useEffect, useState } from 'react';
import { useMarketStore } from '../../../../state/state';
import { useMediaQuery } from '@mantine/hooks';

export const WagerTimeRemaining = (props: {
  game: Game;
  market: Market;
  wager: Wager;
  updateCallback?: Function;
  hideText?: boolean;
}) => {
  const mobileView = useMediaQuery(`(max-width: ${useMantineTheme().breakpoints.xs}px)`);

  if (!props.wager || !props.wager.openingTimestamp) return null;

  const completed =
    props.wager.state === WagerStateEnum.Draw ||
    props.wager.state === WagerStateEnum.Lost ||
    props.wager.state === WagerStateEnum.Won
      ? true
      : false;

  return (
    <>
      <Group position="apart" noWrap mt="xs">
        {!props.hideText && (
          <Box>
            <Title order={3}>Time remaining</Title>
            <Text size="xs">Time left before this bet is complete</Text>
          </Box>
        )}

        <Paper radius="md" sx={{ backgroundColor: props.game.color }}>
          <Center sx={{ height: '50px', width: `${mobileView ? '145px' : '170px'}` }}>
            {props.wager.duration.unit === DurationUnitEnum.Ticks ? (
              <TickTimeRemaining
                market={props.market}
                wager={props.wager}
                updateCallback={props.updateCallback}
                game={props.game}
                wagerCompleted={completed}
              />
            ) : (
              <RealTimeRemaining
                wager={props.wager}
                updateCallback={props.updateCallback}
                game={props.game}
                wagerCompleted={completed}
              />
            )}
          </Center>
        </Paper>
      </Group>
      <Box></Box>
    </>
  );
};

const TickTimeRemaining = (props: {
  market: Market;
  wager: Wager;
  wagerCompleted: boolean;
  game: Game;
  updateCallback?: Function;
}) => {
  const ticks = useMarketStore(props.market)((state) => state.ticks);

  const ticksSinceWager = ticks.length - ticks.findIndex((x) => x.timestamp === props.wager.openingTimestamp);
  const ticksRemaining = Math.max(0, props.wager.duration.value - ticksSinceWager);
  if (ticksRemaining === 0 && props.updateCallback) {
    props.updateCallback();
  }

  const text = ticksRemaining === 0 ? 'Next tick decides!' : ticksRemaining;

  return (
    <Group
      position="center"
      spacing={0}
      sx={(theme) => ({
        flexGrow: 1,
        justifyContent: 'space-evenly',
        '.mantine-Text-root': {
          color: props.game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(props.game.color, 0.8),
        },
      })}
    >
      <Text size="lg" weight="bold" align="center" sx={{ minWidth: '32px' }}>
        {props.wagerCompleted ? 'Ticks are in!' : text}
      </Text>
      {ticksRemaining > 0 && (
        <Group position="left" direction="column" spacing={0}>
          <Text size="lg" weight="bold" transform="uppercase" sx={{ lineHeight: 1 }}>
            {formatDurationUnit(props.wager.duration.value, props.wager.duration.unit)}
          </Text>
          <Text size="sm" sx={{ lineHeight: 1 }}>
            of <strong>{props.wager.duration.value}</strong> total
          </Text>
        </Group>
      )}
    </Group>
  );
};

const RealTimeRemaining = (props: { wager: Wager; wagerCompleted: boolean; game: Game; updateCallback?: Function }) => {
  const [remaining, setRemaining] = useState('');

  useEffect(() => {
    const interval = window.setInterval(() => {
      const remainingMillis =
        (props.wager?.openingTimestamp ?? 0) + props.wager.duration.value * 60 * 1000 - new Date().getTime();

      const remainingSeconds = Math.ceil(remainingMillis / 1000);
      if (props.wagerCompleted) {
        setRemaining("Time's up!");
        if (props.updateCallback) {
          props.updateCallback();
        }
      } else if (remainingSeconds <= 0) {
        if (props.updateCallback) {
          props.updateCallback();
        }
        setRemaining('Next tick decides!');
      } else if (remainingSeconds === 1) {
        setRemaining('1 second');
      } else if (remainingSeconds <= 60) {
        setRemaining(`${remainingSeconds} seconds`);
      } else {
        const remainingMinutes = Math.floor(remainingMillis / 60000);
        const remainingSeconds = Math.ceil((remainingMillis - remainingMinutes * 60000) / 1000);

        setRemaining(`${remainingMinutes} min ${remainingSeconds} sec`);
      }
    }, 200);

    return () => window.clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.wager.duration.value, props.wager?.openingTimestamp, props.wagerCompleted]);

  return (
    <Text
      size="lg"
      weight="bold"
      sx={(theme) => ({
        lineHeight: 1,
        color: props.game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(props.game.color, 0.8),
      })}
    >
      {remaining}
    </Text>
  );
};
