import { createStyles } from '@mantine/core';
import background from '../../assets/backgrounds/bg-landing-top.jpg';

export const useStyles = createStyles((theme) => {
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      margin: 'auto',
      background: `url('${background}')`,
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    longIcon: {
      backgroundImage: 'linear-gradient(to bottom, yellow , green);',
      height: '100px',
      width: '30px',
      borderRadius: '16px',
      position: 'relative',
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        height: '30px',
      },
    },
    iconRowOuter: {
      position: 'relative',
      justifyContent: 'space-between',
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        flexDirection: 'column',
      },
    },
    line: {
      position: 'absolute',
      top: '50%',
      width: '100%',
      height: '2px',
      backgroundColor: 'white',
    },
    iconRow: {
      display: 'flex',
    },
    longIconText: {
      position: 'absolute',
      bottom: '4px',
      left: '10px',
    },
    signUpNow: {
      backgroundImage: 'linear-gradient(to bottom, yellow , green);',
      width: '100px',
      height: '100px',
      borderRadius: '8px',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2,
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        marginTop: '32px',
      },
    },
    roundIconWrapper: {
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        justifyContent: 'center',
      },
    },
    hiddenOnMobile: {
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        display: 'none',
      },
    },
    mobileCenter: {
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    rowWrapper: {
      width: '45%',
    },
  };
});
