import { Container, List, Paper, Space, Stack, Text, useMantineTheme } from '@mantine/core';
import SectionHeader from '../../components/staticSectionHeader';
import { useStyles } from '../legalPages/styles';

const ComplianceAndArbitrationPolicy = () => {
  const { classes } = useStyles();
  return (
    <Container size="md">
      <Paper
        sx={(theme) => ({
          backgroundColor: theme.fn.rgba(theme.other.darkBackgroundMedium, 0.6),
        })}
        className={classes.paper}
      >
        <SectionHeader order={1} align="center">
          Compliance Policy
        </SectionHeader>
        <Stack pb="lg">
          <Text size="lg" mb={16}>
            FXBet is committed to complying with all applicable laws and regulations governing online gambling. We have
            a comprehensive compliance program in place that includes:
          </Text>
          <List>
            <List.Item>A code of conduct for employees that outlines our expectations for ethical behavior</List.Item>
            <List.Item>
              A risk management framework that identifies and mitigates risks to our compliance program
            </List.Item>
            <List.Item>A training program for employees on our compliance policies and procedures</List.Item>
            <List.Item> Regular audits and reviews of our compliance program to ensure that it is effective</List.Item>
          </List>
          <Text size="lg" my={20}>
            We are committed to providing a safe and fair gaming environment for our customers. We believe that our
            compliance program is an important part of achieving this goal.
          </Text>
        </Stack>
      </Paper>
      <Space h={'xl'} />
      {/* <Paper
        sx={(theme) => ({
          backgroundColor: theme.fn.rgba(theme.other.darkBackgroundMedium, 0.6),
        })}
        className={classes.paper}
      >
        <SectionHeader order={1} align="center">
          Arbitration Policy
        </SectionHeader>
        <Stack pb="lg" mb={theme.spacing.lg}>
          <Text size="lg" mb={16}>
            FXBet is committed to complying with all applicable laws and regulations governing online gambling. We have a comprehensive compliance program in place that includes:
          </Text>
          <Text size="lg" mb={16}>
            The arbitration process will be conducted by a neutral arbitrator who will be selected by the parties. The arbitrator will issue a decision that is binding on both parties.
          </Text>
          <Text>
            We believe that arbitration is a fair and efficient way to resolve disputes. We encourage our customers to use this option if they have a dispute with us.
          </Text>
          <Text weight={700}> Contact Information </Text>
          <Text>If you have any questions about our compliance or arbitration policies, please contact us at:
          </Text>
          <Text>
            Stream Innovation Ltd
            <br />
            5 New Street Square, 5th floor, London EC4A 3BF, United Kingdom
            <br />
            <UnstyledMailtoLink email="connect@innostream.co" />
          </Text>
        </Stack>
      </Paper> */}
    </Container>
  );
};

export default ComplianceAndArbitrationPolicy;
