import { HubConnection } from '@microsoft/signalr';
import create, { StateCreator, UseBoundStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import {
  Currency,
  FavoriteGame,
  Game,
  GameState,
  Market,
  Player,
  PolicyChange,
  Tick,
  UserTransactions,
  Wager,
  WagerStateEnum,
  WagerTypeEnum,
} from '../api/interfaces';
import { placeWager as apiPlaceWager } from '../api/game';
import { SoundManager } from '../utils/SoundManager';
import { loginWithRefreshToken, signOut } from '../api/auth'
// @ts-ignore
import ReactRecaptcha3 from 'react-google-recaptcha3';

interface AuthState {
  isAuthenticated: boolean;

  user?: {
    userId?: string;
    role?: '' | 'Sales' | 'SuperAdmin';
    blocked?: boolean;
  };

  token?: {
    accessToken: string;
    expiresAt: number;
    refreshToken: string;
  };
}

export type Updater<T> = (update: (current: T) => void) => void;
export type KeyedUpdater<KeyT, T> = (id: KeyT, update: (current: T) => void) => void;

interface MarketState {
  wagering: GameState;
  updateWagering: Updater<GameState>;

  wager?: Wager;
  placeWager: () => Promise<void>; // create a new wager from wagering state and send it backend via API
  updateWager: Updater<Wager>; // update wager with results from backend (pushed via SignalR)
  setWager: (wager: Wager) => void; // sync local wager with wager placement confirmation from backend (NB: could be from different device / tab, pushed via SignalR)
  clearWager: () => void; // clears the current wager after the user is done viewing the wager results

  ticks: Tick[];
  trendTick?: Tick;
  setTicks: (ticks: Tick[]) => void;
  addTick: (tick: Tick) => void;
  setTrendTick: (tick: Tick) => void;
}

interface UserBalance {
  balance: number;
  stopLoss: number;
  currency: Currency;
}

type HeaderMode = 'landing' | 'login' | 'play';

interface State {
  auth: AuthState;
  games: Game[];
  hub?: HubConnection;
  balance?: UserBalance;
  headerMode: HeaderMode;
  player?: Player;
  playerProfilePicture?: String;
  audioEnabled: boolean;
  pendingWithdrawals: UserTransactions[];
  favoriteGames: FavoriteGame[];

  setHeaderMode: (mode: HeaderMode) => void;
  setAuth: (auth: AuthState) => void;
  setGames: (games: Game[]) => void;

  updateMarket: KeyedUpdater<number, Market>;
  setHub: (hub: HubConnection) => void;
  updateBalance: Updater<UserBalance>;
  setPlayer: (player: Player) => void;
  setPlayerProfilePicture: (picture: String) => void;
  toggleAudioEnabled: () => void;
  setPendingWithdrawals: (withdrawals: UserTransactions[]) => void;
  setFavoriteGames: (games: FavoriteGame[]) => void;
}

const useStore = create<State>()(
  persist(
    immer((set) => ({
      auth: {
        isAuthenticated: false,
      },
      games: [],
      headerMode: 'landing',

      setHeaderMode: (mode: HeaderMode) => {
        set((state) => {
          state.headerMode = mode;
        });
      },
      setAuth: (auth: AuthState) =>
        set((state) => {
          state.auth = auth;
        }),
      setGames: (games: Game[]) =>
        set((state) => {
          state.games = games;
        }),
      updateMarket: (marketId: number, update: (market: Market) => void) => {
        set((state) => {
          var current = findMarket(state.games, marketId);
          if (current) {
            update(current);
          }
        });
      },
      setHub: (hub: HubConnection) =>
        set((state) => {
          state.hub = hub;
        }),
      updateBalance: (update: (balance: UserBalance) => void) => {
        set((state) => {
          if (!state.balance) {
            state.balance = { balance: 0, currency: 'EUR', stopLoss: 0 };
          }
          update(state.balance);
        });
      },
      setPlayer: (player: Player) =>
        set((state) => {
          state.player = player;
        }),
      setPlayerProfilePicture: (picture: String) =>
      {
        set((state) => {
          state.playerProfilePicture = picture;
        });
      },
      pendingWithdrawals: [],
      setPendingWithdrawals: (withdrawals: UserTransactions[]) =>
        set((state) => {
          state.pendingWithdrawals = withdrawals;
        }),
      audioEnabled: true,
      toggleAudioEnabled: () =>
        set((state) => {
          state.audioEnabled = !state.audioEnabled;
        }),
      favoriteGames: [],
      setFavoriteGames: (games: FavoriteGame[]) => {
        set((state) => {
          state.favoriteGames = games;
        });
      }
    })),
    {
      name: 'fxbet-auth', // unique name
      getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
      partialize: (state) => ({ auth: state.auth, audioEnabled: state.audioEnabled }),
      onRehydrateStorage: () => (state, error) => {
        // Page is rehydrating - if our auth token has expired, flag the user as being logged out.
        // If they visit login it will automatically attempt login via refresh token.
        // If they visit a protected page then RequireAuth will do the same.
        if (state?.auth?.token?.expiresAt && state.auth.token.expiresAt < Date.now().valueOf()) {
          state.auth.isAuthenticated = false;
          return
        } else if (state?.auth.token?.refreshToken) {
          try {
            ReactRecaptcha3.getToken().then((token: string) => {
              loginWithRefreshToken(
                state?.auth.token?.refreshToken || '',
                token,
                () => signOut(),
                () => console.log('Token refreshed successfully')
              );
            })
          } catch (error) {
            console.log('Recaptcha not loaded');
            signOut();
          }
        }
      },
    }
  )
);

type ImmerStateCreator<T extends object> = StateCreator<T, [['zustand/immer', never], never], [], T>;
type ImmerStoreApi<T extends object> = Parameters<ImmerStateCreator<T>>[2];

const markets: Record<number, UseBoundStore<ImmerStoreApi<MarketState>> & {}> = {};
const useMarketStore = (market: Market) => {
  if (!markets[market.id]) {
    const ms = create<MarketState>()(
      immer((set, get) => ({
        wagering: {
          durationUnit: market.durations[0].unit,
          durationValue: market.durations[0].value,
          wagerType: WagerTypeEnum.Rise,
          stake: Math.floor((market.minWager + market.maxWager) / 2),
        },
        updateWagering: (update: (wager: GameState) => void) => {
          set((state) => {
            update(state.wagering);
          });
        },
        placeWager: async () => {
          SoundManager.getInstance().playSound('betPlacedSound');
          const wagering = get().wagering;
          const marketId = get().wager?.marketId;
          const wager: Wager = {
            state: WagerStateEnum.Pending,
            duration: {
              unit: wagering.durationUnit,
              value: wagering.durationValue,
            },
            type: wagering.wagerType,
            staked: wagering.stake,
            currency: 'EUR',
            marketId: marketId || 0,
          };
          set((state) => {
            state.wager = wager;
          });

          await apiPlaceWager(market.id, wager);
        },
        ticks: [],
        setTicks: (ticks: Tick[]) => {
          set((current) => {
            current.ticks = ticks;
          });
        },
        addTick: (tick: Tick) => {
          set((current) => {
            current.ticks.push(tick);
          });
        },
        setTrendTick: (tick: Tick) => {
          set((current) => {
            current.trendTick = tick;
          });
        },
        updateWager: (update: (wager: Wager) => void) => {
          set((state) => {
            if (state.wager) {
              update(state.wager);
            }
          });
        },
        setWager: (wager: Wager) => {
          set((state) => {
            state.wager = wager;
          });
        },
        clearWager: () => {
          set((state) => {
            state.wager = undefined;
          });
        },
      }))
    );
    markets[market.id] = ms;
  }

  return markets[market.id];
};


const findMarket = (games: Game[], id: number) => {
  const market = games.flatMap((x) => x.markets).find((x) => x.id === id);

  return market;
};

export { useStore, useMarketStore, findMarket };
