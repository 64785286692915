import { Group, Stack, Text, Title } from '@mantine/core';
import { useStyles } from './styles';

const RoundIcon = ({
  icon,
  text,
  subText,
  color = 'gray',
  step,
}: {
  icon: React.ReactNode;
  text: string;
  subText: string;
  color?: 'gray' | 'green';
  step?: number;
}) => {
  const { classes } = useStyles();

  return (
    <Stack spacing={0}>
      <div className={`${classes.round} ${color === 'green' && classes.green}`}>
        <div className={classes.icon}>{icon}</div>
        {color != 'green' && (
          <div className={classes.stepContainer}>
            <Title className={classes.stepItem} order={1}>
              {step}
            </Title>
          </div>
        )}
      </div>
      <Title order={2} align="center">
        {text}
      </Title>
      <Text align="center" sx={{ maxWidth: '140px', margin: 'auto' }}>
        {subText}
      </Text>
    </Stack>
  );
};

export default RoundIcon;
