import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => {
  return {
    contentContainer: {
      backgroundColor: theme.other.tabBackgroundColor,
      borderRadius: '20px',
      padding: '12px',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    container: {
      backgroundColor: theme.other.tabBackgroundColor,
      borderRadius: '20px',
      padding: '16px',
      height: '100%',
    },
  };
});

export { useStyles };
