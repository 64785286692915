import { Divider, Space, Title, Text, Accordion, Box } from '@mantine/core';
import { useStyles } from './styles';
import GeneralPlayerReport from './bo-general-player-report/GeneralPlayerReport';
import PlayerAccountReport from './bo-player-account-report/PlayerAccountReport';

const Reports = () => {
  const { classes } = useStyles();

  return (
    <>
      <Title>Player Custom Reports</Title>
      <Text>Customise your reports by selecting the preferred options below.</Text>
      <Space h={16} />
      <Divider size={2} />
      <Space h={16} />
      <Box className={classes.container}>
        <Accordion iconPosition="right">
          <Accordion.Item label="Player General Report" sx={(theme) => ({
            "h3 button": {
              borderTop: 'none !important',
            }
          })}>
            <GeneralPlayerReport />
          </Accordion.Item>
          <Accordion.Item label="Player Account Report" sx={(theme) => ({
            "h3 button": {
              borderBottom: `3px solid ${theme.other.blue}`,
            }
          })}>
            <PlayerAccountReport />
          </Accordion.Item>
        </Accordion>
      </Box>
    </>
  );
};

export default Reports;
