import {
  Box,
  Button,
  Divider,
  Group,
  Select,
  Space,
  Table,
  Timeline,
  Title,
  Text,
  Modal,
  Textarea,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PlayerData, UserMin, UserNote, UserNotes } from '../../../api/interfaces';
import { GetSalesAdmin, getPlayerByID } from '../../../api/player';
import UserWallet from '../../../components/userWallet/userWallet';
import AccountSummary from './account-summary/account-summary';
import { useStyles } from '../styles';
import { formatDate, formatPlayerFullName } from '../../../utils/formatters';
import ResponsibleGaming from '../../responsible-gaming/ResponsibleGaming';
import { addNoteToUser, assignSalesAdmin, getSalesAdminByUserId } from '../../../api/backoffice';
import { useStore } from '../../../state/state';

const PlayerAccount = () => {
  const { id } = useParams();
  const [player, setPlayer] = useState<PlayerData>();
  const { classes } = useStyles();
  const [salesAdmin, setSalesAdmin] = useState<UserMin>();
  const [salesAdminList, setSalesAdminList] = useState<UserMin[]>([]);
  const [selectedSalesAdmin, setSelectedSalesAdmin] = useState<string>('');
  const [noteModalOpened, setNoteModalOpened] = useState(false);
  const [noteContent, setNoteContent] = useState('');

  const auth = useStore((state) => state.auth);

  const getPlayer = async () => {
    if (id) {
      const player = await getPlayerByID(id);
      if (player) {
        setPlayer(player);
      }
    }
  };

  const getNextPlayer = async (id: string) => {
    const player = await getPlayerByID(id);
    if (player) {
      setPlayer(player);
    }
  };

  useEffect(() => {
    getPlayer();
    getAssignedSalesAdmin(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, player?.role]);

  const getAssignedSalesAdmin = async (id?: string) => {
    if (id && auth?.user?.role?.includes('SuperAdmin') && player?.role === '') {
      const salesAdmin = await getSalesAdminByUserId(id);
      if (salesAdmin) {
        setSalesAdmin(salesAdmin);
      }
      const salesAdminList = await GetSalesAdmin();
      if (salesAdminList) {
        setSalesAdminList(salesAdminList);
      }
    }
  };

  const manageSalesAdminAssignment = async (searchAdminId: string) => {
    if (id) {
      await assignSalesAdmin({ AdminId: searchAdminId, UserId: id }).then(() => getAssignedSalesAdmin(id));
    }
  };

  const addNote = async () => {
    if (id && noteContent) {
      const request: UserNotes = {
        userId: id,
        note: noteContent,
      };
      const result = await addNoteToUser(request);
      if (result) {
        setNoteContent('');
        setNoteModalOpened(false);
        getPlayer();
      }
    }
  };

  return (
    <>
      <Title align="center" order={1}>
        {player && formatPlayerFullName(player.firstName, player.lastName)}
      </Title>
      <Space h={32} />
      {player && id && <AccountSummary player={player} id={id} getPlayer={getPlayer} getNextPlayer={getNextPlayer} />}
      <Space h={32} />
      {player && id && (
        <>
          <Title align="left" order={1}>
            Personal Details
          </Title>
          <Divider sx={{ margin: '24px 0px' }} size={2} color="blue" />
          <Group noWrap className={classes.contentContainer}>
            {player.profilePictureKey && (
              <Box
                sx={{
                  height: '120px',
                  width: '120px',
                  borderRadius: '50%',
                  margin: '16px',
                  border: '5px solid white',
                  backgroundImage: `url(${player.profilePictureKey})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
            )}
            <Table>
              <tbody>
                <tr>
                  <td>
                    <Title order={2}>Address</Title>
                  </td>
                  <td>
                    <Title order={2}>{player.streetAddress}</Title>
                  </td>
                  <td>
                    <Title order={2}>City</Title>
                  </td>
                  <td>
                    <Title order={2}>{player.city}</Title>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Title order={2}>Country</Title>
                  </td>
                  <td>
                    <Title order={2}>{player.country}</Title>
                  </td>
                  <td>
                    <Title order={2}>Email</Title>
                  </td>
                  <td>
                    <Title order={2}>{player.email}</Title>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Title order={2}>Language</Title>
                  </td>
                  <td>
                    <Title order={2}>{player.language}</Title>
                  </td>
                  <td>
                    <Title order={2}>Phone Number</Title>
                  </td>
                  <td>
                    <Title order={2}>{player.phoneNumber}</Title>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Title order={2}>Birth Date</Title>
                  </td>
                  <td>
                    <Title order={2}>{player.birthDate}</Title>
                  </td>
                  <td>
                    <Title order={2}>Gender</Title>
                  </td>
                  <td>
                    <Title order={2}>{player.gender}</Title>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Group>
        </>
      )}
      {auth?.user?.role?.includes('SuperAdmin') && player?.role === '' && (
        <>
          <Space h={32} />
          <Title align="left" order={1}>
            Sales Admin Details
          </Title>
          <Divider sx={{ margin: '24px 0px' }} size={2} color="blue" />
          <Group noWrap className={classes.contentContainer}>
            <Table>
              <tbody>
                <tr>
                  <td>
                    <Title order={2}>Sales Admin</Title>
                  </td>
                  <td>
                    <Title order={2}>
                      {salesAdmin ? `${salesAdmin?.firstName} ${salesAdmin?.lastName}` : 'Unassigned'}
                    </Title>
                  </td>
                  <td>
                    <Title order={2}>Sales Admin Email</Title>
                  </td>
                  <td>
                    <Title order={2}>{salesAdmin ? salesAdmin?.email : '-'}</Title>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Title order={2}>Set Admin</Title>
                  </td>
                  <td>
                    {salesAdminList.length > 0 && (
                      <Select
                        value={selectedSalesAdmin}
                        onChange={(searchAdminId: string) => setSelectedSalesAdmin(searchAdminId)}
                        data={salesAdminList.map((filter) => ({
                          value: filter.id,
                          label: filter.firstName + ' ' + filter.lastName,
                        }))}
                      />
                    )}
                  </td>
                  <td>
                    <Button
                      disabled={!selectedSalesAdmin}
                      onClick={() => manageSalesAdminAssignment(selectedSalesAdmin)}
                    >
                      Allocate Admin
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Group>
        </>
      )}
      <Space h={32} />
      {player && id && (
        <>
          <Title align="left" order={1}>
            Transaction History
          </Title>
          <Divider sx={{ margin: '24px 0px' }} size={2} color="blue" />
          <Box sx={{ height: '700px' }}>
            <UserWallet id={id} />
          </Box>
          <Space h={32} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title align="left" order={1}>
              Player Notes
            </Title>
            <Button onClick={() => setNoteModalOpened(true)}>Add Note</Button>
          </Box>
          <Divider sx={{ margin: '24px 0px' }} size={2} color="blue" />
          <Box className={classes.contentContainer} sx={{ padding: '24px 12px', height: '450px', overflowY: 'scroll' }}>
            <Timeline>
              {player.userNotes.map((note: UserNote) => {
                return (
                  <Timeline.Item title={formatDate(note.createdDate)}>
                    <Text>{note.note}</Text>
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </Box>
        </>
      )}
      <Space h={32} />
      <ResponsibleGaming isAdmin={true} player={player} />
      <Modal centered opened={noteModalOpened} onClose={() => setNoteModalOpened(false)} title="Add Note">
        <Textarea
          value={noteContent}
          onChange={(event) => setNoteContent(event.currentTarget.value)}
          placeholder="Enter your note"
          label="Note"
          required
        />
        <Group position="right" mt="md">
          <Button onClick={addNote}>Submit</Button>
        </Group>
      </Modal>
    </>
  );
};

export default PlayerAccount;
