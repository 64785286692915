import { Group, Title, Text, Box, useMantineTheme } from '@mantine/core';
import { DurationUnitEnum, Game, Market } from '../../../../api/interfaces';
import { DurationButton } from '../../../../components/game/durationButton';
import { useMediaQuery } from '@mantine/hooks';

export const DurationPicker = (props: {
  game: Game;
  market: Market;
  unit: DurationUnitEnum;
  value: number;
  setDuration: (unit: DurationUnitEnum, value: number) => void;
}) => {
  const mobileView = useMediaQuery(`(max-width: ${useMantineTheme().breakpoints.sm}px)`);

  return (
    <Group position="apart" noWrap align="top" mb="xs">
      <Box sx={{ marginRight: '4px' }}>
        <Title order={3}>Game Duration</Title>
        <Text size="xs">Time your bet will last?</Text>
      </Box>
      <Group noWrap spacing={mobileView ? 4 : 'xs'} align="top">
        {(props.market?.durations ?? []).map((x) => (
          <DurationButton
            key={`${x.value} ${x.unit}`}
            duration={x}
            game={props.game}
            selected={props.unit === x.unit && props.value === x.value}
            onClick={() => props.setDuration(x.unit, x.value)}
          />
        ))}
      </Group>
    </Group>
  );
};
