import { Text, Container, Title, useMantineTheme, Stack } from '@mantine/core';
import { useStyles } from './styles';
import Glossary from './amlGlossary';

const AMLPolicy = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <Container py="50px">
      <Stack
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title id="AML_Policy" order={1} my={theme.spacing.lg}>
          Gaming AML Policy
        </Title>
        <Glossary />
      </Stack>
      <Stack
        id='introduction'
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title order={2} mt={theme.spacing.xl}>
          Introduction
        </Title>
        <Text >
          The company is committed to keeping the online gambling/betting crime-free and to the encouragement of high
          standards of probity and integrity, both for the benefit of the firm and the public generally. Combating money
          laundering effectively is a major objective in this area and the purpose of these guidelines is to help the company
          achieve that in a consistent
          manner.
        </Text>
      </Stack>

      <Stack
        id='internal_controls'
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title order={2} mt={theme.spacing.xl}>
          Application of Internal Controls (processes & procedures)
        </Title>
        <Text >
          Internal Controls are the key link between the risks identified in the AML/CTF risk assessment and the practical
          implementation of mitigation measures. They take the form of internal processes and procedures.
          In relation to suspicions about customer behaviour or transactions, the MLRO should keep separate records of steps
          taken, questions and responses received and decisions made in relation to a customer. This enables staff and the
          company to demonstrate to the regulators and courts the process by which it assesses threats, and decide on the
          appropriate systems and procedures (including due diligence requirements) in light of the risk assessment that has
          been made and how procedures work if money laundering is suspected.
        </Text>
        <Text >
          Internal controls in the form of written processes and procedures are only effective when consistently followed in
          practice. We therefore put in place measures to monitor compliance with internal controls to ensure that the practical
          implementation of mitigation measures remain effective and appropriate. This is in the form of sample checks,
          exception reporting and other auditing measures. Again, the risk-based approach guides the determination of which
          checks to put in place and in what format, based on where the highest risks are.
        </Text>
      </Stack>

      <Stack
        id='risk'
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title order={2} mt={theme.spacing.xl}>
          Risk Based Approach and Risk Assessment Document
        </Title>
        <Text >
          A risk-based approach takes a number of discrete steps in assessing the most cost effective and proportionate ways
          to manage and mitigate the money laundering and terrorist financing risks faced by the company. This is done by way
          of a documented risk assessment, which then forms the core of the measures put in place by the company to mitigate
          the money laundering and terrorist financing risks they face.
        </Text>
        <Text >
          Conducting a risk assessment for AML/CTF, is not a one-off exercise, it is an ongoing process.
        </Text>
        <Text >
          The fight against crime and terrorism imposes costs on government, business and taxpayers. It is essential, therefore,
          that the benefits of any AML/CTF program should outweigh its burdens; that action is targeted wherever possible on
          specific areas of risk and vulnerability and the right balance is struck between the need to prevent the industry being
          misused for money laundering, or terrorist financing and the securing of the commercial viability of the cpmpany. In
          short, any measures that are applied must be proportionate to the risk presented and effective in addressing that risk
        </Text>
        <Text >
          A risk-based approach is outlined by FATF and the 4th Anti-Money Laundering Directive and assists to ensure that the
          procedures, systems and controls designed to mitigate the assessed Money Laundering/Terrorist Financing ML/TF
          risks, are appropriate and proportionate to these risks, whilst providing an effective level of mitigation
        </Text>
        <Text>
          Any risk-based model adopted should be continually reviewed to ensure that it continues to cover all elements of
          possible risk and is updated where necessary. This methodology in turn ensures the best use of resources, value for
          money, and highlights the factors that represent the greatest risks. It is important to use a variety of sources to
          evaluate risk, such as collating information on current process and procedures and measuring effectiveness to ensure
          that valuable resources are being correctly directed and applied and have not become overwhelmed and ineffective
        </Text>
        <Text>
          How a risk-based approach is actually implemented is dependent upon the company processes and guidelines, its size
          and business structure. In order to meet the requirements of the Directive, these guidelines aim to give a high-level
          overview of the operation of a model that builds on the risk-based approach, advocated by the FATF and the Directive.
        </Text>
        <Text>
          However carried out, a risk-based approach needs to be part of the company’s philosophies, and as such is reflected in
          our procedures and controls. There must to be a clear communication of policies and procedures to staff, along with
          robust mechanisms to ensure that they are carried out effectively. Any weaknesses are identified, and improvements
          made wherever necessary. In short, the company has a compliance culture which feeds down from the Directors to
          frontline staff.
        </Text>
        <Text>
          The following steps are followed when undertaking a risk assessment:
          <Text component="ol" >
            <Text component="li">
              <Text component="strong">Risk Identification</Text> - Analysing the company’s business structure and practices in order to identify areas of
              potential ML/TF risk
            </Text>
            <Text component="li">
              <Text component="strong">Risk Mitigation</Text> - Applying measures effectively to mitigate the identified risks
            </Text>
            <Text component="li">
              <Text component="strong">Risk Monitoring</Text> - - Putting in place management information systems and keeping up to date with changes to the
              risk profile through changes to the business or threats
            </Text>
            <Text component="li">
              <Text component="strong">Documentation</Text> - Having internal controls in the form of policies and procedures to cover the identified risks and
              deliver accountability from the Board and Senior Management down
            </Text>
            <Text component="li">
              <Text component="strong">Risk Review</Text> - Monitoring and evaluating the risks identified to ensure the effective operation of internal controls is
              in place to mitigate the risk.
            </Text>
          </Text>
        </Text>
        <Text>
          The risk assessment accurately documents the exposure of our business as a remote gaming operator to money
          laundering and terrorist financing risks and vulnerabilities, including those which may arise from new or developing
          technologies that might favour anonymity taking into account its (a) size, nature and complexity; and (b) customers
          and services and the ways in which it provides those services.
        </Text>
        <Text>
          Having conducted a risk assessment, we as a remote operator are then in a position to take discrete steps to assess
          the most cost effective, proportionate way to manage and mitigate those risks identified. It is recognised that each
          individual department and/or brand is different and while regulators can offer advice and guidance, the final
          Gaming AML Policy 3
          responsibility to assess its own risks according to its business model, rests with the company. A ‘one size fits all’
          approach is not suitable to a risk-based environment.
        </Text>
        <Text>
          As money laundering and terrorist financing threats change constantly, it is recognised that the response for money laundering and/or terrorist financing needs to be as supple as the criminals and terrorists themselves. In this context, a prescriptive and arbitrary ‘tick box’ approach would miss its target and fail to deliver benefits that outweigh the costs of intervention. The company ensures that our risk model is reviewed and if necessary updated regularly to reflect any appropriate changes in risk levels, policies or internal controls. It is important to have systems in place that provide an overall picture of customer behaviour as part of the business relationship.
        </Text>
        <Text>
          It should be noted that financial sanctions (i.e. the requirement to freeze assets of individuals identified as subject to financial sanctions) fall outside the risk-based approach. Similarly, there are no risk-based provisions around the reporting of suspicious activity, once identified. Both must therefore be managed independently of the company’s risk assessment.
        </Text>
        <Text>
          These risks can broadly be categorised and addressed under the following headings (following paragraphs expand on these points):
        </Text>
        <Text component="ol" >
          <Text component="li">
            Customer Risk (see a & b)
          </Text>
          <Text component="li">
            Product/Services Risk (see c & d)
          </Text>
          <Text component="li">
            Transaction/Payment Risk (see e & f)
          </Text>
          <Text component="li">
            Geographical Risk (see g & h)
          </Text>
          <Text component="li">
            Behavioural Risk (monitoring) (see i)
          </Text>
        </Text>
        <Text component="strong">a: Customer Risk</Text>
        <Text>
          This risk category includes types of customers and the style of business relationship. Customer types may include individual accounts or trade accounts. Each customer classification has distinct risks and therefore requires tailored mitigation strategies. The risk assessment should consider what information the operator holds about the customer and that the information is consistent and up to date.
        </Text>
        <Text component="strong">b: Mitigating Customer Risk</Text>
        <Text>
          To assist in the mitigation of the risk posed by Politically Exposed Persons (PEPs), operators should have a risk-based policy on how and when to ascertain whether any customers are considered to be PEPs.
        </Text>
        <Text>
          It is important that the process for the ongoing monitoring of customer risk is continual through the lifetime of the customer relationship.This helps to ensure that any changes which may affect the risk posed by the customer are acted upon to ensure that there is limited exposure to ML/TF risk arising from out of date customer information.
        </Text>
        <Text>
          The company looks to mitigate the risk posed by customers, by conducting a review of their customer base to identify groups of customers. Once these groups have been identified, the potential risks posed by each distinct customer group can be assessed and mitigated. For example, due diligence requirements for trade accounts will differ to those of individual customers, due to the requirement to identify the beneficial owner.        </Text>
        <Text component="strong">c: Assessing Products/Services Risk</Text>
        <Text>
          When assessing product/service risk, some products should be considered as higher risk for passing/movement of funds than others. For example, poker could be seen to be a higher risk game due to the potential for collusion and chip dumping by customers, whilst slots and bingo may be seen to be lower risk games, requiring minimal, if any, ML risk mitigation beyond those applied at the customer level. Any apparently irrational funds movement should attract greater attention e.g. between different products without any apparent reason.
        </Text>
        <Text>
          While some operators may offer facilities for their customers to transfer funds to another customer - commonly known as player-to-player transfers. This presents significantly increased risk as the money may be passed from one customer to another and therefore necessitate the implementation of additional customer due diligence procedures in relation to those involved. It is for this reason that such features are not provided by the company.
        </Text>
        <Text>
          It is not uncommon for customers to have multiple payment methods, and for them to gamble across different platforms and interact with customers registered with another operator. This introduces a risk of the product used by the customer, allowing for the transfer of funds between customers on different networks, making it difficult to track customer funds.
        </Text>
        <Text component="strong">d: Mitigating Products/Services Risk</Text>
        <Text>
          The monitoring of identified high-risk behaviours is a key factor in preventing ML/TF activity.
        </Text>
        <Text>
          One of the key ways to mitigate the risk posed by products which involve a platform, or allow player to player transfers, is to have channels of communication between the operator and the platform operators; this may be via agreed reporting suites or by referral of customers where there is a concern.
        </Text>
        <Text component="strong">e: Assessing Transaction/Payment Risk</Text>
        <Text>
          Deposit and withdrawal methods into and from a customer’s gaming account are now offered from a wide variety of sources, including credit/debit cards, bank transfers, wire transfers and prepaid cards. These are key factors to consider when identifying transaction/payment risk.
        </Text>

        <Text component="strong">f: Mitigating Transaction/Payment Risk</Text>
        <Text>
          An evaluation of the features of the different payment methods offered constitutes part of the assessment of money laundering risk to identify any particular features which may be of higher risk, for example, whether the payment method allows cash deposits.
        </Text>
        <Text>
          It is less common in the online gambling industry for direct cash deposits to be made, but, where that facility is provided, the operator must have policies and procedures in place to safeguard against the additional risk presented. Our firm does not permit cash deposits or withdrawals, nor the handling of cash in any form.
        </Text>
        <Text>
          The company must try and avoid simply acting as a bank, i.e. customer accounts should be used to facilitate gambling, not as a substitute for conventional bank accounts. A review of the payment methods offered to see whether cash deposits are permitted by the payment provider will also assist in addressing the potential risk.
        </Text>

        <Text component="strong">g: Assessing Geographical Risk</Text>
        <Text>
          When looking to assess geographical risk, some countries are deemed to present greater risks than others for money laundering and funding terrorism. These countries typically do not have legislation which meets FATF or EU standards. The company maintains a list of high-risk countries and black-listed countries where neither customers nor transactions are permitted, which is reviewed and updates regularly.
        </Text>

        <Text component="strong">h: Mitigating Geographical Risk</Text>
        <Text>
          The company therefore focuses on money being received from and remitted to such jurisdictions. Corruption and risk indexes are found through such organizations as Transparency International and prove invaluable in assisting to assess such risks. In addition, the company keeps up to date with FATF reports, media reports, and country-specific reports in order to keep our country risk assessments up to date.
        </Text>

        <Text component="strong">i: Monitoring of Customer Behavior</Text>
        <Text>
          The further that a relationship progresses with a customer, the more the company will, or should know about that customer, and information regarding customer behavior and transactions should be used over time to assist in the creation of a customer risk profile. Monitoring of behavior provides a good barometer for the continual assessment of the risk posed by the customer, and any deviations from what has become the norm for that individual should be identified and risk assessed. For example, a customer who may make a number of small deposits then starts making large deposits, or a customer who deposits large quantities of funds but has little or disproportionate betting activity, will increase the potential risk posed by that customer.
        </Text>
        <Text>
          Risk scoring, or the creation of a customer risk profile, is also used to help analyze information so that more objective and consistent decisions can be made more fairly and more quickly. The scoring system allocates points to a particular customer based on their behavior throughout the business relationship and can be weighted according to the perceived risk. Indeed, a risk-based methodology seeks to check the identity of customers at the point that an account ‘trips’ a risk-based trigger.
        </Text>
        <Text>
          A ‘web’ of triggers can be created to cover all elements of risk-related activity based upon customer attributes, funding, and behavior activity and is continually reviewed and amended to ensure that it continues to cover all elements of possible risk. It is acknowledged that the company uses a variety of systems (both manual and automated), including other methods where triggers are set to indicate that a particular account requires reviewing at a certain stage.
        </Text>
        <Text>
          One way of analyzing and applying the attributes listed above can be by subdividing them into specific behaviors, which are weighted and scored. The weighting remains flexible and can be adjusted dependent on how much of a risk each identified behavior is considered to be. Meeting a predefined ‘points’ threshold triggers basic customer due diligence, or enhanced due diligence in appropriate circumstances. The risk thresholds and triggers should be reviewed and monitored to ensure they remain a true reflection of the risk.
        </Text>
      </Stack>
      <Stack
        id='kyc'
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title order={2} mt={theme.spacing.xl}>
          Customer Verification and Due Diligence
        </Title>
        <Text>
          Customer verification is key in ensuring that operators can be satisfied that customers are who they say they are, that they are not acting on behalf of anyone else, and to ensure that enhanced due diligence measures are applied to PEPs.
        </Text>

        <Text>
          Customer cooperation is vital to ensure that the right information is provided and verified, and one way in which this is achieved is by making the customer verification process as easy and user-friendly as possible.
        </Text>

        <Text>
          It may be helpful to highlight to customers that they must undergo a standard verification process before they can begin to use the services of the company, and subsequently may also be required to provide further information about themselves. The point at which customer due diligence is triggered will be dependent upon the company's current limits/thresholds. For example, where the threshold approach is taken, the company will commence customer verification when a threshold has been reached. Alternatively, the company may choose to verify customers on establishing the business relationship where suspicions are raised, large volumes of deposits are anticipated, and/or they originate from a high-risk territory.
        </Text>

        <Text>
          Customer due diligence checks are already familiar to customers from their experience of dealing with the wider banking and financial services sector. Whenever customer due diligence is performed, it is important to remain vigilant throughout the relationship. It is not acceptable to turn a blind eye and hope for the best. The nature of the remote gaming sector is such that weaknesses, if exploited, could pose great risks by virtue of the sums of money involved, the speed of transactions, and the levels of turnover.
        </Text>

        <Text>
          Customer due diligence is achieved by identification, which involves the customer providing their personal information and verification of that identification. Standard/basic due diligence takes place at the outset, or very soon after the commencement of the business relationship, or within the EU when the 2,000 EUR threshold is reached, but it does not happen as a matter of course. Such due diligence verification can be achieved by one or more of the following processes:
        </Text>
        <Text component="ol">
          <Text component="li">
            Independent 3rd party verification e.g. the use of a software system and/or:
          </Text>
          <Text component="li">
            Checking personal documents, e.g.
            <Text component="ul">
              <Text component="li">
                government-issued ID proving identity and age (such as passport, identity card, driving license, or birth certificate)
              </Text>
              <Text component="li">
                proof of address (such as a utility bill, mobile phone bill, insurance certificate, mortgage statement, bank statement, council tax bill, electoral register documentation)
              </Text>
            </Text>
          </Text>
        </Text>
        <Text component="strong">a: Customer Due Diligence - Business Accounts</Text>
        <Text>
          In cases where the company provides business accounts for gaming products in the wider remote gambling environment, then in addition to the personal identification from directors, the following may also be sought in order to satisfy the applicable beneficial ownership requirements:
        </Text>
        <Text component="ol">
          <Text component="li">
            The company is properly registered
          </Text>
          <Text component="li">
            Registered company name
          </Text>
          <Text component="li">
            Registered company address
          </Text>
          <Text component="li">
            Office-holders, shareholders, and/or beneficial owners
          </Text>
          <Text component="li">
            Evidence of ownership of the account funding method.
          </Text>
        </Text>

        <Text component="strong">b: Ongoing and Enhanced Due Diligence (EDD)</Text>
        <Text>
          Customers are continually monitored for any update in their risk profile. Where behaviours or transactions are identified which may increase the customer’s potential risk, further investigations and due diligence are undertaken. Factors which may increase a customer’s risk, include the triggering of an internal activity or monetary threshold, a match against a PEP list/category or a sudden change in behaviour, such as an increase in depositing frequency and/or amount. The company has transactional monitoring programs to support the ongoing/enhanced due diligence in place to identify behaviour/transactions which may be indicative of suspicious behaviour.
        </Text>
        <Text component="ol">
          <Text component="li">
            3rd party verification - e.g. use of software, or using approved 3rd parties to conduct face-to-face verification of customer documents; for example, in some countries Post Offices provide this service.
          </Text>
          <Text component="li">
            Validation of customer documents - Certified copies of documents to validate name, address, date of birth, and source of funds of the customer.
          </Text>
          <Text component="li">
            Address validation check - Using a secure code delivered to the customer’s address to validate that the customer is actually resident at the address stated.
          </Text>
          <Text component="li">
            Reputable funding - Ensure that the first (next) payment or transaction into the customer’s account is carried out through an account held by the customer in his name with an authorized credit institution or recognized under the Payments Services Directive, or otherwise so authorized in another 'reputable jurisdiction'.
          </Text>
          <Text component="li">
            Background and lifestyle checks - Using open-source research to obtain further information about the customer’s source of wealth, occupation, property ownership status, and lifestyle. This utilizes publicly available social media, state/government records, and adverse media checking.
          </Text>
          <Text component="li">
            Source of funds - Confirm the immediate source from which the funds have derived.
          </Text>
          <Text component="li">
            Source of wealth - corroborate sustainable wealth that matches the customer’s gambling profile. This may also overlap with responsible gambling considerations. Accurately identifying a customer’s source of funds and source of wealth can be a complex process and one in which approaching an online customer directly for relevant evidence may be a last resort.
          </Text>
          <Text component="li">
            Internal corroboration of user identity - this could emanate from a variety of sources from customer monitoring, other databases, and face-to-face verification where possible.
          </Text>
        </Text>
        <Text component='ul'>
          <Text component='li'>Note: ‘reputable jurisdiction’ means any country having appropriate legislative measures for the prevention of money laundering and the funding of terrorism, taking into account that country’s membership of, or any declaration or accreditation by, any international organisation recognised as laying down internationally accepted standards for the prevention of money laundering and for combating the funding of terrorism, and which supervises natural and legal persons subject to such legislative measures for compliance therewith. There are countries that are currently considered as having equivalent AML/CFT systems to the EU. The list may be reviewed, in particular in the light of public evaluation reports adopted by the FATF, FSRBs, the IMF or the World Bank according to the revised 2003 FATF Recommendations and Methodology.The list does not apply to Member States of the EU/EEA which benefit de jure from mutual recognition through the implementation of the 3rd AML Directive.The list also includes overseas territories who are not member of the EU/EEA but are part of the membership of France and the Kingdom of the Netherlands of the FATF.The UK Crown Dependencies (Jersey, Guernsey, and Isle of Man) may also be considered as equivalent by Member States.
          </Text>
        </Text>
        <Text>
          The information above will assist in the building and updating of a customer profile over time, which can then be used as a reference for any changes in customer behaviour or transactions.
        </Text>
        <Text component="strong">c: Politically Exposed Persons (PEPs)</Text>
        <Text>
          Politically Exposed Persons are typically defined by the EU as natural persons who are or have been entrusted with prominent public functions and shall include their immediate family members or persons known to be close associates of such persons, but shall not include middle ranking or more junior officials. They would normally remain in this category until one year after leaving office.
        </Text>
        <Text>
          PEPs are considered to be potentially higher risk due to their position and access to wealth and resources. PEPs can provide the appearance of respectability which may assist to deflect suspicion about their transactions. As PEPs usually operate within a sphere of influence, they are uniquely placed to be involved in potential corruption and may be able to circumvent AML/CTF regulation. Corruption includes the misuse of public office for private gain as well as money laundering carried out with reduced risk of detection when public officials are incorporated into the process. The professional status of the PEP often assists and enhances the legitimacy of transactions, making them an attractive prospect to run or be involved in ML/TF activity.
        </Text>
        <Text>
          The fact that a person is a PEP does not automatically mean that they are involved in money laundering. However, where there is a confirmed PEP match, the customer will automatically pose a higher risk and a relationship review and senior management sign-off should be conducted. This may then result in an alteration to their risk profile and justify enhanced customer due diligence and customer monitoring measures being applied.
        </Text>
        <Text>
          Establishing whether a person is a PEP is not straightforward and may require a number of different processes to be involved. Whatever processes are employed to screen for and identify PEPs, new customers should be screened along with the regular screening of existing customers. The company employs the use of internet search engines or subscriptions to suitable databases. The databases used for customer due diligence may be able to assist in this regard.
        </Text>
        <Text>
          The company has put into place processes for:
        </Text>
        <Text component="ol">
          <Text component="li">
            Identifying PEPs
          </Text>
          <Text component="li">
            Obtaining senior management approval in accepting PEPs as customers
          </Text>
          <Text component="li">
            Ensuring that there is proportionate monitoring of such customer accounts
          </Text>
          <Text component="li">
            Measures to establish the source of wealth and funds that are involved in the business relationship/transactions
          </Text>
          <Text component="li">
            Providing evidence of decisions made as part of the PEP identification and monitoring process, including records of management approval and decisions relating to the risk monitoring measures implemented to confirmed PEP matches
          </Text>
        </Text>

        <Text component="strong">d: Sanctions</Text>
        <Text>
          Sanctions are normally used by the international community for one or more of the following reasons:
        </Text>
        <Text component="ol">
          <Text component="li">
            To encourage a change in the behavior of a target country or regime
          </Text>
          <Text component="li">
            To apply pressure on a target country or regime to comply with set objectives
          </Text>
          <Text component="li">
            As an enforcement tool when international peace and security has been threatened and diplomatic efforts have failed
          </Text>
          <Text component="li">
            To prevent and suppress the financing of terrorists and terrorist acts
          </Text>
        </Text>
        <Text>
          Financial sanctions are normally one element of a package of measures used to achieve one or more of the above. Financial sanctions measures can vary from the comprehensive – prohibiting the transfer of funds to a sanctioned country and freezing the assets of a government, the corporate entities, and residents of the target country – to targeted asset freezes on individuals/entities.
        </Text>
        <Text>
          The company has examined carefully all requirements our regulators have placed on us in relation to sanctions. Organisations that might typically be involved with sanctions are the US Office of Foreign Assets Control (OFAC) or its equivalent in other countries, and the European External Action Service (EEAS).
        </Text>
        <Text>
          Where a customer is identified as a designated individual subject to financial sanctions, we are not permitted to carry out any financial transactions or to remit funds back to the customer. The company has ensured that we have appropriate systems and controls in place in order to identify such individuals within the customer-base and apply the necessary restrictions in accordance with the requirements of the relevant sanctions regimes.
        </Text>
        <Text>
          The company additionally ensures that we meet all reporting obligations in relation to financial sanctions. For example, the UK Office of Financial Sanctions Implementation (OFSI) clarifies that reporting to the regulator or submitting a SAR to the FIU does not mean operators meet their reporting obligations for financial sanctions which must be reported to OFSI as a minimum.
        </Text>
        <Text component="p" >
          Note: <a href="https://www.gov.uk/government/uploads/system/uploads/attachment_data/file/636472/financial_sanctions_guidance_august_2017.pdf">financial_sanctions_guidance_august_2017.pdf</a>
        </Text>
      </Stack>
      <Stack
        id='mlro'
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title order={2} mt={theme.spacing.xl}>
          Role of the MLRO
        </Title>
        <Text>
          The company has appointed an MLRO with sufficient seniority and command within the business to fulfill this key role effectively. The MLRO additionally implements the following good practice to:
        </Text>
        <Text component="ol">
          <Text component="li">
            Ensure that the company’s directors and board members are aware of their responsibilities with regard to money laundering
          </Text>
          <Text component="li">
            Report to the board and/or senior management regularly (exactly how and when will be dependent on each company’s internal structure)
          </Text>
        </Text>
        <Text>
          It is important to remember that the term MLRO will not always be referred to within certain jurisdictions, but the scope of the role will be very similar.
        </Text>
        <Text>
          The role of the MLRO is wide-ranging; however, some key responsibilities include:
        </Text>
        <Text component="ol">
          <Text component="li">
            Overall responsibility for the establishment and maintenance of the AML systems
          </Text>
          <Text component="li">
            Monitoring of the day-to-day operation of the AML policies
          </Text>
          <Text component="li">
            Responsibility for the oversight of compliance with the regulatory requirements for the implementation of systems and controls against money laundering
          </Text>
          <Text component="li">
            Receive and consider internal disclosures of suspected money laundering or terrorist financing
          </Text>
          <Text component="li">
            Filing a Suspicious Activity/Transaction Report with the relevant authorities where the grounds for suspicion are sufficient
          </Text>
          <Text component="li">
            Designing and setting up internal anti-money laundering procedures and policies, including customer due diligence measures, reporting, record-keeping, risk assessment, management, and control systems
          </Text>
          <Text component="li">
            Organizing and arranging anti-money laundering training of the firm’s staff
          </Text>
        </Text>
        <Text>
          The MLRO will receive reports of any information or other matters which give rise to a knowledge or suspicion, or reasonable grounds for suspicion, that a person is, or may have engaged in money laundering, or the funding of terrorism. The MLRO should have direct access to the Board of Directors in order to obtain approval and backing on AML/CTF issues across the business.
        </Text>
        <Text>
          The company has given consideration to the level of support provided to the MLRO by way of staffing and other resources that they may require in the fight against money laundering and the funding of terrorism. All relevant staff working for the operator in a customer-facing role are aware of the risks posed by money laundering and the funding of terrorism. There are few employees whose roles are not touched by this area. Those dealing with customer registration, customer funds, and customer services obtain specific training, highlighting the importance for them to be vigilant in order to identify higher-risk situations. All staff are encouraged to report any concerns or suspicions to their MLRO. There is a clear route for the internal escalation of suspicions or concerns, and staff are aware of the process.
        </Text>
        <Text>
          Our employees know the identity of their MLRO and the deputy MLRO and how they can be contacted. As staff are based in different countries from their MLRO, this is reflected in relevant policies.
        </Text>
      </Stack>
      <Stack
        id='reporting'
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title order={2} mt={theme.spacing.xl}>
          Suspicious Activity and Transaction Reporting
        </Title>
        <Text>
          Jurisdiction-specific guidance can be sought from the relevant FIU, but in almost all circumstances, it is a legal obligation to report suspicious transactions. These include instances:
        </Text>
        <Text component="ol">
          <Text component="li">
            Where they know or
          </Text>
          <Text component="li">
            Where they suspect or
          </Text>
          <Text component="li">
            Have reasonable grounds to suspect that a person is engaged in money laundering or funding of terrorism.
          </Text>
        </Text>
        <Text>
          This includes where money laundering or funding of terrorism has been, is being, or may be committed or attempted.
        </Text>
        <Text>
          As a remote gaming operator, the company has a documented process in place for all employees to escalate when they have suspicions that a customer may be engaged in money laundering or terrorist financing. These escalations are sent to the MLRO or, in his/her absence, his Deputy MLRO/nominated officer.
        </Text>
        <Text>
          The company is aware that our obligations in this respect extend beyond our customer base but should also encompass contractors, business contacts, and the like.
        </Text>
        <Text>
          The MLRO must consider each report made to determine whether it gives rise to knowledge or reasonable grounds for suspicion. Where such suspicion is determined, a suspicious transaction report is sent in compliance with any locally applicable process (normally to FIUs, but when required, also to any gambling license issuing authority). Attention will need to be given to any applicable reporting timetables. It should be remembered that in some countries, failure to report is, in itself, a criminal offense, carrying with it a possible prison sentence. All decisions made in relation to the submission or non-submission of a suspicious transaction report should be recorded and documented.
        </Text>
        <Text>
          The company may be compelled to document how staff report their suspicions promptly and without prejudice to the MLRO or nominated officer. The MLRO or nominated officer should take into account all relevant information prior to making a report. Where a suspicious transaction report is not submitted, the reasons for non-submission must be fully documented.
        </Text>
        <Text>
          We are fully aware of the relevant FIU processes in relation to consent/non-consent reporting and that the decision to continue to transact with a customer or continue the business relationship with the customer is the responsibility of the company alone.
        </Text>
        <Text>
          “All relevant staff working for the operator in a customer-facing role needs to be aware of the risks posed by money laundering and the funding of terrorism. There will be few employees whose roles are not touched by this area.”
        </Text>
      </Stack>
      <Stack
        id='reporting'
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title order={2} mt={theme.spacing.xl}>
          Suspicious Activity and Transaction Reporting
        </Title>
        <Text>
          <Text component='strong'>
            Employee Training
          </Text>
          <Text>
            It is imperative that staff receive appropriate and regular training, which is proportionate to the potential risk of exposure to ML/TF as part of their role and responsibilities. The training content includes (dependent upon staff role):
          </Text>
        </Text>
        <Text component="ol">
          <Text component="li">
            Customer due diligence measures
          </Text>
          <Text component="li">
            Record-keeping procedures
          </Text>
          <Text component="li">
            Internal reporting procedures
          </Text>
          <Text component="li">
            Policies and procedures on internal control, risk assessment, risk management, compliance management
          </Text>
          <Text component="li">
            Communications that are adequate and appropriate to prevent the carrying out of operations that may be related to money laundering or the funding of terrorism
          </Text>
          <Text component="li">
            Relevant regulation in each jurisdiction where a license is held
          </Text>
          <Text component="li">
            The recognition and handling of transactions carried out by, or on behalf of, any person who may have been, is, or appears to be engaged in money laundering or the funding of terrorism
          </Text>
        </Text>
        <Text>
          Where possible, the level of training and the means by which staff are trained should be risk-based. It may therefore be appropriate to deliver computer-based training content to Customer Support staff, whilst higher-risk departments may be more appropriately trained in a classroom environment on a wider range of subjects.
        </Text>
        <Text>
          Records should be kept of all training given to staff together with confirmation that they have reached the necessary level of understanding and competence. Staff are made aware that they have personal responsibilities in the area of reporting.
        </Text>
        <Text>
          In order to ensure that training remains relevant and up to date, the company should have an annual (or more frequent) review of all training material for all forms of AML/CTF training implemented.
        </Text>
        <Text>
          “Where possible, the level of training and the means by which staff are trained should be risk-based. It may therefore be appropriate to deliver computer-based training content to Customer Support staff, whilst higher-risk departments may be more appropriately trained in a classroom environment on a wider range of subjects.”
        </Text>

        <Text>
          <Text component='strong'>
            Employee Screening
          </Text>
          <Text>
            Staff who are dishonest present a fraud and business risk to remote gambling operators. The company must ensure that they have in place appropriate procedures for due diligence when hiring employees.
          </Text>
        </Text>
        <Text>
          This includes reference checks, credit record checks, and other vetting measures, including verification of information given during the recruitment phase and confirmation of identity.
        </Text>
        <Text component="ol">
          <Text component="li">
            Be identified and verified
          </Text>
          <Text component="li">
            Be screened to ensure their integrity
          </Text>
          <Text component="li">
            Receive appropriate training
          </Text>
        </Text>
        <Text>
          The firm takes into account the differences in risk posed by the different roles across their business – some roles will pose a higher risk than others, such as a member of senior management. Screening levels and frequency should be adjusted to reflect this risk.
        </Text>
      </Stack>
      <Stack
        id='record_keeping'
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title order={2} mt={theme.spacing.xl}>
          Record Keeping
        </Title>
        <Text>
          Unlike the land-based gambling sector, a remote gaming operator will always enter into a business relationship with a customer. Therefore, there can be no occasional or one-off transactions. The company in particular and remote gaming operators in general are required to keep the following records:
        </Text>
        <Text component="ol">
          <Text component="li">
            Transaction documents
          </Text>
          <Text component="li">
            Due diligence information
          </Text>
          <Text component="li">
            Information relating to suspicious transactions
          </Text>
          <Text component="li">
            MLRO reports
          </Text>
          <Text component="li">
            Training in relation to AML matters
          </Text>
          <Text component="li">
            Policies and procedures
          </Text>
        </Text>
        <Text>
          This information could be required on a timely basis by regulatory authorities. The documents as outlined should be kept for 5 years from the date of the transaction, or the date of completion of any related transaction (EU regulation). Customer due diligence information, or copies thereof, must also be kept for a minimum of 5 years from the date the person concerned ceases to be a registered customer or from the date of the customer’s most recent activity. These records can be kept in documentary or electronic format.
        </Text>
        <Text>
          The company needs to be aware that when operating in more than one jurisdiction, the requirements for the length of time and the scope of the record keeping may vary, and processes need to take account of this.
        </Text>
        <Text>
          Consideration should also be given to the storage location and the permissions applied to the records to ensure that they are easily accessible.
        </Text>
        <Text>
          In order to ensure that document retention requirements are fulfilled, all AML/CTF policy or procedural documents should be version controlled where possible, and all updates recorded for ease of auditing.
        </Text>
        <Text>
          The destruction of personal data is a specific requirement under the Directive. The company should check how this is implemented through national legislation as well as regulatory guidance and put in place appropriate measures to ensure compliance in this area but operates in compliance with the GDPR unless contra-indicated.
        </Text>
      </Stack>
      <Stack
        id='conclusion'
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title order={2} mt={theme.spacing.xl}>
          Conclusion
        </Title>
        <Text>
          Failure to implement sufficient systems and controls may lead to the company being criminally liable or subject to regulatory sanctions, such as fines or licence revocation. Remote gambling operators like this company, and those who work for them, can reduce their personal risks by the implementation and adherence to regulatory requirements. If there is a proper evaluation of the risks, together with a consideration of the way in which they can be mitigated, there is little for those involved to fear.
        </Text>
        <Text>
          The remote gambling industry, and this company in particular, is fully committed to proportionate and risk-based anti-money laundering regulations.
        </Text>
        <Text>
          As a regulated business, the company has developed an appropriate and effective set of measures to counter money laundering and terrorist financing. These processes and guidelines reflect current best practice and shared experience within the industry, but it is recognized that they will develop further over time, especially as and when new challenges arise and have to be addressed.
        </Text>
      </Stack>
      <Stack
        id='glossary'
        sx={(theme) => ({ backgroundColor: theme.other.darkBackgroundOpaque })}
        className={classes.glossary}
        my={theme.spacing.xl}
      >
        <Title order={2} mt={theme.spacing.xl}>
          Glossary of Abbreviations
        </Title>
        <Text component="li" className={classes.bullets}><Text component="strong">AML</Text> - Anti-Money Laundering</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">CDD</Text> - Customer Due Diligence</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">EDD</Text> - Enhanced Due Diligence</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">CTF</Text> - Counter Terrorism Financing Directive, means Directive 2005/60/EC of the European Parliament and of the Council of 26 October 2005 on the prevention of the use of the financial system for the purpose of money laundering and terrorist financing. This is unless the reference is specifically to the 4th Anti-Money Laundering Directive which EU Member States have until 2017 to implement.</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">employees</Text> - mean all persons actively employed or engaged with a remote gaming operation;</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">FATF</Text> - Financial Action Task Force</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">FIU</Text> - Financial Intelligence Unit</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">IMPLO</Text> - The Institute of Money Laundering Prevention Officers</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">KYC</Text> - Know Your Customer</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">LBO</Text> - Licenced Betting Office</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">LCCP</Text> - Licensing Conditions and Codes of Conduct</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">ML</Text> - Money Laundering</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">MLRO</Text> - Money Laundering Reporting Officer</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">NCA</Text> - National Crime Agency</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">OFSI</Text> - Office of Financial Sanctions Implementation</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">OTC</Text> - Over the Counter</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">PEP</Text> - Politically Exposed Person</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">POCA</Text> - Proceeds of Crime Act 2002</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">Recommendations</Text> - refer to the 40+9 Recommendations on the prevention of money laundering and terrorist financing published by the Financial Action Task Force.</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">Remote gaming</Text> - means any form of gaming by means of distance communications</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">SAR</Text> - Suspicious Activity Report</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">SSBT</Text> - Self-Service Betting Terminal</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">SOF</Text> - Source of Funds</Text>
        <Text component="li" className={classes.bullets}><Text component="strong">TF</Text> - Terrorism Financing</Text>

      </Stack>
    </Container >
  );
};

export default AMLPolicy;
