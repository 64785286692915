import { Anchor, Button, Container, Group, TextInput, Text, Title, useMantineTheme, Box } from '@mantine/core';
import { useEffect, useState, FormEvent } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { login, loginWithRefreshToken } from '../../api/auth';
import shallow from 'zustand/shallow';
import { useStore } from '../../state/state';

import { ReactComponent as IconForward } from '../../assets/icons/icon-forward.svg';
import { useForm } from '@mantine/form';
// @ts-ignore
import ReactRecaptcha3 from 'react-google-recaptcha3';

interface LocationState {
  from?: {
    pathname: string;
  };
}

const Login = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string>('');
  const [loadingRefreshToken, setLoadingRefreshToken] = useState<boolean>(true);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const redirectUrl = (location.state as LocationState)?.from?.pathname || '/play';

  const { auth, setAuth, setHeaderMode } = useStore(
    (state) => ({
      auth: state.auth,
      setAuth: state.setAuth,
      setHeaderMode: state.setHeaderMode,
    }),
    shallow
  );

  useEffect(() => {
    setHeaderMode('login');
  }, [setHeaderMode]);

  useEffect(() => {
    if (!auth.isAuthenticated && auth.token?.refreshToken) {
      setLoadingRefreshToken(true);
      try {
        ReactRecaptcha3.getToken().then((token: string) => {
          loginWithRefreshToken(
            auth.token?.refreshToken ?? '',
            token,
            () => {
              setAuth({ isAuthenticated: false });
              setLoadingRefreshToken(false);
            },
            () => {
              setLoadingRefreshToken(false);
              navigate(redirectUrl, { replace: true });
            }
          );
        });
      } catch (error) {
        console.log('error', error);
        setLoadingRefreshToken(false);
      }
    } else {
      setLoadingRefreshToken(false);
    }
  }, [auth.isAuthenticated, auth.token, navigate, redirectUrl, setAuth]);

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value: string) =>
        /^\S+@\S+$/.test(value) ? null : (
          <Box sx={{ width: 300 }}>
            <Text size="md" sx={{ color: theme.other.red }}>
              <span>Valid Email address is required</span>
            </Text>
          </Box>
        ),
      password: (value: string) =>
        value.length > 0 ? null : (
          <Box sx={{ width: 300 }}>
            <Text size="md" sx={{ color: theme.other.red }}>
              <span>Password is required</span>
            </Text>
          </Box>
        ),
    },
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { hasErrors } = form.validate();
    if (submitting || hasErrors) {
      return;
    }

    setSubmitting(true);
    try {
      const token = await ReactRecaptcha3.getToken();
      await login(
        form.values.email,
        form.values.password,
        token,
        (error: string) => {
          setLoginError(error);
          setSubmitting(false);
        },
        () => {
          navigate(redirectUrl, { replace: true });
          setSubmitting(false);
        }
      );
    } catch (error) {
      console.error(error);
      setLoginError('An error occurred during login.');
      setSubmitting(false);
    }
  };

  if (loadingRefreshToken) {
    return (
      <Container>
        <Text>Logging in...</Text>
      </Container>
    );
  }

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '300px' }}>
        <form onSubmit={handleSubmit}>
          <Title order={1} align="center" mb={40}>
            Log into your
            <br />
            FXBet account
          </Title>
          <TextInput
            disabled={submitting}
            label="Email address"
            description="The email address linked to your account"
            radius="md"
            size="lg"
            mb={form.errors.email ? 10 : 36}
            {...form.getInputProps('email')}
          />
          <TextInput
            disabled={submitting}
            label="Password"
            description="Enter your account password to log in"
            radius="md"
            size="lg"
            type="password"
            mb={form.errors.email ? 16 : 42}
            {...form.getInputProps('password')}
          />
          <Group position="apart">
            <Anchor component={Link} to="/forgot-password" size="lg" weight="bold">
              Forgot
              <br />
              password?
            </Anchor>

            <Button
              type="submit"
              size="lg"
              radius="lg"
              loading={submitting}
              rightIcon={<IconForward height={24} style={{ color: theme.white }} />}
              styles={(theme) => ({
                root: {
                  height: '50px',
                  width: '150px',
                  border: 'none',
                  background: theme.other.gradientGreen,
                  padding: '0 14px',
                },
                label: { flexGrow: 1, justifyContent: 'center' },
              })}
            >
              Log In
            </Button>
          </Group>
          {loginError && (
            <Box
              sx={{ width: 300, backgroundColor: theme.fn.rgba(theme.other.darkBackground, 0.55), borderRadius: 50 }}
              mt={16}
              p={16}
            >
              <Text align="center" size="md" sx={{ color: theme.other.red }}>
                {loginError}
              </Text>
            </Box>
          )}
        </form>
      </Box>
    </Container>
  );
};

export { Login };
