import {
  Group,
  Title,
  TextInput,
  Container,
  Text,
  Stack,
  Space,
  NativeSelect,
  Box,
  useMantineTheme,
  Anchor,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { Info } from 'luxon';
import { GreenButton } from '../../components/buttons/GreenButton';
import {
  GetSalesAdmin,
  RegisterPlayerErrorEnum,
  getCountries,
  getSalesAdminByReferralCodePlayer,
  registerPlayer,
} from '../../api/player';
import { useNavigate } from 'react-router';
import { useMediaQuery } from '@mantine/hooks';
import { Link, useLocation } from 'react-router-dom';
import { Countries, SalesAdmins } from '../../api/interfaces';
// @ts-ignore
import ReactRecaptcha3 from 'react-google-recaptcha3';

export const Signup = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referral = queryParams.get('referralCode');
  const [dobDay, setDobDay] = useState('');
  const [dobMonth, setDobMonth] = useState('');
  const [dobYear, setDobYear] = useState('');
  const [registerError, setRegisterError] = useState<RegisterPlayerErrorEnum>();
  const [registered, setRegistered] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [countries, setCountries] = useState<Countries[]>([]);
  const [salesAdmin, setSalesAdmin] = useState<SalesAdmins[]>([]);
  const [referralCode, setReferralCode] = useState<string>('');
  const [selectedAdmin, setSelectedAdmin] = useState<SalesAdmins | undefined>();
  const [referralCodeError, setReferralCodeError] = useState<null | string>(null);

  const mobileView = useMediaQuery(`(max-width: ${useMantineTheme().breakpoints.sm}px)`);
  const direction = mobileView ? 'column' : 'row';
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      birthDate: '',
      country: '',
      phoneNumber: '',
      streetAddress: '',
      city: '',
      postalCode: '',
    },

    validate: {
      firstName: (value) => (value.length < 2 ? 'First name must have at least 2 letters' : null),
      lastName: (value) => (value.length < 2 ? 'Last name must have at least 2 letters' : null),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      streetAddress: (value) => (value.length > 1 ? null : 'Please enter street address'),
      city: (value) => (value.length > 1 ? null : 'Please enter city or town'),
      postalCode: (value) => (value.length > 1 ? null : 'Please enter postal code'),
      phoneNumber: (value) =>
        /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/.test(
          value
        )
          ? null
          : 'Invalid phone number',
      country: (value) => (countryValidation(value) ? null : 'Sign currently unavailable for this country'),
    },
  });

  const countryValidation = (value: string) => {
    const country = countries.find((country) => country.code === value);
    if (country?.playersAllowed) {
      return true;
    } else {
      return false;
    }
  };

  const handleChangeDay = (event: any) => {
    setDobDay(event.currentTarget.value);
  };

  const handleChangeMonth = (event: any) => {
    setDobMonth(event.currentTarget.value);
    if (dobDay !== '' && Number(dobDay) > getDaysInMonth(event.currentTarget.value, dobYear)) {
      setDobDay('');
    }
  };

  const handleChangeYear = (event: any) => {
    const selectedYear = event.currentTarget.value;
    setDobYear(selectedYear);
  };

  const getDaysInMonth = (month: string, year: string) => {
    return new Date(Number(year), Number(month), 0).getDate();
  };

  const handleFormSubmit = async () => {
    const isReferralCodeFound = salesAdmin.some(
      (x) => x.referralCode.toLocaleLowerCase() === referralCode.toLocaleLowerCase()
    );
    if (referralCode && !isReferralCodeFound) {
      setReferralCodeError(isReferralCodeFound ? null : 'Invalid Referral Code');
      return;
    }
    ReactRecaptcha3.getToken().then(async (token: any) => {
      setSubmitting(true);
      var userResult = (await registerPlayer({ ...form.values, referralCode }, token)) as any;
      if (userResult?.success) {
        // requestTrackingPixel();
        setRegistered(true);
      } else {
        setRegisterError(userResult?.error as RegisterPlayerErrorEnum);
      }
      setSubmitting(false);
    });
  };

  const getCountryObject = async () => {
    const result = await getCountries();
    if (result && Array.isArray(result)) {
      setCountries(result);
    }
  };

  const getSaleUsers = async () => {
    const result = await GetSalesAdmin();
    console.log(result);
    setSalesAdmin(result);
  };

  const checkAndSetReferralCode = async () => {
    if (referral) {
      const result = await getSalesAdminByReferralCodePlayer(referral);
      if (result && result.salesUser && result.referralCode) {
        const salesAdmin: SalesAdmins = {
          id: result.salesUser.id,
          referralCode: result.referralCode,
          firstName: result.salesUser.firstName,
          lastName: result.salesUser.lastName,
        };
        setReferralCode(result.referralCode);
        setSelectedAdmin(salesAdmin);
      } else {
      }
    }
  };

  // const requestTrackingPixel = () => {
  //   const utmSource = queryParams.get('utm_source');
  //   if (utmSource?.toLocaleLowerCase() === 'pmm') {
  //     fetch('https://parabolicpteltd10329696.o18.link/p?o=21061038&m=16949&t=i&event=FXBET_Signup', {
  //       method: 'GET',
  //       mode: 'no-cors',
  //     })
  //       .then(() => {
  //         console.log('Tracking pixel requested successfully');
  //       })
  //       .catch((error) => {
  //         console.error('Error requesting tracking pixel:', error);
  //       });
  //   }
  // };

  useEffect(() => {
    if (!referral) {
      getSaleUsers();
    } else {
      checkAndSetReferralCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referral]);

  useEffect(() => {
    if (dobDay && dobMonth && dobYear) {
      const formattedBirthDate = `${dobYear}-${dobMonth.padStart(2, '0')}-${dobDay.padStart(2, '0')}`;
      form.setFieldValue('birthDate', formattedBirthDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dobDay, dobMonth, dobYear]);

  useEffect(() => {
    getCountryObject();
  }, []);

  return (
    <Container>
      {!registered && !registerError && (
        <form onSubmit={form.onSubmit(() => handleFormSubmit())}>
          <Stack>
            <Title align="center" order={1}>
              Excellent choice!
            </Title>
            <Title align="center" order={2}>
              Create an account to start playing. Sign-up is quick and easy.
            </Title>
            <Space h="xl" />
            <Group grow direction={direction}>
              <TextInput
                autoComplete="name"
                label="First Name"
                required
                radius="md"
                size="lg"
                {...form.getInputProps('firstName')}
              />
              <TextInput
                autoComplete="family-name"
                label="Last name"
                required
                radius="md"
                size="lg"
                {...form.getInputProps('lastName')}
              />
            </Group>
            <Group grow direction={direction}>
              <TextInput
                autoComplete="email"
                type="email"
                label="Email Address"
                required
                radius="md"
                size="lg"
                {...form.getInputProps('email')}
              />
              <TextInput
                type="number"
                label="Contact Number"
                required
                radius="md"
                size="lg"
                {...form.getInputProps('phoneNumber')}
              />
            </Group>
            <Group grow spacing={0}>
              <Stack align="center" spacing={0}>
                <Text align="center" weight="bold" sx={{ marginBottom: '4px' }}>
                  Date of Birth <span style={{ color: '#ff6b6b' }}>*</span>
                </Text>
                <Group grow sx={{ width: '70%' }} direction={direction}>
                  <NativeSelect
                    value={dobDay}
                    required
                    onChange={handleChangeDay}
                    placeholder="Day"
                    data={Array.from({ length: getDaysInMonth(dobMonth, dobYear) }, (_, index) => index + 1).map(
                      (x) => ({
                        value: x.toString(),
                        label: x.toString(),
                      })
                    )}
                    variant="filled"
                    radius="md"
                    size="lg"
                  />
                  <NativeSelect
                    value={dobMonth}
                    required
                    onChange={handleChangeMonth}
                    placeholder="Month"
                    data={Info.months().map((x, i) => ({ value: (i + 1).toString(), label: x }))}
                    variant="filled"
                    radius="md"
                    size="lg"
                  />
                  <NativeSelect
                    value={dobYear}
                    required
                    onChange={handleChangeYear}
                    placeholder="Year"
                    data={Array.from({ length: 100 }, (_, index) => 2023 - index).map((x) => ({
                      value: x.toString(),
                      label: x.toString(),
                    }))}
                    variant="filled"
                    radius="md"
                    size="lg"
                  />
                </Group>
              </Stack>
            </Group>
            <Group grow direction={direction}>
              <TextInput
                label="Street Address"
                required
                radius="md"
                size="lg"
                {...form.getInputProps('streetAddress')}
              />
              <TextInput label="City or Town" required radius="md" size="lg" {...form.getInputProps('city')} />
            </Group>
            <Group grow direction={direction}>
              <TextInput label="Postal Code" required radius="md" size="lg" {...form.getInputProps('postalCode')} />
              <Stack spacing={0}>
                <Text weight="bold" align="center" sx={{ marginBottom: '4px' }}>
                  Select a Country <span style={{ color: '#ff6b6b' }}>*</span>
                </Text>
                <NativeSelect
                  {...form.getInputProps('country')}
                  required
                  placeholder="Select your country"
                  data={countries.map((x) => ({ value: x.code, label: x.name }))}
                  variant="filled"
                  radius="md"
                  size="lg"
                />
              </Stack>
            </Group>
            {!referral && (
              <>
                <Group grow direction={direction}>
                  {/* <Stack spacing={0}>
                    <Text weight="bold" align="center" sx={{ marginBottom: '4px' }}>
                      Sales Rep (Optional)
                    </Text>
                    <NativeSelect
                      value={selectedAdmin ? selectedAdmin.referralCode : ''}
                      placeholder=""
                      data={[
                        { value: '', label: '' },
                        ...salesAdmin?.map((admin) => ({
                          value: admin.referralCode,
                          label: `${admin.firstName} ${admin.lastName}`,
                        })),
                      ]}
                      variant="filled"
                      radius="md"
                      size="lg"
                      onChange={(e) => {
                        const selectedReferralCode = e.target.value;
                        const selectedAdmin = salesAdmin.find((admin) => admin.referralCode === selectedReferralCode);
                        setReferralCode(selectedReferralCode);
                        setSelectedAdmin(selectedAdmin);
                      }}
                    />
                  </Stack> */}

                  <TextInput
                    label="Referral Code (optional)"
                    radius="md"
                    size="lg"
                    error={referralCodeError}
                    value={referralCode || ''}
                    onChange={(e) => {
                      const inputReferralCode = e.target.value;
                      const selectedAdmin = salesAdmin.find((admin) => admin.referralCode === inputReferralCode);
                      setReferralCodeError(null);
                      setReferralCode(inputReferralCode);
                      setSelectedAdmin(selectedAdmin);
                    }}
                  />
                </Group>
                <Space h="xl" />
              </>
            )}

            <Text align="center">
              {`By signing-up for FXBet you agree to our `}
              <Anchor underline href="/terms-and-conditions" target="_blank">
                Terms of Use
              </Anchor>
            </Text>
            <Space h="xl" />
            <Group position="center">
              <GreenButton disabled={submitting} sx={{ width: '300px' }} type="submit">
                Create Account
              </GreenButton>
            </Group>
          </Stack>
          <Space h="xl" />
        </form>
      )}
      {registered && !registerError && (
        <Box>
          {queryParams.get('utm_source')?.toLocaleLowerCase() === 'pmm' && (
            <img
              src="https://parabolicpteltd10329696.o18.link/p?o=21061038&m=16949&t=i&event=FXBET_Signup"
              width={0}
              height={0}
            />
          )}
          <Title align="center" order={1}>
            Success!
          </Title>
          <Title align="center" order={2}>
            Thank you for signing-up with FXBet.
          </Title>
          <Title align="center" order={2}>
            A confirmation email will be sent to the address provided.
          </Title>
          <Space h={64} />
          <Title align="center" order={2}>
            If you do not receive a confirmation email, please get in touch.
          </Title>
          <Space h={64} />
          <Group position="center">
            <GreenButton sx={{ width: '300px' }} onClick={() => navigate('/contact-us')}>
              Contact Us
            </GreenButton>
          </Group>
          <Space h="xl" />
        </Box>
      )}
      {!registered && registerError && (
        <Stack align="center">
          <Stack align="center" justify="center" style={{ height: '200px' }}>
            <Title order={1} align="center">
              Oh no! We couldn't complete sign-up
            </Title>
          </Stack>

          <RegistrationError error={registerError} />
        </Stack>
      )}
    </Container>
  );
};

const RegistrationError = (props: { error?: RegisterPlayerErrorEnum }) => {
  switch (props.error) {
    case 'CountryNotPermitted':
      return (
        <Text size="md" align="center">
          FXBet is not available to players in your country of residence.
        </Text>
      );
    case 'PlayerTooYoung':
      return (
        <Text size="md" align="center">
          Your age appears to be below the legal gambling age in your country of residence
        </Text>
      );
    case 'EmailInUse':
      return (
        <>
          <Text size="md" align="center">
            You email is already linked to an account.
          </Text>
          <Anchor component={Link} to="/forgot-password" size="lg" weight="bold" align="center">
            Forgot password?
          </Anchor>
        </>
      );
    default:
      return (
        <Text size="md" align="center">
          An unexpected error occurred. Please try again, or contact our support.
        </Text>
      );
  }
};
