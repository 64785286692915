import { Stack, Title, Text, TextInput, Box, useMantineTheme } from '@mantine/core';
import { SetStateAction, useState } from 'react';
import { requestPasswordReset } from '../../api/player';
import { GreenButton } from '../../components/buttons/GreenButton';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const theme = useMantineTheme();

  const resetPassword = () => {
    if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      requestPasswordReset(email).then(() => {
        setError('Password reset link sent');
        setDisabled(true);
      });
    } else {
      setError('Invalid email');
    }
  };

  return (
    <Stack align="center" m="auto">
      <Title>Request Password Reset</Title>
      <TextInput
        sx={{
          width: '300px',
        }}
        label="Email address"
        required
        size="lg"
        type="email"
        value={email}
        onChange={(e: { target: { value: SetStateAction<string> } }) => setEmail(e.target.value)}
      ></TextInput>
      <GreenButton
        onClick={resetPassword}
        disabled={disabled}
        styles={{
          root: { minWidth: 'fit-content' },
        }}
      >
        Reset Password
      </GreenButton>
      <Box sx={{ width: 300 }}>
        <Text align="center" size="md" sx={{ color: theme.other.red }} mb={error ? 0 : 16}>
          <span>{error}</span>
        </Text>
      </Box>
    </Stack>
  );
};

export default ForgotPassword;
