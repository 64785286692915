import { ChartDataRef, ChartTickData } from './interface';

export const renderMarkerCircles = (
  context: CanvasRenderingContext2D,
  allTicks: ChartTickData[],
  project: (tick: ChartTickData) => { x: number; chartX: number; chartY: number },
  ref: ChartDataRef
) => {
  for (let i = 0; i < allTicks.length; i++) {
    const projected = project(allTicks[i]);

    const isOpeningTimestamp = ref.wager?.openingTimestamp && projected.x === ref.wager?.openingTimestamp;
    const isClosingTimestamp = ref.wager?.closingTimestamp && projected.x === ref.wager?.closingTimestamp;
    const fillStyle =
      isOpeningTimestamp || isClosingTimestamp
        ? ref.gameColorLight // Use color for opening or closing timestamp
        : i === allTicks.length - 1
        ? ref.gameColor // Use color for the last tick
        : '#fff'; // Default color

    const markerSize = window.innerWidth < 768 ? 3 : 4;
    context.fillStyle = fillStyle;

    context.beginPath();
    context.arc(projected.chartX - 50, projected.chartY, markerSize, 0, 2 * Math.PI);
    context.fill();
  }
};

const addAlpha = (color: string, opacity: number): string => {
  const opacityNumber = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + opacityNumber.toString(16).toUpperCase();
};

export const renderChartLines = (
  context: CanvasRenderingContext2D,
  allTicks: ChartTickData[],
  project: (tick: ChartTickData) => { x: number; chartX: number; chartY: number },
  canvas: HTMLCanvasElement,
  ref: ChartDataRef
) => {
  let gradient = context.createLinearGradient(0, 0, 0, canvas.width);
  gradient.addColorStop(0, `${addAlpha(ref.gameColor, 0.1)}`);
  gradient.addColorStop(1, ref.gameColor);

  const initialTick = project(allTicks[0]);
  context.beginPath();
  context.strokeStyle = '#fff';
  context.lineWidth = 2;
  context.setLineDash([]);
  // Move to initial tick position
  context.moveTo(initialTick.chartX - 50, initialTick.chartY);

  // Draw lines between each tick position
  for (let i = 1; i < allTicks.length; i++) {
    const projected = project(allTicks[i]);
    context.lineTo(projected.chartX - 50, projected.chartY);
  }

  // Render
  context.stroke();
  const projected = project(allTicks[allTicks.length - 1]);
  context.lineTo(projected.chartX - 50, canvas.height);
  context.lineTo(0, canvas.height);
  context.fillStyle = gradient;
  context.fill();
};

export const drawBar = (
  context: CanvasRenderingContext2D,
  canvasWidth: number,
  canvasHeight: number,
  color: string,
  width: number,
  yPos: number,
  text: string | null
) => {
  context.fillStyle = color;
  context.fillRect(canvasWidth - width, yPos, width, canvasHeight - yPos);

  if (text) {
    context.fillStyle = '#fff';
    context.font = 'bold 20px Quicksand';
    const textYPos = yPos === 0 ? 20 : canvasHeight - 10;
    context.fillText(text, canvasWidth - width + 5, textYPos);
  }
};

export const drawDashedLine = (context: CanvasRenderingContext2D, yPos: number, width: number, canvasWidth: number) => {
  context.setLineDash([8, 4]);
  context.strokeStyle = '#fff';
  context.lineWidth = 2;

  context.beginPath();
  context.moveTo(0, yPos);
  context.lineTo(canvasWidth - width, yPos);
  context.stroke();
};
