import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { PlayerAccount, PlayerSearchFilterEnum, WagerActivityTimeFilter } from '../../../api/interfaces';
import {
  Box,
  Button,
  Divider,
  Space,
  Stack,
  Table,
  Title,
  Text,
  Group,
  ThemeIcon,
  Select,
  Input,
  InputWrapper,
  Anchor,
  ScrollArea,
} from '@mantine/core';
import { ActiveGradient, InActiveGradient } from '../../../utils/consts';
import { useStyles } from '../styles';
import {
  calculateDateFromToday,
  formatCurrencySymbol,
  formatDate,
  formatPlayerFullName,
} from '../../../utils/formatters';
import { ReactComponent as FrozenIcon } from '../../../assets/icons/frozen.svg';
import { ReactComponent as FlagIcon } from '../../../assets/icons/flag.svg';
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye.svg';
import { useNavigate } from 'react-router-dom';
import { toggleBannedStatus, toggleFlaggedStatus } from '../../../api/backoffice';
import { useStore } from '../../../state/state';
import ExcelDownload from '../../../components/reporting/ExcelDownload';

const PlayerActivity = ({
  activeFilterActivity,
  playerAccounts,
  sortByFilter,
  setSearchTerm,
  setSortByFilter,
  setActiveFilterActivity,
  getPlayers,
}: {
  activeFilterActivity: WagerActivityTimeFilter;
  playerAccounts: PlayerAccount[];
  sortByFilter: string;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  setSortByFilter: Dispatch<SetStateAction<PlayerSearchFilterEnum>>;
  setActiveFilterActivity: Dispatch<SetStateAction<WagerActivityTimeFilter>>;
  getPlayers: Dispatch<SetStateAction<void>>;
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const auth = useStore((state) => state.auth);
  let debounceTimeout: NodeJS.Timeout | null = null;
  const filterButtons: WagerActivityTimeFilter[] = ['24 Hours', '7 Days', '1 Month', '6 Months', 'MTD', 'YTD'];
  const [downloadFileName, setDownloadFileName] = useState<string>('');

  const toggleFlagged = async (currentStatus: boolean, id: string) => {
    const result = await toggleFlaggedStatus(id, !currentStatus);
    if (result) {
      getPlayers();
    }
  };

  const toggleBanned = async (currentStatus: boolean, id: string) => {
    const result = await toggleBannedStatus(id, !currentStatus);
    if (result) {
      getPlayers();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(() => {
      setSearchTerm(value);
    }, 1500);
  };

  useEffect(() => {
    calculateDateFromToday(activeFilterActivity).then((date: string) => {
      const formattedDate = new Date(date);
      const startDate = `${formattedDate.getFullYear()}_${formattedDate.getMonth() + 1}_${formattedDate.getDate()}`;
      const endDate = new Date().toISOString().split('T')[0];
      setDownloadFileName(`Player_Activity_${activeFilterActivity}_${startDate}_${endDate}`);
    });
  }, [activeFilterActivity]);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Title align="left" sx={{ flexGrow: 1 }} order={1}>
          Player Activity
        </Title>
        {auth?.user?.role?.includes('SuperAdmin') && (
          <ExcelDownload
            content={playerAccounts}
            defaultFields={[
              'firstName',
              'lastName',
              'playtime_firstSession',
              'playtime_lastActive',
              'deposit',
              'spent',
              'won',
              'withdraw',
              'isFlagged',
              'isClosed',
              'isBanned',
            ]}
            fileName={downloadFileName}
            headers={[
              'First Name',
              'Last Name',
              'First Session',
              'Last Active',
              'Deposit',
              'Spent',
              'Won',
              'Withdrawn',
              'Flagged',
              'Closed',
              'Banned',
            ]}
          />
        )}
      </Box>
      <Divider size={2} sx={{ margin: '24px 0px' }} />
      <Box sx={{ display: 'flex', margin: '24px 0px' }}>
        <Stack spacing="md">
          <Title align="left" order={2}>
            Choose time span
          </Title>
          {filterButtons.map((button, index) => (
            <Button
              key={index}
              onClick={() => setActiveFilterActivity(button)}
              variant="gradient"
              radius="lg"
              gradient={activeFilterActivity === button ? ActiveGradient : InActiveGradient}
              sx={{ height: '50px', width: '190px' }}
            >
              <Title order={3}>{button}</Title>
            </Button>
          ))}
        </Stack>
        <Box
          sx={{
            flexGrow: 1,
            marginLeft: '16px',
          }}
        >
          <Box className={classes.container} sx={{ width: '100%' }}>
            <Group grow position="apart">
              <InputWrapper label="Search for Player">
                <Input
                  sx={{ width: '200px' }}
                  name="firstName"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
                />
              </InputWrapper>
              <Select
                value={sortByFilter}
                sx={{ maxWidth: '200px' }}
                label="Filter by"
                onChange={(selectedValue: string) => setSortByFilter(selectedValue as PlayerSearchFilterEnum)}
                data={Object.values(PlayerSearchFilterEnum).map((filter) => ({
                  value: filter,
                  label: filter,
                }))}
              />
            </Group>
            <Space h="md" />
            <ScrollArea style={{ height: 410 }}>
              <Table sx={{ tableLayout: 'auto' }}>
                <thead className={classes.tableHeader}>
                  <tr>
                    <th>
                      <Text>Player</Text>
                    </th>
                    <th>
                      <Text>Email</Text>
                    </th>
                    <th>
                      <Text align="center">Player Since</Text>
                    </th>
                    <th>
                      <Text align="center">Last Online</Text>
                    </th>
                    <th>
                      <Text align="center">Deposited</Text>
                    </th>
                    <th>
                      <Text align="center">Spent</Text>
                    </th>
                    <th>
                      <Text align="center">Won</Text>
                    </th>
                    <th>
                      <Text align="center">Withdrawn</Text>
                    </th>
                    {auth?.user?.role?.includes('SuperAdmin') && (
                      <th>
                        <Text align="center">Role</Text>
                      </th>
                    )}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {playerAccounts.map((player, index) => (
                    <tr key={index}>
                      <td>
                        <Text
                          sx={{ color: player.isBanned ? '#00cafb !important' : player.isFlagged ? '#eb6c35' : '' }}
                          lineClamp={1}
                        >
                          <Anchor
                            sx={{ color: player.isBanned ? '#00cafb !important' : player.isFlagged ? '#eb6c35' : '' }}
                            onClick={() => navigate(`/admin/player-overview/player-account/${player.id}`)}
                          >
                            {formatPlayerFullName(player.firstName, player.lastName)}
                          </Anchor>
                        </Text>
                      </td>
                      <td>
                        <Text
                          align="center"
                          sx={{ color: player.isBanned ? '#00cafb !important' : player.isFlagged ? '#eb6c35' : '' }}
                        >
                          {player.email}
                        </Text>
                      </td>
                      <td>
                        <Text
                          align="center"
                          sx={{ color: player.isBanned ? '#00cafb !important' : player.isFlagged ? '#eb6c35' : '' }}
                        >
                          {player.registeredDate ? formatDate(player.registeredDate.toLocaleString(), false, true) : ''}
                        </Text>
                      </td>
                      <td>
                        <Text
                          align="center"
                          sx={{ color: player.isBanned ? '#00cafb !important' : player.isFlagged ? '#eb6c35' : '' }}
                        >
                          {player.playtime.lastActive
                            ? formatDate(player.playtime.lastActive.toLocaleString(), false, true)
                            : ''}
                        </Text>
                      </td>
                      <td>
                        <Text
                          align="center"
                          sx={{ color: player.isBanned ? '#00cafb !important' : player.isFlagged ? '#eb6c35' : '' }}
                        >
                          {formatCurrencySymbol(player.currency)}
                          {player.deposit}
                        </Text>
                      </td>
                      <td>
                        <Text
                          align="center"
                          sx={{ color: player.isBanned ? '#00cafb !important' : player.isFlagged ? '#eb6c35' : '' }}
                        >
                          {formatCurrencySymbol(player.currency)}
                          {player.spent}
                        </Text>
                      </td>
                      <td>
                        <Text
                          align="center"
                          sx={{ color: player.isBanned ? '#00cafb !important' : player.isFlagged ? '#eb6c35' : '' }}
                        >
                          {formatCurrencySymbol(player.currency)}
                          {player.won}
                        </Text>
                      </td>
                      <td>
                        <Text
                          align="center"
                          sx={{ color: player.isBanned ? '#00cafb !important' : player.isFlagged ? '#eb6c35' : '' }}
                        >
                          {formatCurrencySymbol(player.currency)}
                          {player.withdraw}
                        </Text>
                      </td>
                      {auth?.user?.role?.includes('SuperAdmin') && (
                        <td>
                          <Text
                            align="center"
                            sx={{ color: player.isBanned ? '#00cafb !important' : player.isFlagged ? '#eb6c35' : '' }}
                          >
                            {' '}
                            {player.role}{' '}
                          </Text>
                        </td>
                      )}
                      <td>
                        <Group position="center">
                          <ThemeIcon
                            sx={{
                              cursor: 'pointer',
                              backgroundColor: `${player.isFlagged ? '#eb6c35' : null}`,
                              display: 'fex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '0px',
                            }}
                            onClick={() => toggleFlagged(player.isFlagged, player.id)}
                          >
                            <FlagIcon style={{ display: 'flex' }} />
                          </ThemeIcon>
                          <ThemeIcon
                            sx={{
                              cursor: 'pointer',
                              backgroundColor: `${player.isBanned ? '#00cafb' : null}`,
                              display: 'fex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '0px',
                            }}
                            onClick={() => toggleBanned(player.isBanned, player.id)}
                          >
                            <FrozenIcon style={{ display: 'flex' }} />
                          </ThemeIcon>
                          <ThemeIcon
                            sx={{
                              cursor: 'pointer',
                              display: 'fex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '0px',
                            }}
                            onClick={() => navigate(`/admin/player-overview/player-account/${player.id}`)}
                          >
                            <EyeIcon style={{ display: 'flex' }} />
                          </ThemeIcon>
                        </Group>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </ScrollArea>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PlayerActivity;
