import { useEffect, useState } from 'react';
import { getMarketStatistics } from '../../api/game';
import { calculateDateFromToday } from '../../utils/formatters';
import {
  MarketStatistics,
  PlayerAccount,
  PlayerSearchFilterEnum,
  PlayerSearchParams,
  WagerActivityTimeFilter,
} from '../../api/interfaces';
import { Box, Group, Title, Select, Space } from '@mantine/core';
import { useStyles } from './styles';
import { searchAccounts } from '../../api/backoffice';
import WagerInsights from './wager-insights/WagerInsights';
import PlayerInsights from './player-insights/PlayerInsights';

const Dashboard = () => {
  const { classes } = useStyles();
  const [marketStatistics, setMarketStatistics] = useState<MarketStatistics[]>([]);
  const [playerInsights, setPlayerInsights] = useState<PlayerAccount[]>([]);
  const [timeSpan, setTimeSpan] = useState<WagerActivityTimeFilter>('1 Month');
  const [queryInterval, setQueryInterval] = useState<string>('5');

  const getGameInsights = async () => {
    const date = await calculateDateFromToday(timeSpan);
    const marketStatistics = await getMarketStatistics('?since=' + date);
    if (marketStatistics) {
      setMarketStatistics(marketStatistics);
    }
  };

  const getPlayerInsights = async () => {
    const date = await calculateDateFromToday(timeSpan);
    const searchParams: PlayerSearchParams = {
      duration: new Date(date),
    };
    const playerInsights = await searchAccounts(searchParams);
    if (playerInsights) {
      setPlayerInsights(playerInsights);
    }
  };

  // const setTimeSpanHandler = (duration: WagerActivityTimeFilter) => {
  //   setTimeSpan(duration);
  // };

  // const setIntervalHandler = (interval: string) => {
  //   setQueryInterval(interval);
  // };

  //This is incase we want to implement the filter again
  // useEffect(() => {
  //   const fetchData = () => {
  //     getGameInsights();
  //     getPlayerInsights();
  //   };
  //   fetchData();
  //   const intervalId = setInterval(fetchData, parseInt(queryInterval) * 60000);
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [timeSpan, queryInterval]);

  useEffect(() => {
    const fetchData = () => {
      getGameInsights();
      getPlayerInsights();
    };
    fetchData();
    // const intervalId = setInterval(fetchData, parseInt(queryInterval) * 60000);
    // return () => {
    //   clearInterval(intervalId);
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title align="center" order={1}>
        FXBet Overview
      </Title>
      <Space h={32} />
      {/* <Box className={classes.contentContainer}>
          <Group>
            <Title order={2}>Time Span</Title>
            <Select
              onChange={setTimeSpanHandler}
              value={timeSpan}
              data={[
                { value: '24 Hours', label: '24 Hours' },
                { value: '7 Days', label: '7 Days' },
                { value: '1 Month', label: '1 Month' },
                { value: '6 Months', label: '6 Months' },
              ]}
            />
          </Group>
        </Box> */}
      {/* <Box className={classes.contentContainer}>
          <Group>
            <Title order={2}>Set Interval</Title>
            <Select
              onChange={setIntervalHandler}
              value={queryInterval}
              data={[
                { value: '1', label: '1 minute' },
                { value: '5', label: '5 minutes' },
                { value: '15', label: '15 minutes' },
                { value: '30', label: '30 minutes' },
                { value: '60', label: '60 minutes' },
              ]}
            />
          </Group>
        </Box> */}
      <WagerInsights marketStatistics={marketStatistics} timeSpan={timeSpan} />
      <Space h={32} />
      <PlayerInsights playerAccounts={playerInsights} timeSpan={timeSpan} />
    </>
  );
};

export default Dashboard;
