import { host } from '../host';
import fetch from './interceptor';

export const sendContactUsMessage = async (name: string, email: string,  message: string, token: string) => {
  try {
    const body = {
      name,
      email,
      message,
      token,
    };
    await fetch(`${host}/api/contact-us`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-Recaptcha-token': token || '',
      },
      body: JSON.stringify(body),
    });
  } catch {}
};
