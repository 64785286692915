import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => {
  return {
    container: {
      backgroundColor: theme.other.tabBackgroundColor,
      borderRadius: '20px',
      padding: '16px',
    },
    InputWrapper: {
      label: {
        marginLeft: '16px',
      },
    },
  };
});

export { useStyles };
