export enum Months {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export enum WeekDays {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export const ActiveGradient = { from: '#8C52DA', to: '#2B0C9E', deg: 180 };
export const InActiveGradient = { from: '#2A94D9', to: '#15499D', deg: 180 };
export const GrayGradient = { from: '#82909B', to: '#363F4C', deg: 180 };

export const gameList = ['Hong Kong Kicks','Big Ben','Ninja Wars','Bucks Bunny','Aussie Rules','European Winner','China Town','Coin Bonanza','Ripple Effect','Up In The Ether','Golden Nuggets','Platinized','Cutlery Thief']

export const reCAPTCHAConfigs = {
  siteKey: '6LfSZTgoAAAAALp3ZuI5PkRe3umBSvLDLODJN6nV',
}

export const googleAnalytics = {
  trackingID: 'G-GRGN5ZV649'
}

export const isoToPhoneCode: { [isoCode: string]: string } = {
  AF: '+93',  // Afghanistan
  AL: '+355', // Albania
  DZ: '+213', // Algeria
  AD: '+376', // Andorra
  AO: '+244', // Angola
  AI: '+1',   // Anguilla
  AG: '+1',   // Antigua and Barbuda
  AR: '+54',  // Argentina
  AM: '+374', // Armenia
  AU: '+61',  // Australia
  AT: '+43',  // Austria
  AZ: '+994', // Azerbaijan
  BS: '+1',   // Bahamas
  BH: '+973', // Bahrain
  BD: '+880', // Bangladesh
  BB: '+1',   // Barbados
  BY: '+375', // Belarus
  BE: '+32',  // Belgium
  BZ: '+501', // Belize
  BJ: '+229', // Benin
  BM: '+1',   // Bermuda
  BT: '+975', // Bhutan
  BO: '+591', // Bolivia
  BA: '+387', // Bosnia and Herzegovina
  BW: '+267', // Botswana
  BR: '+55',  // Brazil
  BN: '+673', // Brunei
  BG: '+359', // Bulgaria
  BF: '+226', // Burkina Faso
  BI: '+257', // Burundi
  KH: '+855', // Cambodia
  CM: '+237', // Cameroon
  CA: '+1',   // Canada
  CV: '+238', // Cape Verde
  KY: '+1',   // Cayman Islands
  CF: '+236', // Central African Republic
  TD: '+235', // Chad
  CL: '+56',  // Chile
  CN: '+86',  // China
  CO: '+57',  // Colombia
  KM: '+269', // Comoros
  CG: '+242', // Congo
  CD: '+243', // Congo (Democratic Republic)
  CR: '+506', // Costa Rica
  HR: '+385', // Croatia
  CU: '+53',  // Cuba
  CY: '+357', // Cyprus
  CZ: '+420', // Czech Republic
  DK: '+45',  // Denmark
  DJ: '+253', // Djibouti
  DM: '+1',   // Dominica
  DO: '+1',   // Dominican Republic
  EC: '+593', // Ecuador
  EG: '+20',  // Egypt
  SV: '+503', // El Salvador
  GQ: '+240', // Equatorial Guinea
  ER: '+291', // Eritrea
  EE: '+372', // Estonia
  ET: '+251', // Ethiopia
  FJ: '+679', // Fiji
  FI: '+358', // Finland
  FR: '+33',  // France
  GA: '+241', // Gabon
  GM: '+220', // Gambia
  GE: '+995', // Georgia
  DE: '+49',  // Germany
  GH: '+233', // Ghana
  GR: '+30',  // Greece
  GD: '+1',   // Grenada
  GT: '+502', // Guatemala
  GN: '+224', // Guinea
  GW: '+245', // Guinea-Bissau
  GY: '+592', // Guyana
  HT: '+509', // Haiti
  HN: '+504', // Honduras
  HK: '+852', // Hong Kong
  HU: '+36',  // Hungary
  IS: '+354', // Iceland
  IN: '+91',  // India
  ID: '+62',  // Indonesia
  IR: '+98',  // Iran
  IQ: '+964', // Iraq
  IE: '+353', // Ireland
  IL: '+972', // Israel
  IT: '+39',  // Italy
  CI: '+225', // Ivory Coast
  JM: '+1',   // Jamaica
  JP: '+81',  // Japan
  JO: '+962', // Jordan
  KZ: '+7',   // Kazakhstan
  KE: '+254', // Kenya
  KW: '+965', // Kuwait
  KG: '+996', // Kyrgyzstan
  LA: '+856', // Laos
  LV: '+371', // Latvia
  LB: '+961', // Lebanon
  LS: '+266', // Lesotho
  LR: '+231', // Liberia
  LY: '+218', // Libya
  LI: '+423', // Liechtenstein
  LT: '+370', // Lithuania
  LU: '+352', // Luxembourg
  MO: '+853', // Macau
  MK: '+389', // Macedonia
  MG: '+261', // Madagascar
  MW: '+265', // Malawi
  MY: '+60',  // Malaysia
  MV: '+960', // Maldives
  ML: '+223', // Mali
  MT: '+356', // Malta
  MH: '+692', // Marshall Islands
  MQ: '+596', // Martinique
  MR: '+222', // Mauritania
  MU: '+230', // Mauritius
  YT: '+262', // Mayotte
  MX: '+52',  // Mexico
  FM: '+691', // Micronesia
  MD: '+373', // Moldova
  MC: '+377', // Monaco
  MN: '+976', // Mongolia
  ME: '+382', // Montenegro
  MS: '+1',   // Montserrat
  MA: '+212', // Morocco
  MZ: '+258', // Mozambique
  MM: '+95',  // Myanmar
  NA: '+264', // Namibia
  NR: '+674', // Nauru
  NP: '+977', // Nepal
  NL: '+31',  // Netherlands
  NC: '+687', // New Caledonia
  NZ: '+64',  // New Zealand
  NI: '+505', // Nicaragua
  NE: '+227', // Niger
  NG: '+234', // Nigeria
  KP: '+850', // North Korea
  NO: '+47',  // Norway
  OM: '+968', // Oman
  PK: '+92',  // Pakistan
  PS: '+970', // Palestine
  PA: '+507', // Panama
  PG: '+675', // Papua New Guinea
  PY: '+595', // Paraguay
  PE: '+51',  // Peru
  PH: '+63',  // Philippines
  PL: '+48',  // Poland
  PT: '+351', // Portugal
  QA: '+974', // Qatar
  RO: '+40',  // Romania
  RU: '+7',   // Russia
  RW: '+250', // Rwanda
  SH: '+290', // Saint Helena
  KN: '+1',   // Saint Kitts and Nevis
  LC: '+1',   // Saint Lucia
  VC: '+1',   // Saint Vincent and the Grenadines
  WS: '+685', // Samoa
  SM: '+378', // San Marino
  ST: '+239', // Sao Tome and Principe
  SA: '+966', // Saudi Arabia
  SN: '+221', // Senegal
  RS: '+381', // Serbia
  SC: '+248', // Seychelles
  SL: '+232', // Sierra Leone
  SG: '+65',  // Singapore
  SK: '+421', // Slovakia
  SI: '+386', // Slovenia
  SB: '+677', // Solomon Islands
  SO: '+252', // Somalia
  ZA: '+27',  // South Africa
  KR: '+82',  // South Korea
  ES: '+34',  // Spain
  LK: '+94',  // Sri Lanka
  SD: '+249', // Sudan
  SR: '+597', // Suriname
  SZ: '+268', // Swaziland
  SE: '+46',  // Sweden
  CH: '+41',  // Switzerland
  SY: '+963', // Syria
  TW: '+886', // Taiwan
  TJ: '+992', // Tajikistan
  TZ: '+255', // Tanzania
  TH: '+66',  // Thailand
  TG: '+228', // Togo
  TO: '+676', // Tonga
  TT: '+1',   // Trinidad and Tobago
  TN: '+216', // Tunisia
  TR: '+90',  // Turkey
  TM: '+993', // Turkmenistan
  TC: '+1',   // Turks and Caicos Islands
  UG: '+256', // Uganda
  UA: '+380', // Ukraine
  AE: '+971', // United Arab Emirates
  GB: '+44',  // United Kingdom
  US: '+1',   // United States
  UY: '+598', // Uruguay
  UZ: '+998', // Uzbekistan
  VU: '+678', // Vanuatu
  VA: '+39',  // Vatican City
  VE: '+58',  // Venezuela
  VN: '+84',  // Vietnam
  YE: '+967', // Yemen
  ZM: '+260', // Zambia
  ZW: '+263', // Zimbabwe
};