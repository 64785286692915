import { Anchor, Title } from '@mantine/core';
import { useStyles } from './styles';

function Glossary() {
  const { classes } = useStyles();
  return (
    <>
      <Title order={2} mt="0" mb="sm">
        Glossary
      </Title>
      <Anchor className={classes.anchor} href="#privacy_policy">
        Privacy Policy
      </Anchor>
      <Anchor className={classes.anchor} href="#info_type">
        Information We Collect
      </Anchor>
      <Anchor className={classes.anchor} href="#cookies">
        Cookies
      </Anchor>
      <Anchor className={classes.anchor} href="#collected_info_use">
        How We Use Collected Information
      </Anchor>
      <Anchor className={classes.anchor} href="#personal_info">
        Personal Information
      </Anchor>
      <Anchor className={classes.anchor} href="#third_party">
        Sharing With Third Parties
      </Anchor>
      <Anchor className={classes.anchor} href="#safeguard">
        Information Safeguarding
      </Anchor>
      <Anchor className={classes.anchor} href="#retain_information">
        How Information is Used
      </Anchor>
      <Anchor className={classes.anchor} href="#transaction">
        Transaction Processing
      </Anchor>
      <Anchor className={classes.anchor} href="#data_security">
        Data Security
      </Anchor>
      <Anchor className={classes.anchor} href="#legal_rights">
        Legal Rights
      </Anchor>
      <Anchor className={classes.anchor} href="#no_fee">
        No Fee Usually Required
      </Anchor>
      <Anchor className={classes.anchor} href="#info_needed">
        What We May Need From You
      </Anchor>
      <Anchor className={classes.anchor} href="#response_time">
        Response Time Limit
      </Anchor>
      <Anchor className={classes.anchor} href="#minors">
        Minors
      </Anchor>
      <Anchor className={classes.anchor} href="#contact">
        How to Contact Us
      </Anchor>
    </>
  );
}
export default Glossary;
