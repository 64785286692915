import { Box, Center, Group, NumberInput, Paper, Space, Text, Title, useMantineTheme } from '@mantine/core';
import { Game, Market, WagerTypeEnum } from '../../../../api/interfaces';
import { WagerSlider } from '../../../../components/game/wagerSlider';
import { WagerTypeButton } from '../../../../components/game/wagerTypeButton';

import { ReactComponent as IconEuro } from '../../../../assets/icons/icon-currency-euro.svg';
import { formatCurrency } from '../../../../utils/formatters';
import { useMarketStore } from '../../../../state/state';

export const WagerTypeAndAmount = (props: { game: Game; market: Market }) => {
  const wagering = useMarketStore(props.market)((state) => state.wagering);
  const updateWagering = useMarketStore(props.market)((state) => state.updateWagering);

  const theme = useMantineTheme();

  const fontSize = wagering.stake < 100 ? '40px' : wagering.stake < 1000 ? '32px' : '24px';

  if (!props.market) return null;

  return (
    <>
      <Group position="apart" grow>
        <Box>
          <Title order={3}>Bet UP or DOWN</Title>
          <Text size="xs">Is the feed going to move up or down?</Text>
        </Box>
        <Group spacing="xs" position="center" noWrap={true}>
          <Box>
            <WagerTypeButton
              game={props.game}
              type={WagerTypeEnum.Rise}
              selected={wagering.wagerType === WagerTypeEnum.Rise}
              onClick={() =>
                updateWagering((state) => {
                  state.wagerType = WagerTypeEnum.Rise;
                })
              }
            />
          </Box>
          <Box>
            <WagerTypeButton
              game={props.game}
              type={WagerTypeEnum.Fall}
              selected={wagering.wagerType === WagerTypeEnum.Fall}
              onClick={() =>
                updateWagering((state) => {
                  state.wagerType = WagerTypeEnum.Fall;
                })
              }
            />
          </Box>
        </Group>
      </Group>
      <Group position="apart" grow>
        <Box>
          <Title order={3}>What will you bet?</Title>
          <Text size="xs">
            Select an amount you
            <br />
            want to stake
          </Text>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <NumberInput
            radius="md"
            variant="filled"
            value={wagering.stake}
            min={props.market.minWager}
            max={props.market.maxWager}
            hideControls={true}
            icon={<IconEuro style={{ color: theme.white, height: '25px' }} />}
            styles={{
              input: {
                height: '50px',
                width: '100%',
                maxWidth: '220px',
                [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                  width: '115px !important',
                },
                fontSize: fontSize,
                textAlign: 'right',
              },
            }}
            onChange={(val) => {
              if (val)
                updateWagering((state) => {
                  state.stake = Math.round(val);
                });
            }}
          />
        </Box>
      </Group>
      <WagerSlider
        game={props.game}
        min={props.market.minWager}
        max={props.market.maxWager}
        value={wagering.stake}
        setValue={(val) =>
          updateWagering((state) => {
            state.stake = val;
          })
        }
      />
      <Center>
        <Paper radius="md" sx={{ padding: '4px 10px 5px 10px' }} mb="xs">
          <Text size="md" sx={{ lineHeight: 1 }}>
            <strong>
              You could win
              <span style={{ paddingLeft: '0.66rem' }}>
                {formatCurrency(wagering.stake * props.market.forOdds, props.market.wagerCurrency)}
              </span>
            </strong>
          </Text>
        </Paper>
      </Center>
    </>
  );
};
