import { AppShell, MantineProvider, Global, Container } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';

import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import './App.css';
import { CustomHeader } from './components/layout/header';
import { RequireAuth } from './components/requireAuth';
import { Login } from './pages/auth/login';
import { GameList } from './pages/games/list';
import { PlayRouter } from './pages/games/play/index';
import { Landing } from './pages/landing/Landing';

import BackgroundPurpleChart from './assets/backgrounds/bg-purple-chart.png';
import { Signup } from './pages/signup';
import { CustomNavbar } from './components/layout/navbar';
import { CustomFooter } from './components/layout/footer';
import Account from './pages/account/Account';
import { useStore } from './state/state';

import Wallet from './pages/wallet/wallet';
import { ConfirmEmail } from './pages/signup/confirmEmail';
import ResponsibleGaming from './pages/responsible-gaming/ResponsibleGaming';
import ActiveBets from './pages/active-bets/activeBets';
import ResponsibleGamingModal from './components/modals/ResponsibleGamingModal';
import WagerActivity from './pages/bo-wager-activity/WagerActvity';

import { DepositFunds } from './pages/wallet/deposit';
import { ContactUs } from './pages/contact-us/ContactUs';
import ForgotPassword from './pages/forgot-password/forgotPassword';
import { ResetPassword } from './pages/forgot-password/ResetPassword';

// static pages
import PrivacyPolicy from './pages/legalPages/privacyPolicy';
import TermsAndConditions from './pages/legalPages/termsAndConditions';
import ResponsibleGambling from './pages/legalPages/responsible-gambling';
import AMLPolicy from './pages/legalPages/amlPolicy';
import ComplianceAndArbitrationPolicy from './pages/legalPages/complianceAndArbitration';
import PaymentsPolicy from './pages/legalPages/payments-policy';
import LegalFooter from './components/layout/LegalFooter';
import Dashboard from './pages/bo-dashboard/Dashboard';
import PlayerAccount from './pages/bo-player-accounts/player-account/player-account';
import GameControls from './pages/bo-game-controls/GameControls';
import PlayerOverview from './pages/bo-player-overview/PlayerOverview';
import WalletAndFinance from './pages/bo-wallet-finance/WalletFinanceBO';
// @ts-ignore
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { useEffect } from 'react';
import { reCAPTCHAConfigs } from './utils/consts';
import Help from './pages/help/help';
import InitializeGoogleAnalytics from './components/analytics/analytics';
import UserDashboard from './pages/dashboard/UserDashboard';
import PolicyChangeModal from './components/modals/PolicyChangeModal';

function App() {
  const state = useStore((state) => state.auth);

  useEffect(() => {
    ReactRecaptcha3.init(reCAPTCHAConfigs.siteKey).then((status: any) => {
      InitializeGoogleAnalytics();
    });
  }, []);

  return (
    <MantineProvider
      withNormalizeCSS
      withGlobalStyles
      theme={{
        fontFamily: 'Quicksand, sans-serif',
        fontSizes: {
          xs: 12,
          sm: 14,
          md: 16,
          lg: 18,
          xl: 20,
        },
        lineHeight: 1.3,
        white: '#f7f6f6',
        colorScheme: 'dark',
        headings: {
          fontWeight: 'bold',
          fontFamily: 'Quicksand, sans-serif',
          sizes: {
            h1: { fontSize: '30px' },
            h2: { fontSize: '20px' },
            h3: { fontSize: '16px' },
            h4: { fontSize: '14px' },
          },
        },
        radius: {
          sm: 5,
          md: 10,
          lg: 20,
        },
        spacing: {
          xs: 10,
          sm: 12,
          md: 16,
          lg: 20,
          xl: 24,
        },
        other: {
          darkBackgroundOpaque: 'rgba(5,33,48,0.32);',
          darkBackgroundMedium: 'rgba(5,33,48,0.62);',
          darkBackground: 'rgba(5,33,48,0.7)',
          darkBackgroundSolid: 'rgba(5,33,48,1)',
          green: '#88c32c',
          lightGreen: '#7fe1ad',
          red: '#ff5353',
          blue: '#00cafb',
          orange: '#ffc581',
          tabBackgroundColor: '#033551',
          tabBackgroundColorAlt: '#224962',
          white: '#FFFFFF',
          gradientGreen: 'linear-gradient(to bottom, rgb(217, 224, 33) 0%, rgb(0, 146, 69) 73%)',
          gradientGreenOpacity: 'linear-gradient(to bottom, rgba(217, 224, 33, 0.7) 0%, rgba(0, 146, 69, 0.7) 73%)',
          gradientBlue: 'linear-gradient(to bottom, rgba(0, 202, 251, 0.7) 0%, rgba(11, 94, 171, 0.7) 50%)',
          gradientBlueNoOpacity: 'linear-gradient(to bottom, rgba(0, 202, 251, 1) 0%, rgba(11, 94, 171, 1) 50%)',
          gradientPurple: 'linear-gradient(to bottom, rgba(226, 118, 248, 0.7) 0%, rgba(78, 11, 171, 0.7) 71%)',
          gradientBlueSolid:
            'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(3,167,245,1) 50%, rgba(11,94,171,1) 100%);',
        },
      }}
      styles={{
        Text: {
          root: {
            color: '#f7f6f6',
          },
        },
        Title: (theme) => ({
          root: {
            color: theme.white,
            lineHeight: 1.3,
            '&:is(h1)': {
              [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                fontSize: '20px',
              },
            },
          },
        }),
        Timeline: (theme) => ({
          itemTitle: {
            borderTop: `2px solid ${theme.other.blue}`,
            borderBottom: `2px solid ${theme.other.blue}`,
            fontFamily: 'Quicksand,sans-serif',
            fontWeight: 'bold',
            fontSize: '20px',
            lineHeight: 1.35,
            margin: 0,
            color: theme.other.white,
          },
          itemBullet: {
            backgroundColor: `${theme.other.blue} !important`,
            color: `${theme.other.blue} !important`,
            borderColor: `${theme.other.blue} !important`,
          },
          item: {
            '&::before': {
              borderLeftColor: `${theme.other.white} !important`,
            },
          },
        }),
        TextInput: (theme) => ({
          root: {
            display: 'flex',
            flexDirection: 'column',
          },
          description: {
            textAlign: 'center',
            fontSize: '12px !important',
            color: '#FFF !important',
            order: 1,
            marginTop: '5px',
            marginBottom: '0',
          },
          error: {
            order: 1,
            textAlign: 'center',
          },
          label: {
            fontSize: '16px',
            fontWeight: 'bold',
            display: 'block',
            textAlign: 'center',
            color: '#fff',
          },
          input: {
            color: theme.white,
            border: 0,
            textAlign: 'center',
            backgroundColor: theme.other.darkBackground,
            '&:autofill': { background: theme.other.darkBackground },
          },
        }),
        Button: (theme) => ({
          root: {
            '&:hover:not([disabled])': {
              filter: 'brightness(0.9)',
            },
          },
        }),
        NumberInput: (theme) => ({
          filledVariant: {
            backgroundColor: theme.other.darkBackground,
            border: 0,
          },
          input: {
            color: theme.white,
          },
        }),
        NativeSelect: (theme) => ({
          filledVariant: {
            backgroundColor: theme.other.darkBackground,
            border: 0,
          },
          input: {
            color: theme.white,
          },
        }),
        Paper: (theme) => ({
          root: {
            backgroundColor: theme.other.darkBackground,
          },
        }),
        Skeleton: (theme) => ({
          root: {
            opacity: 0.7,
          },
        }),
        Progress: (theme) => ({
          root: {
            background: '#fff',
          },
          bar: {
            background: '#69b935',
            borderTopRightRadius: '0 !important',
            borderBottomRightRadius: '0 !important',
          },
        }),
        Tabs: (theme) => ({
          tabsListWrapper: {
            borderRadius: '20px 20px 0px 0px',
            overflow: 'hidden',
            borderColor: `${theme.other.white} !important `,
          },
          tabsList: {
            background: theme.other.gradientBlue,
          },
          tabActive: {
            background: theme.other.gradientPurple,
          },
          tabLabel: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: 'white',
          },
          body: {
            padding: '0px',
          },
        }),
        Divider: (theme) => ({
          root: {
            borderTopColor: `${theme.other.blue} !important`,
            width: '100%',
          },
        }),
        Container: (theme) => ({
          root: {
            height: '100%',
          },
        }),
        Accordion: (theme) => ({
          item: {
            backgroundColor: 'none',
            '&:hover': {
              backgroundColor: theme.other.tabBackgroundColor,
            },
            borderBottom: `0px`,
            h3: {
              button: {
                borderTop: `3px solid ${theme.other.blue} !important`,
              },
            },
          },
          itemOpened: {
            '&:hover': {
              backgroundColor: 'none',
            },
            h3: {
              button: {
                borderBottom: `3px solid ${theme.other.blue} !important`,
              },
            },
          },
          control: {
            '&:hover': {
              backgroundColor: theme.other.tabBackgroundColor,
            },
          },
          label: { color: theme.other.white, fontWeight: 'bold' },
          icon: { color: `${theme.other.white}` },
          contentInner: { color: theme.other.white },
        }),
        ThemeIcon: (theme) => ({
          root: {
            backgroundColor: theme.other.darkBackground,
            borderRadius: '50%',
            padding: '2px',
          },
        }),
        Checkbox: (theme) => ({
          label: {
            fontSize: '16px',
            color: theme.other.white,
            fontWeight: 'bold',
          },
        }),
        AppShell: (theme) => ({
          main: {
            display: 'flex',
            flexDirection: 'column',
            //at xs (phone) sizes, we switch to viewport width units for consistent layout across devices
            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
              padding: '84px 3.75vw !important',
            },
            [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
              paddingBottom: '0px',
            },
          },
        }),
      }}
    >
      <Global
        styles={(theme) => ({
          body: {
            backgroundColor: 'white',
            backgroundPosition: 'top',
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          },
          '#root': {
            backgroundImage: `url('${BackgroundPurpleChart}'), linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(3, 167, 245, 0.8) 53%, rgba(11, 94, 171, 0.8) 100%)`,
            backgroundSize: '100vw',
            backgroundRepeat: 'no-repeat',
            minHeight: '100vh',
          },
        })}
      />
      <BrowserRouter>
        <AppShell
          padding="xl"
          fixed
          header={<CustomHeader />}
          navbar={<CustomNavbar />}
          footer={state.isAuthenticated ? <CustomFooter /> : <></>}
        >
          <Container fluid px={0} sx={{ margin: '0px' }}>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/confirm-email" element={<ConfirmEmail />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/responsible-gambling" element={<ResponsibleGambling />} />
              <Route path="/aml-policy" element={<AMLPolicy />} />
              <Route path="/compliance-and-arbitration" element={<ComplianceAndArbitrationPolicy />} />
              <Route path="/payments-policy" element={<PaymentsPolicy />} />
              <Route
                path="/*"
                element={
                  <RequireAuth>
                    <Outlet />
                  </RequireAuth>
                }
              >
                {/* Authorized routes */}
                <Route path="user-dashboard" element={<UserDashboard />} />
                <Route path="responsible-gaming" element={<ResponsibleGaming />} />
                <Route path="play" element={<GameList />} />
                <Route path="play/:gameName/:marketName" element={<PlayRouter />} />
                <Route path="account" element={<Account />} />
                <Route path="wallet" element={<Wallet />} />
                <Route path="wallet/deposit" element={<DepositFunds />} />
                <Route path="active-bets" element={<ActiveBets />} />
                <Route path="help" element={<Help />} />
                <Route path="*" element={<Navigate to="/play" replace={true} />} />
              </Route>
              {/* BACK OFFICE */}
              <Route
                path="/admin"
                element={
                  <RequireAuth adminOnly={true}>
                    <Outlet />
                  </RequireAuth>
                }
              >
                {/* Backoffice routes to go here... */}
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="player-overview" element={<PlayerOverview />} />
                <Route path="game-controls" element={<GameControls />} />
                <Route path="player-overview/player-account/:id" element={<PlayerAccount />} />
                <Route path="wallet-finance" element={<WalletAndFinance />} />
                <Route path="wager-activity" element={<WagerActivity />} />
              </Route>
            </Routes>
          </Container>

          <LegalFooter />
        </AppShell>
        <NotificationsProvider limit={1} />
      </BrowserRouter>
      <ResponsibleGamingModal />
      <PolicyChangeModal />
    </MantineProvider>
  );
}

export default App;
