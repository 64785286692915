import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => {
  return {
    tableContainer: {
      backgroundColor: theme.other.tabBackgroundColor,
      borderRadius: '0px 0px 10px 10px',
      margin: '0px 0px 24px 0px',
      height: '800px',
      overflow: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    },
  };
});

export { useStyles };
