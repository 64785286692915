import { Button, Group, Image, Slider, useMantineTheme } from '@mantine/core';
import { Game, GameThemeEnum } from '../../api/interfaces';

import { ReactComponent as IconMinus } from '../../assets/icons/icon-play-value-minus.svg';
import { ReactComponent as IconPlus } from '../../assets/icons/icon-play-value-plus.svg';

export const WagerSlider = (props: {
  game: Game;
  value: number;
  min: number;
  max: number;
  setValue: (val: number) => void;
}) => {
  const theme = useMantineTheme();

  return (
    <Group position="center" sx={{ marginBottom: '12px' }}>
      <Button
        variant="outline"
        disabled={props.value <= props.min}
        onClick={() => props.setValue(Math.max(props.value - 1, props.min))}
        style={{ padding: '0 12px 0 0', border: 0, background: 'transparent' }}
      >
        <IconMinus style={{ color: theme.white, height: '24px', width: '24px' }} />
      </Button>
      <Slider
        value={props.value}
        min={props.min}
        max={props.max}
        label={null}
        radius="sm"
        onChange={(val) => {
          if (val) props.setValue(Math.round(val));
        }}
        thumbChildren={<Image height={50} fit="contain" src={props.game.iconAction} />}
        style={{ flexGrow: 1 }}
        styles={{
          track: {
            height: '20px',
            '::before': {
              backgroundColor: theme.fn.rgba(props.game.color, 0.5),
            },
            '::after': {
              content: `"${props.max} EUR"`,
              color: props.game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(props.game.color, 0.8),
              fontSize: '12px',
              position: 'absolute',
              top: '2px',
              right: '4px',
              fontWeight: 'bold',
            },
          },
          bar: {
            backgroundColor: props.game.color,
            whiteSpace: 'nowrap',
            '::before': {
              content: `"${props.min} EUR"`,
              color: props.game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(props.game.color, 0.8),
              fontSize: '12px',
              position: 'absolute',
              top: '2px',
              left: '8px',
              fontWeight: 'bold',
            },
          },
          thumb: { width: '62px', height: '50px', background: 'transparent', border: 0 },
        }}
      />
      <Button
        disabled={props.value >= props.max}
        onClick={() => props.setValue(Math.min(props.max, props.value + 1))}
        style={{ padding: '0 0 0 12px', border: 0, background: 'transparent' }}
      >
        <IconPlus style={{ color: theme.white, height: '24px', width: '24px' }} />
      </Button>
    </Group>
  );
};
