import { Box, Button, Divider, Grid, Group, Space, Stack, Title, Text, Table, Modal } from '@mantine/core';
import { PlayerData, PlayerSearchFilterEnum, PlayerSearchParams } from '../../../../api/interfaces';
import {
  calculateDateFromToday,
  formatCurrency,
  formatCurrencySymbol,
  formatDate,
  minutesToDhms,
} from '../../../../utils/formatters';
import { useStyles } from '../../styles';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  completePendingWithdrawal,
  failPendingWithdrawal,
  getReferralCodeByID,
  searchAccounts,
  toggleAdminRole,
  toggleBannedStatus,
  toggleClosedStatus,
  toggleFlaggedStatus,
  toggleSuperAdminRole,
  toggleVerifiedStatus,
} from '../../../../api/backoffice';
import { useDisclosure } from '@mantine/hooks';
import DepositModal from '../../../../components/modals/OperatorDepositModal';
import OperatorWithdrawalModal from '../../../../components/modals/OperatorWithdrawalModal';
import { useStore } from '../../../../state/state';
import { showNotification } from '@mantine/notifications';

const AccountSummary = ({
  player,
  id,
  getPlayer,
  getNextPlayer,
}: {
  player: PlayerData;
  id: string;
  getPlayer: Dispatch<SetStateAction<void>>;
  getNextPlayer: (id: string) => Promise<void>;
}) => {
  const { classes } = useStyles();
  const [depositModalState, depositModalHandler] = useDisclosure(false);
  const [withdrawalModalState, withdrawalModalHandler] = useDisclosure(false);
  const [referralCode, setReferralCode] = useState<string>('');
  const [playerList, setPlayerList] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [loadingNextPlayer, setLoadingNextPlayer] = useState(false);
  const state = useStore((state) => state.auth);

  const onComplete = async (withdrawalId: number) => {
    const result = await completePendingWithdrawal(withdrawalId);
    if (result) {
      getPlayer();
    }
  };

  const onReject = async (withdrawalId: number) => {
    const result = await failPendingWithdrawal(withdrawalId);
    if (result) {
      getPlayer();
    }
  };

  const toggleVerified = async () => {
    const currentStatus = player.isVerified;
    const result = await toggleVerifiedStatus(player.id, !currentStatus);
    if (result) {
      getPlayer();
    }
  };

  const toggleFlagged = async () => {
    const currentStatus = player.isFlagged;
    const result = await toggleFlaggedStatus(player.id, !currentStatus);
    if (result) {
      getPlayer();
    }
  };

  const toggleAdmin = async () => {
    const currentStatus = player.role.split(',').some((x) => x === 'Sales');
    await toggleAdminRole(player.id, !currentStatus).then((resp) => {
      if (resp && resp.success) {
        setReferralCode(resp.result);
        getPlayer();
      } else {
        showNotification({
          title: 'Cannot Revoke Admin',
          message: 'Revoking will leave the user without a sales admin.',
          color: 'red',
        });
      }
    });
    getPlayer();
  };

  const toggleSuperAdmin = async () => {
    const currentStatus = player.role.includes('SuperAdmin');
    await toggleSuperAdminRole(player.id, !currentStatus);
    getPlayer();
  };

  const toggleBanned = async () => {
    const currentStatus = player.isBanned;
    const result = await toggleBannedStatus(player.id, !currentStatus);
    if (result) {
      getPlayer();
    }
  };

  const toggleClosed = async () => {
    const result = await toggleClosedStatus(player.id);
    if (result.success) {
      getPlayer();
    }
  };

  const getReferralCode = async () => {
    if (player.id && player.role === 'Sales') {
      const referralCode = await getReferralCodeByID(player.id);
      if (referralCode) {
        setReferralCode(referralCode);
      }
    }
  };

  const handleCopyLink = () => {
    const host = window.location.host;
    const protocol = window.location.protocol;
    const newUrl = `${protocol}//${host}/signup?referralCode=${referralCode}`;

    navigator.clipboard
      .writeText(newUrl)
      .then(() => {
        console.log('URL copied successfully:', newUrl);
        alert('Link copied to clipboard!');
      })
      .catch((err) => {
        console.error('Error copying URL: ', err);
      });
  };

  useEffect(() => {
    getReferralCode();
  }, [player.id]);

  useEffect(() => {
    if (!depositModalState || !withdrawalModalState) {
      getPlayer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositModalState, withdrawalModalState]);

  useEffect(() => {
    if (state.user?.role === 'Sales') {
      const getPlayers = async () => {
        const date = new Date(await calculateDateFromToday('YTD'));
        let params: PlayerSearchParams = {
          duration: date,
          filter: PlayerSearchFilterEnum.Name,
          isDesc: true,
        };

        const result = await searchAccounts(params);
        if (result) {
          const playerIds = result.map((player) => player.id);
          const playerIdToMove = player.id;

          // Find the index of playerIdToMove
          const index = playerIds.indexOf(playerIdToMove);

          if (index !== -1) {
            // Remove the playerIdToMove from its current position
            playerIds.splice(index, 1);
            // Add the playerIdToMove to the beginning of the array
            playerIds.unshift(playerIdToMove);
          }

          setPlayerList(playerIds);
        }
      };

      getPlayers();
    }
  }, [state]);

  useEffect(() => {
    const fetchPlayerData = async () => {
      if (playerList.length > 0 && currentIndex >= 0 && currentIndex < playerList.length) {
        setLoadingNextPlayer(true);
        const playerId = playerList[currentIndex];
        await getNextPlayer(playerId);
        setLoadingNextPlayer(false);
      }
    };

    fetchPlayerData();
  }, [currentIndex]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title align="left" order={1}>
          Overview
        </Title>
        {referralCode && (
          <Group>
            <Title>Referral Code: {referralCode}</Title>
            <Button onClick={() => handleCopyLink()}>Copy Link</Button>
          </Group>
        )}
        {playerList.length > 0 && (
          <Group>
            <Button
              disabled={loadingNextPlayer}
              style={{ visibility: currentIndex > 0 ? 'visible' : 'hidden' }}
              onClick={() => setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0))}
            >
              Back
            </Button>
            <Button
              disabled={loadingNextPlayer}
              style={{ visibility: currentIndex < playerList.length - 1 ? 'visible' : 'hidden' }}
              onClick={() => setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, playerList.length - 1))}
            >
              Next
            </Button>
          </Group>
        )}
      </Box>
      <Divider sx={{ margin: '24px 0px' }} size={2} color="blue" />
      <Grid>
        <Grid.Col span={4}>
          <Box className={classes.container}>
            <Title order={2}>Account Status</Title>
            <Space h="md" />
            <Divider size={2} sx={{ margin: '16px 0px' }} />
            <Stack>
              {state?.user?.role === 'SuperAdmin' && (
                <Group grow>
                  <Button
                    size="lg"
                    onClick={() => toggleAdmin()}
                    radius="lg"
                    variant="gradient"
                    gradient={
                      player.role.split(',').some((x) => x === 'Sales')
                        ? { from: '#2A94D9', to: '#15499D', deg: 180 }
                        : { from: '#D9E021', to: '#009245', deg: 180 }
                    }
                  >
                    {player.role.split(',').some((x) => x === 'Sales') ? 'Revoke Sales Admin' : 'Promote Sales Admin'}
                  </Button>
                  <Button
                    size="lg"
                    onClick={() => toggleSuperAdmin()}
                    radius="lg"
                    variant="gradient"
                    gradient={
                      player.role.includes('SuperAdmin')
                        ? { from: '#2A94D9', to: '#15499D', deg: 180 }
                        : { from: '#D9E021', to: '#009245', deg: 180 }
                    }
                  >
                    {player.role.includes('SuperAdmin') ? 'Revoke Super Admin' : 'Promote Super Admin'}
                  </Button>
                </Group>
              )}
              <Group grow>
                <Button
                  size="lg"
                  onClick={() => toggleFlagged()}
                  radius="lg"
                  variant="gradient"
                  gradient={
                    player.isFlagged
                      ? { from: '#D9E021', to: '#009245', deg: 180 }
                      : { from: '#2A94D9', to: '#15499D', deg: 180 }
                  }
                >
                  {player.isFlagged ? 'UNFLAG Account' : 'FLAG Account'}
                </Button>
                <Button
                  size="lg"
                  onClick={() => toggleBanned()}
                  radius="lg"
                  variant="gradient"
                  gradient={
                    !player.isBanned
                      ? { from: '#2A94D9', to: '#15499D', deg: 180 }
                      : { from: '#D9E021', to: '#009245', deg: 180 }
                  }
                >
                  {player.isBanned ? 'UNFREEZE Account' : 'FREEZE Account'}
                </Button>
              </Group>
              <Group grow>
                <Button
                  size="lg"
                  onClick={() => toggleVerified()}
                  radius="lg"
                  variant="gradient"
                  gradient={
                    !player.isVerified
                      ? { from: '#D9E021', to: '#009245', deg: 180 }
                      : { from: '#FF5353', to: '#630202', deg: 180 }
                  }
                >
                  {!player.isVerified ? 'VERIFY Account' : 'REFUTE Account'}
                </Button>
                <Button
                  size="lg"
                  onClick={() => toggleClosed()}
                  radius="lg"
                  variant="gradient"
                  gradient={
                    !player.isClosed
                      ? { from: '#FF5353', to: '#630202', deg: 180 }
                      : { from: '#D9E021', to: '#009245', deg: 180 }
                  }
                >
                  {!player.isClosed ? 'DEACTIVATE Account' : 'ACTIVATE Account'}
                </Button>
              </Group>
            </Stack>
            <Divider size={2} sx={{ margin: '16px 0px' }} />
            <Stack>
              <Group grow>
                <Title order={2}>ID</Title>
                <Title order={2}>{player.id}</Title>
              </Group>
              {state?.user?.role === 'SuperAdmin' && (
                <Group grow>
                  <Title order={2}>Role</Title>
                  <Title order={2}>{player.role}</Title>
                </Group>
              )}
              <Group grow>
                <Title order={2}>Account Status</Title>
                <Title
                  order={2}
                  sx={(theme) => (player.isFlagged ? { color: theme.other.red } : { color: theme.other.green })}
                >
                  {player.isFlagged ? 'Flagged' : 'Not Flagged'}
                </Title>
              </Group>
              <Group grow>
                <Title order={2}>KYC Verification</Title>
                <Title
                  order={2}
                  sx={(theme) => (player.isVerified ? { color: theme.other.green } : { color: theme.other.red })}
                >
                  {player.isVerified ? 'Verified' : 'Not Verified'}
                </Title>
              </Group>
              <Group grow>
                <Title order={2}>Player Since</Title>
                <Title order={2}>{formatDate(player.registeredDate, false)}</Title>
              </Group>
              <Group grow>
                <Title order={2}>Last Online</Title>
                <Title order={2}>
                  {player && player.playtime && player.playtime.lastActive
                    ? formatDate(player.playtime.lastActive.toString(), false)
                    : 'No active date available'}
                </Title>
              </Group>
              <Group grow>
                <Title order={2}>Last Deposit</Title>
                <Title order={2}>
                  {player && player.playtime && player.playtime.lastDeposit
                    ? formatDate(player.playtime.lastDeposit.toString(), false)
                    : 'No deposit date available'}
                </Title>
              </Group>
              <Group grow>
                <Title order={2}>Play Time</Title>
                <Title order={2}>
                  {player && player.playtime && player.playtime.minutesTotal
                    ? minutesToDhms(player.playtime.minutesTotal)
                    : 'No available playtime'}
                </Title>
              </Group>
            </Stack>
          </Box>
        </Grid.Col>
        <Grid.Col span={8}>
          <Box className={classes.container}>
            <Title order={2}>Wager & Financial Overview</Title>
            <Space h="md" />
            <Divider size={2} sx={{ margin: '16px 0px' }} />
            <Grid>
              {(state?.user?.role === 'SuperAdmin' || state?.user?.role === 'Sales') && (
                <Grid.Col span={2} sx={{ padding: '8px 8px' }}>
                  <Stack justify="top" sx={{ height: '100%' }}>
                    <Button
                      size="lg"
                      onClick={() => depositModalHandler.open()}
                      radius="lg"
                      variant="gradient"
                      gradient={{ from: '#D9E021', to: '#009245', deg: 180 }}
                    >
                      DEPOSIT
                    </Button>
                    {state?.user?.role === 'SuperAdmin' && (
                      <Button
                        size="lg"
                        onClick={() => withdrawalModalHandler.open()}
                        radius="lg"
                        variant="gradient"
                        gradient={{ from: '#2A94D9', to: '#15499D', deg: 180 }}
                      >
                        WITHDRAW
                      </Button>
                    )}
                  </Stack>
                </Grid.Col>
              )}
              <Grid.Col
                span={state?.user?.role === 'SuperAdmin' || state?.user?.role === 'Sales' ? 9 : 12}
                sx={{ padding: '0px 8px' }}
              >
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        <Title order={2}>Current Balance</Title>
                      </td>
                      <td>
                        <Title align="right" order={2}>
                          {`${formatCurrencySymbol(player.currency)} ${formatCurrency(player.balance)}`}
                        </Title>
                      </td>
                      <td>
                        <Title order={2}>Total Wagers Placed</Title>
                      </td>
                      <td>
                        <Title order={2}>{player.wagerCount}</Title>
                      </td>
                      <td>
                        <Title align="right" order={2}>
                          {`${formatCurrencySymbol(player.currency)} ${formatCurrency(player.spent)}`}
                        </Title>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Title order={2}>Total Deposits</Title>
                      </td>
                      <td>
                        <Group position="right" direction="row">
                          <Title order={2}>{`${player.depositCount}`}</Title>
                          <Title align="right" order={2}>{`
                        ${formatCurrencySymbol(player.currency)} ${formatCurrency(player.deposit)}`}</Title>
                        </Group>
                      </td>
                      <td>
                        <Title order={2}>Total Wins</Title>
                      </td>
                      <td>
                        <Title order={2}>{player.winCount}</Title>
                      </td>
                      <td>
                        <Title align="right" order={2}>{`${formatCurrencySymbol(player.currency)} ${formatCurrency(
                          player.won
                        )}`}</Title>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Title order={2}>Last Deposit</Title>
                      </td>
                      <td>
                        <Title order={2} align="right">{`${formatCurrencySymbol(player.currency)} ${formatCurrency(
                          player.lastDeposit || 0
                        )}`}</Title>
                      </td>
                      <td>
                        <Title order={2}>Total Losses</Title>
                      </td>
                      <td>
                        <Title order={2}>{player.lostCount}</Title>
                      </td>
                      <td>
                        <Title align="right" order={2}>{`${formatCurrencySymbol(player.currency)} ${formatCurrency(
                          player.lost
                        )}`}</Title>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Title order={2}>Player Profit</Title>
                      </td>
                      <td>
                        <Title align="right" order={2}>{`${formatCurrencySymbol(player.currency)} ${formatCurrency(
                          player.profit
                        )}`}</Title>
                      </td>
                      <td>
                        <Title order={2}>Total Draws</Title>
                      </td>
                      <td>
                        <Title align="right" order={2}>
                          {player.drawCount}
                        </Title>
                      </td>
                      <td>
                        <Title align="right" order={2}>
                          -
                        </Title>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Title order={2}>Total Withdrawals</Title>
                      </td>
                      <td>
                        <Title align="right" order={2}>
                          {`${formatCurrencySymbol(player.currency)} ${formatCurrency(player.withdraw)}`}
                        </Title>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Grid.Col>
            </Grid>
            <Divider size={2} sx={{ margin: '16px 0px' }} />
            <Title order={2}>Pending Withdrawals</Title>
            <Divider size={2} sx={{ margin: '16px 0px' }} />
            <Table sx={{ overflow: 'scroll' }}>
              <tbody>
                {player.pendingWithdrawals.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <Text>{item.userName}</Text>
                      </td>
                      <td>
                        <Text>{formatDate(item.initiatedAt.toString(), false)}</Text>
                      </td>
                      <td>
                        <Text>{`${formatCurrencySymbol(item.currency)} ${formatCurrency(item.amount)}`}</Text>
                      </td>
                      <td>
                        <Group align="center" grow>
                          <Button
                            onClick={() => onComplete(item.id)}
                            radius="lg"
                            variant="gradient"
                            gradient={{ from: '#D9E021', to: '#009245', deg: 180 }}
                          >
                            ✓
                          </Button>
                          <Button
                            onClick={() => onReject(item.id)}
                            radius="lg"
                            variant="gradient"
                            gradient={{ from: '#FF5353', to: '#630202', deg: 180 }}
                          >
                            X
                          </Button>
                        </Group>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Box>
        </Grid.Col>
      </Grid>

      <Modal
        centered
        title={<Title order={3}>Operator deposit</Title>}
        radius="lg"
        size="lg"
        opened={depositModalState}
        onClose={depositModalHandler.close}
      >
        <DepositModal userId={player.id} depositModalHandler={depositModalHandler} />
      </Modal>

      <Modal
        centered
        title={<Title order={3}>Operator withdrawal</Title>}
        radius="lg"
        size="lg"
        opened={withdrawalModalState}
        onClose={withdrawalModalHandler.close}
      >
        <OperatorWithdrawalModal userId={player.id} withdrawalModalHandler={withdrawalModalHandler} />
      </Modal>
    </>
  );
};

export default AccountSummary;
