import { Button, Divider, Group, Stack, Title, Text, Tabs, Box, Table, Space } from '@mantine/core';
import { useEffect, useState } from 'react';
import {
  WagerActivityGameFilter,
  WagerActivityTimeFilter,
  MarketStatistics,
  Currency,
  GameCategoryEnum,
  MapGameDataItem,
  MarketStatisticsCounts,
  WagerHistory,
} from '../../api/interfaces';
import { ActiveGradient, InActiveGradient } from '../../utils/consts';
import AllBettingHistory from './all-betting-history/allBettingHistory';
import LiveBets from './live-bets/liveBets';
import { useStyles } from './styles';
import { getMarketStatistics } from '../../api/game';
import { formatCurrencySymbol, getCurrentDate } from '../../utils/formatters';
import { CurrencyIcon } from '../../components/game/marketButton';
import { CategoryButton } from '../../components/game/categoryButton';
import ReportDownloadModal from '../../components/reporting/ExcelDownload';
import { useStore } from '../../state/state';
import ExcelDownload from '../../components/reporting/ExcelDownload';

const WagerActivity = () => {
  const { classes } = useStyles();
  const [activeTab, setActiveTab] = useState(1);
  const [activeFilter, setActiveFilter] = useState<WagerActivityTimeFilter>('24 Hours');
  const [activeGameFilter, setActiveGameFilter] = useState<WagerActivityGameFilter>('All Games');
  const [categoryButtons, setCategoryButtons] = useState<WagerActivityGameFilter[]>([]);
  const [markets, setMarkets] = useState<MarketStatistics[]>([]);
  const [filteredMarkets, setFilteredMarkets] = useState<MarketStatistics[]>([]);
  const [marketTotals, setMarketTotals] = useState<MarketStatisticsCounts>();
  const [wagerParentHistory, setparentWagerHistory] = useState<WagerHistory[]>([]);
  const [downloadFileName, setDownloadFileName] = useState<string>('');
  const auth = useStore((state) => state.auth);
  const filename = `Betting_Activity_${getCurrentDate()}`;

  const filterButtons: WagerActivityTimeFilter[] = ['24 Hours', '7 Days', '1 Month', '6 Months'];

  const calculateDateFromToday = async (duration: WagerActivityTimeFilter) => {
    const today = new Date();
    const durationMap: { [key: string]: () => Date } = {
      '24 Hours': () => new Date(today.getTime() - 24 * 60 * 60 * 1000),
      '7 Days': () => new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000),
      '1 Month': () => new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()),
      '6 Months': () => new Date(today.getFullYear(), today.getMonth() - 6, today.getDate()),
    };

    if (duration in durationMap) {
      const calculatedDate = durationMap[duration]();
      return calculatedDate.toISOString();
    } else {
      throw new Error('Invalid duration');
    }
  };

  const filterMarkets = () => {
    if (activeGameFilter === 'All Games') {
      setFilteredMarkets(markets);
    } else {
      const filteredMarkets = markets.filter((market) => market.category === activeGameFilter);
      setFilteredMarkets(filteredMarkets);
    }
  };

  const getMarketStats = async () => {
    const date = await calculateDateFromToday(activeFilter);
    const category: WagerActivityGameFilter[] = [];
    const marketStatistics = await getMarketStatistics('?since=' + date);
    if (marketStatistics) {
      marketStatistics.forEach((item: MarketStatistics) => {
        if (!category.includes(item.category)) {
          category.push(item.category);
        }
      });
      setMarkets(marketStatistics);
      setCategoryButtons(category);
    }
  };

  const transformData = (data: MarketStatistics[]): MapGameDataItem[] => {
    const groupedData: MapGameDataItem[] = [];

    data.forEach((item) => {
      const existingEntry = groupedData.find((entry) => entry.gameName === item.gameName);

      if (existingEntry) {
        existingEntry.market.push(item);
      } else {
        const newEntry: MapGameDataItem = {
          gameName: item.gameName,
          gameColor: item.color,
          market: [item],
        };
        groupedData.push(newEntry);
      }
    });
    return groupedData;
  };

  const calculateTotals = (marketStatistics: MarketStatistics[]) => {
    const totals: MarketStatisticsCounts = {
      totalBetsCount: 0,
      totalBets: 0,
      totalWins: 0,
      totalWinsCount: 0,
      totalLosses: 0,
      totalLossesCount: 0,
    };
    marketStatistics.forEach((market) => {
      totals.totalBetsCount += market.totalBetsCount;
      totals.totalBets += market.totalBets;
      totals.totalWins += market.totalWins;
      totals.totalWinsCount += market.totalWinsCount;
      totals.totalLosses += market.totalLosses;
      totals.totalLossesCount += market.totalLossesCount;
    });
    return totals;
  };

  useEffect(() => {
    calculateDateFromToday(activeFilter).then((date: string) => {
      const formattedDate = new Date(date);
      const startDate = `${formattedDate.getFullYear()}_${formattedDate.getMonth() + 1}_${formattedDate.getDate()}`;
      const endDate = new Date().toISOString().split('T')[0];
      setDownloadFileName(`Wager_Activity_${startDate}_${endDate}`);
    });
  }, [activeFilter]);

  useEffect(() => {
    getMarketStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter]);

  useEffect(() => {
    filterMarkets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGameFilter, markets]);

  useEffect(() => {
    setMarketTotals(calculateTotals(filteredMarkets));
  }, [filteredMarkets]);
  return (
    <>
      <Title align="center" order={1}>
        WAGER Activity &#38; Active Bets
      </Title>
      <Space h={32} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title align="left" order={1}>
          Wager Activity
        </Title>
        {auth?.user?.role?.includes('SuperAdmin') && (
          <ReportDownloadModal
            content={filteredMarkets}
            defaultFields={[
              'currencyPair',
              'totalBetsCount',
              'totalBets',
              'totalWinsCount',
              'totalWins',
              'totalLossesCount',
              'totalLosses',
            ]}
            fileName={downloadFileName}
            headers={[
              'Currency Pair',
              'Total Bets Count',
              'Total Bets',
              'Total Wins Count',
              'Total Wins',
              'Total Losses Count',
              'Total Losses',
            ]}
          />
        )}
      </Box>
      <Divider size={2} sx={{ margin: '24px 0px' }} />
      <Box sx={{ display: 'flex', margin: '24px 0px' }}>
        <Stack spacing="md">
          <Title align="left" order={2}>
            Choose an arena
          </Title>
          <Button
            onClick={() => setActiveGameFilter('All Games')}
            variant="gradient"
            radius="lg"
            gradient={activeGameFilter === 'All Games' ? ActiveGradient : InActiveGradient}
            size="lg"
            sx={{ height: '50px', width: '190px' }}
          >
            <Title order={3}>All Games</Title>
          </Button>
          {categoryButtons?.map((button, index) => (
            <CategoryButton
              key={index}
              category={button as GameCategoryEnum}
              selected={activeGameFilter === button}
              onClick={() => {
                setActiveGameFilter(button);
              }}
            />
          ))}
          <Title align="left" order={2}>
            Choose time span
          </Title>
          {filterButtons.map((button, index) => (
            <Button
              key={index}
              onClick={() => setActiveFilter(button)}
              variant="gradient"
              radius="lg"
              gradient={activeFilter === button ? ActiveGradient : InActiveGradient}
              sx={{ height: '50px', width: '190px' }}
            >
              <Title order={3}>{button}</Title>
            </Button>
          ))}
        </Stack>
        <Box
          sx={{
            flexGrow: 1,
            height: '596px',
            marginLeft: '16px',
            padding: '0px 20px 20px 20px',
            overflow: 'scroll',
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
          className={classes.tableContainer}
        >
          <Box className={classes.wagerActivityTableHeader}>
            <Group grow>
              <Text align="center"></Text>
              <Text align="center">Total Count</Text>
              <Text align="center">Total Wagers</Text>
              <Text align="center">Player Win Count</Text>
              <Text align="center">Player Wins</Text>
              <Text align="center">Player Loss Count</Text>
              <Text align="center">Player Loss</Text>
              <Text align="center">Profit/Loss</Text>
            </Group>
          </Box>
          <Box className={classes.wagerActivityTotalHeader}>
            <Group grow>
              <Title order={2} pl={12}>
                All Games
              </Title>
              <Text align="center" sx={{ color: '#00cafb' }}>
                {marketTotals?.totalBetsCount}
              </Text>
              <Text align="center" sx={{ color: '#00cafb' }}>
                {formatCurrencySymbol('EUR')}

                {marketTotals?.totalBets}
              </Text>
              <Text align="center" sx={{ color: '#88c32c' }}>
                {marketTotals?.totalWinsCount}
              </Text>
              <Text align="center" sx={{ color: '#88c32c' }}>
                {formatCurrencySymbol('EUR')}

                {marketTotals?.totalWins}
              </Text>
              <Text align="center" sx={{ color: '#eb6c35' }}>
                {marketTotals?.totalLossesCount}
              </Text>
              <Text align="center" sx={{ color: '#eb6c35' }}>
                {formatCurrencySymbol('EUR')}
                {marketTotals?.totalLosses}
              </Text>
              <Text
                align="center"
                sx={{
                  color: (marketTotals?.totalLosses ?? 0) - (marketTotals?.totalWins ?? 0) < 0 ? '#eb6c35' : '#88c32c',
                }}
              >
                {formatCurrencySymbol('EUR')}
                {((marketTotals?.totalLosses ?? 0) - (marketTotals?.totalWins ?? 0)).toFixed(2)}
              </Text>
            </Group>
          </Box>
          {transformData(filteredMarkets).map((gameObject, index) => {
            return (
              <Table
                sx={{
                  borderTop: `2px solid ${gameObject.gameColor}`,
                  margin: '8px 0px',
                }}
                key={index}
              >
                <thead style={{ borderBottom: `2px solid ${gameObject.gameColor}` }}>
                  <tr>
                    <th>
                      <Title order={2} sx={{ color: gameObject.gameColor }}>
                        {gameObject.gameName}
                      </Title>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {gameObject.market.map((market, index) => {
                    const pair = market.currencyPair.split('/') as Currency[];
                    return (
                      <tr key={index}>
                        <td>
                          <Group grow>
                            <Text>
                              <Group noWrap>
                                <CurrencyIcon currency={pair[0]} />
                                {pair[0]} vs {pair[1]}
                                <CurrencyIcon currency={pair[1]} />
                              </Group>
                            </Text>
                            <Text align="center" sx={{ color: '#00cafb' }}>
                              {market.totalBetsCount}
                            </Text>
                            <Text align="center" sx={{ color: '#00cafb' }}>
                              {formatCurrencySymbol(market.currency)}

                              {market.totalBets}
                            </Text>
                            <Text align="center" sx={{ color: '#88c32c' }}>
                              {market.totalWinsCount}
                            </Text>
                            <Text align="center" sx={{ color: '#88c32c' }}>
                              {formatCurrencySymbol(market.currency)}

                              {market.totalWins}
                            </Text>
                            <Text align="center" sx={{ color: '#eb6c35' }}>
                              {market.totalLossesCount}
                            </Text>
                            <Text align="center" sx={{ color: '#eb6c35' }}>
                              {formatCurrencySymbol(market.currency)}

                              {market.totalLosses}
                            </Text>
                            <Text
                              align="center"
                              sx={{ color: market.totalLosses - market.totalWins < 0 ? '#eb6c35' : '#88c32c' }}
                            >
                              {formatCurrencySymbol(market.currency)}
                              {(market.totalLosses - market.totalWins).toFixed(2)}
                            </Text>
                          </Group>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            );
          })}
        </Box>
      </Box>
      <Box></Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title align="left" order={1}>
          Betting Activity
        </Title>
        {auth?.user?.role?.includes('SuperAdmin') && (
          <ExcelDownload
            content={wagerParentHistory}
            defaultFields={[
              'userFirstName',
              'userLastName',
              'placedAt',
              'game_category',
              'game_name',
              'currencyPair',
              'duration_value',
              'duration_unit',
              'type',
              'staked',
              'state',
            ]}
            fileName={filename}
            headers={[
              'First Name',
              'Last Name',
              'Bet placed on',
              'Game Category',
              'Game Name',
              'Currency Pair',
              'Duration',
              'Duration Unit',
              'Type',
              'Staked',
              'State',
            ]}
          />
        )}
      </Box>
      <Space h={32} />
      <Divider size={2} />
      <Tabs sx={{ margin: '24px 0px' }} grow position="center" active={activeTab} onTabChange={setActiveTab}>
        <Tabs.Tab label="Live Bets">
          <LiveBets />
        </Tabs.Tab>
        <Tabs.Tab label="All Betting History">
          <AllBettingHistory setparentWagerHistory={setparentWagerHistory} />
        </Tabs.Tab>
      </Tabs>
    </>
  );
};

export default WagerActivity;
