import { createStyles, Group, Title } from '@mantine/core';
import { Game } from '../../../api/interfaces';
import SVG from 'react-inlinesvg';
import { removeEmbeddedSize } from '../../../utils/svg';

const useSvgStyles = createStyles((theme, params: { color: string; hasWager: boolean }) => {
  return {
    svg: {
      color: params.color,
      height: '50px',
      width: 'auto',
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        height: '32px',
        color: params.color,
      },
    },
  };
});

const GameTitle = (props: { game: Game; hasWager: boolean }) => {
  const { classes } = useSvgStyles({ color: props.game.color, hasWager: props.hasWager });

  return (
    <Group position="apart">
      <Title order={1}>{props.game.name}</Title>
      <SVG src={props.game.iconGame} className={classes.svg} preProcessor={removeEmbeddedSize} />
    </Group>
  );
};

export { GameTitle };
