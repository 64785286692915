import {
  Stack,
  Title,
  Text,
  TextInput,
  Divider,
  Anchor,
  Grid,
  Box,
  Space,
  useMantineTheme,
  Container,
} from '@mantine/core';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { GreenButton } from '../../components/buttons/GreenButton';
import UserWallet from '../../components/userWallet/userWallet';
import { useStore } from '../../state/state';
import { formatCurrency } from '../../utils/formatters';
import { useMediaQuery } from '@mantine/hooks';
import { KYCandWithdraw } from '../../components/userWallet/KYCandWithdraw';

export interface Payload {
  order_ref: number;
  status: string;
  total_requested: { amount: number; currency: string };
  total_settled: { amount: number; currency: string };
  trasaction_id: string;
}

const Wallet = () => {
  const balance = useStore((state) => state.balance);
  const colRef = useRef<null | HTMLDivElement>(null);
  const mobileView = useMediaQuery(`(max-width: ${useMantineTheme().breakpoints.sm}px)`);

  if (!balance) {
    return <Text>Loading wallet...</Text>;
  }

  const scrollToColumn = () => {
    if (colRef.current) {
      colRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <>
      <Container fluid>
        <Title align="center" order={1}>
          Wallet &amp; Balances
        </Title>
        <Space h="xl" />
        {/* This could prob be flex container but maintine v4 doesnt support it */}
        <Grid gutter={64}>
          <Grid.Col xs={12} sm={6}>
            <Stack>
              <Grid grow>
                <Grid.Col span={12} sm={6}>
                  <Stack spacing={0} align="center">
                    <Title order={2}>Your current balance</Title>
                    <Text> Total funds in your wallet</Text>
                  </Stack>
                </Grid.Col>
                <Grid.Col span={12} sm={6}>
                  <TextInput
                    radius="md"
                    size="lg"
                    readOnly
                    value={`${balance.currency}  ${formatCurrency(balance.balance)}`}
                  ></TextInput>
                </Grid.Col>
              </Grid>
              {mobileView && (
                <Text align="center" underline onClick={scrollToColumn}>
                  View Transactions
                </Text>
              )}
              <Divider size={3} />
              <Stack align="center" spacing="xs">
                <Title order={2}>Deposit into your FXBet account</Title>
                <Text>
                  Funds can be securely deposited into your FXBet account with a valid Credit or Debit card. Get started
                  to play!
                </Text>
              </Stack>
              <Anchor component={Link} to="/wallet/deposit" style={{ width: '100%' }}>
                <GreenButton
                  styles={{
                    root: { width: '100%' },
                  }}
                >
                  <Stack spacing={0}>
                    <Text>Deposit Funds</Text>
                  </Stack>
                </GreenButton>
              </Anchor>
              <Divider size={3} />
              <Stack align="center">
                <KYCandWithdraw />
              </Stack>
              <Divider size={3} />
            </Stack>
          </Grid.Col>
          <Grid.Col xs={12} sm={6}>
            <Box ref={colRef} sx={{ height: '100%' }}>
              <UserWallet></UserWallet>
            </Box>
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
};

export default Wallet;
