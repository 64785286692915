import { GameCategoryEnum } from '../../api/interfaces';
import { Button, createStyles } from '@mantine/core';

import { ReactComponent as IconBigBills } from '../../assets/icons/icon-game-category-bigbills.svg';
import { ReactComponent as IconMinerMadness } from '../../assets/icons/icon-game-category-minermadness.svg';
import { ReactComponent as IconNonstop } from '../../assets/icons/icon-game-category-nonstop.svg';
import { ReactComponent as IconSocial } from '../../assets/icons/icon-game-category-social.svg';

const useStyles = createStyles((theme, params: { selected: boolean }) => ({
  categoryButton: {
    width: '190px',
    height: '50px',
    fontSize: '16px',
    border: 'none',
    padding: '0 14px',
    background: params.selected ? theme.other.gradientPurple : theme.other.gradientBlue,
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: { height: '40px', width: 'auto', flexGrow: 1 },
    [`@media (max-width: 390px)`]: { fontSize: '10px' },
    [`@media (max-width: 340px)`]: { fontSize: '9px' },
  },
}));

export const CategoryButton = (props: { category: GameCategoryEnum; selected: boolean; onClick: () => void }) => {
  const { classes } = useStyles({ selected: props.selected });

  switch (props.category) {
    case GameCategoryEnum.BigBills:
      return (
        <Button
          className={classes.categoryButton}
          radius="lg"
          rightIcon={<IconBigBills height={24} width={24} style={{ color: '#FFF' }} />}
          onClick={props.onClick}
          styles={{
            inner: { justifyContent: 'space-between' },
            label: { fontWeight: 'normal', lineHeight: 1.3, flexGrow: 1, justifyContent: 'center' },
          }}
        >
          Forex
        </Button>
      );
    case GameCategoryEnum.MinerMadness:
      return (
        <Button
          className={classes.categoryButton}
          radius="lg"
          rightIcon={<IconMinerMadness height={24} width={24} style={{ color: '#FFF' }} />}
          onClick={props.onClick}
          styles={{
            inner: { justifyContent: 'space-between' },
            label: { fontWeight: 'normal', lineHeight: 1.3, flexGrow: 1, justifyContent: 'center' },
          }}
        >
          Metals
        </Button>
      );
    case GameCategoryEnum.NonStopGame:
      return (
        <Button
          className={classes.categoryButton}
          radius="lg"
          rightIcon={<IconNonstop height={24} width={24} style={{ color: '#FFF' }} />}
          onClick={props.onClick}
          styles={{
            inner: { justifyContent: 'space-between' },
            label: { fontWeight: 'normal', lineHeight: 1.3, flexGrow: 1, justifyContent: 'center' },
          }}
        >
          Crypto
        </Button>
      );
    case GameCategoryEnum.SocialFrenzy:
      return (
        <Button
          className={classes.categoryButton}
          radius="lg"
          rightIcon={<IconSocial height={24} width={24} style={{ color: '#FFF' }} />}
          onClick={props.onClick}
          styles={{
            inner: { justifyContent: 'space-between' },
            label: { fontWeight: 'normal', lineHeight: 1.3, flexGrow: 1, justifyContent: 'center' },
          }}
        >
          Social
        </Button>
      );
  }
};
