import { Group, Menu, Text, UnstyledButton } from '@mantine/core';
import { signOut } from '../../api/auth';

import whiteProfile from '../../assets/footer/profile.svg';
import purpleProfile from '../../assets/navbar/profile_purple.svg';

import { ReactComponent as IconProfilePurple } from '../../assets/navbar/profile_purple.svg';
import { ReactComponent as IconProfileGreen } from '../../assets/navbar/profile_green.svg';
import { ReactComponent as ActiveBetsPurple } from '../../assets/navbar/activebets_purple.svg';
import { ReactComponent as ActiveBetsGreen } from '../../assets/navbar/activebets_green.svg';
import { ReactComponent as IconLogout } from '../../assets/navbar/logout_purple.svg';
import { ReactComponent as RespondGamingPurple } from '../../assets/navbar/respondgaming_purple.svg';
import { ReactComponent as RespondGamingGreen } from '../../assets/navbar/respondgaming_green.svg';
import { ReactComponent as IconWalletPurple } from '../../assets/navbar/wallet_purple.svg';
import { ReactComponent as IconWalletGreen } from '../../assets/navbar/wallet_green.svg';
import { useStyles } from './styles';
import { NavLink } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useStore } from '../../state/state';
import sound from '../../assets/navbar/sound_button.svg';
import sound_mute from '../../assets/navbar/mute_button.svg';
import { getPlayerProfilePicture } from '../../api/player';

export const AccountMenu = (props: { inFooter?: boolean }) => {
  const [menuIsOpen, setmenuIsOpen] = React.useState(false);
  const userRole = useStore((state) => state.auth.user?.role);
  const playerImage = useStore((state) => state.playerProfilePicture);
  const toggleAudio = useStore((state) => state.toggleAudioEnabled);
  const audioEnabled = useStore<boolean>((state) => state.audioEnabled);
  const { classes } = useStyles();

  useEffect(() => {
    if (playerImage == null) {
      getProfilePicture();
    }
  }, [playerImage]);

  const getProfilePicture = async () => {
    await getPlayerProfilePicture();
  };

  const menuControl = props.inFooter ? (
    <Group direction="column" align="center" spacing={0}>
      <img src={menuIsOpen ? purpleProfile : whiteProfile} width="24" height="24" alt="" />
      <Text align="center">Account</Text>
    </Group>
  ) : (
    <Group spacing={8}>
      {playerImage ? (
        <img id="profile-image" src={playerImage.toString()} className={classes.iconWithImage} alt="" />
      ) : (
        <IconProfilePurple className={classes.icon} />
      )}
    </Group>
  );

  return (
    <Menu
      transition="pop-top-right"
      size="xl"
      placement="center"
      control={menuControl}
      shadow="md"
      onOpen={() => setmenuIsOpen(true)}
      onClose={() => setmenuIsOpen(false)}
      className={classes.linkHover}
    >
      <NavLink style={{ textDecoration: 'none', color: 'white' }} to="account">
        {({ isActive }) => (
          <Menu.Item
            icon={
              isActive ? (
                <IconProfileGreen className={classes.menuIcon} />
              ) : (
                <IconProfilePurple className={classes.menuIcon} />
              )
            }
          >
            Profile
          </Menu.Item>
        )}
      </NavLink>
      {userRole === '' && (
        <>
          <NavLink to="responsible-gaming">
            {({ isActive }) => (
              <Menu.Item
                icon={
                  isActive ? (
                    <RespondGamingGreen className={classes.menuIcon} />
                  ) : (
                    <RespondGamingPurple className={classes.menuIcon} />
                  )
                }
              >
                Responsible Gaming Controls
              </Menu.Item>
            )}
          </NavLink>
          <NavLink to="wallet">
            {({ isActive }) => (
              <Menu.Item
                icon={
                  isActive ? (
                    <IconWalletGreen className={classes.menuIcon} />
                  ) : (
                    <IconWalletPurple className={classes.menuIcon} />
                  )
                }
              >
                Wallet
              </Menu.Item>
            )}
          </NavLink>
          <NavLink to="active-bets">
            {({ isActive }) => (
              <Menu.Item
                icon={
                  isActive ? (
                    <ActiveBetsGreen className={classes.menuIcon} />
                  ) : (
                    <ActiveBetsPurple className={classes.menuIcon} />
                  )
                }
              >
                Active Bets
              </Menu.Item>
            )}
          </NavLink>
          <Menu.Item onClick={toggleAudio}>
            <img
              style={{ marginRight: '10px', backgroundColor: 'none' }}
              src={audioEnabled ? sound : sound_mute}
              className={classes.menuIcon}
              alt=""
            />
            Music
          </Menu.Item>
        </>
      )}
      <Menu.Item icon={<IconLogout className={classes.menuIcon} />} onClick={() => signOut()}>
        Logout{' '}
      </Menu.Item>
    </Menu>
  );
};
