import { Container, Grid, Stack, Tabs, Title, useMantineTheme, Text, Divider } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useState } from 'react';

import GamingStatistics from './gaming-stats/GamingStatistics';
import { PlayerData } from '../../api/interfaces';
import { GamingLimits } from './gaming-limits/GamingLimits';

export const ResponsibleGaming = ({ isAdmin, id, player }: { isAdmin?: boolean; id?: string; player?: PlayerData }) => {
  const mobileView = useMediaQuery(`(max-width: ${useMantineTheme().breakpoints.sm}px)`);
  const [activeTab, setActiveTab] = useState(1);

  return (
    <>
      {!mobileView && !isAdmin && (
        <>
          <Stack align="center" sx={{ margin: '16px 0px' }}>
            <Title order={1} align="center">
              Responsible Gaming
            </Title>
            <Text align="center">
              Manage responsible gaming settings, adjust limits and view statistics about your gaming history.
            </Text>
            <Text align="center" weight="bold"></Text>
          </Stack>
          <Stack align="center">
            <Container sx={{ width: '100%' }} fluid={true}>
              <Grid gutter={64} grow={true} sx={{ marginBottom: '24px' }}>
                {!isAdmin && (
                  <Grid.Col span={6}>
                    <Title order={1} align="center">
                      Track Gaming Statistics
                    </Title>
                    <GamingStatistics isAdmin={false} />
                  </Grid.Col>
                )}

                <Grid.Col span={6}>
                  <Title order={1} align="center">
                    Set Gaming Limits
                  </Title>
                  <GamingLimits isAdmin={false} />
                </Grid.Col>
              </Grid>
            </Container>
          </Stack>
        </>
      )}
      {!mobileView && isAdmin && (
        <>
          <Title align="left" order={1}>
            Responsible Gaming
          </Title>
          <Divider size={2} sx={{ margin: '24px 0px' }} />
          <Stack>
            <GamingStatistics isAdmin={true} player={player} />
            <Divider size={2} sx={{ margin: '24px 0px' }} />
            <Title align="left" order={1}>
              Responsible Gaming Settings
            </Title>
            <GamingLimits isAdmin={true} player={player} />
          </Stack>
        </>
      )}
      {mobileView && !isAdmin && (
        <Tabs grow position="center" active={activeTab} onTabChange={setActiveTab}>
          {!isAdmin && (
            <Tabs.Tab label="Track Gaming Statistics">
              <Stack align="center">
                <Container sx={{ width: '100%' }} fluid={true}>
                  <Grid grow={true}>
                    <Grid.Col span={12}>
                      <Title order={1} align="center" sx={{ margin: '16px 0px' }}>
                        Track Gaming Statistics
                      </Title>
                      <GamingStatistics isAdmin={false} />
                    </Grid.Col>
                  </Grid>
                </Container>
              </Stack>
            </Tabs.Tab>
          )}

          <Tabs.Tab label="Set Gaming Limits">
            <Stack align="center">
              <Container sx={{ width: '100%' }} fluid={true}>
                <Grid grow={true}>
                  <Grid.Col span={12}>
                    <Title order={1} align="center" sx={{ margin: '16px 0px' }}>
                      Set Gaming Limits
                    </Title>
                    <GamingLimits isAdmin={false} />
                  </Grid.Col>
                </Grid>
              </Container>
            </Stack>
          </Tabs.Tab>
        </Tabs>
      )}
    </>
  );
};

export default ResponsibleGaming;
