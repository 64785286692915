import { Title, TitleOrder } from '@mantine/core';

type Props = {
  children: JSX.Element | JSX.Element[] | string | string[];
  order: TitleOrder;
  align?: 'right' | 'left' | 'center' | 'justify' | undefined;
};

function SectionHeader({ children, order, align = 'left' }: Props) {
  return (
    <Title order={order} my="lg" align={align}>
      {children}
    </Title>
  );
}
export default SectionHeader;
