const playerFieldMapping: any = {
  id: 'Player ID',
  firstName: 'First Name',
  lastName: 'Last Name',
  country: 'Country',
  email: 'Email Address',
  registeredDate: 'Sign-up Date',
  isVerified: 'Verification Status',
  winCount: 'Total Win Count',
  lostCount: 'Total Loss Count',
  won: 'Total Wins',
  lost: 'Total Losses',
  drawCount: 'Total Draw Count',
  profit: 'Player Profit',
  age: 'Player Age',
  emailVerified: 'Account Status',
  favoriteGame: 'Favorite Game',
  lastOnline: 'Last Active Date',
  totalPlays: 'Total Games Played',
  name: 'Player Name',
  phoneNumber: 'Phone Number',
  deposit: 'Total Deposit',
  depositCount: 'Total Deposit Count',
  // Add other key-value pairs as needed
};

const accountFieldMapping: any = {
  totalActive: 'Total Active Accounts',
  totalVerified: 'Total Verified Accounts',
  noDeposit: 'Total Accounts with No Deposit',
  totalNew: 'Total New Accounts',
  modeCountry: 'Most Common Country of Residence (All Active Accounts)',
  totalFlagged: 'Total Flagged Accounts',
  totalDeactivated: 'Total Deactivated Accounts',
  totalFrozen: 'Total Frozen Accounts',
  modeAge: 'Most Common Age (All Active Accounts)',
  incomplete: 'Total Incomplete Accounts',
  totalIdle: 'Total Idle Accounts',
  withResponsibleGaming: 'Responsible Gaming',
  modeGender: 'Most Common Gender (All Active Accounts)',
  // Add other key-value pairs as needed
};

export const mapKeyToTextPlayerFields = (keyToFind: string) => {
  if (playerFieldMapping.hasOwnProperty(keyToFind)) {
    return playerFieldMapping[keyToFind];
  } else {
    return null;
  }
};

export const mapKeyToTextAccountFields = (keyToFind: string) => {
  if (accountFieldMapping.hasOwnProperty(keyToFind)) {
    return accountFieldMapping[keyToFind];
  } else {
    return null;
  }
};

