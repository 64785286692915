import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => {
  return {
    tableContainer: {
      backgroundColor: theme.other.tabBackgroundColor,
      borderRadius: '10px',
    },
    wagerActivityTableHeader: {
      backgroundColor: theme.other.tabBackgroundColor,
      position: 'sticky',
      top: '0',
      zIndex: 200,
      paddingTop: '20px',
    },
    wagerActivityTotalHeader: {
      borderTop: '2px solid white',
      borderBottom: '2px solid white',
      padding: '8px 0px',
      margin: '16px 0px',
    },
    gameItemContainer: {
      backgroundColor: '#024761',
      borderRadius: '10px',
      padding: '8px',
    },
    gameContainer: {
      width: 'calc(100% - 120px)',
    },
    tempMarketButton: {
      width: '100%',
      height: '60px',
      backgroundColor: '#e51e1f',
      borderRadius: '10px',
    },
  };
});

export { useStyles };
