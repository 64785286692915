export const verficiationDocumentation = (firstName?: string, lastName?: string, email?: string) => {
  const emailObject = {
    email: 'compliance@fxbet.co',
    kyc_subject: `FXBet Verification request: ${firstName} ${lastName}`,
    kyc_body: `I would like to verify my Account. Please find the following documents attached:

    - A Certified Copy of your ID or Passport
    - A Copy of your Utility Bill for Proof of Address

    My FXBet Account Details are:
    Player Name: ${firstName} ${lastName}
    Player Email Address: ${email}\n
    `,
  };

  return `mailto:${emailObject.email}?subject=${encodeURIComponent(emailObject.kyc_subject)}&body=${encodeURIComponent(
    emailObject.kyc_body
  )}`;
};
