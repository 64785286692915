import { Divider, Group, Title, Text, Button } from '@mantine/core';
import { toggleAllMarketStatus } from '../../../api/backoffice';

const MasterControls = () => {
  const toggleMarkets = async (areClosed: boolean) => {
    const result = await toggleAllMarketStatus(areClosed);
    console.log(result);
  };

  return (
    <>
      <Title>Master Controls</Title>
      <Divider size={2} sx={{ margin: '24px 0px' }} />
      <Group grow>
        <Text>
          In extreme case, all games on the platform can
          <br />
          <b>ACTIVATED or SUSPENDED</b>
        </Text>
        <Group grow>
          <Button
            onClick={() => toggleMarkets(false)}
            radius="lg"
            variant="gradient"
            gradient={{ from: '#D9E021', to: '#009245', deg: 180 }}
          >
            ACTIVATE all games
          </Button>
          <Button
            onClick={() => toggleMarkets(true)}
            radius="lg"
            variant="gradient"
            gradient={{ from: '#FF5353', to: '#630202', deg: 180 }}
          >
            SUSPEND all games
          </Button>
        </Group>
      </Group>
    </>
  );
};

export default MasterControls;
