export interface Confetti {
  color: {
    front: string;
    back: string;
  };
  dimensions: {
    x: number;
    y: number;
  };
  position: {
    x: number;
    y: number;
  };
  rotation: number;
  scale: {
    x: number;
    y: number;
  };
  velocity: {
    x: number;
    y: number;
  };
}
const confettiArray: Confetti[] = [];
const confettiCount = 200;
const gravity = 0.5;
const terminalVelocity = 1;
const drag = 0.075;

const colors = [
  { front: 'red', back: 'darkred' },
  { front: 'green', back: 'darkgreen' },
  { front: 'blue', back: 'darkblue' },
  { front: 'yellow', back: 'darkyellow' },
  { front: 'orange', back: 'darkorange' },
  { front: 'pink', back: 'darkpink' },
  { front: 'purple', back: 'darkpurple' },
  { front: 'turquoise', back: 'darkturquoise' },
];

const randomRange = (min: number, max: number): number => Math.random() * (max - min) + min;

const createConfettiInstance = (canvas: HTMLCanvasElement): Confetti => {
  const positionX = randomRange(0, canvas.width);
  const positionY = -20;
  const rotation = randomRange(0, 2 * Math.PI);
  const scale = 1; //randomRange(0.5, 1.5);
  const velocityX = randomRange(-25, 25);
  const velocityY = randomRange(0, -50);

  const colorIndex = Math.floor(randomRange(0, colors.length));
  const color = colors[colorIndex];

  return {
    color: color,
    dimensions: {
      x: randomRange(10, 20),
      y: randomRange(10, 30),
    },
    position: {
      x: positionX,
      y: positionY,
    },
    rotation: rotation,
    scale: {
      x: scale,
      y: scale,
    },
    velocity: {
      x: velocityX,
      y: velocityY,
    },
  };
};

export const initConfetti = (canvas: HTMLCanvasElement): void => {
  if (!canvas || confettiArray.length > 0) return;
  for (let i = 0; i < confettiCount; i++) {
    confettiArray.push(createConfettiInstance(canvas));
  }
};

export const drawConfetti = (canvas: HTMLCanvasElement): void => {
  const context = canvas.getContext('2d');
  if (!context) return;
  initConfetti(canvas);
  for (let i = confettiArray.length - 1; i >= 0; i--) {
    const confetto = confettiArray[i];
    let width = confetto.dimensions.x * confetto.scale.x;
    let height = confetto.dimensions.y * confetto.scale.y;

    // Move canvas to position and rotate
    context.translate(confetto.position.x, confetto.position.y);
    context.rotate(confetto.rotation);

    // Updating position based on velocity
    confetto.velocity.x -= confetto.velocity.x * drag;
    confetto.velocity.y = Math.min(confetto.velocity.y + gravity, terminalVelocity);
    confetto.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random();

    confetto.position.x += confetto.velocity.x;
    confetto.position.y += confetto.velocity.y;

    if (confetto.position.x > canvas.width) confetto.position.x = 0;
    if (confetto.position.x < 0) confetto.position.x = canvas.width;

    confetto.scale.y = Math.cos(confetto.position.y * 0.1);
    context.fillStyle = confetto.scale.y > 0 ? confetto.color.front : confetto.color.back;

    context.fillRect(-width / 2, -height / 2, width, height);

    context.setTransform(1, 0, 0, 1, 0, 0);

    // Remove confetti that are off the canvas
    if (confetto.position.y > canvas.height || confetto.position.x < 0 || confetto.position.x > canvas.width) {
      confettiArray.splice(i, 1);
    }
  }
};
