import { Title, Space, Divider, Box } from '@mantine/core';
import PendingWithdrawals from './pendingWithdrawal/PendingWithdrawals';
import { useEffect, useState } from 'react';
import { financeSummary, getPendingWithdrawals } from '../../api/backoffice';
import { FinancePeriod, HistoryActivity, UserTransactions, WagerActivityTimeFilter } from '../../api/interfaces';
import { financePeriodFormatter, getCurrentDate } from '../../utils/formatters';
import UserWallet from '../../components/userWallet/userWallet';
import { useStore } from '../../state/state';
import ExcelDownload from '../../components/reporting/ExcelDownload';

const WalletAndFinance = () => {
  const emptyFinancePeriod: FinancePeriod = {
    period: {
      winnings: 0,
      deposits: 0,
      withdrawals: 0,
      losses: 0,
      spend: 0,
      profit: 0,
    },
    periodBefore: {
      winnings: 0,
      deposits: 0,
      withdrawals: 0,
      losses: 0,
      spend: 0,
      profit: 0,
    },
  };

  const [timeSpan, setTimeSpan] = useState<WagerActivityTimeFilter>('1 Month');
  const [pendingWithdrawals, setPendingWithdrawals] = useState<UserTransactions[]>([]);
  const [financialSummary, setFinancialSummary] = useState<FinancePeriod>({ ...emptyFinancePeriod });
  const [parentHistoryActivity, setParentHistoryActivity] = useState<HistoryActivity[]>([]);
  const auth = useStore((state) => state.auth);
  const filename = `Wallet_Finance_${getCurrentDate()}`;

  const getWithdrawals = async () => {
    const withdrawals = await getPendingWithdrawals();
    if (withdrawals) {
      setPendingWithdrawals(withdrawals);
    }
  };

  const getFinanceSummary = async () => {
    const data = await financeSummary(financePeriodFormatter(timeSpan));
    if (data) {
      setFinancialSummary(data);
    }
  };

  useEffect(() => {
    const fetchData = () => {
      getWithdrawals();
      getFinanceSummary();
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getFinanceSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSpan]);

  return (
    <>
      <Title order={1} align="center">
        Wallet & Financial Overview
      </Title>
      <Space h={32} />
      <PendingWithdrawals
        financialSummary={financialSummary}
        pendingWithdrawals={pendingWithdrawals}
        timeSpan={timeSpan}
        setTimeSpan={setTimeSpan}
        getWithdrawals={getWithdrawals}
      ></PendingWithdrawals>
      <Space h={32} />
      <Box sx={{ display: 'flex' }}>
        <Title align="left" sx={{ flexGrow: 1 }} order={1}>
          Wallet & Financial Overview
        </Title>
        {auth?.user?.role?.includes('SuperAdmin') && (
          <ExcelDownload
            content={parentHistoryActivity}
            defaultFields={['timestamp', 'firstName', 'lastName', 'type', 'wagerType', 'label', 'adjustment']}
            fileName={filename}
            headers={['Date', 'First Name', 'Last Name', 'Type', 'Wager Type', 'Details', 'Winnings/Losses']}
          />
        )}
      </Box>
      <Divider size={2} sx={{ margin: '24px 0px' }} />
      <Box sx={{ height: '900px' }}>
        <UserWallet isAdmin={true} setParentHistoryActivity={setParentHistoryActivity} />
      </Box>
    </>
  );
};

export default WalletAndFinance;
