import {
  Accordion,
  Box,
  Divider,
  Text,
  Group,
  Space,
  Stack,
  Title,
  Image,
  Table,
  Button,
  Select,
  Input,
  Textarea,
  InputWrapper,
} from '@mantine/core';
import { useStyles } from '../styles';
import { Currency, EditableGame, Game, Market } from '../../../api/interfaces';
import { CurrencyIcon } from '../../../components/game/marketButton';
import { formatCurrencySymbol } from '../../../utils/formatters';
import { useEffect, useState } from 'react';
import React from 'react';
import { getGames, toggleMarketStatus, updateGame, updateMarket } from '../../../api/game';
import { ReactComponent as IconEuro } from '../../../assets/icons/icon-currency-euro.svg';

const GameControl = ({ gamesList }: { gamesList: Game[] }) => {
  const { classes } = useStyles();
  const [openGamesCount, setOpenGamesCount] = useState<number>(0);
  const [idleMarkets, setIdleMarkets] = useState<number>(0);
  const [disabledMarkets, setDisabledMarkets] = useState<number>(0);
  const [editableGamesList, setEditableGamesList] = useState<EditableGame[]>([]);

  const countGamesStats = (gamesList: Game[]) => {
    let idleMarketsCount = 0;
    let disabledMarketsCount = 0;
    let openGamesCount = 0;

    gamesList.forEach((game) => {
      game.markets.forEach((market) => {
        if (market.open === 'Closed') {
          idleMarketsCount++;
        }
        if (market.enabled === 'Disabled') {
          disabledMarketsCount++;
        }
        if (market.open === 'Open' && market.enabled === 'Enabled') {
          openGamesCount++;
        }
      });
    });

    setIdleMarkets(idleMarketsCount);
    setDisabledMarkets(disabledMarketsCount);
    setOpenGamesCount(openGamesCount);
  };

  const handleEditGame = (game: Game) => {
    setEditableGamesList((prevEditableGamesList) =>
      prevEditableGamesList.map((gameItem) =>
        gameItem.id === game.id ? { ...gameItem, isEditable: !gameItem.isEditable } : gameItem
      )
    );
  };

  const handleEditMarket = (game: Game, index: number) => {
    setEditableGamesList((prevEditableGamesList) =>
      prevEditableGamesList.map((gameItem) => {
        if (gameItem.id === game.id) {
          const updatedMarkets = gameItem.markets.map((market, marketIndex) =>
            marketIndex === index ? { ...market, isEditable: !market.isEditable } : market
          );
          return { ...gameItem, markets: updatedMarkets };
        }
        return gameItem;
      })
    );
  };

  const handleGameInputChange = (game: Game, field: keyof Game, value: string) => {
    setEditableGamesList((prevEditableGamesList) =>
      prevEditableGamesList.map((gameItem) => (gameItem.id === game.id ? { ...gameItem, [field]: value } : gameItem))
    );
  };

  const handleMarketInputChange = (game: Game, marketIndex: number, field: keyof Market, value: string | number) => {
    setEditableGamesList((prevEditableGamesList) =>
      prevEditableGamesList.map((gameItem) => {
        if (gameItem.id === game.id) {
          const updatedMarkets = gameItem.markets.map((market, index) =>
            index === marketIndex ? { ...market, [field]: value } : market
          );
          return { ...gameItem, markets: updatedMarkets };
        }
        return gameItem;
      })
    );
  };

  const handleMarketDurationInputChange = (game: Game, marketIndex: number, durationIndex: number, value: number) => {
    setEditableGamesList((prevEditableGamesList) =>
      prevEditableGamesList.map((gameItem) => {
        if (gameItem.id === game.id) {
          const updatedMarkets = gameItem.markets.map((market, index) =>
            index === marketIndex
              ? {
                  ...market,
                  durations: market.durations.map((duration, durIndex) =>
                    durIndex === durationIndex ? { ...duration, value: value } : duration
                  ),
                }
              : market
          );
          return { ...gameItem, markets: updatedMarkets };
        }
        return gameItem;
      })
    );
  };

  const resetEditableState = () => {
    const updatedEditableGamesList = gamesList.map((game) => ({
      ...game,
      isEditable: false,
      markets: game.markets.map((market) => ({ ...market, isEditable: false })),
    }));
    setEditableGamesList(updatedEditableGamesList);
  };

  const saveGame = async (game: Game) => {
    const result = await updateGame(game);
    if (result) {
      getGames();
      handleEditGame(game);
    }
  };

  const saveMarket = async (market: Market) => {
    const result = await updateMarket(market);
    if (result) {
      console.log('saved');
    }
  };

  const toggleGame = (game: Game, status: boolean) => {
    game.markets.forEach(async (market) => {
      await toggleMarketStatus(market.id, status);
    });
  };

  useEffect(() => {
    if (gamesList) {
      resetEditableState();
      countGamesStats(gamesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gamesList]);

  return (
    <>
      <Title>Game Controls</Title>
      <Divider size={2} sx={{ margin: '24px 0px' }} />
      <Group grow>
        <Box className={classes.container}>
          <Group position="apart">
            <Title order={2}>Total No. Games</Title>
            <Title order={2}>{gamesList.length}</Title>
          </Group>
        </Box>
        <Box className={classes.container}>
          <Group position="apart">
            <Title order={2}>Open Markets</Title>
            <Title order={2}>{openGamesCount}</Title>
          </Group>
        </Box>
        <Box className={classes.container}>
          <Group position="apart">
            <Title order={2}>Idle Markets</Title>
            <Title order={2}>{idleMarkets}</Title>
          </Group>
        </Box>
        <Box className={classes.container}>
          <Group position="apart">
            <Title order={2}>Disabled Markets</Title>
            <Title order={2}>{disabledMarkets}</Title>
          </Group>
        </Box>
        <Box></Box>
      </Group>
      <Space h={32} />
      <Box className={classes.container}>
        <Accordion iconPosition="right">
          {editableGamesList.map((game) => (
            <Accordion.Item label={game.name} key={game.id}>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: '200px', marginRight: '48px' }}>
                  <Image src={game.imageGame} radius="md" fit="cover"></Image>
                </Box>
                <Stack sx={{ flexGrow: 1 }}>
                  {game.isEditable ? (
                    <>
                      <Group grow sx={{ display: 'flex', alignItems: 'flex-start' }} spacing={64}>
                        <Stack>
                          <InputWrapper
                            className={classes.InputWrapper}
                            sx={{
                              width: '100%',
                              label: {
                                marginLeft: '16px',
                              },
                            }}
                            size="lg"
                            label="Game Title"
                          >
                            <Input
                              name="name"
                              size="lg"
                              radius="md"
                              value={game.name}
                              sx={{ width: '100%' }}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleGameInputChange(game, 'name', e.target.value)
                              }
                            />
                          </InputWrapper>
                          <InputWrapper className={classes.InputWrapper} size="lg" label="Game Description">
                            <Textarea
                              autosize
                              minRows={4}
                              value={game.description}
                              size="lg"
                              radius="md"
                              sx={{ width: '100%' }}
                              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                handleGameInputChange(game, 'description', e.target.value)
                              }
                            />
                          </InputWrapper>
                        </Stack>
                        <Stack justify="flex-start">
                          <Select
                            value={game.category}
                            radius="md"
                            readOnly
                            size="lg"
                            label="Game Arena"
                            className={classes.InputWrapper}
                            data={[
                              { value: 'NonStopGame', label: 'Crypto' },
                              { value: 'BigBills', label: 'Forex' },
                              { value: 'MinerMadness', label: 'Metals' },
                            ]}
                            onChange={
                              (value: string | null) => handleGameInputChange(game, 'category', value || '') // Update 'category' with fallback to empty string
                            }
                          />
                          <Select
                            value={game.volatility}
                            radius="md"
                            readOnly
                            size="lg"
                            label="Game Type"
                            className={classes.InputWrapper}
                            data={[
                              { value: 'Fast', label: 'Fast' },
                              { value: 'Slow', label: 'Slow' },
                            ]}
                            onChange={
                              (value: string | null) => handleGameInputChange(game, 'volatility', value || '') // Update 'category' with fallback to empty string
                            }
                          />
                        </Stack>

                        <Stack justify="center">
                          <Space h={12} />
                          <Button
                            onClick={() => toggleGame(game, false)}
                            radius="lg"
                            size="lg"
                            variant="gradient"
                            gradient={{ from: '#D9E021', to: '#009245', deg: 180 }}
                          >
                            ACTIVATE Game
                          </Button>
                          <Space h={12} />
                          <Button
                            onClick={() => toggleGame(game, true)}
                            radius="lg"
                            variant="gradient"
                            size="lg"
                            gradient={{ from: '#FF5353', to: '#630202', deg: 180 }}
                          >
                            SUSPEND Game
                          </Button>
                        </Stack>
                      </Group>
                      <Space h={8} />
                      <Group>
                        <Button radius="lg" onClick={() => saveGame(game)}>
                          Update
                        </Button>
                        <Button radius="lg" onClick={() => resetEditableState()}>
                          Cancel
                        </Button>
                      </Group>
                      <Space h={8} />
                    </>
                  ) : (
                    <Group position="apart">
                      <Text>{game.description}</Text>
                      <Group>
                        <Box className={classes.container}>{game.category}</Box>
                        <Box className={classes.container}>{game.rating}</Box>
                        <Box className={classes.container}>{game.volatility}</Box>
                        <Button radius="lg" onClick={() => handleEditGame(game)}>
                          Edit
                        </Button>
                      </Group>
                    </Group>
                  )}
                  <Divider size={2} />
                  <Table>
                    <tbody style={{ display: 'flex', flexDirection: 'column' }}>
                      {game.markets.map((market, index) => {
                        const pair = market.currencyPair.split('/') as Currency[];
                        return (
                          <React.Fragment key={market.id}>
                            {market.isEditable ? (
                              <>
                                <Group grow spacing={64}>
                                  <Group>
                                    <CurrencyIcon currency={pair[0]} />
                                    <Text>
                                      {pair[0]} vs {pair[1]}
                                    </Text>
                                    <CurrencyIcon currency={pair[1]} />
                                  </Group>
                                  <Title order={2}>Game Duration Options</Title>
                                  <Title order={2} sx={{ paddingLeft: '64px' }}>
                                    Wager Limits
                                  </Title>
                                  <Title order={2}>Wager Odds</Title>
                                </Group>
                                <Space h={32} />
                                <Group spacing={0} grow sx={{ display: 'flex', alignItems: 'stretch', height: '100%' }}>
                                  <Stack sx={{ borderRight: '2px solid #00cafb', paddingRight: '32px', flex: 1 }}>
                                    <Select
                                      value={market.enabled}
                                      radius="md"
                                      readOnly
                                      size="lg"
                                      label="Game Status"
                                      className={classes.InputWrapper}
                                      data={[
                                        { value: 'Enabled', label: 'Enabled' },
                                        { value: 'Disabled', label: 'Disabled' },
                                      ]}
                                      onChange={(value: string | null) =>
                                        handleMarketInputChange(game, index, 'enabled', value || '')
                                      }
                                    />
                                    <Select
                                      value={market.open}
                                      radius="md"
                                      readOnly
                                      size="lg"
                                      label="Game Feed"
                                      data={[
                                        { value: 'Open', label: 'Open' },
                                        { value: 'Closed', label: 'Closed' },
                                      ]}
                                      className={classes.InputWrapper}
                                      onChange={(value: string | null) =>
                                        handleMarketInputChange(game, index, 'open', value || '')
                                      }
                                    />
                                    <InputWrapper className={classes.InputWrapper} size="lg" label="Max Players">
                                      <Input
                                        value={market.maxActiveBets}
                                        size="lg"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                          handleMarketInputChange(
                                            game,
                                            index,
                                            'maxActiveBets',
                                            parseFloat(e.target.value) || 0
                                          )
                                        }
                                      />
                                      <Text size="sm" pt={8}>
                                        Once capacity is reached, other players will see the game as CLOSED
                                      </Text>
                                    </InputWrapper>
                                  </Stack>
                                  <Stack
                                    sx={{
                                      borderRight: '2px solid #00cafb',
                                      padding: '0px 32px',
                                      flex: 1,
                                    }}
                                  >
                                    <Group grow>
                                      <InputWrapper size="lg" label="Ticks" className={classes.InputWrapper}>
                                        <Input
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleMarketDurationInputChange(
                                              game,
                                              index,
                                              0,
                                              parseFloat(e.target.value) || 0
                                            )
                                          }
                                          size="lg"
                                          value={market.durations[0].value}
                                        />
                                      </InputWrapper>
                                      <InputWrapper size="lg" label="Ticks" className={classes.InputWrapper}>
                                        <Input
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleMarketDurationInputChange(
                                              game,
                                              index,
                                              1,
                                              parseFloat(e.target.value) || 0
                                            )
                                          }
                                          size="lg"
                                          value={market.durations[1].value}
                                        />
                                      </InputWrapper>
                                    </Group>
                                    <Group grow>
                                      <InputWrapper size="lg" label="Ticks" className={classes.InputWrapper}>
                                        <Input
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleMarketDurationInputChange(
                                              game,
                                              index,
                                              2,
                                              parseFloat(e.target.value) || 0
                                            )
                                          }
                                          size="lg"
                                          value={market.durations[2].value}
                                        />
                                      </InputWrapper>
                                      <InputWrapper size="lg" label="Minutes" className={classes.InputWrapper}>
                                        <Input
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleMarketDurationInputChange(
                                              game,
                                              index,
                                              3,
                                              parseFloat(e.target.value) || 0
                                            )
                                          }
                                          size="lg"
                                          value={market.durations[3].value}
                                        />
                                      </InputWrapper>
                                    </Group>
                                    <Group grow></Group>
                                  </Stack>
                                  <Stack
                                    sx={{
                                      borderRight: '2px solid #00cafb',
                                      padding: '0px 32px',
                                      flex: 1,
                                    }}
                                  >
                                    <InputWrapper size="lg" label="Min" className={classes.InputWrapper}>
                                      <Input
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                          handleMarketInputChange(game, index, 'minWager', parseFloat(e.target.value))
                                        }
                                        icon={<IconEuro style={{ color: 'white', height: '16px' }} />}
                                        size="lg"
                                        value={market.minWager}
                                      />
                                    </InputWrapper>
                                    <InputWrapper size="lg" label="For" className={classes.InputWrapper}>
                                      <Input
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                          handleMarketInputChange(game, index, 'forOdds', parseFloat(e.target.value))
                                        }
                                        size="lg"
                                        value={market.forOdds}
                                      />
                                    </InputWrapper>
                                  </Stack>
                                  <Stack sx={{ flex: 1, paddingLeft: '32px' }}>
                                    <InputWrapper size="lg" label="For" className={classes.InputWrapper}>
                                      <Input
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                          handleMarketInputChange(game, index, 'forOdds', parseFloat(e.target.value))
                                        }
                                        size="lg"
                                        value={market.forOdds}
                                      />
                                    </InputWrapper>
                                    <InputWrapper size="lg" label="Against" className={classes.InputWrapper}>
                                      <Input
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                          handleMarketInputChange(
                                            game,
                                            index,
                                            'againstOdds',
                                            parseFloat(e.target.value)
                                          )
                                        }
                                        size="lg"
                                        value={market.againstOdds}
                                      />
                                    </InputWrapper>
                                  </Stack>
                                </Group>
                                <Space h={32} />
                                <Group>
                                  <Button radius="lg" onClick={() => saveMarket(market)}>
                                    Update
                                  </Button>
                                  <Button radius="lg" onClick={() => resetEditableState()}>
                                    Cancel
                                  </Button>
                                </Group>
                                <Space h={32} />
                                <Divider size={2} />
                                <Space h={16} />
                              </>
                            ) : (
                              <tr style={{ display: 'flex', flexDirection: 'row' }}>
                                <td style={{ flexGrow: 1 }}>
                                  <Group noWrap>
                                    <CurrencyIcon currency={pair[0]} />
                                    <Text>
                                      {' '}
                                      {pair[0]} vs {pair[1]}
                                    </Text>
                                    <CurrencyIcon currency={pair[1]} />
                                  </Group>
                                </td>
                                <td style={{ flexGrow: 1 }}>
                                  <Stack>
                                    <Text>
                                      Game Status:
                                      <br />
                                      <span style={{ color: market.enabled === 'Enabled' ? '#88c32c' : '#eb6c35' }}>
                                        {market.enabled === 'Enabled' ? 'Enabled' : 'Disabled'}{' '}
                                      </span>
                                    </Text>
                                  </Stack>
                                </td>
                                <td style={{ flexGrow: 1 }}>
                                  <Stack>
                                    <Text>
                                      Feed Status:
                                      <br />
                                      <span style={{ color: market.open === 'Open' ? '#88c32c' : '#eb6c35' }}>
                                        {market.open === 'Open' ? 'Open' : 'Closed'}
                                      </span>
                                    </Text>
                                  </Stack>
                                </td>
                                <td style={{ flexGrow: 1 }}>
                                  <Stack>
                                    <Text>
                                      Wager Limits:
                                      <br />
                                      <span>
                                        {formatCurrencySymbol(market.wagerCurrency)} {market.wagerCurrency}{' '}
                                        {market.minWager} - {market.maxWager}
                                      </span>
                                    </Text>
                                  </Stack>
                                </td>
                                <td
                                  style={{
                                    flexGrow: 1,
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    paddingRight: '0px',
                                  }}
                                >
                                  <Button radius="lg" onClick={() => handleEditMarket(game, index)}>
                                    Edit
                                  </Button>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </Table>
                </Stack>
              </Box>
            </Accordion.Item>
          ))}
        </Accordion>
      </Box>
    </>
  );
};

export default GameControl;
