import { showNotification } from '@mantine/notifications';

let { fetch: originalFetch } = window;
let fetch = async (...args: any[]) => {
  let [resource, config] = args;
  let response = await originalFetch(resource, config);
  if (response.status === 204) {
    return response;
  }
  if (response.status !== 200) {
    showNotification({
      title: 'Whoops!',
      message: 'something unexpected happened',
    });
  }
  return response;
};

export default fetch;
