import { Container, List, Paper, Stack, useMantineTheme } from '@mantine/core';
import SectionHeader from '../../components/staticSectionHeader';
import { useStyles } from '../legalPages/styles';

const PaymentsPolicy = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  return (
    <Container size="md">
      <Paper
        sx={(theme) => ({
          backgroundColor: theme.fn.rgba(theme.other.darkBackgroundMedium, 0.6),
        })}
        className={classes.paper}
      >
        <SectionHeader order={1} align="center">
          Payment Transactions and Processors
        </SectionHeader>
        <Stack pb="lg" mb={theme.spacing.lg}>
          <List>
            <List.Item className={classes.bullets}>
              You are fully responsible for paying all monies owed to us. You must make all payments to us in good faith
              and not attempt to reverse a payment made or take any action which will cause such payment to be reversed
              by a third party in order to avoid a liability legitimately incurred. You will reimburse us for any
              chargebacks, denial or reversal of payment you make and any loss suffered by us as a consequence thereof.
              We reserve the right to also impose an administration fee of €60 (or currency equivalent) per chargeback,
              denial or reversal of payment you make.
            </List.Item>
            <List.Item className={classes.bullets}>
              We reserve the right to use third-party electronic payment processors and or merchant banks to process
              payments made by you and you agree to be bound by their terms and conditions providing they are made aware
              to you and those terms do not conflict with these Terms.
            </List.Item>
            <List.Item className={classes.bullets}>
              All transactions made on our site might be checked to prevent money laundering or terrorism financing
              activity. Suspicious transactions will be reported to the relevant authority depending on the jurisdiction
              governing the transaction.
            </List.Item>
            <List.Item className={classes.bullets}>
              FXBet’s obligations towards responsible gaming and AML legislation trump commercial conditions.
            </List.Item>
          </List>
        </Stack>
      </Paper>
    </Container>
  );
};

export default PaymentsPolicy;
