import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => {
  return {
    sectionWrapper: {
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
    },
    section: {
      width: '45%',
      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        width: '100%',
        marginBottom: '32px',
      },
    },
    isAdminSection: {
      width: '100%',
    },
  };
});

export { useStyles };
