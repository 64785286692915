import { showNotification } from '@mantine/notifications';

let notificationStack: any[] = [];

const ToggleMarketNotification = (marketFound: boolean) => {
  const notificationOptions = {
    color: marketFound ? 'red' : 'green',
    icon: '',
    disallowClose: true,
    autoClose: 2000,
    message: marketFound ? 'Removed Market From Favourites' : 'Added Market To Favourites',
  };

  const newNotification = showNotification(notificationOptions);

  notificationStack.push(newNotification);
};

export default ToggleMarketNotification;
