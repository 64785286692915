import { GreenButton, GreenButtonProps } from './GreenButton';
import { ReactComponent as IconForward } from '../../assets/icons/icon-forward.svg';
import { useMantineTheme } from '@mantine/core';

type ProceedButtonProps = Omit<GreenButtonProps, 'rightIcon'>;

export const ProceedButton = (props: ProceedButtonProps) => {
  const theme = useMantineTheme();
  return (
    <GreenButton
      {...props}
      rightIcon={<IconForward height={24} style={{ color: theme.white }} />}
      styles={(theme) => {
        let styles = props.styles ?? {};
        if (typeof styles === 'function') {
          styles = styles(theme);
        }
        return {
          ...styles,
          label: {
            flexGrow: 1,
            color: theme.other.white,
            justifyContent: 'center',
            ':disabled': {
              background: theme.other.gradientGreenOpacity,
              '.mantine-Title-root': {
                color: theme.fn.darken(theme.other.white, 0.2),
              },
            },
            ...(styles.root ?? {}),
          },
        };
      }}
    >
      {props.children}
    </GreenButton>
  );
};
