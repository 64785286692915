import { useEffect, useState } from 'react';
import { AccountInsight } from '../../../api/interfaces';
import { getAccountsInsight } from '../../../api/backoffice';
import { Box, Button, Checkbox, Grid, Space, Stack, Table, Text, Title } from '@mantine/core';
import { downloadExcel } from 'react-export-table-to-excel';
import { mapKeyToTextAccountFields } from '../../../utils/Pipes';
import { useStyles } from '../styles';
import { getCurrentDate } from '../../../utils/formatters';

interface AccountInsightDisplay {
  insightPeriod: '24 Hours' | '7 Days' | '1 Month' | '6 Months' | '1 Year' | 'MTD' | 'YTD' | 'All Time';
  insightsData: InsightsDataDisplay;
}

interface InsightsDataDisplay {
  totalActive: number;
  totalVerified: number;
  noDeposit: number;
  totalNew: number;
  modeCountry: string;
  totalFlagged: number;
  totalDeactivated: number;
  totalFrozen: number;
  modeAge: number;
  incomplete: number;
  totalIdle: number;
  modeGender: string;
}

const GeneralPlayerReport = () => {
  const [selectedTimeSpans, setSelectedTimeSpans] = useState<string[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(['totalActive', 'totalFlagged', 'noDeposit']);
  const [tableData, setTableData] = useState<{ timeline: string; data: string[] }[]>([]);
  const [loadedData, setLoadedData] = useState<boolean>(true);
  const { classes } = useStyles();

  const createDefaultInsightData = () => ({
    totalActive: 0,
    totalVerified: 0,
    noDeposit: 0,
    totalNew: 0,
    totalFlagged: 0,
    modeCountry: '',
    totalDeactivated: 0,
    totalFrozen: 0,
    modeAge: 0,
    incomplete: 0,
    totalIdle: 0,
    modeGender: '',
  });

  const periods: AccountInsightDisplay['insightPeriod'][] = [
    '24 Hours',
    '7 Days',
    '1 Month',
    '6 Months',
    '1 Year',
    'MTD',
    'YTD',
    'All Time',
  ];

  const defaultInsightsData = periods.map((period) => ({
    insightPeriod: period,
    insightsData: createDefaultInsightData(),
  }));

  const [insightsData, setInsightsData] = useState<AccountInsightDisplay[] | []>(defaultInsightsData);

  const toggleColumn = (label: string) => {
    if (selectedColumns.includes(label)) {
      setSelectedColumns(selectedColumns.filter((col) => col !== label));
    } else {
      setSelectedColumns([...selectedColumns, label]);
    }
  };

  const DownloadReport = () => {
    const flattenedArray = tableData.map((item) => [item.timeline, ...item.data]);
    const modifiedColumns = [...selectedColumns];
    modifiedColumns.unshift('Time Span');
    downloadExcel({
      fileName: `Player General Report_${getCurrentDate()}`,
      sheet: 'Player Report',
      tablePayload: {
        header: modifiedColumns,
        body: flattenedArray,
      },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getAccountsInsight();
      if (Array.isArray(response) && response.length > 1) {
        const filteredInsights = filterInsightsData(response);
        setInsightsData(filteredInsights);
        setLoadedData(true);
      } else {
        setLoadedData(false);
      }
    };
    fetchData();
  }, []);

  const mapPeriodToAccountInsightDisplay = (
    period: AccountInsight['insightPeriod']
  ): AccountInsightDisplay['insightPeriod'] => {
    switch (period) {
      case 'Day':
        return '24 Hours';
      case 'Week':
        return '7 Days';
      case 'Month':
        return '1 Month';
      case 'Months':
        return '6 Months';
      case 'Year':
        return '1 Year';
      case 'MTD':
        return 'MTD';
      case 'YTD':
        return 'YTD';
      case 'Ever':
        return 'All Time';
    }
  };

  const filterInsightsData = (insightArray: AccountInsight[]): AccountInsightDisplay[] => {
    return insightArray.map((insight) => {
      return {
        insightPeriod: mapPeriodToAccountInsightDisplay(insight.insightPeriod),
        insightsData: {
          totalActive: insight.insightsData.totalActive,
          totalVerified: insight.insightsData.totalVerified,
          noDeposit: insight.insightsData.noDeposit,
          totalNew: insight.insightsData.totalNew,
          totalFlagged: insight.insightsData.totalFlagged,
          modeCountry: insight.insightsData.modeCountry,
          totalDeactivated: insight.insightsData.totalDeactivated,
          totalFrozen: insight.insightsData.totalFrozen,
          modeAge: insight.insightsData.modeAge,
          incomplete: insight.insightsData.incomplete,
          totalIdle: insight.insightsData.totalIdle,
          modeGender: insight.insightsData.modeGender,
        },
      };
    });
  };

  useEffect(() => {
    const timelines: string[] = [];
    if (insightsData && Object.keys(insightsData).length > 0) {
      insightsData?.forEach((data: AccountInsightDisplay) => {
        timelines.push(data.insightPeriod);
      });
      setSelectedTimeSpans(timelines);
    }
  }, [insightsData]);

  useEffect(() => {
    setTableData(
      selectedTimeSpans.map((timeline) => {
        const rowData: { [key: string]: string } = {};
        selectedColumns.forEach((col) => {
          const data = insightsData.find((data) => data.insightPeriod === timeline);
          rowData[col] = data ? data.insightsData[col as keyof typeof data.insightsData].toString() : '';
        });
        return { timeline, data: Object.values(rowData) };
      })
    );
  }, [selectedTimeSpans, selectedColumns, insightsData]);

  return (
    <>
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Stack>
          <Title order={3} mt={16}>
            Select Columns
          </Title>
          <Box sx={{ display: 'flex' }}>
            <Grid sx={{ width: '70%' }}>
              {insightsData.length > 0 &&
                Object.keys(insightsData[0].insightsData).map((key, i) => (
                  <Grid.Col span={(i + 1) % 3 === 0 ? 6 : 3} key={JSON.stringify(key)}>
                    <Checkbox
                      defaultChecked={selectedColumns.includes(key)}
                      label={mapKeyToTextAccountFields(key)}
                      onChange={() => toggleColumn(key)}
                    />
                  </Grid.Col>
                ))}
            </Grid>
          </Box>
        </Stack>
      </Box>
      <Space h={24} />
      <Text size="xs">“Active Accounts” excludes only deleted, deactivated or incomplete accounts.</Text>
      <Space h={24} />
      <Title order={3} mb="sm">
        Report Preview
      </Title>
      <Box className={classes.removeScroll} sx={{ width: '100%', maxWidth: 'calc(100vw - 214px)', overflowX: 'auto' }}>
        <Table>
          <thead className={classes.tableHeader}>
            <tr>
              <th style={{ minWidth: '150px' }}>
                <Text>Time Span</Text>
              </th>
              {selectedColumns.map((col) => (
                <th key={col} style={{ minWidth: '150px' }}>
                  <Text align="center">{mapKeyToTextAccountFields(col)}</Text>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loadedData &&
              tableData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>
                    <Text>{row.timeline}</Text>
                  </td>
                  {row.data.map((cell, cellIndex) => (
                    <td key={cellIndex}>
                      <Text align="center">{cell}</Text>
                    </td>
                  ))}
                </tr>
              ))}
            {!loadedData && (
              <Box mt={64} sx={{ width: '100%' }}>
                <Title align="center">Failed to load data</Title>
              </Box>
            )}
          </tbody>
        </Table>
      </Box>
      <Space h={32} />
      <Button radius="lg" onClick={DownloadReport}>
        Generate & Download Report
      </Button>
    </>
  );
};

export default GeneralPlayerReport;
