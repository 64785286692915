import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => {
  return {
    round: {
      background: theme.other.gradientBlueNoOpacity,
      borderRadius: '50%',
      width: '145px',
      height: '145px',
      padding: '16px',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      position: 'relative',
      boxShadow: '0px 0px 22px 0px rgba(0,0,0,0.75)',
      marginBottom: '24px',
    },
    green: {
      backgroundImage: `${theme.other.gradientGreen} !important`,
    },
    icon: {
      color: 'white',
      width: '88px',
      height: '88px',
      margin: 'auto',
    },
    stepContainer: {
      position: 'absolute',
      height: '48px',
      width: '48px',
      background: theme.other.gradientBlueNoOpacity,
      borderRadius: '50%',
      bottom: '0',
      left: '0',
      marginLeft: '-8px',
      boxShadow: '0px 0px 22px 0px rgba(0,0,0,0.75)',
      display: 'flex',
    },
    stepItem: {
      color: theme.other.white,
      margin: 'auto',
    },
  };
});
