import {
  Group,
  Input,
  Select,
  Container,
  Text,
  Stack,
  Timeline,
  InputWrapper,
  Box,
  Title,
  Anchor,
} from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { useStyles } from './styles';
import { Dispatch, useEffect, useState } from 'react';
import { DateRangeRequest, MapHisotryDataItem, WagerHistory } from '../../../api/interfaces';
import { formatCurrencySymbol, formatDurationUnit, formatWagerType, formatDate } from '../../../utils/formatters';
import { gameList } from '../../../utils/consts';
import { useNavigate } from 'react-router-dom';
import { getWagerHistory } from '../../../api/backoffice';

const AllBettingHistory = ({
  setparentWagerHistory,
}: {
  setparentWagerHistory: Dispatch<React.SetStateAction<WagerHistory[]>>;
}) => {
  const { classes } = useStyles();
  const [wagerHistory, setWagerHistory] = useState<WagerHistory[]>([]);
  const navigate = useNavigate();
  const [filteredWagerHistory, setFilteredWagerHistory] = useState<WagerHistory[]>([]);
  const [dateRangeValue, setDateRangeValue] = useState<[Date | null, Date | null]>([
    new Date(new Date().setHours(0, 0, 0, 0) - 5 * 24 * 60 * 60 * 1000),
    new Date(new Date().setHours(0, 0, 0, 0)),
  ]);
  const [filters, setFilters] = useState({
    userFirstName: '',
    userLastName: '',
    state: '',
    category: '',
    type: '',
    duration: '',
    game: '',
  });

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSelectChange = (value: string | null, name: string) => {
    if (value != null) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: value,
      }));
    }
  };

  const filterData = () => {
    const filteredData = wagerHistory.filter((item) => {
      let passFilters = true;
      const durationArray = filters.duration.split(' ');
      if (filters.userFirstName && !item.userFirstName.toLowerCase().includes(filters.userFirstName.toLowerCase())) {
        passFilters = false;
      }
      if (filters.userLastName && !item.userLastName.toLowerCase().includes(filters.userLastName.toLowerCase())) {
        passFilters = false;
      }
      if (filters.state && item.state !== filters.state) {
        passFilters = false;
      }
      if (filters.category && item.game.category !== filters.category) {
        passFilters = false;
      }
      if (filters.type && item.type !== filters.type) {
        passFilters = false;
      }
      if (
        filters.duration &&
        (item.duration.value.toString() !== durationArray[0] || item.duration.unit !== durationArray[1])
      ) {
        passFilters = false;
      }
      if (filters.game && item.game.name !== filters.game) {
        passFilters = false;
      }
      return passFilters;
    });
    setparentWagerHistory(filteredData);
    setFilteredWagerHistory(filteredData);
  };

  function transformData(inputData: WagerHistory[]): MapHisotryDataItem[] {
    const transformedData: MapHisotryDataItem[] = [];
    if (inputData) {
      inputData.forEach((item) => {
        const completedAt = item.completedAt?.slice(0, 10);
        const existingEntry = transformedData.find((entry) => entry.transactionDate === completedAt);

        if (existingEntry) {
          existingEntry.items.push(item);
        } else {
          const newEntry: MapHisotryDataItem = {
            transactionDate: completedAt,
            items: [item],
          };
          transformedData.push(newEntry);
        }
      });
    }
    return transformedData;
  }

  useEffect(() => {
    if (dateRangeValue[0] && dateRangeValue[1]) {
      const dateRangeRequest: DateRangeRequest = {
        from: dateRangeValue[0],
        to: dateRangeValue[1],
      };
      const fetchData = async () => {
        const wagerHistory = await getWagerHistory(dateRangeRequest);
        if (wagerHistory) {
          setWagerHistory(wagerHistory);
          setFilteredWagerHistory(wagerHistory);
        }
      };
      fetchData();
    }
  }, [dateRangeValue]);

  useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, wagerHistory]);

  return (
    <Container sx={{ padding: '20px' }} fluid className={classes.tableContainer}>
      <Stack>
        <Text weight="bold">Betting history across all games</Text>
        <Group noWrap grow>
          <DateRangePicker
            label="Date and Time"
            placeholder="Pick dates range"
            value={dateRangeValue}
            onChange={setDateRangeValue}
          />
          <InputWrapper label="Player Name">
            <Input
              placeholder="Player First Name"
              name="userFirstName"
              value={filters.userFirstName}
              onChange={handleInputChange}
            />
          </InputWrapper>
          <Select
            label="Arena"
            placeholder="Arena"
            data={[
              { value: '', label: 'All' },
              { value: 'NonStopGame', label: 'NonStopGame' },
              { value: 'BigBills', label: 'BigBills' },
              { value: 'MinerMadness', label: 'MinerMadness' },
              { value: 'SocialFrenzy', label: 'SocialFrenzy' },
            ]}
            name="category"
            value={filters.category}
            onChange={(e) => handleSelectChange(e, 'category')}
          />
          <Select
            label="Game"
            placeholder="Game"
            data={[{ value: '', label: 'All' }, ...gameList.map((game) => ({ value: game, label: game }))]}
            name="game"
            value={filters.game}
            onChange={(e) => handleSelectChange(e, 'game')}
          />
          <Select
            label="Duration"
            placeholder="Duration"
            data={[
              { value: '', label: 'All' },
              { value: '5 Ticks', label: '5 Ticks' },
              { value: '10 Ticks', label: '10 Ticks' },
              { value: '15 Ticks', label: '15 Ticks' },
              { value: '2 Minutes', label: '2 Minutes' },
            ]}
            name="duration"
            value={filters.duration}
            onChange={(e) => handleSelectChange(e, 'duration')}
          />
          <Select
            label="Direction"
            placeholder="Direction"
            data={[
              { value: '', label: 'All' },
              { value: 'Rise', label: 'Up' },
              { value: 'Fall', label: 'Down' },
            ]}
            name="type"
            value={filters.type}
            onChange={(e) => handleSelectChange(e, 'type')}
          />
          <Select
            label="Outcome"
            placeholder="Outcome"
            data={[
              { value: '', label: 'All' },
              { value: 'Won', label: 'Won' },
              { value: 'Draw', label: 'Draw' },
              { value: 'Cancelled', label: 'Cancelled' },
              { value: 'Lost', label: 'Lost' },
            ]}
            name="state"
            value={filters.state}
            onChange={(e) => handleSelectChange(e, 'state')}
          />
        </Group>
        {filteredWagerHistory && filteredWagerHistory.length > 0 && (
          <Timeline bulletSize={24} lineWidth={2}>
            {transformData(filteredWagerHistory)
              .sort((a, b) => new Date(b.transactionDate).getDate() - new Date(a.transactionDate).getDate())
              .map((transactions, index) => {
                transactions.items.sort((a, b) => new Date(b.placedAt).getTime() - new Date(a.placedAt).getTime());
                return (
                  <Timeline.Item
                    key={index}
                    title={
                      <Group position="apart" noWrap pr={16}>
                        <Title order={2}>{formatDate(transactions.transactionDate)}</Title>
                        <Group noWrap>
                          <Title order={2}>Total Bets Placed</Title>
                          <Box sx={{ minWidth: '32px', textAlign: 'center' }}>{transactions.items.length}</Box>
                        </Group>
                      </Group>
                    }
                    bullet=" "
                  >
                    {transactions.items.map((item, index) => {
                      return (
                        <Group
                          key={index}
                          grow
                          sx={{ padding: '4px', backgroundColor: index % 2 === 0 ? '' : '#224962' }}
                        >
                          <Stack spacing={0}>
                            <Anchor onClick={() => navigate(`/admin/player-overview/player-account/${item.userId}`)}>
                              <Text sx={{ fontWeight: 'bold' }}>{item.userFirstName}</Text>
                              <Text sx={{ fontWeight: 'bold' }}>{item.userLastName}</Text>
                            </Anchor>
                          </Stack>
                          <Text>{item.game.category}</Text>
                          <Text sx={{ fontWeight: 'bold' }}>{item.game.name}</Text>
                          <Text sx={{ color: '#00cafb' }}>{item.currencyPair}</Text>

                          <Stack spacing={0}>
                            <Text>
                              {item.duration.value} {formatDurationUnit(2, item.duration.unit)}
                            </Text>
                          </Stack>
                          <Text sx={{ fontWeight: 'bold' }}>{formatWagerType(item.type)}</Text>
                          <Text sx={{ fontWeight: 'bold' }}>
                            {formatCurrencySymbol(item.currency)} {item.staked}
                          </Text>
                          <Text
                            style={{
                              color: item.state === 'Won' ? '#88c32c' : item.state === 'Lost' ? '#ff5353' : '#00cafb',
                            }}
                          >
                            Player <strong>{item.state}</strong>
                          </Text>
                        </Group>
                      );
                    })}
                  </Timeline.Item>
                );
              })}
          </Timeline>
        )}
      </Stack>
      {filteredWagerHistory && filteredWagerHistory.length === 0 && (
        <Box sx={{ height: 'calc(100% - 100px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Title>There is no data to display for this selection</Title>
        </Box>
      )}
    </Container>
  );
};

export default AllBettingHistory;
