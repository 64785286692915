import { WagerTypeEnum } from '../../../../api/interfaces';
import { ChartDataRef } from './interface';
import { drawBar, drawDashedLine } from './utils';

export const manageWinLossAnimation = (
  context: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  projectValueToY: (value: number) => number,
  ref: ChartDataRef
) => {
  // Check if wager is defined
  if (!ref.wager || ref.wager.openingValue === undefined) {
    return;
  }

  const barWidth = 50;
  const openingValueY = projectValueToY(ref.wager.openingValue);

  // Draw bars
  const lossBarColor = '#EA1D21';
  const winBarColor = '#034059';
  if (ref.wager.type === WagerTypeEnum.Fall) {
    drawBar(context, canvas.width, canvas.height, lossBarColor, barWidth, 0, 'Loss');
    drawBar(context, canvas.width, canvas.height, winBarColor, barWidth, openingValueY, 'Win');
  } else {
    drawBar(context, canvas.width, canvas.height, winBarColor, barWidth, 0, 'Win');
    drawBar(context, canvas.width, canvas.height, lossBarColor, barWidth, openingValueY, 'Loss');
  }

  // Draw dashed line
  drawDashedLine(context, openingValueY, 2, canvas.width);
};
