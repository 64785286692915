import { Stack, Title, Text, Button, Box, Group, Space, Timeline, Grid, useMantineTheme, Anchor } from '@mantine/core';
import { Dispatch, useEffect, useState } from 'react';
import { HistoryActivity, MergedDataItem, WagerHistory } from '../../api/interfaces';
import { getPlayerHistory } from '../../api/player';
import { useStyles } from './styles';
import { ReactComponent as ListIcon } from '../../assets/icons/list-icon.svg';
import { ReactComponent as DepositIcon } from '../../assets/icons/deposit-icon.svg';
import { ReactComponent as WithdrawalIcon } from '../../assets/icons/withdrawal-icon.svg';
import { ReactComponent as WagerIcon } from '../../assets/icons/wager-icon.svg';
import { ReactComponent as UpArrow } from '../../assets/icons/up-arrow.svg';
import { ReactComponent as DownArrow } from '../../assets/icons/down-arrow.svg';
import { ReactComponent as HourGlassFull } from '../../assets/icons/hourglass-full.svg';
import { ReactComponent as Withdrawal } from '../../assets/icons/withdrawal-icon.svg';
import { ReactComponent as Deposit } from '../../assets/icons/deposit-icon.svg';
import { ReactComponent as FilledCircleIcon } from '../../assets/icons/filled-circle.svg';
import { ReactComponent as CircleIcon } from '../../assets/icons/circle.svg';
import { ActiveGradient, InActiveGradient } from '../../utils/consts';
import { getAllAccountHistory } from '../../api/backoffice';
import { formatCurrency, formatCurrencySymbol, formatPlayerFullName, formatTime } from '../../utils/formatters';
import { useMediaQuery } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';

const UserWallet = ({
  isAdmin,
  id,
  withdrawalsOnly,
  setParentHistoryActivity,
}: {
  isAdmin?: boolean;
  id?: string;
  withdrawalsOnly?: boolean;
  setParentHistoryActivity?: Dispatch<React.SetStateAction<HistoryActivity[]>>;
}) => {
  const [playerHistory, setPlayerHistory] = useState<MergedDataItem[]>([]);
  const [filterPlayerHistory, setFilterPlayerHistory] = useState<MergedDataItem[]>([]);
  const [visibleTransactions, setVisibleTransactions] = useState(5);
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState<HistoryActivity['type'] | 'All' | 'Withdrawals'>(
    withdrawalsOnly ? 'Withdrawals' : 'All'
  );

  const mobileTabletView = useMediaQuery(`(max-width: ${useMantineTheme().breakpoints.md}px)`);
  const filterTransactionsByType = (filter: HistoryActivity['type'] | 'All' | 'Withdrawals') => {
    let filteredData: MergedDataItem[] = [];
    if (filter === 'All') {
      filteredData = playerHistory;
    } else if (filter === 'Withdrawals') {
      filteredData = playerHistory
        .map((item) => ({
          transactionDate: item.transactionDate,
          items: item.items.filter((subItem) => subItem.type.includes('Withdraw')),
        }))
        .filter((item) => item.items.length > 0);
    } else {
      filteredData = playerHistory
        .map((item) => ({
          transactionDate: item.transactionDate,
          items: item.items.filter((subItem) => subItem.type === filter),
        }))
        .filter((item) => item.items.length > 0);
    }
    setFilterPlayerHistory(filteredData);
    setActiveButton(filter);
  };

  const handleShowMore = () => {
    setVisibleTransactions(visibleTransactions + 3);
  };

  const getTransactionIcon = (transaction: HistoryActivity) => {
    if (transaction.type === 'Wager' && transaction.adjustment > 0) {
      return <UpArrow width="20px" />;
    } else if (transaction.type === 'Wager' && transaction.adjustment < 0) {
      return <DownArrow width="20px" />;
    } else if (transaction.type === 'WithdrawPending' || transaction.type === 'WithdrawProcessing') {
      return <HourGlassFull width="20px" />;
    } else if (transaction.type === 'WithdrawCompleted') {
      return <Withdrawal width="20px" />;
    } else if (transaction.type === 'Deposit') {
      return <Deposit width="20px" />;
    }
    return '-';
  };

  function mergeData(data: HistoryActivity[]): MergedDataItem[] {
    const mergedData: MergedDataItem[] = [];
    for (const item of data) {
      const { timestamp } = item;
      const date = new Date(timestamp);
      const transactionDate = date.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });
      const existingItem = mergedData.find((mergedItem) => mergedItem.transactionDate === transactionDate);
      if (existingItem) {
        existingItem.items.push(item);
      } else {
        mergedData.push({
          transactionDate,
          items: [item],
        });
      }
    }
    return mergedData;
  }

  const fetchPlayerHistory = async () => {
    let history;
    if (isAdmin) {
      history = await getAllAccountHistory();
      if (history && setParentHistoryActivity) {
        setParentHistoryActivity(history);
      }
    } else {
      history = await getPlayerHistory(id);
    }
    if (history) {
      setPlayerHistory(mergeData(history));
      setFilterPlayerHistory(mergeData(history));
    }
  };

  useEffect(() => {
    fetchPlayerHistory();
  }, []);

  const formatType = (type: HistoryActivity['type']) => {
    if (type === 'WithdrawPending') {
      return 'Pending';
    } else if (type === 'WithdrawProcessing') {
      return 'Processing';
    } else if (type === 'WithdrawCompleted') {
      return 'Completed';
    } else if (type === 'WithdrawFailed') {
      return 'Failed';
    } else if (type === 'Refund') {
      return 'Credit';
    } else {
      return type;
    }
  };

  const { classes } = useStyles();
  return (
    <Stack spacing={0} sx={{ height: '100%', display: 'flex' }}>
      <Box
        sx={{
          borderTopLeftRadius: '30px',
          borderTopRightRadius: '30px',
          backgroundColor: '#7C37BD',
          padding: '16px',
        }}
      >
        <Stack spacing={0} className={classes.titleAlignment}>
          <Title order={2}>Deposit, Wager, Withdrawal History</Title>
          <Text>Full transaction history of your account</Text>
        </Stack>
      </Box>
      <Box className={classes.container}>
        {withdrawalsOnly && (
          <Grid>
            <Grid.Col span={6} md={3}>
              <Button
                onClick={() => filterTransactionsByType('Withdrawals')}
                variant="gradient"
                className={classes.fullHeight}
                radius="lg"
                gradient={activeButton === 'Withdrawals' ? ActiveGradient : InActiveGradient}
              >
                <Stack spacing={0} align="center">
                  <WithdrawalIcon className={classes.pdfIcon}></WithdrawalIcon>
                  <Text>Withdrawals</Text>
                </Stack>
              </Button>
            </Grid.Col>
            <Grid.Col span={6} md={3}>
              <Button
                onClick={() => filterTransactionsByType('WithdrawPending')}
                variant="gradient"
                className={classes.fullHeight}
                radius="lg"
                gradient={activeButton === 'WithdrawPending' ? ActiveGradient : InActiveGradient}
              >
                <Stack spacing={0} align="center">
                  <Group noWrap spacing={0}>
                    <FilledCircleIcon className={classes.pdfIcon}></FilledCircleIcon>
                    <CircleIcon className={classes.pdfIcon}></CircleIcon>
                    <CircleIcon className={classes.pdfIcon}></CircleIcon>
                  </Group>
                  <Text>Pending</Text>
                </Stack>
              </Button>
            </Grid.Col>
            <Grid.Col span={6} md={3}>
              <Button
                onClick={() => filterTransactionsByType('WithdrawProcessing')}
                variant="gradient"
                className={classes.fullHeight}
                radius="lg"
                gradient={activeButton === 'WithdrawProcessing' ? ActiveGradient : InActiveGradient}
              >
                <Stack spacing={0} align="center">
                  <div>
                    <FilledCircleIcon className={classes.pdfIcon}></FilledCircleIcon>
                    <FilledCircleIcon className={classes.pdfIcon}></FilledCircleIcon>
                    <CircleIcon className={classes.pdfIcon}></CircleIcon>
                  </div>
                  <Text>Processing</Text>
                </Stack>
              </Button>
            </Grid.Col>
            <Grid.Col span={6} md={3}>
              <Button
                onClick={() => filterTransactionsByType('WithdrawCompleted')}
                variant="gradient"
                className={classes.fullHeight}
                radius="lg"
                gradient={activeButton === 'WithdrawCompleted' ? ActiveGradient : InActiveGradient}
              >
                <Stack spacing={0} align="center">
                  <div>
                    <FilledCircleIcon className={classes.pdfIcon}></FilledCircleIcon>
                    <FilledCircleIcon className={classes.pdfIcon}></FilledCircleIcon>
                    <FilledCircleIcon className={classes.pdfIcon}></FilledCircleIcon>
                  </div>
                  <Text>Completed</Text>
                </Stack>
              </Button>
            </Grid.Col>
          </Grid>
        )}

        {!withdrawalsOnly && (
          <Grid>
            <Grid.Col span={6} md={3}>
              <Button
                onClick={() => filterTransactionsByType('All')}
                variant="gradient"
                className={classes.fullHeight}
                radius="lg"
                gradient={activeButton === 'All' ? ActiveGradient : InActiveGradient}
              >
                <Stack spacing={0} align="center">
                  <ListIcon className={classes.pdfIcon}></ListIcon>
                  <Text>ALL</Text>
                </Stack>
              </Button>
            </Grid.Col>
            <Grid.Col span={6} md={3}>
              <Button
                onClick={() => filterTransactionsByType('Deposit')}
                variant="gradient"
                className={classes.fullHeight}
                radius="lg"
                gradient={activeButton === 'Deposit' ? ActiveGradient : InActiveGradient}
              >
                <Stack spacing={0} align="center">
                  <DepositIcon className={classes.pdfIcon}></DepositIcon>
                  <Text>Deposits</Text>
                </Stack>
              </Button>
            </Grid.Col>
            <Grid.Col span={6} md={3}>
              <Button
                onClick={() => filterTransactionsByType('Withdrawals')}
                variant="gradient"
                className={classes.fullHeight}
                radius="lg"
                gradient={activeButton === 'Withdrawals' ? ActiveGradient : InActiveGradient}
              >
                <Stack spacing={0} align="center">
                  <WithdrawalIcon className={classes.pdfIcon}></WithdrawalIcon>
                  <Text>Withdrawals</Text>
                </Stack>
              </Button>
            </Grid.Col>
            <Grid.Col span={6} md={3}>
              <Button
                onClick={() => filterTransactionsByType('Wager')}
                variant="gradient"
                className={classes.fullHeight}
                radius="lg"
                gradient={activeButton === 'Wager' ? ActiveGradient : InActiveGradient}
              >
                <Stack spacing={0} align="center">
                  <WagerIcon className={classes.pdfIcon}></WagerIcon>
                  <Text>Wagers</Text>
                </Stack>
              </Button>
            </Grid.Col>
          </Grid>
        )}
        <Space h="xl"></Space>
        <Box
          sx={{
            flex: '1 1 0px',
            overflow: 'scroll',
            '::-webkit-scrollbar': {
              display: 'none',
            },
            minHeight: '250px',
          }}
        >
          <Timeline bulletSize={mobileTabletView ? 12 : 24} lineWidth={mobileTabletView ? 1 : 2}>
            {filterPlayerHistory?.slice(0, visibleTransactions).map((transactions, index) => {
              return (
                <Timeline.Item key={index} title={transactions.transactionDate} bullet=" ">
                  <table style={{ minWidth: '100%' }}>
                    <tbody>
                      {transactions.items.map((item, index) => {
                        return (
                          <Group grow key={index}>
                            <Group noWrap>
                              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {getTransactionIcon(item)}
                              </Box>
                              <Text>{formatTime(item.timestamp)}</Text>
                            </Group>
                            {isAdmin && (
                              <Text lineClamp={1}>
                                <Anchor
                                  onClick={() => navigate(`/admin/player-overview/player-account/${item.userId}`)}
                                >
                                  {item.firstName &&
                                    item.lastName &&
                                    formatPlayerFullName(item.firstName, item.lastName)}
                                </Anchor>
                              </Text>
                            )}
                            {!mobileTabletView && <Text>{formatType(item.type)}</Text>}
                            {mobileTabletView && item.currencyPair ? (
                              <Text>{item.currencyPair}</Text>
                            ) : (
                              <Text>
                                {item.label.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
                                  return str.toUpperCase();
                                })}
                              </Text>
                            )}
                            <Text align="right">
                              {formatCurrencySymbol(item.currency)} {formatCurrency(item.adjustment)}
                            </Text>
                          </Group>
                        );
                      })}
                    </tbody>
                  </table>
                </Timeline.Item>
              );
            })}
          </Timeline>
          <Space h="xl"></Space>
        </Box>
        <Space h="xl"></Space>
        <Group position="right">
          {filterPlayerHistory.length > visibleTransactions && (
            <Button onClick={handleShowMore} variant="filled" radius="md">
              <Text>Show more history</Text>
            </Button>
          )}
        </Group>
      </Box>
    </Stack>
  );
};

export default UserWallet;
