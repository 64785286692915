import { Anchor, Image, Text, Group, useMantineTheme, Box, Center, Space, Stack } from '@mantine/core';
import Curacao from '../../assets/images/Curacao.png';
import { useStyles } from './styles';
import { useMediaQuery } from '@mantine/hooks';

const LegalFooter = () => {
  const { classes } = useStyles();
  const mobileView = useMediaQuery(`(max-width: ${useMantineTheme().breakpoints.md}px)`);
  const direction = mobileView ? 'column' : 'row';

  return (
    <Group noWrap direction={direction} position="center" sx={{ margin: '16px 0px' }}>
      <Box>
        {mobileView && (
          <>
            <Group grow spacing={0} className={classes.legalLinks}>
              <Anchor sx={{ fontSize: '12px', textAlign: 'center' }} href="/terms-and-conditions" target="_blank">
                Terms of Use{' '}
              </Anchor>
              <Anchor sx={{ fontSize: '12px', textAlign: 'center' }} href="/privacy-policy" target="_blank">
                Privacy Policy
              </Anchor>
              <Anchor sx={{ fontSize: '12px', textAlign: 'center' }} href="/aml-policy" target="_blank">
                AML Policy
              </Anchor>
            </Group>
            <Space h={8}></Space>
            <Group grow spacing={0} className={classes.legalLinks}>
              <Anchor sx={{ fontSize: '12px', textAlign: 'center' }} href="/payments-policy" target="_blank">
                Payments Policy
              </Anchor>
              <Anchor sx={{ fontSize: '12px', textAlign: 'center' }} href="/compliance-and-arbitration" target="_blank">
                Compliance Policy
              </Anchor>
              <Anchor sx={{ fontSize: '12px', textAlign: 'center' }} href="/responsible-gambling" target="_blank">
                Responsible Gaming
              </Anchor>
            </Group>
          </>
        )}
        {!mobileView && (
          <Group direction={direction} mb={8} position="center" className={classes.legalLinks}>
            <Anchor href="/terms-and-conditions" target="_blank">
              Terms of Use{' '}
            </Anchor>
            <Anchor href="/privacy-policy" target="_blank">
              Privacy Policy
            </Anchor>
            <Anchor href="/aml-policy" target="_blank">
              AML Policy
            </Anchor>
            <Anchor href="/payments-policy" target="_blank">
              Payments Policy
            </Anchor>
            <Anchor href="/compliance-and-arbitration" target="_blank">
              Compliance Policy
            </Anchor>{' '}
            {/* Arbitration commented out for now*/}
            <Anchor href="/responsible-gambling" target="_blank">
              Responsible Gaming
            </Anchor>
          </Group>
        )}
        <Space h={16}></Space>
        <Text align="center" size="xs" mb={8}>
          Copyright © FXBet, {new Date().getFullYear()}. FXBet and its logos are trademarks of FXBet Ltd.
        </Text>
        <Center>
          <Text align="center" size="xs" mb={8} style={{ maxWidth: mobileView ? '100%' : '80%' }}>
            FXBet.co is operated by FXBet N.V.,registered in the Commercial Registry of Curaçao under number 157134 and
            address Emancipatie Boulevard Dominico F. “Don” Martina 31, Curaçao. FXBet N.V. is duly licensed through a
            sublicense granted by C.I.L., Curaçao Interactive Licensing N.V., pursuant to Master Gaming License
            #5536/JAZ issued by the Governor of Curaçao.
          </Text>
        </Center>
        <Center>
          <Group mt='md' direction='row'>
            <img src='https://www.gstatic.com/recaptcha/api2/logo_48.png' />
            <Stack spacing={5}>
              <Text align="center" size="xs">
                protected by reCAPTCHA
              </Text>
              <Group direction='row'>
                <Anchor href="https://policies.google.com/privacy?hl=en" target="_blank">
                  <Text size="xs">
                    Privacy
                  </Text>
                </Anchor>
                <Text size="xs">
                  -
                </Text>
                <Anchor href="https://policies.google.com/terms?hl=en" target="_blank">
                  <Text size="xs">
                    Terms
                  </Text>
                </Anchor>
              </Group>
            </Stack>
          </Group>
        </Center>
      </Box>
      <Image width={80} src={Curacao} />
    </Group>
  );
};

export default LegalFooter;
