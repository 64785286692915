import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useStore } from '../state/state';
import { PlayerHub } from '../api/playerhub';
import { HubConnectionState } from '@microsoft/signalr';
import jwt from 'jwt-decode';

export interface Token {
  exp: number;
  iat: number;
  name: string;
  nbf: string;
  role?: 'Sales';
  sub: string;
}

const RequireAuth = ({ children, adminOnly }: { children: JSX.Element; adminOnly?: boolean }) => {
  const isAuthenticated = useStore((state) => state.auth.isAuthenticated);
  const accessToken = useStore((state) => state.auth.token?.accessToken);

  let role: '' | 'Sales' = '';
  if (accessToken) {
    role = jwt<Token>(accessToken).role || '';
  }

  const setHeaderMode = useStore((state) => state.setHeaderMode);
  const hub = useStore((state) => state.hub);

  const location = useLocation();

  if (isAuthenticated) {
    console.log('Require auth rendered, authed');
  } else {
    console.log('Require auth rendered, not authed');
  }

  useEffect(() => {
    if (isAuthenticated) {
      PlayerHub.getInstance();
      setHeaderMode('play');
    }
  }, [isAuthenticated, setHeaderMode]);

  useEffect(() => {
    const tickInterval = window.setInterval(() => {
      if (hub?.state === HubConnectionState.Connected) {
        try {
          hub.send('tick');
        } catch {}
      }
    }, 60000);

    return () => window.clearInterval(tickInterval);
  }, [hub, hub?.state]);

  if (!isAuthenticated) {
    console.log('User not authed');
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (adminOnly && !(role.includes("Sales") || role.includes("Admin"))) {
    return <Navigate to="/play" state={{ from: location }} />;
  }

  return children;
};

export { RequireAuth };
