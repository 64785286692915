import { Footer, Group, Image, MediaQuery, Stack, Text, UnstyledButton } from '@mantine/core';
import activeBets from '../../assets/footer/activebets.svg';
import wallet from '../../assets/footer/wallet.svg';
import logo from '../../assets/fxbet-logo.svg';
import { Link, NavLink } from 'react-router-dom';
import { useStyles } from './styles';
import { AccountMenu } from './AccountMenu';
import game from '../../assets/navbar/games_white.svg';
import active_bets_green from '../../assets/navbar/activebets_green.svg';
import games_green from '../../assets/navbar/games_green.svg';
import wallet_green from '../../assets/navbar/wallet_green.svg';

const CustomFooter = () => {
  const { classes } = useStyles();
  return (
    <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
      <Footer height={60} className={classes.footer} sx={{ zIndex: 300 }}>
        <Stack justify="center" sx={{ height: '100%', padding: '0px 10px' }}>
          <Group grow align="center" position="apart">
            <NavLink to="/play">
              {({ isActive }) => (
                <UnstyledButton>
                  <Stack align="center" spacing={0}>
                    <img src={isActive ? games_green : game} width="24" height="24" alt="" />
                    <Text align="center">Games</Text>
                  </Stack>
                </UnstyledButton>
              )}
            </NavLink>
            <NavLink to="/wallet">
              {({ isActive }) => (
                <UnstyledButton>
                  <Stack align="center" spacing={0}>
                    <img src={isActive ? wallet_green : wallet} width="24" height="24" alt="" />
                    <Text align="center">Wallet</Text>
                  </Stack>
                </UnstyledButton>
              )}
            </NavLink>
            <Link to="/play">
              <Image src={logo} />
            </Link>
            <NavLink to="/active-bets">
              {({ isActive }) => (
                <UnstyledButton>
                  <Stack align="center" spacing={0}>
                    <img src={isActive ? active_bets_green : activeBets} width="24" height="24" alt="" />
                    <Text align="center">Bets</Text>
                  </Stack>
                </UnstyledButton>
              )}
            </NavLink>
            <AccountMenu inFooter={true} />
          </Group>
        </Stack>
      </Footer>
    </MediaQuery>
  );
};

export { CustomFooter };
