import { Group, useMantineTheme } from '@mantine/core';
import { Game, Market, MarketEnabledEnum } from '../../../../api/interfaces';
import { MarketButton } from '../../../../components/game/marketButton';
import { useMediaQuery } from '@mantine/hooks';

const MarketPicker = (props: { game: Game; market: Market; multiview?: boolean | undefined }) => {
  const { multiview } = props;
  const mobileView = useMediaQuery(`(max-width: ${useMantineTheme().breakpoints.sm}px)`);

  return (
    <>
      <Group grow position={mobileView ? 'apart' : 'right'} mb="xs" noWrap mt="xs">
        {multiview &&
          props.game.markets
            .filter((x) => x.id === props.market.id)
            .map((x) => <MarketButton key={x.id} market={x} game={props.game} selected={x === props.market} />)}
        {!multiview &&
          props.game.markets
            .filter((x) => x.enabled === MarketEnabledEnum.Enabled)
            .map((x) => <MarketButton key={x.id} market={x} game={props.game} selected={x === props.market} />)}
      </Group>
    </>
  );
};

export { MarketPicker };
