import { Button, ButtonProps } from '@mantine/core';

export type GreenButtonProps = Omit<ButtonProps<'button'>, 'size' | 'radius'>;

export const GreenButton = (props: GreenButtonProps) => {
  return (
    <Button
      {...props}
      size="lg"
      radius="lg"
      styles={(theme) => {
        let styles = props.styles ?? {};
        if (typeof styles === 'function') {
          styles = styles(theme);
        }
        return {
          ...styles,
          root: {
            height: '50px',
            width: '150px',
            border: 'none',
            background: theme.other.gradientGreen,
            color: `${theme.other.white} !important`,
            padding: '0 14px',
            ':disabled': {
              background: theme.other.gradientGreenOpacity,
              '.mantine-Title-root': {
                color: theme.fn.darken(theme.other.white, 0.2),
              },
            },
            ...(styles.root ?? {}),
          },
        };
      }}
    >
      {props.children}
    </Button>
  );
};
