import { useState, useEffect } from 'react';
import { Group, Input, Select, Container, Text, Stack, Timeline, InputWrapper, Title, Box } from '@mantine/core';
import { HubConnectionState } from '@microsoft/signalr';
import { useStore } from '../../../state/state';
import { LiveWager } from '../../../api/interfaces';
import { useStyles } from './styles';
import { formatCurrencySymbol, formatDurationUnit, formatWagerType } from '../../../utils/formatters';
import { gameList } from '../../../utils/consts';

const LiveBets = () => {
  const { classes } = useStyles();
  const hub = useStore((state) => state.hub);
  const [liveBets, setLiveBets] = useState<LiveWager[]>([]);
  const [filteredLiveBets, setFilteredLiveBets] = useState<LiveWager[]>([]);
  const [newBet, SetNewBet] = useState<LiveWager>();
  const [filters, setFilters] = useState({
    userFirstName: '',
    state: '',
    category: '',
    type: '',
    duration: '',
    game: '',
  });

  const filterData = () => {
    const filteredData = liveBets.filter((item: LiveWager) => {
      let passFilters = true;
      const durationArray = filters.duration.split(' ');
      if (filters.userFirstName && !item.userFirstName.toLowerCase().includes(filters.userFirstName.toLowerCase())) {
        passFilters = false;
      }
      if (filters.category && item.gameCategory !== filters.category) {
        passFilters = false;
      }
      if (filters.type && item.type !== filters.type) {
        passFilters = false;
      }
      if (
        filters.duration &&
        (item.duration.value.toString() !== durationArray[0] || item.duration.unit !== durationArray[1])
      ) {
        passFilters = false;
      }
      if (filters.game && item.gameName !== filters.game) {
        passFilters = false;
      }
      return passFilters;
    });
    setFilteredLiveBets(filteredData);
  };

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSelectChange = (value: string | null, name: string) => {
    if (value != null) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (hub?.state === HubConnectionState.Connected) {
      try {
        hub.send('subscribeAllWagers');
        hub.on('updateAllWagers', (wager: LiveWager) => {
          SetNewBet(wager);
        });
      } catch {
        console.log('error calling hub method');
      }
    }
    return () => {
      if (hub?.state === HubConnectionState.Connected) {
        hub.off('updateAllWagers');
        hub.send('unsubscribeAllWagers');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hub, hub?.state]);

  useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveBets, filters]);

  useEffect(() => {
    if (newBet) {
      if (newBet.closingTimestamp) {
        setLiveBets((prevLiveBets) => prevLiveBets.filter((bet) => bet.wagerId !== newBet.wagerId));
      } else {
        setLiveBets((prevLiveBets) => [...prevLiveBets, newBet]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBet]);

  return (
    <Container sx={{ padding: '20px' }} fluid className={classes.tableContainer}>
      <Stack>
        <Text weight="bold">Live bets currently at play</Text>
        <Group noWrap>
          <InputWrapper label="Player Name">
            <Input
              placeholder="Player First Name"
              name="userFirstName"
              value={filters.userFirstName}
              onChange={handleInputChange}
            />
          </InputWrapper>
          <Select
            label="Arena"
            placeholder="Arena"
            data={[
              { value: '', label: 'All' },
              { value: 'NonStopGame', label: 'NonStopGame' },
              { value: 'BigBills', label: 'BigBills' },
              { value: 'MinerMadness', label: 'MinerMadness' },
              { value: 'SocialFrenzy', label: 'SocialFrenzy' },
            ]}
            name="category"
            value={filters.category}
            onChange={(e) => handleSelectChange(e, 'category')}
          />
          <Select
            label="Game"
            placeholder="Game"
            data={[{ value: '', label: 'All' }, ...gameList.map((game) => ({ value: game, label: game }))]}
            name="game"
            value={filters.game}
            onChange={(e) => handleSelectChange(e, 'game')}
          />
          <Select
            label="Duration"
            placeholder="Duration"
            data={[
              { value: '', label: 'All' },
              { value: '5 Ticks', label: '5 Ticks' },
              { value: '10 Ticks', label: '10 Ticks' },
              { value: '15 Ticks', label: '15 Ticks' },
              { value: '2 Minutes', label: '2 Minutes' },
            ]}
            name="duration"
            value={filters.duration}
            onChange={(e) => handleSelectChange(e, 'duration')}
          />
          <Select
            label="Direction"
            placeholder="Direction"
            data={[
              { value: '', label: 'All' },
              { value: 'Rise', label: 'Up' },
              { value: 'Fall', label: 'Down' },
            ]}
            name="type"
            value={filters.type}
            onChange={(e) => handleSelectChange(e, 'type')}
          />
        </Group>
        {filteredLiveBets && filteredLiveBets.length > 0 && (
          <Timeline bulletSize={24} lineWidth={2}>
            <Timeline.Item title={'Game currently live'}>
              {filteredLiveBets.map((item, index) => {
                return (
                  <Group
                    key={item.openingTimestamp + item.userId}
                    grow
                    sx={{ padding: '4px', backgroundColor: index % 2 === 0 ? '' : '#224962' }}
                  >
                    <Stack spacing={0}>
                      <Text sx={{ fontWeight: 'bold' }}>{item.userFirstName}</Text>
                      <Text sx={{ fontWeight: 'bold' }}>{item.userLastName}</Text>
                    </Stack>
                    <Text>{item.gameCategory}</Text>
                    <Text sx={{ fontWeight: 'bold' }}>{item.gameName}</Text>
                    <Text sx={{ color: '#00cafb' }}>{item.currencyPair}</Text>
                    <Stack spacing={0}>
                      <Text>
                        {item.duration.value} {formatDurationUnit(2, item.duration.unit)}
                      </Text>
                    </Stack>
                    <Text sx={{ fontWeight: 'bold' }}>{formatWagerType(item.type)}</Text>
                    <Text sx={{ fontWeight: 'bold' }}>
                      {formatCurrencySymbol(item.currency)} {item.staked}
                    </Text>
                    <Text style={{ color: '#00cafb' }}>
                      Game is <strong>Live</strong>
                    </Text>
                  </Group>
                );
              })}
            </Timeline.Item>
          </Timeline>
        )}
      </Stack>
      {filteredLiveBets && filteredLiveBets.length === 0 && (
        <Box sx={{ height: 'calc(100% - 100px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Title>There is no data to display for this selection</Title>
        </Box>
      )}
    </Container>
  );
};

export default LiveBets;
