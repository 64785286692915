import { Modal, Stack, Title, Anchor, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { AckPolicyChange, getPolicyChanges } from '../../api/player';
import { GreenButton } from '../buttons/GreenButton';
import { useStore } from '../../state/state';
import { PolicyChange } from '../../api/interfaces';

const PolicyChangeModal = () => {
  const auth = useStore.getState().auth;
  const [modalState, setModalState] = useDisclosure(false);
  const [policyChange, setPolicyChange] = useState<PolicyChange[]>([]);

  const handleModalClose = () => {
    if (modalState && policyChange.length === 0) {
      setModalState.close();
    } else {
      setModalState.open();
    }
  };

  useEffect(() => {
    var policy = undefined;
    if (auth && auth.token) {
      policy = localStorage.getItem('policyChange');
      if (policy) {
        setPolicyChange(JSON.parse(policy));
      }
      else {
        policy = getPolicyChanges().then((result) => {
          if (result) {
            setPolicyChange(result);
            localStorage.setItem('policyChange', JSON.stringify(result));
          }
        });
      }
    }
  }, [auth]);


  useEffect(() => {
    if (!policyChange || policyChange.length === 0) {
      setModalState.close();
    }
    else {
      setModalState.open();
    }
  }, [policyChange]);

  function handleAccept(): void {
    AckPolicyChange();
    localStorage.removeItem('policyChange');
    setModalState.close();
  }

  return (
    <Stack align="center" spacing={32}>
      <Modal size="md" radius="md" centered opened={modalState} onClose={handleModalClose} withCloseButton={false}>
        <Stack align='center'>
          <Title mb="sm" order={2}>Some Policies Have Changed</Title>
          <Group spacing="xs" position='center'>
            {
              policyChange.map((policy, i) => {
                return (
                  <Anchor key={policy.policyName} href={policy.policyLink}>
                    {policy.policyName}
                    {i < policyChange.length - 1 && <>, </>}
                  </Anchor>
                );
              })
            }
          </Group>
          <GreenButton mt="md" style={{ width: 300 }} onClick={handleAccept}>Acknowledged</GreenButton>
        </Stack>
      </Modal>
    </Stack>
  );
};

export default PolicyChangeModal;
