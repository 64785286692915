import { Stack, MediaQuery, Navbar, Text, UnstyledButton, Indicator } from '@mantine/core';
import { useStyles } from './styles';
import { NavLink } from 'react-router-dom';
import active_bets_green from '../../assets/navbar/activebets_green.svg';
import wallet_green from '../../assets/navbar/wallet_green.svg';
import dashboard_green from '../../assets/navbar/dashboard_green.svg';
import dashboard_purle from '../../assets/navbar/dashboard_purple.svg';
import contact_green from '../../assets/navbar/contactus_green.svg';
import active_bets_purple from '../../assets/navbar/activebets_purple.svg';
import wallet_purple from '../../assets/navbar/wallet_purple.svg';
import contact_purple from '../../assets/navbar/contactus_purple.svg';
import games_green from '../../assets/navbar/games_green.svg';
import games_purple from '../../assets/navbar/games_purple.svg';
import activeBets from '../../assets/navbar/activebets.svg';
import dice from '../../assets/navbar/dice.svg';
import dice_green from '../../assets/navbar/dice_green.svg';
import group from '../../assets/navbar/group.svg';
import group_green from '../../assets/navbar/group_green.svg';
import monetization from '../../assets/navbar/monetization.svg';
import monetization_purple from '../../assets/navbar/monetization_purple.svg';
import sound from '../../assets/navbar/sound_button.svg';
import sound_mute from '../../assets/navbar/mute_button.svg';
import help_green from '../../assets/navbar/help_green.svg';
import help_purple from '../../assets/navbar/help_purple.svg';
import multi_play_green from '../../assets/navbar/multi-play_green.svg';
import multi_play_purple from '../../assets/navbar/multi-play_purple.svg';

import { useStore } from '../../state/state';
import { getPendingWithdrawals } from '../../api/backoffice';
import { useEffect, useState } from 'react';

const CustomNavbar = () => {
  const headerMode = useStore((state) => state.headerMode);
  const { classes } = useStyles();
  const audioEnabled = useStore((state) => state.audioEnabled);
  const toggleAudio = useStore((state) => state.toggleAudioEnabled);
  const state = useStore((state) => state.auth);
  const pendingWithdrawals = useStore((state) => state.pendingWithdrawals);
  const [indicator, setIndicator] = useState(0);

  useEffect(() => {
    state.isAuthenticated &&
      state?.user?.role?.includes('Sales') &&
      getPendingWithdrawals().then((res) => {
        if (res !== undefined) {
          setIndicator(res.length);
        }
      });
    setIndicator(pendingWithdrawals.length);
  }, [state?.user?.role, pendingWithdrawals.length]);

  if (state.isAuthenticated && headerMode === 'play') {
    return (
      <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
        <Navbar className={classes.navbar} width={{ base: 80 }}>
          <Navbar.Section grow>
            {state?.user?.role === '' && (
              <Stack align="center">
                <NavLink to="play">
                  {({ isActive }) => (
                    <UnstyledButton className={classes.linkHover}>
                      <Stack align="center" spacing={0}>
                        <img src={isActive ? games_green : games_purple} className={classes.navIcons} alt="" />
                        <Text align="center">Games</Text>
                      </Stack>
                    </UnstyledButton>
                  )}
                </NavLink>
                <NavLink to="user-dashboard">
                  {({ isActive }) => (
                    <UnstyledButton className={classes.linkHover}>
                      <Stack align="center" spacing={0}>
                        <img
                          src={isActive ? multi_play_green : multi_play_purple}
                          className={classes.navIcons}
                          alt=""
                        />
                        <Text align="center">
                          Multi
                          <br />
                          Play
                        </Text>
                      </Stack>
                    </UnstyledButton>
                  )}
                </NavLink>
                <NavLink to="active-bets">
                  {({ isActive }) => (
                    <UnstyledButton className={classes.linkHover}>
                      <Stack align="center" spacing={0}>
                        <img
                          src={isActive ? active_bets_green : active_bets_purple}
                          className={classes.navIcons}
                          alt=""
                        />
                        <Text align="center">Active Bets</Text>
                      </Stack>
                    </UnstyledButton>
                  )}
                </NavLink>
                <NavLink to="wallet">
                  {({ isActive }) => (
                    <UnstyledButton className={classes.linkHover}>
                      <Stack align="center" spacing={0}>
                        <img src={isActive ? wallet_green : wallet_purple} className={classes.navIcons} alt="" />
                        <Text align="center">Wallet</Text>
                      </Stack>
                    </UnstyledButton>
                  )}
                </NavLink>
                <NavLink to="help">
                  {({ isActive }) => (
                    <UnstyledButton className={classes.linkHover}>
                      <Stack align="center" spacing={0}>
                        <img src={isActive ? help_green : help_purple} className={classes.navIcons} alt="" />
                        <Text align="center">Help</Text>
                      </Stack>
                    </UnstyledButton>
                  )}
                </NavLink>
                <NavLink to="contact-us">
                  {({ isActive }) => (
                    <UnstyledButton className={classes.linkHover}>
                      <Stack align="center" spacing={0}>
                        <img src={isActive ? contact_green : contact_purple} className={classes.navIcons} alt="" />
                        <Text align="center">Contact</Text>
                      </Stack>
                    </UnstyledButton>
                  )}
                </NavLink>
              </Stack>
            )}
            {(state?.user?.role === 'Sales' || state.user?.role === "SuperAdmin") && (
              <Stack align="center">
                <NavLink to="/admin/dashboard">
                  {({ isActive }) => (
                    <UnstyledButton className={classes.linkHover}>
                      <Stack align="center" spacing={0}>
                        <img
                          src={isActive ? dashboard_green : dashboard_purle}
                          width="40"
                          height="40"
                          alt="active-bets-link"
                        />
                        <Text align="center">Overview</Text>
                      </Stack>
                    </UnstyledButton>
                  )}
                </NavLink>
                <NavLink to="/admin/wager-activity">
                  {({ isActive }) => (
                    <UnstyledButton className={classes.linkHover}>
                      <Stack align="center" spacing={0}>
                        <img
                          src={isActive ? active_bets_green : activeBets}
                          width="40"
                          height="40"
                          alt="active-bets-link"
                        />
                        <Text align="center">Wager Activity</Text>
                      </Stack>
                    </UnstyledButton>
                  )}
                </NavLink>
                {state?.user?.role === 'SuperAdmin' && (
                  <NavLink to="/admin/game-controls">
                    {({ isActive }) => (
                      <UnstyledButton className={classes.linkHover}>
                        <Stack align="center" spacing={0}>
                          <img src={isActive ? dice_green : dice} width="40" height="40" alt="game-controls-link" />
                          <Text align="center">Game Controls</Text>
                        </Stack>
                      </UnstyledButton>
                    )}
                  </NavLink>
                )}
                <NavLink to="/admin/player-overview">
                  {({ isActive }) => (
                    <UnstyledButton className={classes.linkHover}>
                      <Stack align="center" spacing={0}>
                        <img src={isActive ? group_green : group} width="40" height="40" alt="player-account-link" />
                        <Text align="center">Player Accounts</Text>
                      </Stack>
                    </UnstyledButton>
                  )}
                </NavLink>
                <NavLink to="/admin/wallet-finance">
                  {({ isActive }) => (
                    <UnstyledButton className={classes.linkHover}>
                      <Stack align="center" spacing={0}>
                        <Indicator disabled={indicator <= 0} inline label={indicator} size={16}>
                          <img
                            src={isActive ? monetization : monetization_purple}
                            width="40"
                            height="40"
                            alt="wallet-and-finance-link"
                          />
                        </Indicator>
                        <Text align="center">Wallet + Finance</Text>
                      </Stack>
                    </UnstyledButton>
                  )}
                </NavLink>
              </Stack>
            )}
          </Navbar.Section>
          <Navbar.Section>
            <Stack align="center">
              <UnstyledButton className={classes.linkHover} onClick={toggleAudio}>
                <Stack align="center" spacing={0}>
                  <img src={audioEnabled ? sound : sound_mute} className={classes.navIcons} alt="" />
                </Stack>
              </UnstyledButton>
            </Stack>
          </Navbar.Section>
        </Navbar>
      </MediaQuery>
    );
  } else {
    return <></>;
  }
};

export { CustomNavbar };
