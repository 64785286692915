import { Box, Checkbox, Global, Group, Stack, useMantineTheme, Text } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import slugify from 'slugify';
import { useMarketStore, useStore } from '../../../state/state';
import { GameTitle } from './title';
import { TickChart } from './tick-chart/tickChart';
import { useEffect, useRef } from 'react';
import { PreGraph } from './preGraph';
import { DurationOrPosition } from './durationOrPosition';
import { CurrencyPair, Game, Market, Multiview, WagerStateEnum } from '../../../api/interfaces';
import { PlaceWagerButton } from './placeWager/placeWagerButton';
import { WagerCompleted } from './placeWager/wagerCompleted';
import winnerBackground from '../../../assets/backgrounds/winner-wallpaper.jpg';
import loserBackground from '../../../assets/backgrounds/loser-wallpaper.jpg';
import { useMediaQuery } from '@mantine/hooks';
import { SoundManager } from '../../../utils/SoundManager';
import { BatchRenderingProvider } from '../../../components/batch-render/BatchRenderingContext';
import { ToggleFavoriteMarket } from '../../../api/player';
import { WagerTimeRemaining } from './watchWager/wagerTimeRemaining';
import { WagerTypeAndAmount } from './placeWager/wagerTypeAndAmount';
import { ReactComponent as CloseCircle } from '../../../assets/icons/close_white.svg';

export const PlayRouter = (props: { name?: string; currencyPair?: CurrencyPair; multiview?: Multiview }) => {
  const params = useParams<{ gameName: string; marketName: string }>();
  const { multiview } = props;
  const navigate = useNavigate();

  const soundManager = SoundManager.getInstance();

  useEffect(() => () => {
    soundManager.stopSound('gameMusic');
    soundManager.stopLoopSound('gameMusic');
  });

  useEffect(() => {
    soundManager.loopSound('gameMusic');
  });

  const games = useStore((state) => state.games);

  if (games.length === 0) {
    return null; //game list is still loading, pause
  }

  const game = games.find(
    (x) => slugify(x.name, { lower: true }) === (props.name ? slugify(props.name, { lower: true }) : params.gameName)
  );
  const market = game?.markets.find(
    (x) =>
      slugify(x.currencyPair, { lower: true }) ===
      (props.currencyPair ? slugify(props.currencyPair, { lower: true }) : params.marketName)
  );

  if (!game || !market) {
    navigate('/play');
    return null;
  }

  return <Play game={game} market={market} multiview={multiview} />;
};

export const Play = (props: { game: Game; market: Market; multiview?: Multiview }) => {
  const hub = useStore((state) => state.hub);
  const wager = useMarketStore(props.market)((state) => state.wager);
  const soundManager = SoundManager.getInstance();
  const { multiview } = props;
  const completeRef = useRef<HTMLDivElement>(null);

  const scrollToComplete = () => {
    if (completeRef.current) {
      completeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  useEffect(() => {
    if (
      wager?.state === WagerStateEnum.Won ||
      wager?.state === WagerStateEnum.Lost ||
      wager?.state === WagerStateEnum.Draw ||
      wager?.state === WagerStateEnum.Cancelled
    ) {
      scrollToComplete();
    }
    if (wager?.state === WagerStateEnum.Won) {
      soundManager.playSound('winner');
    }
  }, [soundManager, wager?.state]);

  useEffect(() => {
    props.game.markets.forEach((x) => {
      hub?.send('subscribe', x.id);
    });

    return () => {
      props.game.markets.forEach((x) => {
        hub?.send('unsubscribe', x.id);
      });
    };
  }, [props.game, hub]);

  const backgroundImage = () => {
    if (wager?.state === WagerStateEnum.Won) {
      return `url(${winnerBackground})`;
    } else if (wager?.state === WagerStateEnum.Lost) {
      return `url(${loserBackground})`;
    } else {
      return props.game.imageBackground ? `url(${props.game.imageBackground})` : 'inherit';
    }
  };

  return (
    <Box sx={{ height: '100%' }}>
      {!multiview && (
        <Global
          styles={(theme) => ({
            body: {
              backgroundImage: backgroundImage(),
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            },
            '#root': {
              //override the root background image to remove chart whilst playing a game, so that game background can be shown beneath gradient
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(3, 167, 245, 0.8) 53%, rgba(11, 94, 171, 0.8) 100%)`,
            },
          })}
        ></Global>
      )}
      <PlaceWager game={props.game} market={props.market} completeRef={completeRef} multiview={multiview} />
    </Box>
  );
};

const PlaceWager = (props: {
  game: Game;
  market: Market;
  completeRef: React.RefObject<HTMLDivElement>;
  multiview?: Multiview;
}) => {
  const { game, market, multiview } = props;
  const wager = useMarketStore(market)((state) => state.wager);

  const mobileView = useMediaQuery(`(max-width: ${useMantineTheme().breakpoints.xs}px)`);
  const ticks = useMarketStore(props.market)((state) => state.ticks);
  const chartIsLoading = +new Date() - ticks[ticks.length - 1]?.localTimestamp > 5000;
  const leftEdgeTime = +new Date().getTime() - 45000;
  const favoriteGames = useStore((state) => state.favoriteGames);

  const flexDirection = (multiview: Multiview | undefined) => {
    if (multiview?.layout === '3' || multiview?.layout === '4' || mobileView) {
      return 'column';
    } else {
      return 'row';
    }
  };

  const isCompletedWager = () =>
    wager?.state === WagerStateEnum.Won ||
    wager?.state === WagerStateEnum.Lost ||
    wager?.state === WagerStateEnum.Draw ||
    wager?.state === WagerStateEnum.Cancelled;

  const RecentTicksCount = ticks.filter((x) => x.timestamp >= leftEdgeTime).length;

  function handleFavoriteClick() {
    ToggleFavoriteMarket(market.id);
  }

  return (
    <Box
      sx={{
        borderRadius: '16px',
        background: multiview?.multiview ? 'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,90,146,1) 100%)' : '',
        height: multiview?.multiview ? 'auto' : '100%',
        padding: '24px',
      }}
    >
      {multiview && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
          <Text onClick={handleFavoriteClick} sx={{ cursor: 'pointer' }}>
            <CloseCircle height={16} width={16} />
          </Text>
        </Box>
      )}
      <Group
        direction={flexDirection(multiview)}
        noWrap
        sx={{
          height: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Stack
          spacing={0}
          sx={{
            height: '100%',
            width: multiview?.layout === '2' ? '50%' : '100%',
          }}
        >
          <PreGraph game={game} market={market} wager={wager} multiview={multiview?.multiview} />
          <BatchRenderingProvider>
            <TickChart game={game} market={market} wager={wager} />
          </BatchRenderingProvider>
          <DurationOrPosition game={game} market={market} wager={wager} />
        </Stack>
        <Stack spacing={16} sx={{ height: '100%', width: multiview?.layout === '2' ? '50%' : '100%' }}>
          <GameTitle game={game} hasWager={!!wager} />
          {wager ? (
            <WagerTimeRemaining game={props.game} market={props.market} wager={wager} />
          ) : (
            <>
              <WagerTypeAndAmount game={props.game} market={props.market} />
              <Group grow position="center" noWrap>
                <PlaceWagerButton game={game} market={market} chartLoading={RecentTicksCount < 2 || chartIsLoading} />
                {!multiview?.multiview && (
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Checkbox
                      size="xl"
                      label="Add to Multi Play"
                      checked={favoriteGames.some((game) => game.currencyPair === market.currencyPair)}
                      onClick={() => handleFavoriteClick()}
                    ></Checkbox>
                  </Box>
                )}
              </Group>
            </>
          )}
          {wager?.state && isCompletedWager() && (
            <>
              <WagerCompleted game={game} market={market} wagerState={wager?.state} wager={wager} />
              <PlaceWagerButton game={game} market={market} chartLoading={RecentTicksCount < 2 || chartIsLoading} />
            </>
          )}
        </Stack>
      </Group>
    </Box>
  );
};
