import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Title, TextInput, Container, Text, Stack, Group, Box, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';

import { setInitialPassword } from '../../api/player';
import { GreenButton } from '../../components/buttons/GreenButton';

// User arrives at this page through an email link.
export const ConfirmEmail = () => {
  const [submitting, setSubmitting] = useState(false);

  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: (values) => {
      const errors = { password: '', confirmPassword: '' };
      if (!isPasswordValid(values.password)) {
        errors.password = 'WEAK PASSWORD';
      }

      if (values.confirmPassword !== values.password) {
        errors.confirmPassword = 'Passwords do not match';
      }

      return errors;
    },
  });

  useEffect(() => {
    form.validate(); // This was one needed aspect to correctly perform on change validation using old useForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values]);

  const fieldIsDirty = (fieldName: 'password' | 'confirmPassword') => () => form.values[fieldName].length > 0;
  const isPasswordDirty = fieldIsDirty('password');
  const isConfirmPasswordDirty = fieldIsDirty('confirmPassword');
  const passwordIsInvalid = isPasswordDirty() && !isPasswordValid(form.values.password);
  const passwordMatchesConfirm = isConfirmPasswordDirty() && form.values.password === form.values.confirmPassword;

  const formIsValid = useMemo(() => {
    return !passwordIsInvalid && passwordMatchesConfirm;
  }, [passwordIsInvalid, passwordMatchesConfirm]);

  function isPasswordValid(password: string) {
    const hasDigits = /[0-9]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasSymbols = /[!@#$%^&*()_+\-=[\]{}|;':",./<>?]/.test(password);
    const isLengthValid = password.length >= 9;

    return hasDigits && hasUppercase && hasSymbols && isLengthValid;
  }

  function handlePasswordChange(fieldName: 'password' | 'confirmPassword', event: React.ChangeEvent<HTMLInputElement>) {
    form.setFieldValue(fieldName, event.target.value);
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (submitting || !formIsValid) {
      return;
    }
    setSubmitting(true);
    (async () => {
      await setInitialPassword(searchParams.get('user') ?? '', searchParams.get('token') ?? '', form.values.password);
      navigate('/wallet/deposit', { state: 'account-created' });
    })();
  }

  return (
    <Container size={414} pl={7} pr={7}>
      <form onSubmit={handleSubmit}>
        <Stack align="center" spacing={4}>
          <Title order={2} align="center" mb={24}>
            Email verified!
          </Title>
          <Title order={1} align="center" mb={24}>
            Create a password
            <br />
            for your new account...
          </Title>

          <Box sx={{ width: 350 }}>
            <Text size="xs" align="right" sx={{ color: theme.other.orange }}>
              {passwordIsInvalid ? <span>WEAK PASSWORD</span> : <span>&nbsp;</span>}
            </Text>
          </Box>
          <TextInput
            radius="md"
            size="lg"
            value={form.values.password}
            onChange={(e) => handlePasswordChange('password', e)}
            type="password"
            sx={{ width: '350px' }}
          />
          <Box sx={{ minHeight: 58, width: 350 }}>
            {isPasswordDirty() && passwordIsInvalid ? (
              <Text size="xs" align="right" sx={{ color: theme.other.orange }}>
                Please create a new password
                <br />
                according to the description below
              </Text>
            ) : null}
          </Box>

          <Group position="apart" align="flex-end" sx={{ width: 350 }}>
            <Title order={3}>Repeat password</Title>
            <Text size="xs" weight="bold">
              {!isPasswordDirty() || !isConfirmPasswordDirty()
                ? 'MUST MATCH'
                : !passwordMatchesConfirm && isConfirmPasswordDirty()
                ? 'PASSWORDS DONT MATCH'
                : isConfirmPasswordDirty() && passwordMatchesConfirm
                ? 'PERFECT MATCH'
                : null}
            </Text>
          </Group>
          <TextInput
            radius="md"
            size="lg"
            value={form.values.confirmPassword}
            onChange={(e) => handlePasswordChange('confirmPassword', e)}
            label=""
            type="password"
            sx={{ width: '350px' }}
          />

          <Text size="xs" align="center" mb={8} sx={{ width: 300 }}>
            Create a password that you will remember. Your password should be 9 or more characters long, contain at
            least one uppercase and lowercase letter, a number and a symbol.
          </Text>
          <GreenButton type="submit" disabled={submitting || !formIsValid} sx={{ alignSelf: 'center' }}>
            {submitting ? 'Getting Ready' : 'Start Gaming'}
          </GreenButton>
        </Stack>
      </form>
    </Container>
  );
};
