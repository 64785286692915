import {
  Grid,
  Title,
  Stack,
  TextInput,
  Text,
  Divider,
  Select,
  useMantineTheme,
  Group,
  Box,
  Modal,
  Space,
  Anchor,
  Container,
  Table,
  Paper,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { GreenButton } from '../../components/buttons/GreenButton';
import { PurpleButton } from '../../components/buttons/PurpleButton';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useRef, useState } from 'react';
import { updatePlayer, closeAccount, UpdatePlayerPicture, getPlayerProfilePicture, DeletePlayerProfilePicture } from '../../api/player';
import { useStore } from '../../state/state';
import { Gender, Player } from '../../api/interfaces';
import { getPlayerProfile } from '../../api/backoffice';
import { signOut } from '../../api/auth';
import { verficiationDocumentation } from '../../utils/emailTemplates';
import { isoToPhoneCode } from '../../utils/consts';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { DropzoneStatus } from '@mantine/dropzone/lib/Dropzone';
import placeholder from '../../assets/icons/ProfilePhotoPlaceholder.svg';

const Account = ({ isAdmin, id }: { isAdmin?: boolean; id?: string }) => {
  const initialPlayerInfo = useStore((state) => state.player);
  const initialPlayerImage = useStore((state) => state.playerProfilePicture);
  const [playerInfo, setPlayerInfo] = useState<Player>();
  const [disabled, setDisabled] = useState(true);
  const [opened, { open, close }] = useDisclosure(false);
  const [image, setImage] = useState<String | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const isReadonly = !isAdmin;

  useEffect(() => {
    if (!isAdmin && initialPlayerInfo) {
      setPlayerInfo(initialPlayerInfo);
      if (initialPlayerImage) {
        setImage(initialPlayerImage);
      } else {
        getProfilePicture();
      }
    } else if (isAdmin && id) {
      getPlayerInfo();
    }
  }, [initialPlayerInfo]);

  const getProfilePicture = async () => {
    await getPlayerProfilePicture().then((res) => {
      if (res) {
        setImage(res);
      }
    })
  };

  const getPlayerInfo = async () => {
    if (!id) {
      return;
    }
    const player = await getPlayerProfile(id);
    if (player) {
      setPlayerInfo(player);
    }
  };

  const documentsIsVerified = false;

  const mobileView = useMediaQuery(`(max-width: ${useMantineTheme().breakpoints.xs}px)`);

  const saveChanges = () => {
    if (!playerInfo || disabled) {
      return;
    }

    updatePlayer({
      gender: playerInfo.gender,
      language: playerInfo.language,
    }).then(() => {
      if (imageFile) {
        UpdatePlayerPicture(imageFile);
      }
      setPlayerInfo({ ...playerInfo });
      useStore.getState().setPlayer({ ...playerInfo });
      setDisabled(true);
    });
  };

  const updatePicture = async (image: File) => {
    await UpdatePlayerPicture(image);
  };

  const deletePicture = async () => {
    if (initialPlayerImage) {
      await DeletePlayerProfilePicture();
    }
    setImage(null);
  }

  const handleGenderChange = (gender: Gender | null) => {
    if (playerInfo && gender) {
      setPlayerInfo({ ...playerInfo, gender });
      setDisabled(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedImage: File | undefined = e.dataTransfer.files[0];

    if (droppedImage) {
      setImageFile(droppedImage);

      const reader = new FileReader();
      reader.readAsDataURL(droppedImage);

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target?.result) {
          setImage(event.target.result.toString());
        }
      };
      updatePicture(droppedImage);
    }
  };

  const handleFileInputChange = (e: File[]) => {
    const selectedImage: File | null = e && e[0];

    if (selectedImage) {
      setImageFile(selectedImage);

      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target?.result) {
          setImage(event.target.result.toString());
        }
      };
      updatePicture(selectedImage);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleLanguageChange = (language: string | null) => {
    if (playerInfo && language) {
      setPlayerInfo({ ...playerInfo, language });
      setDisabled(false);
    }
  };

  const removeAccount = async () => {
    const result = await closeAccount();
    if (result?.success) {
      signOut();
    }
  };

  function dropzoneChildren(status: DropzoneStatus, theme: any) {
    return <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>

      <div>
        <Text size="xl" inline>
          Drag images here or click to select files
        </Text>
        <Text size="sm" color="dimmed" inline mt={7}>
          Attach as many files as you like, each file should not exceed 3mb
        </Text>
      </div>
    </Group>
  }

  const theme = useMantineTheme();

  return (
    <Container fluid>
      <Title order={1} align="center" mb={32}>
        {isAdmin ? 'Account Details' : 'Your Account Details'}
      </Title>
      {playerInfo && (
        <Grid gutter={!mobileView ? 64 : 16}>
          <Grid.Col mb="md" sx={{ display: 'grid' }} xs={12} sm={6}>
            <Paper radius={"lg"} sx={{ height: "100%" }} p="md" >
              <Stack>
                <Title order={2} align="center">
                  Account Details
                </Title>
                <Divider size={2} />
              </Stack>
              <Group grow sx={{ justifyContent: 'center' }}>
                <Stack mt="sm" align="center">
                  <Box
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    sx={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <Box
                      sx={{
                        ...{
                          width: '150px',
                          height: '150px',
                          borderRadius: '50%',
                          border: '3px solid white',
                          backgroundImage: `url(${image ? image : placeholder})`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          backgroundPositionY: image ? '0' : '5px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        },
                      }} />
                  </Box>
                  <Dropzone
                    accept={IMAGE_MIME_TYPE}
                    style={{ display: 'none' }}
                    onDrop={handleFileInputChange}
                    ref={fileInputRef}
                    maxSize={3 * 1024 ** 2}>
                    {(status) => dropzoneChildren(status, theme)}
                  </Dropzone>
                  {!image && (
                    <Anchor>
                      <Title onClick={() => fileInputRef.current?.click()} order={3}>
                        Upload
                      </Title>
                    </Anchor>
                  )}
                  {image && image ? (
                    <Group direction='row'>
                      <Anchor>
                        <Title onClick={() => fileInputRef.current?.click()} order={3}>
                          Update
                        </Title>
                      </Anchor>
                      |
                      <Anchor>
                        <Title onClick={deletePicture} order={3}>
                          Remove
                        </Title>
                      </Anchor>
                    </Group>
                  ) : null}
                </Stack>
                <Table
                  sx={{
                    maxWidth: mobileView ? '100%' : '',
                    'tbody tr td': {
                      borderBottom: 'none',
                    },
                  }}
                  mt="xs"
                  mb="xs"
                >
                  <tbody>
                    <tr>
                      <td>
                        <Title order={3}>Verification:</Title>
                      </td>
                      <td>
                        <Text>{playerInfo.isVerified ? 'Verified' : 'Unverified'}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Title order={3}>Games Played: </Title>
                      </td>
                      <td>
                        <Text>{playerInfo.quickStats.totalPlayed}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Title order={3}>Wins:</Title>
                      </td>
                      <td>
                        <Text>{playerInfo.quickStats.wins}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Title order={3}>Losses:</Title>
                      </td>
                      <td>
                        <Text>{playerInfo.quickStats.losses}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Title order={3}>Draws:</Title>
                      </td>
                      <td>
                        <Text>{playerInfo.quickStats.draws}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Title order={3}>Favorite Game:</Title>
                      </td>
                      <td>
                        <Text>{playerInfo.quickStats.favoriteGame}</Text>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Group>
              <Divider size={2} />
              <Stack mt="md">
                <Title order={2} align="center">
                  Verified Documents
                </Title>
                {documentsIsVerified ? (
                  <Stack>
                    <Text>
                      Thank you for providing your documents for verification.
                      <br />
                      You are up to date, and may Initiate a SEPA standard bank transfer from FXBet to any IBAN bank
                      account in participating EU and EFTA countries, or update your documentation using the below
                      buttons.
                    </Text>
                    <Group grow>
                      <Stack spacing={0}>
                        <Title order={3}>Identity document</Title>
                        <Text>Your ID document has successfully been verified</Text>
                      </Stack>
                      <TextInput radius="md" size="lg" readOnly value="VERIFIED" />
                    </Group>
                    <Group grow>
                      <Stack spacing={0}>
                        <Title order={3}>Mobile number</Title>
                        <Text>Verified phone number linked to your account</Text>
                      </Stack>
                      <TextInput radius="md" size="lg" readOnly value="+00 123 456 789" />
                    </Group>
                    <Group grow>
                      <Stack spacing={0}>
                        <Title order={3}>Country of residence</Title>
                      </Stack>
                      <TextInput radius="md" size="lg" readOnly value={playerInfo.country} />
                    </Group>
                    <Group grow>
                      <Stack spacing={0}>
                        <Title order={3}>Building, house or suite number</Title>
                      </Stack>
                      <TextInput radius="md" size="lg" readOnly value="Suit 24" />
                    </Group>
                    <Group grow>
                      <Stack spacing={0}>
                        <Title order={3}>Street address</Title>
                      </Stack>
                      <TextInput radius="md" size="lg" readOnly value="Nodrama Road" />
                    </Group>
                    <Group grow>
                      <Stack spacing={0}>
                        <Title order={3}>Town or city</Title>
                      </Stack>
                      <TextInput radius="md" size="lg" readOnly value="Woolville" />
                    </Group>
                    <Group grow>
                      <Stack spacing={0}>
                        <Title order={3}>Postal or zip code</Title>
                      </Stack>
                      <TextInput radius="md" size="lg" readOnly value="12345" />
                    </Group>
                    {mobileView && <Divider size={3} sx={{ borderColor: '#35BEFA' }} />}
                  </Stack>
                ) : (
                  <Stack align="center">
                    <Text>
                      As part of regulatory and licensing requirements, we need to first confirm your identity before you
                      can withdraw funds from FXBet.
                    </Text>
                    <Text>
                      We ensure verifying your ID, address and contact details is super-fast and secure... its easy, let's
                      start!
                    </Text>
                    <GreenButton
                      styles={{
                        root: { minWidth: 'fit-content' },
                      }}
                    >
                      <Anchor
                        underline={false}
                        href={verficiationDocumentation(
                          initialPlayerInfo?.firstName,
                          initialPlayerInfo?.lastName,
                          initialPlayerInfo?.email
                        )}
                      >
                        <Text>Start Document Verification</Text>
                      </Anchor>
                    </GreenButton>
                  </Stack>
                )}
              </Stack>
              <Divider size={2} mt={16}></Divider>
              {!mobileView && (
                <Stack mt="md" align="center">
                  <Title order={2} align="center">
                    Deactivate
                  </Title>
                  <Text>
                    Warning: - By clicking the below you will deactivate your account, and will not be able to retrieve
                    it.{' '}
                  </Text>
                  <GreenButton
                    onClick={open}
                    styles={{
                      root: { minWidth: 'fit-content' },
                    }}
                  >
                    Deactivate Account
                  </GreenButton>
                </Stack>
              )}
            </Paper>
          </Grid.Col>
          <Grid.Col mb="md" sx={{ display: 'grid' }} xs={12} sm={6}>
            <Paper radius={"lg"} sx={{ height: "100%" }} p="md">
              <Stack>
                <Title order={2} align="center">
                  Profile Information
                </Title>
                {!mobileView && <Divider size={2} />}
              </Stack>
              <Stack mt="sm" justify="space-between">
                <Group direction={mobileView ? 'column' : 'row'} grow>
                  <Stack spacing={0}>
                    <Title order={3}>Full name</Title>
                    <Text>First and last name as it appears on your ID.</Text>
                  </Stack>
                  <TextInput
                    radius="md"
                    size="lg"
                    readOnly={isReadonly}
                    value={`${playerInfo.firstName} ${playerInfo.lastName}`}
                  />
                </Group>
                <Group direction={mobileView ? 'column' : 'row'} grow>
                  <Stack spacing={0}>
                    <Title order={3}>Email address</Title>
                    <Text>Verified email address linked to your account.</Text>
                  </Stack>
                  <TextInput radius="md" size="lg" readOnly={isReadonly} value={playerInfo.email} />
                </Group>
                <Group direction={mobileView ? 'column' : 'row'} grow>
                  <Stack spacing={0}>
                    <Title order={3}>Phone number</Title>
                    <Text>Number entered when you created this account.</Text>
                  </Stack>
                  <Group noWrap>
                    <TextInput
                      radius="md"
                      size="lg"
                      readOnly={isReadonly}
                      value={isoToPhoneCode[playerInfo.country.toUpperCase()]}
                    />
                    <TextInput radius="md" size="lg" readOnly={isReadonly} value={playerInfo.phoneNumber} />
                  </Group>
                </Group>
                <Group direction={mobileView ? 'column' : 'row'} grow>
                  <Stack spacing={0}>
                    <Title order={3}>Country</Title>
                    <Text>Country entered when you created this account.</Text>
                  </Stack>
                  <TextInput radius="md" size="lg" readOnly={isReadonly} value={playerInfo.country} />
                </Group>
                <Group direction={mobileView ? 'column' : 'row'} grow>
                  <Stack spacing={0}>
                    <Title order={3}>Date of birth</Title>
                    <Text>Birth date entered when you created this account.</Text>
                  </Stack>
                  <TextInput radius="md" size="lg" readOnly={isReadonly} value={playerInfo.birthDate} />
                </Group>
                <Group direction={mobileView ? 'column' : 'row'} grow>
                  <Stack spacing={0}>
                    <Title order={3}>Gender (optional)</Title>
                    <Text>Information that helps us customise your experience.</Text>
                  </Stack>
                  <Select
                    radius="md"
                    size="lg"
                    value={playerInfo.gender}
                    onChange={(e: Gender | null) => handleGenderChange(e)}
                    data={[
                      { value: 'Undisclosed', label: 'Prefer not to say' },
                      { value: 'Male', label: 'Male' },
                      { value: 'Female', label: 'Female' },
                    ]}
                  />
                </Group>
                <Group direction={mobileView ? 'column' : 'row'} grow>
                  <Stack spacing={0}>
                    <Title order={3}>Language (optional)</Title>
                    <Text>What is your preferred language to communicate?</Text>
                  </Stack>
                  <Select
                    value={playerInfo.language}
                    onChange={(e: string | null) => handleLanguageChange(e)}
                    radius="md"
                    readOnly
                    size="lg"
                    data={[
                      { value: 'EN', label: 'English' },
                      { value: 'FIN', label: 'Finnish' },
                      { value: 'SWE', label: 'Swedish' },
                      { value: 'DAN', label: 'Danish' },
                    ]}
                  />
                </Group>
                <GreenButton
                  style={{ width: 'fit-content', alignSelf: 'center', marginTop: '32px' }}
                  onClick={saveChanges}
                  disabled={disabled}
                >
                  <Title order={3}>Save all Changes</Title>
                </GreenButton>
              </Stack>
            </Paper>
          </Grid.Col>
          {mobileView && (
            <Stack mt="md" align="center">
              <Title order={2} align="center">
                Deactivate
              </Title>
              <Text>
                Warning: - By clicking the below you will deactivate your account, and will not be able to retrieve it.{' '}
              </Text>
              <GreenButton
                onClick={open}
                styles={{
                  root: { minWidth: 'fit-content' },
                }}
              >
                Deactivate Account
              </GreenButton>
            </Stack>
          )}
          {mobileView && <Divider mt="sm" size={2} />}
        </Grid>
      )}
      <Modal
        size="lg"
        opened={opened}
        onClose={close}
        centered={true}
        padding={32}
        radius={10}
        withCloseButton={false}
        styles={(theme) => ({ modal: { background: theme.other.gradientBlueSolid } })}
      >
        <Stack align="center" spacing={16}>
          <Title order={2}>Remove your FXBet account</Title>
          <Divider size={2}></Divider>
          <Title order={3} sx={{ textAlign: 'center', color: 'red' }}>
            Warning!
          </Title>
          <Text sx={{ textAlign: 'center' }}>
            Continuing will permanently remove your FXBet account. It can NOT be recovered.
          </Text>
          <Text sx={{ textAlign: 'center' }}>
            Please withdraw ALL funds BEFORE removing your account. Funds left in your account will be forfeited and can
            NOT be recovered.
          </Text>
          <Space></Space>
          <Group grow noWrap={mobileView ? false : true} align="center">
            <PurpleButton
              onClick={close}
              styles={{
                root: { minWidth: '210px', margin: mobileView ? 'auto' : '' },
              }}
            >
              <Stack spacing={0}>
                <Title order={3}>Cancel</Title>
                <Text>Do not remove account</Text>
              </Stack>
            </PurpleButton>
            <GreenButton
              onClick={removeAccount}
              styles={{
                root: { minWidth: '210px', margin: mobileView ? 'auto' : '' },
              }}
            >
              <Stack spacing={0}>
                <Title order={3}>Remove Account</Title>
                <Text>Remove forever</Text>
              </Stack>
            </GreenButton>
          </Group>
        </Stack>
      </Modal>
    </Container>
  );
};

export default Account;
