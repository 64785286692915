import { host } from '../host';
import { useStore } from '../state/state';
import {
  ApiResponse,
  EmptyErrorEnum,
  Gender,
  Limits,
  Player,
  Wager,
  HistoryActivity,
  PlayTime,
  BalanceSummary,
  InitiatePagoraTopupResponse,
  PlayerData,
  Countries,
  PolicyChange,
  referralCodeParent,
} from './interfaces';
import fetch from './interceptor';
import ToggleMarketNotification from '../components/toggleMarketNotification/toggleMarketNotification';

export type PasswordErrorEnum = 'PlayerNotFound' | 'PlayerBanned' | 'TokenInvalid' | 'Unspecified';

export type RegisterPlayerErrorEnum = 'EmailInUse' | 'PlayerTooYoung' | 'CountryNotPermitted' | 'Unspecified';

const registerPlayer = async (
  player: {
    email: string;
    firstName: string;
    lastName: string;
    country: string;
    birthDate: string;
    streetAddress: string;
    city: string;
    postalCode: string;
    phoneNumber: string;
    referralCode?: string;
  },
  token: string
) => {
  try {
    const response = await fetch(`${host}/api/player/register`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-Recaptcha-Token': token || '',
      },
      body: JSON.stringify(player),
    });

    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<void, RegisterPlayerErrorEnum>;
      return json;
    } else {
      return { success: false, result: '', error: 'Unspecified' };
    }
  } catch {}
};

const setInitialPassword = async (userId: string, token: string, password: string) => {
  try {
    const body = {
      userId,
      token,
      password,
    };
    const response = await fetch(`${host}/api/player/set-initial-password`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  } catch {}
};

const setNewPassword = async (userId: string, token: string, password: string) => {
  try {
    const body = {
      userId,
      token,
      password,
    };
    const response = await fetch(`${host}/api/player/reset-password`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<void, PasswordErrorEnum>;

      return json.success ? json.success : json.error;
    } else {
      return 'Unspecified';
    }
  } catch {}
};

const requestPasswordReset = async (email: string) => {
  try {
    const body = {
      email,
    };
    const response = await fetch(`${host}/api/player/request-reset-password`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  } catch {}
};

const UpdatePlayerPicture = async (picture: File | null) => {
  try {
    const state = useStore.getState();

    if (!picture) {
      return;
    }

    const formData = new FormData();
    formData.append('FormFile', picture);

    const response = await fetch(`${host}/api/player/picture`, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
      },
      body: formData,
    });

    if (response.status === 200) {
      const reader = new FileReader();
      reader.readAsDataURL(picture);
      reader.onloadend = () => {
        const base64data = reader.result;
        state.setPlayerProfilePicture(base64data?.toString() || '');
      };
    }
  } catch {}
};

const updatePlayer = async (update: {
  gender?: Gender;
  language?: string;
  iban?: string;
  profilePictureKey?: string;
}) => {
  try {
    const state = useStore.getState();

    const response = await fetch(`${host}/api/player`, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(update),
    });

    if (response.status !== 200) {
      // TODO notify user of fault
    }
  } catch {}
};

const updatePlayerLimits = async (update: Limits, userId?: string) => {
  try {
    const state = useStore.getState();

    const response = await fetch(`${host}/api/player/limits${userId ? `?userId=${userId}` : ''}`, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(update),
    });

    if (response.status !== 200) {
      // TODO notify user of fault
    }
  } catch {}
};

const getPlayer = async (): Promise<Player> => {
  try {
    const state = useStore.getState();

    const response = await fetch(`${host}/api/player/me`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
      },
    });
    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<Player, EmptyErrorEnum>;
      return json.result;
    }
    throw new Error('Failed to get player');
  } catch (error) {
    console.error(error);
    throw new Error('Failed to get player');
  }
};

const getPlayerProfilePicture = async (): Promise<string | null> => {
  try {
    const state = useStore.getState();

    const response = await fetch(`${host}/api/player/picture`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
      },
    });

    if (response.status === 200) {
      const image = (await response.text()) as string;
      state.setPlayerProfilePicture(image);
      return image;
    }

    return null;
  } catch {
    return '';
  }
};

const DeletePlayerProfilePicture = async () => {
  try {
    const state = useStore.getState();

    const response = await fetch(`${host}/api/player/picture`, {
      method: 'Delete',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
      },
    });

    if (response.status === 200) {
      const json = await response.json();
      return json.success;
    }

    return null;
  } catch {}
};

const getRecentWagers = async () => {
  try {
    const state = useStore.getState();

    const response = await fetch(`${host}/api/wager/list`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
      },
    });

    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<Wager[], EmptyErrorEnum>;

      return json.result;
    }
  } catch {}
};

const getPlayerHistory = async (id?: string) => {
  try {
    const state = useStore.getState();
    let endpoint = `${host}/api/player/history`;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await fetch(endpoint, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
      },
    });

    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<HistoryActivity[], EmptyErrorEnum>;

      return json.result;
    }
  } catch {}
};

const getActiveWagers = async () => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/wager/active`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
      },
    });

    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<Wager[], EmptyErrorEnum>;

      return json.result;
    }
  } catch {}
};

const getPlayTime = async () => {
  try {
    const state = useStore.getState();

    const response = await fetch(`${host}/api/player/playtime`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<PlayTime, EmptyErrorEnum>;
      return json.result;
    }
  } catch {}
};

const getBalanceSummary = async () => {
  try {
    const state = useStore.getState();

    const response = await fetch(`${host}/api/player/balance-summary`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<BalanceSummary, EmptyErrorEnum>;
      return json.result;
    }
  } catch {}
};

const initiatePagoraTopup = async (amount: number) => {
  try {
    const state = useStore.getState();
    const body = {
      currency: 'EUR',
      amount: amount,
      domain: window.location.origin,
    };

    const response = await fetch(`${host}/api/player/topup-pagora`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<InitiatePagoraTopupResponse, EmptyErrorEnum>;
      return json.result;
    }
  } catch {}
};

const closeAccount = async () => {
  try {
    const state = useStore.getState();

    const response = await fetch(`${host}/api/player/close-account`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    });
    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<boolean, EmptyErrorEnum>;
      return json;
    }
  } catch {}
};

const requestWithdrawal = async (amount: number, iban: string) => {
  try {
    const state = useStore.getState();
    const body = {
      amount: amount,
      iban: iban,
    };

    const response = await fetch(`${host}/api/player/withdrawal-request`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<boolean, EmptyErrorEnum>;
      return json;
    }
  } catch {
    console.log('error in requestWithdrawal');
  }
};

const getPlayerByID = async (Id: string) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/player/overview/${Id}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<PlayerData, EmptyErrorEnum>;
      return json.result;
    }
  } catch {}
};

const getCountries = async () => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/player/countries`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<Countries[], EmptyErrorEnum>;
      return json;
    }
  } catch {}
};

const operatorWithdrawal = async (userId: string, amount: number) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/player/operator-withdrawal`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: userId, amount: amount }),
    });
    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<any, EmptyErrorEnum>;
      return json;
    }
  } catch {
    console.log('error in operator withdrawal');
  }
};

const operatorDeposit = async (userId: string, amount: number) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/player/operator-top-up`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: userId, amount: amount }),
    });
    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<any, EmptyErrorEnum>;
      return json;
    }
  } catch {
    console.log('error in operator withdrawal');
  }
};

const getPolicyChanges = async () => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/player/policy-updates`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = (await response.json()) as PolicyChange[];
      return json;
    }
  } catch {}
};

const AckPolicyChange = async () => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/player/policy-ack`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = (await response.json()) as ApiResponse<any, EmptyErrorEnum>;
      return json.result;
    }
  } catch {}
};

const GetFavoriteGames = async () => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/player/favorite-markets`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
      },
    });
    if (response.status === 200) {
      const updatedFavoriteGames = await response.json();

      // Update the state with the new list of favorite games
      useStore.getState().setFavoriteGames(updatedFavoriteGames);

      return updatedFavoriteGames;
    }
  } catch {
    console.log('error in get favorite games');
  }
};

const ToggleFavoriteMarket = async (marketId: number) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/player/favorite-markets/${marketId}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
      },
    });
    if (response.status === 200) {
      GetFavoriteGames();
      const marketFound = state.favoriteGames.some((game) => game.marketId === marketId);
      ToggleMarketNotification(marketFound);
      const json = await response;
      return json;
    }
  } catch {
    console.log('error in toggle favorite market');
  }
};

const GetSalesAdmin = async () => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/player/sales-users`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
      },
    });
    if (response.status === 200) {
      const salesUsers = await response.json();
      return salesUsers;
    }
  } catch {
    console.log('error in get favorite games');
  }
};

const getSalesAdminByReferralCodePlayer = async (referralCode: string) => {
  try {
    const state = useStore.getState();
    const response = await fetch(`${host}/api/player/check-referral/${referralCode}`, {
      method: 'Get',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${state.auth.token?.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = (await response.json()) as referralCodeParent;
      return json.referralCode;
    }
  } catch {}
};

export {
  registerPlayer,
  setInitialPassword,
  setNewPassword,
  updatePlayer,
  UpdatePlayerPicture,
  DeletePlayerProfilePicture,
  updatePlayerLimits,
  getRecentWagers,
  getPlayer,
  getPlayerHistory,
  getActiveWagers,
  getPlayTime,
  requestPasswordReset,
  getBalanceSummary,
  initiatePagoraTopup,
  closeAccount,
  requestWithdrawal,
  getPlayerByID,
  getCountries,
  operatorDeposit,
  operatorWithdrawal,
  getPlayerProfilePicture,
  getPolicyChanges,
  GetFavoriteGames,
  ToggleFavoriteMarket,
  AckPolicyChange,
  GetSalesAdmin,
  getSalesAdminByReferralCodePlayer,
};
