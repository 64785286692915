/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, useMantineTheme } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Game, Market, Wager, WagerStateEnum, MarketOpenEnum, Multiview } from '../../../../api/interfaces';
import { useMarketStore } from '../../../../state/state';
import { SoundManager } from '../../../../utils/SoundManager';
import { GameStateBubble } from '../GameStateBubble';
import { ChartDataRef, ChartTickData } from './interface';
import { manageWinLossAnimation } from './win-loss';
import { renderChartLines, renderMarkerCircles } from './utils';
import { useBatchRendering } from '../../../../components/batch-render/BatchRenderingContext';
import { drawConfetti } from './confetti';

const clockSmoothFrames = 4; //TODO these should not be frame dependant but instead be time based
const verticalSmoothFrames = 6;
const renderedHistoryMs = 90000;

// we have to handle the scenario where the user clock has drifted ahead from server time
let clockCompensation = { lastTimestamp: 0, drift: 0 };
const adjustClockCompensation = (now: number, timestamp: number) => {
  if (clockCompensation.lastTimestamp !== timestamp) {
    // we do this by adjusting a "clock compensator" by 1/clockSmoothFrames of the difference
    const difference = timestamp - now; // difference between latest server timestamp and now, should be negative in all sensible scenarios

    const adjustment =
      (clockCompensation.drift / clockSmoothFrames) * (clockSmoothFrames - 1) + difference / clockSmoothFrames;
    clockCompensation = {
      lastTimestamp: timestamp,
      drift: Math.max(0, adjustment),
    };
    if (clockCompensation.drift > 0) {
      const sm = SoundManager.getInstance();
      sm.playSound('tickMove');
    }
  }
};

const renderToCanvas = (ref: ChartDataRef, canvas: HTMLCanvasElement) => {
  const context = canvas.getContext('2d');
  let now = new Date().getTime();
  if (!context) return;

  //calculate bounds from ticks
  const leftEdgeTime = now - 60000;
  const tickCutoff = leftEdgeTime - renderedHistoryMs; // we render some history past the left edge so that we have lines coming as they should and so that Y scaling is gentle
  //TODO use history for Y scaling but don't bother rendering all those ticks
  const allTicks = ref.source.filter((x) => x.timestamp >= tickCutoff).map((x) => ({ x: x.timestamp, y: x.value }));

  let lastTick = allTicks[allTicks.length - 1];
  if (!lastTick) return;
  adjustClockCompensation(now, lastTick.x);
  now += clockCompensation.drift;

  const bounds = allTicks.reduce(
    (b, x) => ({
      minX: leftEdgeTime,
      //maxX: Math.max(b.maxX, x.x),
      maxX: now,
      minY: Math.min(b.minY, x.y),
      maxY: Math.max(b.maxY, x.y),
    }),
    { minX: Number.MAX_VALUE, maxX: Number.MIN_VALUE, minY: Number.MAX_VALUE, maxY: Number.MIN_VALUE }
  );
  const rangeX = bounds.maxX - bounds.minX;

  // animate Y scaling
  ref.maxY =
    ref.maxY === -1
      ? bounds.maxY
      : (ref.maxY / verticalSmoothFrames) * (verticalSmoothFrames - 1) + bounds.maxY / verticalSmoothFrames;
  ref.minY =
    ref.minY === -1
      ? bounds.minY
      : (ref.minY / verticalSmoothFrames) * (verticalSmoothFrames - 1) + bounds.minY / verticalSmoothFrames;
  const rangeY = ref.maxY - ref.minY;
  const pixelsY = canvas.height - 20; //we reserve 20 pixels off the edges for overflow from the markers
  const pixelsX = canvas.width - 10; //we reserve 10 pixels on the right edge for overflow from the marker

  const translationX = ((now - bounds.maxX) / rangeX) * pixelsX;

  const projectValueToY = (value: number) => {
    return pixelsY + 10 - ((value - bounds.minY) / rangeY) * pixelsY;
  };

  const project = (tick: ChartTickData) => ({
    x: tick.x,
    chartX: ((tick.x - bounds.minX) / rangeX) * pixelsX - translationX, // Adjusted to include leftward movement
    chartY: pixelsY + 10 - ((tick.y - bounds.minY) / rangeY) * pixelsY,
  });

  //draw chart lines
  renderChartLines(context, allTicks, project, canvas, ref);
  //draw marker circles at each tick
  renderMarkerCircles(context, allTicks, project, ref);

  if (ref.wager?.openingValue !== undefined) {
    manageWinLossAnimation(context, canvas, projectValueToY, ref);
  }
  if (ref.wager?.state === WagerStateEnum.Won) {
    drawConfetti(canvas);
  }
};

export const TickChart = (props: { game: Game; market: Market; wager?: Wager }) => {
  const ticks = useMarketStore(props.market)((state) => state.ticks);
  const theme = useMantineTheme();
  const { ref, width, height } = useElementSize();
  const { registerComponentForBatchRendering, unregisterComponentForBatchRendering } = useBatchRendering();

  const chartTicks = useRef<ChartDataRef>({
    source: [],
    trend: { x: 0, y: 0 },
    gameColor: props.game.color,
    gameColorLight: theme.fn.lighten(props.game.color, 0.2),
    minY: -1,
    maxY: -1,
    noRecentTicks: true,
  });
  const fifteenSeconds = 15000;
  chartTicks.current.source = ticks;
  chartTicks.current.wager = props.wager;
  let lastTick = +new Date() - chartTicks.current.source[chartTicks.current.source.length - 1]?.localTimestamp;
  chartTicks.current.noRecentTicks = lastTick > fifteenSeconds || Number.isNaN(lastTick);

  const leftEdgeTime = +new Date().getTime() - 45000;
  const RecentTicksCount = chartTicks.current.source.filter((x) => x.timestamp >= leftEdgeTime).length;

  useLayoutEffect(() => {
    const canvasRef = ref.current;
    const context = canvasRef?.getContext('2d');

    if (!context || !canvasRef) return;

    const renderChart = () => {
      context.clearRect(0, 0, canvasRef.width, canvasRef.height);
      renderToCanvas(chartTicks.current, canvasRef);
    };

    registerComponentForBatchRendering(renderChart);

    return () => {
      unregisterComponentForBatchRendering(renderChart);
    };
  }, [ticks]);

  useEffect(() => {
    const handleResize = () => {
      const canvasElement = ref.current;
      if (canvasElement) {
        canvasElement.width = width;
        canvasElement.height = height;
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize, false);

    return () => {
      window.removeEventListener('resize', handleResize, false);
    };
  }, [width, height]);

  //closed markets can be viewed, but no ticks should be shown and no wagers allowed
  //we may still broadcast ticks for closed markets even though the UI should not display them
  //a full market behaves as a closed one, except that players with active wagers continue to receive updates
  const gameClosed = () => {
    if (
      props.market.open === MarketOpenEnum.Closed ||
      (props.market.open === MarketOpenEnum.Full && props.wager?.state !== WagerStateEnum.Active)
    ) {
      return <GameStateBubble title="Market is closed" game={props.game} subtext="It will reopen shortly" />;
    } else if (chartTicks.current.noRecentTicks === undefined || chartTicks.current.noRecentTicks) {
      return <GameStateBubble title="An error occurred" game={props.game} subtext="Please try again" error />; // there hasn't nessesarily been an error, maybe we should just show loading here
    } else if (RecentTicksCount < 2) {
      return <GameStateBubble title="Loading" game={props.game} loader />;
    }
  };

  return (
    <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', position: 'relative' }}>
      {gameClosed()}
      <Divider size={3} color={`${props.game.color} !important`} />
      <canvas ref={ref} style={{ flexGrow: 1, minHeight: '220px' }}></canvas>
      <Divider size={3} color={`${props.game.color} !important`} />
    </Box>
  );
};
