import { Button, Text, Image, useMantineTheme, Group, Paper, Center, Box } from '@mantine/core';
import { Currency, Game, GameThemeEnum, Market } from '../../api/interfaces';
import { formatCurrencyPair } from '../../utils/formatters';
import { useCallback } from 'react';

import IconAUD from '../../assets/currencies/AUD.png';
import IconBTC from '../../assets/currencies/BTC.png';
import IconCNY from '../../assets/currencies/CNY.png';
import IconETH from '../../assets/currencies/ETH.svg';
import IconEUR from '../../assets/currencies/EUR.png';
import IconGBP from '../../assets/currencies/GBP.png';
import IconHKD from '../../assets/currencies/HKD.png';
import IconJPY from '../../assets/currencies/JPY.png';
import IconUSD from '../../assets/currencies/USD.png';
import IconXAG from '../../assets/currencies/XAG.svg';
import IconXAU from '../../assets/currencies/XAU.svg';
import IconXPT from '../../assets/currencies/XPT.svg';
import IconXRP from '../../assets/currencies/XRP.svg';
import { useNavigate } from 'react-router-dom';
import slugify from 'slugify';
import { ToggleFavoriteMarket } from '../../api/player';

export const MarketDisplay = (props: { market: Market; game: Game }) => {
  //display mode is used in WagerSummary, and is inactive
  return (
    <Center sx={{ height: '42px' }}>
      <InnerButton market={props.market} game={props.game} />
    </Center>
  );
};

export const FavoriteMarketSelect = (props: { market: Market; game: Game }) => {
  // display mode is used in Multi-play and allows for selecting the market for multi-play

  function onClick() {
    ToggleFavoriteMarket(props.market.id);
  }

  return <MarketButtonRoot market={props.market} game={props.game} selected={false} onClick={onClick} />

}

const MarketButtonRoot = (props: { market: Market; game: Game, selected: boolean, onClick: () => void }) => {
  const theme = useMantineTheme();

  return (
    <Button
      variant="filled"
      radius="md"
      size="lg"
      onClick={props.onClick}
      styles={{
        root: {
          backgroundColor: theme.fn.rgba(props.game.color, props.selected ? 1 : 0.5),
          padding: '0 10px',
          [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
            padding: '0 2px',
          },
          div: {
            color: props.game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(props.game.color, 0.8),
          },
          '&:hover': {
            backgroundColor: theme.fn.darken(props.game.color, 0.2),
          },
        },
      }}
    >
      <InnerButton market={props.market} game={props.game} />
    </Button>
  );
}


export const MarketButton = (props: { market: Market; game: Game; selected: boolean }) => {
  //select mode is used in MarketPicker, and allows for selecting the active market for a game
  //display mode is used in WagerSummary, and is inactive
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(
      `/play/${slugify(props.game.name, { lower: true })}/${slugify(props.market.currencyPair, { lower: true })}`
    );
  }, [navigate, props.game.name, props.market.currencyPair]);

  return <MarketButtonRoot market={props.market} game={props.game} selected={props.selected} onClick={onClick} />

};

const InnerButton = (props: { market: Market; game: Game }) => {
  const pair = props.market.currencyPair.split('/') as Currency[];

  return (
    <Box>
      <Group position="center" spacing={4} sx={{ marginBottom: '1px' }}>
        <CurrencyIcon currency={pair[0]} />
        <Text size="xs" sx={{ fontWeight: 700, fontSize: '10px', color: props.game.color }}>
          vs
        </Text>
        <CurrencyIcon currency={pair[1]} />
      </Group>
      <Text size="xs" sx={{ fontWeight: 700, color: props.game.color }}>
        {formatCurrencyPair(pair[0], pair[1])}
      </Text>
    </Box>
  );
};

const iconSize = 20;
export const CurrencyIcon = (props: { currency: Currency; className?: string }) => {
  switch (props.currency) {
    case 'AUD':
      return <Image width={iconSize} height={iconSize} src={IconAUD} className={props.className} />;
    case 'BTC':
      return <Image width={iconSize} height={iconSize} src={IconBTC} className={props.className} />;
    case 'CNY':
      return <Image width={iconSize} height={iconSize} src={IconCNY} className={props.className} />;
    case 'ETH':
      return <Image width={iconSize} height={iconSize} src={IconETH} className={props.className} />;
    case 'EUR':
      return <Image width={iconSize} height={iconSize} src={IconEUR} className={props.className} />;
    case 'GBP':
      return <Image width={iconSize} height={iconSize} src={IconGBP} className={props.className} />;
    case 'HKD':
      return <Image width={iconSize} height={iconSize} src={IconHKD} className={props.className} />;
    case 'JPY':
      return <Image width={iconSize} height={iconSize} src={IconJPY} className={props.className} />;
    case 'USD':
      return <Image width={iconSize} height={iconSize} src={IconUSD} className={props.className} />;
    case 'XAG':
      return <Image width={iconSize} height={iconSize} src={IconXAG} className={props.className} />;
    case 'XAU':
      return <Image width={iconSize} height={iconSize} src={IconXAU} className={props.className} />;
    case 'XPT':
      return <Image width={iconSize} height={iconSize} src={IconXPT} className={props.className} />;
    case 'XRP':
      return <Image width={iconSize} height={iconSize} src={IconXRP} className={props.className} />;
  }
};
