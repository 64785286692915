import { Container, Anchor, NumberInput, Stack, Group, Box, Title, Text, useMantineTheme } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { initiatePagoraTopup } from '../../api/player';
import { ReactComponent as IconEuro } from '../../assets/icons/icon-currency-euro.svg';
import { PurpleButton } from '../../components/buttons/PurpleButton';
import { useStore } from '../../state/state';

export const DepositFunds = () => {
  const [amount, setAmount] = useState(500);
  const player = useStore((state) => state.player);
  const [submitting, setSubmitting] = useState(false);
  const balance = useStore((state) => state.balance);
  const [userState, setUserState] = useState('active' as 'account-created' | 'low-balance' | 'active');
  const theme = useMantineTheme();
  const fontSize = amount < 100 ? '40px' : amount < 1000 ? '32px' : '24px';

  const limit = 1000;
  const min = 20;

  const numFormatter = new Intl.NumberFormat();
  const lowBalance = balance && balance?.balance <= 50;

  useEffect(() => {
    getState();
  }, [player?.registeredDate]);

  const getState = async () => {
    if (player) {
      let registeredDate = new Date(player.registeredDate);
      let newPlayer = registeredDate > new Date(Date.now() - 12 * 60 * 60 * 1000);
      newPlayer ? setUserState('account-created') : lowBalance ? setUserState('low-balance') : setUserState('active');
    }
  };

  const onClickPay = () => {
    (async () => {
      setSubmitting(true);

      const response = await initiatePagoraTopup(amount);

      if (response) {
        (window as Window).location = response.url;
      }
    })();
  };

  return (
    <Container>
      <Stack align="center" mb="md">
        {userState === 'account-created' ? (
          <>
            <Title align="center" order={2}>
              {' '}
              Welcome to FXBet! <br /> In order to get started, you'll need to make a deposit into your account.{' '}
            </Title>
            <Text style={{ maxWidth: '500px' }} align="center">
              Funds can be securely deposited into your account with a valid Credit or Debit card. Get started to play!
            </Text>
          </>
        ) : (
          <>
            <Title align="center" order={2}>
              {' '}
              {userState === 'low-balance' ? "Looks like you're getting low!" : 'Looking to top up?'}
            </Title>
            <Text style={{ maxWidth: '500px' }} align="center">
              Funds can be securely deposited into your account with a valid Credit or Debit card to keep playing.
            </Text>
          </>
        )}
      </Stack>
      <Container size={414} pl={7} pr={7}>
        <Stack align="center">
          <Group position="apart" noWrap spacing="xs" style={{ width: '100%' }}>
            <Box sx={{ marginRight: '4px', flexShrink: 1 }}>
              <Title order={3} align="left">
                Amount
              </Title>
              <Text size="xs" align="left">
                At this time the doposit must be between {numFormatter.format(min)} EUR and {numFormatter.format(limit)} EUR
              </Text>
            </Box>
            <NumberInput
              radius="md"
              variant="filled"
              value={amount}
              min={min}
              max={limit}
              hideControls={true}
              icon={<IconEuro style={{ color: theme.white, height: '25px' }} />}
              styles={{
                root: { height: '50px', width: '191px', flexBasis: '191px', flexGrow: 0, flexShrink: 0 },
                icon: { width: '44px' },
                input: {
                  height: '50px',
                  fontSize: fontSize,
                  textAlign: 'right',
                  paddingLeft: '44px',
                  paddingRight: '12px',
                },
              }}
              onChange={(val) => {
                if (val) setAmount(Math.round(val * 100) / 100);
              }}
            />
          </Group>

          <PurpleButton
            onClick={onClickPay}
            disabled={submitting}
            styles={{
              root: { width: '100%' },
            }}
          >
            Pay with Credit Card
          </PurpleButton>
          <Anchor component={Link} to="/wallet">
            <Text size="xs" align="right">
              cancel
            </Text>
          </Anchor>
        </Stack>
      </Container>
    </Container>
  );
};
