import { Box, Center, Grid, Modal, SegmentedControl, Stack, Title, useMantineTheme } from '@mantine/core';
import { useEffect, useState } from 'react';
import { PlayRouter } from '../games/play';
import { FavoriteGame, Game } from '../../api/interfaces';
import { useMediaQuery } from '@mantine/hooks';
import row_2 from '../../assets/icons/dashboard/2 row.svg';
import row_2_green from '../../assets/icons/dashboard/2 row_green.svg';
import column_2 from '../../assets/icons/dashboard/2 column.svg';
import column_2_green from '../../assets/icons/dashboard/2 column_green.svg';
import column_3 from '../../assets/icons/dashboard/3 column.svg';
import column_3_green from '../../assets/icons/dashboard/3 column_green.svg';
import column_4 from '../../assets/icons/dashboard/4 column.svg';
import column_4_green from '../../assets/icons/dashboard/4 column_green.svg';
import { GetFavoriteGames } from '../../api/player';
import { useStore } from '../../state/state';
import { GameCardMulti } from './gameCardMulti';
import { GreenButton } from '../../components/buttons/GreenButton';
import { useStyles } from './styles';

const UserDashboard = () => {
  const mobileView = useMediaQuery(`(max-width: ${useMantineTheme().breakpoints.sm}px)`);
  const [layout, setLayout] = useState('2');
  const [nonMobileSpan, setNonMobileSpan] = useState(6);
  const [marketIDs, setMarketIDs] = useState<FavoriteGame[]>();
  const games = useStore((state) => state.games);
  const [selectedGameId, setSelectedGameId] = useState<number | null>(null);
  const [opened, setOpened] = useState(false);
  const favoriteGames = useStore((state) => state.favoriteGames);
  const { classes } = useStyles();

  const setFavoriteGames = async () => {
    if (favoriteGames.length > 0) {
      setMarketIDs(favoriteGames);
    } else {
      try {
        GetFavoriteGames();
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    setMarketIDs(favoriteGames);
  }, [favoriteGames]);

  useEffect(() => {
    setFavoriteGames();
  }, []);

  const resizeLayout = (e: any) => {
    switch (e) {
      case '1':
        setNonMobileSpan(12);
        setLayout('1');
        break;
      case '2':
        setNonMobileSpan(6);
        setLayout('2');
        break;
      case '3':
        setNonMobileSpan(4);
        setLayout('3');
        break;
      case '4':
        setNonMobileSpan(3);
        setLayout('4');
    }
  };

  return (
    <Stack sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', maxHeight: '100%' }}>
        <GreenButton sx={{ marginRight: '64px' }} onClick={() => setOpened(!opened)}>
          Add Game
        </GreenButton>
        <SegmentedControl
          value={layout}
          onChange={resizeLayout}
          data={[
            {
              label: (
                <Center>
                  {layout === '1' && <img width={30} height={30} src={row_2_green} alt="" />}
                  {layout !== '1' && <img width={30} height={30} src={row_2} alt="row_2" />}
                </Center>
              ),
              value: '1',
            },
            {
              label: (
                <Center>
                  {layout === '2' && <img width={30} height={30} src={column_2_green} alt="" />}
                  {layout !== '2' && <img width={30} height={30} src={column_2} alt="" />}
                </Center>
              ),
              value: '2',
            },
            {
              label: (
                <Center>
                  {layout === '3' && <img width={30} height={30} src={column_3_green} alt="" />}
                  {layout !== '3' && <img width={30} height={30} src={column_3} alt="" />}
                </Center>
              ),
              value: '3',
            },
            {
              label: (
                <Center>
                  {layout === '4' && <img width={30} height={30} src={column_4_green} alt="" />}
                  {layout !== '4' && <img width={30} height={30} src={column_4} alt="" />}
                </Center>
              ),
              value: '4',
            },
          ]}
        />
      </Box>
      <Box>
        <Grid>
          {marketIDs &&
            marketIDs.map((market: FavoriteGame, index: number) => (
              <Grid.Col sx={{ padding: '4px' }} span={mobileView ? 12 : nonMobileSpan} key={index}>
                <PlayRouter
                  name={market.game}
                  currencyPair={market.currencyPair}
                  multiview={{ multiview: true, layout: layout }}
                />
              </Grid.Col>
            ))}
        </Grid>
      </Box>
      <Modal
        size={900}
        opened={opened}
        onClose={() => setOpened(false)}
        centered
        styles={{
          modal: {
            backgroundColor: 'black',
          },
        }}
      >
        <Box>
          <Center>
            <Title order={2} mt="md">
              Choose a Game
            </Title>
          </Center>
          <Box
            sx={{
              height: '500px',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Stack className={classes.gameIndicatorStack} spacing={0} p={64} pt={32}>
              {games.map((game) => (
                <GameCardMulti
                  setSelected={setSelectedGameId}
                  selected={selectedGameId}
                  favoriteGames={favoriteGames}
                  game={game as Game}
                />
              ))}
            </Stack>
          </Box>
        </Box>
      </Modal>
    </Stack>
  );
};

export default UserDashboard;
