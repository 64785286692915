import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => {
  return {
    contentContainer: {
      backgroundColor: theme.other.tabBackgroundColor,
      borderRadius: '20px',
      padding: '12px',
    },
    container: {
      backgroundColor: theme.other.tabBackgroundColor,
      borderRadius: '20px',
      padding: '16px',
    },
    tableHeader: {
      borderTop: `2px solid ${theme.other.blue}`,
      borderBottom: `2px solid ${theme.other.blue}`,
    },
  };
});

export { useStyles };
