import { Center, Divider, Group, Image, Paper, Text, Overlay, Stack, Box, Title } from '@mantine/core';
import { Game, GameCategoryEnum, GameVolatilityEnum, MarketEnabledEnum } from '../../../api/interfaces';
import SVG from 'react-inlinesvg';

import { ReactComponent as IconRightTriangle } from '../../../assets/icons/icon-play-right-triangle.svg';
import { ReactComponent as IconVolatilityFast } from '../../../assets/icons/icon-game-volatility-fast.svg';
import { ReactComponent as IconVolatilitySlow } from '../../../assets/icons/icon-game-volatility-slow.svg';

import { ReactComponent as IconCategoryForex } from '../../../assets/icons/icon-game-category-bigbills.svg';
import { ReactComponent as IconCategoryMetals } from '../../../assets/icons/icon-game-category-minermadness.svg';
import { ReactComponent as IconCategoryCrypto } from '../../../assets/icons/icon-game-category-nonstop.svg';
import { ReactComponent as IconCategorySocial } from '../../../assets/icons/icon-game-category-social.svg';

import { ReactComponent as IconStarRating } from '../../../assets/icons/icon-play-star.svg';
import { toMaxPrecision } from '../../../utils/formatters';
import { removeEmbeddedSize } from '../../../utils/svg';
import { useStyles } from './styles';

export const GameCard = (props: { game: Game }) => {
  const { classes } = useStyles();
  const allMarketsClosedForGame = props.game?.markets?.every((x) => x.open !== 'Open' || x.enabled === MarketEnabledEnum.Disabled);

  return (
    <Paper radius="md" className={classes.paperContainer}>
      <Group spacing={0} direction="column" align="stretch" grow>
        <Image
          src={props.game.imageGame}
          radius="md"
          fit="cover"
          styles={{ image: { boxShadow: '0px 0px 8px rgba(0, 202, 251, 0.75)' } }}
        />
        <Group position="apart" mt={12} mb={6} spacing={0} noWrap>
          <Text size="md" weight="bold" sx={{ lineHeight: 1, [`@media (max-width: 380px)`]: { fontSize: '14px' } }}>
            {props.game.name}
          </Text>
          <IconRightTriangle color={props.game.color} height={16} width={16} />
        </Group>
        <Divider size={3} color={`${props.game.color} !important`} />
        <Group position="apart" mt={4} spacing={0}>
          <Group
            noWrap
            sx={(theme) => ({
              justifyContent: 'space-between',
              gap: '12px',
              [`@media (max-width: 390px)`]: { gap: 0, flexGrow: 1, marginRight: '8px' },
            })}
          >
            <GameVolatilityIndicator game={props.game} />
            <GameCategoryIndicator game={props.game} />
            <GameRatingIndicator game={props.game} />
          </Group>
          <SVG
            preProcessor={removeEmbeddedSize}
            src={props.game.iconGame}
            color={props.game.color}
            className={classes.gameIcon}
          ></SVG>
        </Group>
        <Center
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {allMarketsClosedForGame && (
            <Overlay opacity={0.8} color="#000" radius="md" sx={{ overflow: 'hidden' }}>
              <Stack justify="flex-end" sx={{ height: '100%' }}>
                <Box className={classes.overBoxTextContainer}>
                  <Text align="center" mt={4} weight="bold">
                    All markets closed
                  </Text>
                </Box>
              </Stack>
            </Overlay>
          )}
          <Text align="center" mt={4} sx={{ fontSize: 10, lineHeight: 1.1 }}>
            {props?.game?.description}
          </Text>
        </Center>
      </Group>
    </Paper>
  );
};

const GameVolatilityIndicator = (props: { game: Game }) => {
  if (props.game.volatility === GameVolatilityEnum.Fast) {
    return (
      <Group direction="column" spacing={0} align="center">
        <Center sx={{ height: '28px' }}>
          <IconVolatilityFast style={{ color: '#FFF' }} width={32} height={20} />
        </Center>
        <Text size="xs" weight="bold" sx={{ color: props.game.color }}>
          Fast
        </Text>
      </Group>
    );
  } else if (props.game.volatility === GameVolatilityEnum.Slow) {
    return (
      <Group direction="column" spacing={0} align="center">
        <Center sx={{ height: '28px' }}>
          <IconVolatilitySlow style={{ color: '#FFF' }} width={32} height={20} />
        </Center>
        <Text size="xs" weight="bold" sx={{ color: props.game.color }}>
          Slow
        </Text>
      </Group>
    );
  }

  return null;
};

const GameCategoryIndicator = (props: { game: Game }) => {
  if (props.game.category === GameCategoryEnum.BigBills) {
    return (
      <Group direction="column" spacing={0} align="center">
        <Center sx={{ height: '28px' }}>
          <IconCategoryForex color="#FFF" width={26} height={26} />
        </Center>
        <Text size="xs" weight="bold" sx={{ color: props.game.color }}>
          Forex
        </Text>
      </Group>
    );
  } else if (props.game.category === GameCategoryEnum.MinerMadness) {
    return (
      <Group direction="column" spacing={0} align="center">
        <Center sx={{ height: '28px' }}>
          <IconCategoryMetals color="#FFF" width={26} height={26} />
        </Center>
        <Text size="xs" weight="bold" sx={{ color: props.game.color }}>
          Metal
        </Text>
      </Group>
    );
  } else if (props.game.category === GameCategoryEnum.NonStopGame) {
    return (
      <Group direction="column" spacing={0} align="center">
        <Center sx={{ height: '28px' }}>
          <IconCategoryCrypto color="#FFF" width={26} height={26} />
        </Center>
        <Text size="xs" weight="bold" sx={{ color: props.game.color }}>
          Crypto
        </Text>
      </Group>
    );
  } else if (props.game.category === GameCategoryEnum.SocialFrenzy) {
    //not used in initial launch
    return (
      <Group direction="column" spacing={0} align="center">
        <Center sx={{ height: '28px' }}>
          <IconCategorySocial color="#FFF" width={26} height={26} />
        </Center>
        <Text size="xs" weight="bold" sx={{ color: props.game.color }}>
          Social
        </Text>
      </Group>
    );
  }

  return null;
};

const GameRatingIndicator = (props: { game: Game }) => {
  return (
    <Group direction="column" spacing={0} align="center">
      <Center sx={{ height: '28px' }}>
        <IconStarRating color="#FFF" width={26} height={26} />
      </Center>
      <Text size="xs" weight="bold" sx={{ color: props.game.color }}>
        {toMaxPrecision(props.game.rating, 1)}
      </Text>
    </Group>
  );
};
