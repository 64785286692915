import { createStyles } from '@mantine/core';
import { Game, GameThemeEnum } from '../../../api/interfaces';

const useStyles = createStyles((theme, game: Game) => {
  return {
    flexBetweenWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    dFlexSpaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    noWrap: {
      flexWrap: 'nowrap',
    },
    fullWidth: {
      width: '100%',
    },
    fromToSection: {
      padding: '8px',
    },
    fromToIcon: {
      width: '75px',
      height: '75px',
    },
    resultsWrapper: {
      display: 'flex',
      width: '100%',
    },
    resultSection: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      borderRadius: '8px',
      padding: '8px',
      width: '60%',
      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        width: '100%',
      },
    },
    Pending: {},
    Active: {
      backgroundImage: 'linear-gradient(to bottom, yellow , green);',
      borderRadius: '50px',
      padding: '4px',
      minWidth: '80px',
      textAlign: 'center',
    },
    Won: {
      backgroundColor: '#458D48',
      borderRadius: '50px',
      padding: '4px',
      minWidth: '80px',
      textAlign: 'center',
    },
    Lost: {
      backgroundColor: '#912C45',
      borderRadius: '50px',
      padding: '4px',
      minWidth: '80px',
      textAlign: 'center',
    },
    Draw: {
      backgroundColor: '#8A3E37',
      borderRadius: '50px',
      padding: '4px',
      minWidth: '80px',
      textAlign: 'center',
    },
    GameColourButton: {
      backgroundColor: game.color,
      [".mantine-Text-root, .mantine-Title-root"]: {
        color: `${game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(game.color, 0.8)} !important`,
      },
      ':hover': {
        backgroundColor: theme.fn.darken(game.color, 0.3),
      }
    },
    OnGameColourText: {
      [".mantine-Text-root, .mantine-Title-root"]: {
        color: `${game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(game.color, 0.8)} !important`,
      },
    },
    Cancelled: {},
    iconGame: {
      maxWidth: '50px',
      maxHeight: '50px',
    },
  };
});

export { useStyles };
