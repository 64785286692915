import { Game, GameThemeEnum, WagerTypeEnum } from '../../api/interfaces';

import { ReactComponent as IconUp } from '../../assets/icons/icon-play-arrow-up.svg';
import { ReactComponent as IconDown } from '../../assets/icons/icon-play-arrow-down.svg';
import { Button, Image, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

export const WagerTypeButton = (props: { type: WagerTypeEnum; game: Game; selected: boolean; onClick: () => void }) => {
  const theme = useMantineTheme();
  const mobileView = useMediaQuery(`(max-width: ${useMantineTheme().breakpoints.sm}px)`);

  switch (props.type) {
    case WagerTypeEnum.Rise:
      return (
        <Button
          variant="filled"
          radius="md"
          size="lg"
          leftIcon={<IconUp style={{ height: '32px', color: theme.white }} />}
          onClick={props.onClick}
          styles={{
            root: {
              backgroundColor: theme.fn.rgba(props.game.color, props.selected ? 1 : 0.5),
              color: props.game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(props.game.color, 0.8),
              'svg g path': {
                fill: `${
                  props.game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(props.game.color, 0.8)
                } !important`,
              },
              padding: '5px 6px 5px 12px',
              '&:hover': {
                backgroundColor: theme.fn.darken(props.game.color, 0.2),
              },
            },
            leftIcon: { marginRight: '4px' },
            label: { flexGrow: 1, justifyContent: 'center', textTransform: 'uppercase' },
          }}
        >
          {!mobileView &&
            (props.selected ? <Image fit="contain" height={40} src={props.game.iconAction} /> : <span>Up</span>)}
        </Button>
      );
    case WagerTypeEnum.Fall:
      return (
        <Button
          variant="filled"
          radius="md"
          size="lg"
          leftIcon={<IconDown style={{ height: '32px', color: theme.white }} />}
          onClick={props.onClick}
          styles={{
            root: {
              color: props.game.theme === GameThemeEnum.Light ? theme.white : theme.black,
              'svg g path': {
                fill: `${props.game.theme === GameThemeEnum.Light ? theme.white : theme.black} !important`,
              },
              backgroundColor: theme.fn.rgba(props.game.color, props.selected ? 1 : 0.5),
              padding: '5px 6px 5px 12px',
              '&:hover': {
                backgroundColor: theme.fn.darken(props.game.color, 0.2),
              },
            },
            leftIcon: { marginRight: '4px' },
            label: { flexGrow: 1, justifyContent: 'center', textTransform: 'uppercase' },
          }}
        >
          {!mobileView &&
            (props.selected ? <Image fit="contain" height={40} src={props.game.iconAction} /> : <span>Down</span>)}
        </Button>
      );
  }
};
