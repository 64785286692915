import {
  Box,
  Divider,
  Table,
  Title,
  Text,
  Checkbox,
  Select,
  Button,
  Group,
  ScrollArea,
  Tooltip,
  Pagination,
  NativeSelect,
} from '@mantine/core';
import { useStyles } from '../styles';
import { ChangeEvent, useEffect, useState } from 'react';
import { assignSalesAdmin, getUsersForSalesManagement } from '../../../api/backoffice';
import { Country, PlayerLink, UserMin, UserSalesManagementFilter } from '../../../api/interfaces';
import { useNavigate } from 'react-router-dom';
import { GetSalesAdmin, getCountries } from '../../../api/player';

const ManageSalesUserAllocation = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [unAllocatedAccounts, setUnAllocatedAccounts] = useState<PlayerLink[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<UserMin[]>([]);
  const [salesAccount, setSalesAccount] = useState<UserMin[]>([]);
  const [selectedSalesAdmin, setSelectedSalesAdminID] = useState<UserMin>();
  const [selectedCountry, setSelectedCountry] = useState<string>('All');
  const [selectedFilter, setSelectedFilter] = useState<string>('All');
  const [searchAdmin, setSearchAdmin] = useState<UserMin>();
  const UserFilter = ['All', 'Unallocated', 'Allocated', 'Admin'];
  const [countries, setCountries] = useState<Country[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageTotal, setPageTotal] = useState<number>(0);

  const getAccounts = async () => {
    let params: UserSalesManagementFilter = {
      filter: selectedFilter as 'All' | 'Unallocated' | 'Allocated' | 'Admin',
      adminId: searchAdmin?.id,
      page: page,
      pageSize: 20,
      countryCode: selectedCountry === 'All' ? undefined : selectedCountry,
    };

    await getUsersForSalesManagement(params).then((res) => {
      if (res) {
        setUnAllocatedAccounts(res.data);
        setPageTotal(res.totalPages);
      } else {
        setUnAllocatedAccounts([]);
        setPage(1);
      }
    });
  };

  const getSalesAccount = async () => {
    GetSalesAdmin().then((result) => {
      let all: UserMin = {
        id: '',
        firstName: 'All',
        lastName: '',
        email: '',
      };
      if (result && result?.length > 0) {
        setSalesAccount([all].concat(result));
      }
    });
  };

  const allocateUser = () => {
    const selectedAccountIDs = selectedAccounts.map((account) => account.id);
    const salesAdminID = selectedSalesAdmin?.id;

    if (selectedAccountIDs.length > 0 && salesAdminID) {
      selectedAccountIDs.forEach(async (id) => {
        await assignSalesAdmin({ AdminId: salesAdminID, UserId: id }).then((result) => {
          if (result.success) {
            getAccounts();
            setSelectedAccounts((prevSelected) => prevSelected.filter((account) => account.id !== id));
          }
        });
      });
    }
  };

  const getCountryList = async () => {
    try {
      const result = await getCountries();
      if (result && Array.isArray(result)) {
        const countriesWithPlayersAllowed = result.filter((country: Country) => country.playersAllowed);
        setCountries(countriesWithPlayersAllowed);
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
      setCountries([]);
    }
  };

  useEffect(() => {
    getSalesAccount();
    getCountryList();
  }, []);

  useEffect(() => {
    if (selectedFilter !== 'Admin' || (selectedFilter === 'Admin' && searchAdmin)) {
      getAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, selectedFilter, selectedSalesAdmin, searchAdmin, page]);

  const handleCheckboxChange = (account: UserMin) => {
    setSelectedAccounts((prevSelected) => {
      if (prevSelected.some((a) => a.id === account.id)) {
        return prevSelected.filter((a) => a.id !== account.id);
      } else {
        return [...prevSelected, account];
      }
    });
  };

  const handleSalesAdminChange = (salesAdminID: string) => {
    const salesAdmin = salesAccount.find((account) => account.id === salesAdminID);
    setSelectedSalesAdminID(salesAdmin);
  };

  const handleSearchAdminChange = (salesAdminID: string) => {
    const salesAdmin = salesAccount.find((account) => account.id === salesAdminID);
    if (salesAdmin?.firstName === 'All') {
      setSelectedFilter('All');
      setSelectedSalesAdminID(undefined);
    }

    setSearchAdmin(salesAdmin);
  };

  const handleCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e) {
      setSelectedCountry(e.target.value);
      setPage(1);
    } else {
      setSelectedCountry('All');
    }
  };

  const handleFilterChange = (selectedFilterID: string | null) => {
    if (selectedFilterID) {
      setSelectedFilter(selectedFilterID);
      setPage(1);
    } else {
      setSelectedFilter('All');
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Title align="left" sx={{ flexGrow: 1 }} order={1}>
          Player Sales Details
        </Title>
      </Box>
      <Divider size={2} sx={{ margin: '24px 0px' }} />
      <Box className={classes.container} sx={{ width: '100%' }}>
        <Group
          direction="row"
          sx={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', marginBottom: '16px' }}
        >
          <Group direction="row" sx={{ alignItems: 'center' }}>
            <NativeSelect
              value={selectedCountry || ''}
              sx={{ maxWidth: '200px', marginLeft: '16px' }}
              label="Country"
              onChange={(e) => handleCountryChange(e)}
              data={[{ value: 'All', label: 'All' }].concat(
                countries.map((country) => ({ value: country.code, label: country.name }))
              )}
            />
            <Select
              value={selectedFilter || ''}
              sx={{ maxWidth: '200px', marginLeft: '16px' }}
              label="Filter"
              onChange={(e) => handleFilterChange(e)}
              data={UserFilter.map((filter) => ({
                value: filter,
                label: filter,
              }))}
            />
            <Group direction="row" sx={{ justifyItems: 'center' }}>
              <Tooltip
                label='Select the "Admin" filter before selecting Admin to search by.'
                disabled={selectedFilter === 'Admin'}
                withArrow
              >
                <Box>
                  <Select
                    value={searchAdmin?.id || ''}
                    sx={{ maxWidth: '200px' }}
                    disabled={selectedFilter !== 'Admin'}
                    label="Sales Admin to Search By"
                    onChange={(searchAdminId: string) => handleSearchAdminChange(searchAdminId)}
                    data={salesAccount.map((filter) => ({
                      value: filter.id,
                      label: filter.firstName + ' ' + filter.lastName,
                    }))}
                  />
                </Box>
              </Tooltip>
            </Group>
          </Group>

          <Group direction="row" sx={{ alignItems: 'center' }}>
            <Box sx={{ display: 'flex' }}>
              <Select
                value={selectedSalesAdmin?.id || ''}
                sx={{ maxWidth: '200px' }}
                label="Sales Admin to Assign"
                onChange={(selectedSalesAdminID: string) => handleSalesAdminChange(selectedSalesAdminID)}
                data={salesAccount.map((filter) => ({
                  value: filter.id,
                  label: filter.firstName + ' ' + filter.lastName,
                }))}
              />
              <Button
                disabled={!selectedSalesAdmin || selectedSalesAdmin.firstName === 'All'}
                onClick={() => allocateUser()}
                sx={{ marginTop: '24px', marginLeft: '16px' }}
              >
                Allocate Admin
              </Button>
            </Box>
          </Group>
        </Group>
        <ScrollArea style={{ height: 410 }}>
          <Table sx={{ tableLayout: 'auto' }}>
            <thead className={classes.tableHeader}>
              <tr>
                <th>
                  <Text>Player</Text>
                </th>
                <th>
                  <Text>Email</Text>
                </th>
                <th>
                  <Text>Country</Text>
                </th>
                <th>
                  <Text>Sales Admin</Text>
                </th>
                <th>
                  <Text align="center">Action</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {unAllocatedAccounts.map((linkedAccont, index) => (
                <tr
                  style={{ cursor: 'pointer' }}
                  key={index}
                  onClick={(event) => {
                    if (event.target instanceof Element && !event.target.closest('input[type="checkbox"]')) {
                      navigate(`/admin/player-overview/player-account/${linkedAccont.player.id}`);
                    }
                  }}
                >
                  <td>
                    <Text>{linkedAccont.player.firstName + ' ' + linkedAccont.player.lastName}</Text>
                  </td>
                  <td>
                    <Text>{linkedAccont.player.email}</Text>
                  </td>
                  <td>
                    <Text>{linkedAccont.player.country?.name}</Text>
                  </td>
                  <td>
                    <Text>
                      {linkedAccont.salesAdmin && linkedAccont.salesAdmin.id
                        ? linkedAccont.salesAdmin.firstName + ' ' + linkedAccont.salesAdmin.lastName
                        : 'Unallocated'}
                    </Text>
                  </td>
                  <td style={{ display: 'flex', justifyContent: 'center' }}>
                    <Group position="center">
                      <Checkbox
                        checked={selectedAccounts.some((a) => a.id === linkedAccont.player.id)}
                        onChange={(event) => {
                          event.stopPropagation();
                          handleCheckboxChange(linkedAccont.player);
                        }}
                      />
                    </Group>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollArea>
        <Box>
          {pageTotal > 1 && (
            <Pagination
              style={{ justifyContent: 'flex-end' }}
              initialPage={page}
              total={pageTotal}
              boundaries={2}
              page={page}
              onChange={(page) => setPage(page)}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default ManageSalesUserAllocation;
