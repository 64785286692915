import { Anchor, Container, Paper, Stack, Text, useMantineTheme } from '@mantine/core';
import SectionHeader from '../../components/staticSectionHeader';
import { useStyles } from './styles';
import UnstyledMailtoLink from '../../components/mailToLink/unstyledMailToLink';

const ResponsibleGamblingPage = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  return (
    <Container size="md">
      <Paper
        sx={(theme) => ({
          backgroundColor: theme.fn.rgba(theme.other.darkBackgroundMedium, 0.6),
        })}
        className={classes.paper}
      >
        <SectionHeader order={1} align="center">
          Responsible Gaming
        </SectionHeader>
        <Stack pb="lg" mb={theme.spacing.lg} className={classes.stack}>
          <Text size="lg" mb={16}>
            FXBet Ltd is a Curaçao-licensed online betting operator that adheres strictly to the guidelines and
            regulations of responsible betting/gambling on each market where the company operates.
          </Text>

          <Text size="lg" mb={16}>
            FXBet provides an entertaining betting platform and has different measures to mitigate problem gambling
            according to applicable industry standards, such as monitoring players’ deposit behaviours, providing
            cooling-off or termination options, and informing about available help organisations in each relevant
            market.
          </Text>
        </Stack>
        <Stack sx={{ borderBottom: '1px solid #0000002b;' }}>
          <Text size="lg" mb={16}>
            - FXBet does not offer game bonuses or incentives as a means of acquiring customers or increasing the number
            of bets placed. There are no wagering requirements to trap Players in place at any time.
          </Text>

          <Text size="lg" mb={16}>
            - If a player wants to set limits on their online activity, they can do so in the application or through
            contacting Customer Support. This could include blocking themselves from playing certain games, all games,
            or locking themselves out of the application for a defined period. Players can also limit the maximum amount
            of their stake. They can amend this amount at any time, but any change will only be realised 24 hours after
            the last update.
          </Text>

          <Text size="lg" mb={16}>
            - For players who want to set limits on their gambling, FXBets offers a voluntary self-exclusion policy,
            allowing players to close their account or restrict their gambling activities on the platform for a
            specified duration. Once their account has been self-excluded, it will be closed until the expiry of the
            selected period. At the expiry of the self-exclusion period, they will be able to re-commence the use of any
            service by contacting Customer Support.
          </Text>

          <Text size="lg" mb={20}>
            - During the self-exclusion period, should a player attempt to create a new account, a best-effort attempt
            will be made to detect such behaviour. The player will accept that the company shall have no financial
            liability and shall not be held otherwise accountable if they continue gambling or using a new account with
            the service under a different name or credentials.
          </Text>
        </Stack>
        <Stack sx={{ borderBottom: '1px solid #0000002b;' }}>
          <Text size="lg" my={20}>
            If you believe that you have a gambling problem, you should restrict yourself from playing immediately.
            There are several ways to block yourself from betting on FXBet. Please contact our Customer Support on{' '}
            <UnstyledMailtoLink email="support@fxbet.co" />
          </Text>

          <Text size="lg" align="center" style={{ marginTop: '1.5rem' }}>
            For more information and a range of support services, please also get in touch with these independent
            organisations:
          </Text>

          <Anchor
            href="https://www.begambleaware.org"
            target="_blank"
            rel="noreferrer"
            style={{
              display: 'block',
              marginTop: '0.5rem',
              textDecoration: 'none',
              color: 'rgb(176 220 255)',
            }}
          >
            https://www.begambleaware.org
          </Anchor>

          <Anchor
            href="https://www.gamcare.org.uk"
            target="_blank"
            rel="noreferrer"
            style={{
              display: 'block',
              marginTop: '0.5rem',
              textDecoration: 'none',
              color: 'rgb(176 220 255)',
            }}
          >
            https://www.gamcare.org.uk
          </Anchor>
        </Stack>
      </Paper>
    </Container>
  );
};

export default ResponsibleGamblingPage;
