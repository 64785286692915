import {
  Container,
  List,
  Paper,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useStyles } from "../legalPages/styles";
import SectionHeader from "../../components/staticSectionHeader";

const TermsAndConditions = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <Container>
      <Paper
        sx={(theme) => ({
          backgroundColor: theme.fn.rgba(theme.other.darkBackgroundMedium, 0.6),
        })}
        className={classes.paper}>
        <Stack pb="lg" mb={theme.spacing.lg} className={classes.stack}>
          <SectionHeader order={1} align="center">
            FXBet General Terms and Definitions
          </SectionHeader>
          <Text component="p" my={1}>
            * The following Betting Rules pertaining to the company FXBet (the
            “Company”) stipulate the manner of accepting bets, paying winnings
            and resolving disputes (the “Rules”). These Rules shall govern any
            other relations between the Company FXBet and the Company’s client
            (the “Client”).
          </Text>
          <Text component="p" my={1}>
            * These Rules shall apply to betting on the website www.fxbet.co and
            at FXBet betting facilities.
          </Text>
          <Text component="p" my={1}>
            * The Company provides a gambling service within the scope of
            amusement and entertainment only. Your Account is for Your sole
            personal use only and shall not be used for any professional,
            business or commercial purpose. You do not earn interest on the
            balance credited to your Account. The company is neither a financial
            institution, nor an exchange platform and shall not be treated as
            such; and therefore the Company does not execute any conversion
            between currencies, including between crypto currency and fiat
            currency or vice versa. The Company and its services offered are not
            currency, foreign exchange or other financial products. The services
            offered should in no way be construed as financial, investment nor
            trading services.
          </Text>
          <Text component="p" my={1}>
            * Bets may only be placed by individuals who are 18 years of age or
            the age of majority in their state (if the age of majority is over
            18) and agree to the Betting Rules offered by the Company. The
            Client shall be held liable for violation of this regulation.
          </Text>
          <List my={1}>
            * The following individuals are not allowed to place bets:
            <ul>
              <li>
                individuals who are under 18 years of age at the time of
                placement;
              </li>
              <li>
                individuals who are prohibited from entering into a contract
                with a company subject to the effective legislation.
              </li>
            </ul>
          </List>
          <Text component="p" my={1}>
            * The right to access and/or use the Website (including any or all
            of the products offered through the Website) may be considered
            illegal in the following countries: Netherlands, Curaçao, Aruba,
            Bonaire, Sint Maarten, Sint Eustatius, Saba), France, United States
            of America, United Kingdom, Sweden, Australia, Austria, Czech
            Republic, Belgium, Germany, Hungary, Slovakia, Estonia, Spain,
            Serbia, Slovenia, Latvia, Ireland, Ontario, Canada, Poland, Turkey,
            Switzerland. The FXBet website is not to be used for betting,
            gambling or other purposes by people who reside in countries or
            jurisdictions in which such types of activity are illegal.
          </Text>
          <Text component="p" my={1}>
            * The fact that our Website is available in such a country and/or
            jurisdiction or that it can be displayed in the official language of
            any of those countries cannot is not to be considered official
            authorisation or legal grounds for using the FXBet website and
            depositing funds into accounts or withdrawing winnings. The
            availability of the Website does not mean that it contains any
            proposals, incitement or invitation to use or subscribe to betting,
            gambling or any of the other services in any jurisdiction where such
            activity is illegal.
          </Text>
          <Text component="p" my={1}>
            * The Client is responsible for determining whether his/her
            accessing and/or use of the Website is compliant with applicable
            laws in your jurisdiction and the Client warrants to us that
            gambling is legal in the territory where he/she resides. When
            opening an account and/or using our Website the Client must make
            sure that his/her actions are legal in the territory in which they
            reside. The Client also guarantees and agree that he/she have
            received legal advice before registering on our Website. If we
            become aware that the Client is a resident in a country where the
            use of our Website is considered illegal, we will have the right to
            close the account and refund any remaining balance on the Client’s
            account at the moment of its closure (after the deduction of any
            winnings credited after the Client’s most recent deposit was made).
          </Text>
          <Text component="p" my={1}>
            * The Company shall be entitled to refuse bets from Clients who fail
            to abide by these Rules. The Company reserves the right to refuse to
            accept a bet of any type from any Client should they violate social
            standards of conduct and public order.
          </Text>
          <Text component="p" my={1}>
            * The Company reserves the right to refuse to accept a bet from any
            individual without giving a reason.
          </Text>
          <Text component="p" my={1}>
            * All bets shall be settled based on the data provided by the
            processing center.
          </Text>
          <Text component="p" my={1}>
            * After receiving returns, the Client shall check if the winnings
            are correct. Should the Client disagree with the winnings, they
            shall give notice to the Company thereof with their account number,
            date, time, event, stake, selection, odds, and reasons of
            disagreement being stated. Any claims regarding winnings may be
            filed within 10 (ten) days. Documents supporting and substantiating
            such complaint shall be enclosed.
          </Text>
          <Text component="p" my={1}>
            * A bet placed by the Client shall be deemed won if all outcomes
            specified in such bet have been predicted correctly.
          </Text>
          <Text component="p" my={1}>
            * In the event of any disagreement between the Client (contract
            party) and the Company on any matter pertaining to the observance
            and fulfilment of a betting arrangement between the Client (contract
            party) and the Company, including payouts, outcomes, odds and other
            material terms of the arrangement, as well as declaring such
            arrangement unconcluded or void, the parties agree that any disputes
            shall be settled by serving a complaint to the opposite party
            (pre-trial procedure). The party who feels that their rights have
            been infringed must submit a written claim to the other party within
            10 days.
          </Text>
          <Text component="p" my={1}>
            * Under the pre-trial procedure, a party claiming the violation of
            their rights shall serve a written complaint to the opposite party.
            Whenever a complaint is served to the Company, it shall be delivered
            to the Company’s registered office stated in the Company’s
            constituent documents and supported by the respective statement from
            the public register of companies. Whenever a complaint is served to
            the Client (contract party), it shall be delivered to their place of
            residence (or place of stay).
          </Text>
          <Text component="p" my={1}>
            * In the case of technical failures, bets will be refunded only if
            the event in question does not occur or bets on the event are not
            settled by the Company.
          </Text>
          <Text component="p" my={1}>
            * Should these Rules be amended, Clients shall be notified
            accordingly. Bets accepted after the specified date shall be subject
            to the amended Rules. Earlier bets shall remain unchanged.
          </Text>
          <Text component="p" my={1}>
            * No connection failure while receiving confirmation of a bet shall
            entail the cancellation of such bet.
          </Text>
          <Text component="p" my={1}>
            * Placing a bet acts as proof that the Client agrees to and accepts
            these Betting Rules.
          </Text>
          <Text component="p" my={1}>
            * Each registered Client may have only one account. Clients may
            register only one account per family, address, e-mail address, IP
            address, credit/debit card, e-wallet, or electronic payment method.
            Persons otherwise associated with a Client will not be allowed to
            register on the website.
          </Text>
          <Text component="p" my={1}>
            * No registered Client may be re-registered as a new Client (under a
            new name, with a new email address, etc.).
          </Text>
          <Text component="p" my={1}>
            * In the event of:
          </Text>
          <Text component="p" my={1}>
            * duplicate registration (including registering under a new name),
            the submission of someone else's, invalid, or forged documents
            (including those that have been edited by using any kind of software
            or graphic editor)
          </Text>
          <Text component="p" my={1}>
            * multiple breaches of the Betting Company’s T&C
          </Text>
          <Text component="p" my={1}>
            * doubts about the identity of the Client or the information they
            have provided (i.e. address, credit/debit card details, other data)
          </Text>
          <Text component="p" my={1}>
            * any types of fraud committed either by the Client or by another
            person acting in your interests or in collusion with you, including
            but not limited to:
          </Text>
          <Text component="p" my={1}>
            * refund or rake fraud
          </Text>
          <Text component="p" my={1}>
            * your use of a stolen or unverified bank card as a source of funds
          </Text>
          <Text component="p" my={1}>
            * any actions the Client has carried out or attempted to carry out
            which may reasonably be considered illegal in any applicable
            jurisdiction, which were committed deliberately or with the
            intention to deceive and/or circumvent constraints set in law
            regardless of whether this action or attempt ultimately causes loss
            or damage to your account
          </Text>
          <Text component="p" my={1}>
            * when the Client placed the bet, they had information about the
            result of that event
          </Text>
          <Text component="p" my={1}>
            * the Client is suspected of using special software or hardware
            which facilitate automated betting, including but not limited to the
            use of glitches, faults or errors in our software in connection with
            the Services we offer (including betting); your use of rogue
            equipment and programs or analytical systems, including but not
            limited to software that allows the Client to place bets without
            human intervention (for example, bots), etc.
          </Text>
          <Text component="p" my={1}>
            * unfair means of any kind were used to obtain information or
            circumvent restrictions imposed by the Company.
          </Text>
          <Text component="p" my={1}>
            * The Company’s management is entitled to request at their
            discretion any documents from the Client substantiating their
            identity or other data they have provided (for example, passport
            details, residential address), as well as to cancel any payments
            until all such details have been verified.
          </Text>
          <Text component="p" my={1}>
            * The Company’s management reserves the right to conduct a video
            conference as a part of the identity verification process, or
            request for the documents to be sent by post. Verification may take
            up to 72 hours from the receipt of the documents. The outcome of the
            video conference can be provided to the Client within 5 working days
            after a video conference takes place. If it is proven that the data
            that was provided is not valid, the Company is entitled to take any
            reasonable measures including but not limited to canceling all bets
            and suspending all transactions for the duration of their
            investigation, and to proceed with full verification of the account
            once any documents required for the verification procedure have been
            requested.
          </Text>
          <Text component="p" my={1}>
            * If the Client refuses to undergo verification, the Company
            reserves the right to void their bets (including bets placed from
            duplicate accounts). Furthermore, the management reserves the right
            to take any reasonable measures, including but not limited to
            blocking the account and freezing the available funds until the
            Client is established and their deposit methods have been confirmed.
          </Text>
          <Text component="p" my={1}>
            * Upon completion of the investigation, the Company can make any
            decision that it deems to be fair and reasonable:
          </Text>
          <Text component="p" my={1}>
            * to block (close) the account (including any duplicate accounts),
            which may entail:
          </Text>
          <Text component="p" my={1}>
            * at our sole discretion, to cancel all winnings and refund the
            balance of your account at the start of the investigation (minus any
            canceled winnings) made from your main and duplicate accounts. We
            also have the right to refund any amounts that are owed to us in
            connection with this duplicate account, directly from any of your
            accounts (including any other duplicate account).
          </Text>
          <Text component="p" my={1}>
            * At our sole discretion (in exceptional cases),to allow the
            continued use of the main account and recognize it as valid, while
            all bets placed by the Client from the duplicate account will be
            void, the duplicate account(s) will be blocked and/or canceled by
            decision of the Company (the decision is made for each particular
            case individually, according to the extent of the violation).
          </Text>
          <Text component="p" my={1}>
            * The Client shall be responsible for keeping their password and
            account number received at registration confidential. All bets
            registered with the Company shall be valid. Bet cancellation shall
            be subject to these Rules. Should the Client’s login details come
            into the possession of a third party, the Company should be
            informed, the Client should change their username and password and
            their email password to stronger ones. The Client must not disclose
            any cash withdrawal codes or codes for changing your phone number to
            any third party.
          </Text>
          <Text component="p" my={1}>
            * The Client confirms that any activities on the account are
            performed by themselves. If the account is managed by a third party,
            the Client shall take exclusive responsibility for access to the
            account.
          </Text>
          <Text component="p" my={1}>
            * The Client shall be held liable for the violation of the
            above-mentioned paragraphs. Should these Rules be violated, the
            Company reserves the right to refuse to pay any winnings or to
            refund stakes, as well as to cancel any bets. The Company shall not
            be liable in relation to the moment when they become aware that the
            Client falls within any of the aforementioned categories. This means
            that the Company shall be entitled to take the above measures at any
            time once they have become aware that the Client is an individual
            who can be designated as above.
          </Text>
          <Text component="p" my={1}>
            * In no event shall the Company FXBet be held liable to the Client
            for any indirect, collateral, or incidental losses or damages
            (including loss of profit), even though they may have been notified
            that such losses or damages are likely to occur.
          </Text>
          <Text component="p" my={1}>
            * If an account is inactive for 3 months, the Company reserves the
            right to block it.
          </Text>
          <Text component="p" my={1}>
            * The Company reserves the right to update these Rules and add new
            provisions at any time. Such new rules or amendments shall take
            effect immediately after publication on the website.
          </Text>
          <Text component="p" my={1}>
            * What is gambling addiction? Gambling problems are associated with
            mental health issues such as depression, anxiety, and suicide. They
            can affect family relationships, professional and academic progress,
            and can even lead to bankruptcy and crime.
          </Text>
          <Text component="p" my={1}>
            * Gambling is an entertaining activity rather than a source of
            income.
          </Text>
          <Text component="p" my={1}>
            * Gambling is a thrilling and amusing pastime. Unfortunately, in
            some cases, involvement in such activities may cause problems. We
            take care of our Clients and do our best to ensure responsible use
            of our website without any negative consequences. We advertise our
            products and services in a responsible manner and will not target
            young or susceptible people.
          </Text>
          <Text component="p" my={1}>
            * Underage gambling. The Company does not allow minors (individuals
            under 18 years of age) to be involved in betting. We take every
            possible precaution to prevent a violation of this rule. We
            guarantee that our advertisements, sponsorship, and marketing
            campaigns do not contain information targeted to minors.
          </Text>
        </Stack>
        <Stack pb="lg" className={classes.stack}>
          <SectionHeader order={2}>GAMBLING ADDICTION PREVENTION</SectionHeader>
          <Text component="p" my={1}>
            No advertising campaign or promotion misleads users, nor do they
            distort the nature of the services for potentially susceptible
            Clients. Clients are informed about the chances of winning as well
            as the potential risks. Services are provided based on the amount
            paid and excessive spending is not encouraged.
          </Text>
          <Text component="p" my={1}>
            Clients are informed about the odds of winning and of the potential
            consequences and risks of losing.
          </Text>
          <Text component="p" my={1}>
            You can request your full bet history. Your withdrawals and deposits
            can be accessed through the "My Account" section.
          </Text>
          <Text component="p" my={1}>
            All Clients have a self-excluding option.
          </Text>
        </Stack>
        <Stack pb="lg" className={classes.stack}>
          <SectionHeader order={2}>GENERAL BETTING RULES</SectionHeader>
          <Text component="p" my={1}>
            The minimum and maximum stake is determined by the Company for each
            selection separately.
          </Text>
          <Text component="p" my={1}>
            A bet is considered to be accepted after its registration on the
            server and its online confirmation. Registered bets may not be
            altered or revoked.
          </Text>
          <Text component="p" my={1}>
            Bets are only accepted in amounts not exceeding the current balance
            on the Client’s account. Once a bet has been registered, the stake
            is debited from the account. After bets have been settled, the
            return is credited to the Client’s account.
          </Text>
          <Text component="p" my={1}>
            A bet shall be canceled should the Client deliberately mislead the
            staff (Company employees) by submitting false data and requests
            pertaining to betting, payouts, results of the event, or any other
            details or requests of such nature. This rule shall also apply to
            minors (individuals under 18 years of age) and their parents.
          </Text>
          <Text component="p" my={1}>
            Bets are accepted on the opening prices for major currency pairs in
            the forex market. The Client should predict whether the opening
            price in the forex market on a specified date will be more or less
            than the price quoted by the Company. Should the opening price be
            equal to that quoted by the Company, bets are settled as lost. Bets
            are settled based on the average data from…
          </Text>
          <Text component="p" my={1}>
            Should there be any discrepancies, quotations of instruments set on
            the Company’s Server shall prevail.
          </Text>
        </Stack>
        <Stack pb="lg" className={classes.stack}>
          <SectionHeader order={2}>DEPOSITS AND WITHDRAWALS</SectionHeader>
          <Text component="p" my={1}>
            There are various ways of depositing and withdrawing funds from the
            Client's account. All deposit and withdrawal methods can be found in
            the “Payments” section.
          </Text>
          <Text component="p" my={1}>
            The FXBet Security Service is entitled to:
          </Text>
          <Text component="p" my={1}>
            - decline cash withdrawal requests if deposits were made through
            e-payment systems.
          </Text>
          <Text component="p" my={1}>
            - refuse any withdrawal should the deposit or withdrawal amounts be
            inconsistent with bets placed.
          </Text>
          <Text component="p" my={1}>
            - Permitted withdrawal amounts shall be calculated based on the
            amount of the bets placed from any given deposit.
          </Text>
          <Text component="p" my={1}>
            - refuse any withdrawal if the betting account is misused. In this
            case your account must be verified before withdrawal can take place.
          </Text>
          <Text component="p" my={1}>
            The FXBet Security Service does not recommend Clients:
          </Text>
          <Text component="p" my={1}>
            - transfer funds from one payment system to another;
          </Text>
          <Text component="p" my={1}>
            - deposit and withdraw funds without placing bets;
          </Text>
          <Text component="p" my={1}>
            In the foregoing events, funds will be returned to your account.
          </Text>
          <Text component="p" my={1}>
            The Client can only withdraw funds using the same payment details
            that were used for depositing funds into your account. If the Client
            use different methods to make a deposit, withdrawals should be
            proportionate to the deposits made using any particular method.
          </Text>
          <Text component="p" my={1}>
            FXBet may refuse withdrawals via payment systems or in cash and
            offer a bank transfer as a substitute.
          </Text>
          <Text component="p" my={1}>
            Our administration does not recommend making deposits and
            withdrawing funds using someone else’s electronic wallet. Our
            Security Team reserves the right to deem such deposits to be
            fraudulent and block users’ transactions without prior notification.
            Our administration is entitled to deny withdrawals of funds using
            payment details which do not belong to the Client.
          </Text>
          <Text component="p" my={1}>
            In certain circumstances and in respect to certain Clients FXBet may
            decide not to reimburse service charges imposed by payment systems
            on deposits or withdrawals, which FXBet usually reimburses.
          </Text>
          <Text component="p" my={1}>
            If a client doesn't comply with the Company's rules (for example by
            breaching the T&C, not placing a bet before requesting a withdrawal,
            etc.), the Company reserves the right to refuse to allow that client
            to make a withdrawal.
          </Text>
        </Stack>
        <Stack pb="lg" className={classes.stack}>
          <SectionHeader order={2}>
            AML POLICY (Anti-Money Laundering/Terrorism Financing)
          </SectionHeader>
          <Text component="p" my={1}>
            The Company carries out all appropriate measures to combat money
            laundering and international terrorism. The Company takes a strong
            position to prevent all kinds of illegal activity. To fulfill its
            obligations, the Company is bound to inform competent authorities if
            there is a reason to suspect that funds which were deposited by the
            Client into his or her account are related to illegal activity or
            terrorism financing. The company is also obliged to block such funds
            and to undertake measures provided for by the rules of the AML
            policy.
          </Text>
          <Text>Money laundering means:</Text>
          <Text component="p" my={1}>
            - Hiding or keeping privacy regarding the information about the real
            source, location, disposal, movement, ownership or other property
            rights related to property obtained as a result of illegal activity,
          </Text>
          <Text component="p" my={1}>
            - Conversion, moving, obtaining, possession or use of property which
            was got as the result of criminal activity for the purpose of
            concealing the illicit source of such property or assisting persons
            involved in crime to avoid legal consequences of their actions,
          </Text>
          <Text component="p" my={1}>
            - A situation in which the property was obtained as a result of
            criminal activity committed on the territory of another state.
          </Text>
          <Text component="p" my={1}>
            In order to prevent the infiltration of criminal capital into the
            economy of the state, many countries fight against money laundering
            and terrorism financing.
          </Text>
          <Text component="p" my={1}>
            The company applies internal laws and regulations and special
            measures to help international organizations in combating money
            laundering and terrorism financing around the world.
          </Text>
          <Text component="p" my={1}>
            When you open an account, you agree to undertake the following
            obligations:
          </Text>
          <Text component="p" my={1}>
            - You agree that you comply with all applicable laws and regulations
            on combating money laundering and terrorism financing, including the
            АМL Policy.
          </Text>
          <Text component="p" my={1}>
            - You confirm that you have no information or any suspicions that
            the funds used for deposits, in the past, present or future, are
            received from any illegal source, or have any relation to
            legalization of income obtained illegally, or other unlawful
            activity prohibited by applicable law or the instructions of any
            international organizations;
          </Text>
          <Text component="p" my={1}>
            - You agree to immediately provide any information we think fit to
            require according to applicable laws and regulatory requirements in
            respect of combating the legalization of funds obtained illegally.
          </Text>
          <Text component="p" my={1}>
            The company collects and keeps the passport or other ID of the
            Client, and a record of all changes made in the account.
          </Text>
          <Text component="p" my={1}>
            The company monitors any suspicious activity on the Client’s
            account, as well as operations carried out under special conditions.
          </Text>
          <Text component="p" my={1}>
            The company has the right to ban the Client at any time, if the
            Company has grounds for supposing that transactions have any
            relation to money laundering and criminal activity. In accordance
            with international law the Company is not obliged to inform the
            Client about its suspicion or that it has been passed on to relevant
            authorities.
          </Text>
          <Text component="p" my={1}>
            In accordance with the internal AML procedures the Company performs
            initial and ongoing personal identity verification procedures as
            provided by the level of risk of each Client.
          </Text>
          <Text component="p" my={1}>
            - The company will ask you to provide basic information to confirm
            your identity.
          </Text>
          <Text component="p" my={1}>
            - The company will record and preserve all data and ID, as well as
            which methods of confirmation have been used and the results of
            verification procedures.
          </Text>
          <Text component="p" my={1}>
            - The company will check your personal data to match the list of
            persons suspected of terrorism, which is formed by the authorized
            state and independent authorities. A minimum set of identification
            data includes the Client’s full name, date of birth, and residential
            or registered address.
          </Text>
          <Text component="p" my={1}>
            - Sources of funds that you plan to deposit into the account.
          </Text>
          <Text component="p" my={1}>
            To verify and confirm the authenticity of the above-mentioned data,
            the Company may require the following documents.
          </Text>
          <Text component="p" my={1}>
            - passport or identification card, or other equivalent document that
            meets the following requirements: contains the name, date of birth
            and a photograph of the document holder;
          </Text>
          <Text component="p" my={1}>
            - issued by the national public authorities, a recently obtained
            receipt for the payment of utility bills (not older than 3 months)
            or other documents confirming the address of the Client.
          </Text>
          <Text component="p" my={1}>
            The company may also require other additional information, confirmed
            by relevant documents. In certain cases, the Company may also
            require notarized copies of documents from the Client.
          </Text>
        </Stack>
        <Stack pb="lg" className={classes.stack}>
          <SectionHeader order={2}>
            TRANSFER OF RIGHTS AND OBLIGATIONS
          </SectionHeader>
          <Text component="p" my={1}>
            The Company reserves the right to transfer, assign, sublicense or
            pledge any rights under the Rules, in whole or in part, to any
            person without notice to the Client, provided that any such
            assignment will be on the same terms or terms that are no less
            advantageous to the Client.
          </Text>
          <Text component="p" my={1}>
            The Player may not assign, sublicense or otherwise transfer or
            encumber in any manner whatsoever any of his/her rights or
            obligations under the Rules.
          </Text>
          <Text component="p" my={1}>
            In case the Player encumbers any of his/her rights under the Rules,
            it shall forfeit all its rights under the Terms towards the Company.
          </Text>
        </Stack>
        <Stack pb="lg" className={classes.stack}>
          <SectionHeader order={2}>LEGISLATION</SectionHeader>
          <Text component="p" my={1}>
            The Rules shall be governed and interpreted according to Curaç The
            Rules shall be governed and interpreted according to Curaç ao law.
            The place of the court that has jurisdiction over any dispute
            relating to the Rules is Curaçao.
          </Text>
        </Stack>
      </Paper>
    </Container>
  );
};

export default TermsAndConditions;
