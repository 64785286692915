import { Stack, Text, TextInput, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { getPlayerHistory, operatorDeposit } from '../../api/player';
import { EmptyErrorEnum, ModalHandler } from '../../api/interfaces';
import { GreenButton } from '../buttons/GreenButton';
import { useStore } from '../../state/state';

const OperatorDepositModal = ({
  userId,
  currency,
  depositModalHandler,
}: {
  userId: string;
  depositModalHandler: ModalHandler;
  currency?: string;
}) => {
  const [depositAmount, setDepositAmount] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<String>();
  const state = useStore((state) => state.auth);
  const [allowSalesAdminTopup, setAllowSalesAdminTopup] = useState(false);

  const handleDepositChange = (amount: string) => {
    let parsedAmount = parseFloat(amount) || 0;
    if (state?.user?.role === 'Sales' && parsedAmount > 20) {
      parsedAmount = 20;
      setErrorMessage('Sales role has a maximum deposit limit of 20.');
    } else {
      setErrorMessage(undefined);
    }
    setDepositAmount(parsedAmount);
  };

  const depositFunds = async () => {
    const result = await operatorDeposit(userId, depositAmount);
    if (result?.success) {
      depositModalHandler.close();
    } else {
      setErrorMessage(result?.error);
    }
  };

  useEffect(() => {
    const fetchPlayerHistory = async () => {
      const history = await getPlayerHistory(userId);
      if (history && history?.length > 0 && state?.user?.role === 'Sales') {
        setAllowSalesAdminTopup(false);
        setErrorMessage('User has already had their initial top up');
      } else {
        setAllowSalesAdminTopup(true);
      }
    };
    fetchPlayerHistory();
  }, []);

  return (
    <Stack>
      <TextInput
        label="Amount"
        icon={<Title order={3}>{currency ? currency : 'EUR'}</Title>}
        placeholder="Deposit amount"
        radius="md"
        size="lg"
        value={depositAmount}
        disabled={!allowSalesAdminTopup}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDepositChange(e.target.value)}
      />
      {errorMessage && (
        <Text color="red" align="center">
          {errorMessage}
        </Text>
      )}
      <GreenButton
        styles={{
          root: { width: '100%' },
        }}
        type="submit"
        disabled={!allowSalesAdminTopup}
        onClick={() => depositFunds()}
      >
        Submit Request
      </GreenButton>
    </Stack>
  );
};

export default OperatorDepositModal;
