import { Button, Center, Popover, Stack, createStyles, Text, Anchor } from '@mantine/core';
import { useMarketStore, useStore } from '../../../../state/state';
import { Game, GameThemeEnum, Market, MarketOpenEnum, WagerStateEnum } from '../../../../api/interfaces';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { useState } from 'react';
import { GreenButton } from '../../../../components/buttons/GreenButton';

const useSvgStyles = createStyles((theme, params: { color: string }) => {
  return {
    svg: {
      color: theme.white,
      height: '40px',
      width: 'auto',
      paddingLeft: '12px',
    },
  };
});

export const PlaceWagerButton = (props: { game: Game; market: Market; chartLoading: boolean }) => {
  const [opened, setOpened] = useState(false);
  const { wager, wagering, placeWager } = useMarketStore(props.market)(
    (state) => ({ wager: state.wager, wagering: state.wagering, placeWager: state.placeWager }),
    shallow
  );
  const balance = useStore((state) => state.balance?.balance ?? 0);
  const clearWager = useMarketStore(props.market)((state) => state.clearWager);

  const isCompletedWager = () =>
    wager?.state === WagerStateEnum.Won ||
    wager?.state === WagerStateEnum.Lost ||
    wager?.state === WagerStateEnum.Draw ||
    wager?.state === WagerStateEnum.Cancelled;

  const { classes } = useSvgStyles({ color: props.game.color });

  if (wager?.state === WagerStateEnum.Active || wager?.state === WagerStateEnum.Pending) {
    return null;
  }

  const disabled =
    wagering.stake > balance ||
    wagering.stake < props.market.minWager ||
    wagering.stake > props.market.maxWager ||
    props.market.open === MarketOpenEnum.Closed ||
    props.market.open === MarketOpenEnum.Full ||
    props.chartLoading;

  return (
    <Center>
      <Popover
        opened={opened && wagering.stake > balance}
        onClose={() => setOpened(false)}
        position="top"
        placement="center"
        withArrow
        trapFocus={false}
        closeOnEscape={false}
        transition="pop-top-left"
        width={260}
        target={
          <span onMouseEnter={() => setOpened(true)}>
            <Button
              disabled={disabled}
              variant="filled"
              radius="lg"
              size="lg"
              sx={(theme) => ({
                [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
                  width: '100%',
                },
                backgroundColor: props.game.color,
                color: props.game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(props.game.color, 0.8),
                'svg g path': {
                  fill: `${
                    props.game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(props.game.color, 0.8)
                  } !important`,
                },
                '&:hover': {
                  backgroundColor: theme.fn.darken(props.game.color, 0.2),
                },
                '&:disabled': {
                  background: `${theme.fn.rgba(props.game.color, 0.7)} !important`,
                  color: `${theme.fn.lighten(props.game.color, 0.5)} !important`,
                  'svg g path': {
                    fill: `${theme.fn.lighten(props.game.color, 0.5)} !important`,
                  },
                },
              })}
              onClick={() => {
                isCompletedWager() ? clearWager() : placeWager();
              }}
            >
              {wagering.stake <= balance ? (
                isCompletedWager() ? (
                  <>
                    PLAY AGAIN TO WIN{' '}
                    <img style={{ height: '30px', width: '30px', marginLeft: '8px' }} src={props.game.iconAction}></img>
                  </>
                ) : (
                  <>
                    PLACE BET <SVG src={props.game.iconGame} className={classes.svg} />
                  </>
                )
              ) : (
                'Insufficient Funds'
              )}
            </Button>
            {/*isCompletedWager() &&
              <Button
                onClick={() => placeWager()}
                disabled={disabled}
                variant="filled"
                radius="lg"
                size="lg"
                sx={(theme) => ({
                  width: '390px',
                  [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
                    width: '100%',
                  },
                  backgroundColor: props.game.color,
                  color: props.game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(props.game.color, 0.8),
                  'svg g path': {
                    fill: `${props.game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(props.game.color, 0.8)
                      } !important`,
                  },
                  '&:hover': {
                    backgroundColor: theme.fn.darken(props.game.color, 0.2),
                  },
                  '&:disabled': {
                    background: `${theme.fn.rgba(props.game.color, 0.7)} !important`,
                    color: `${theme.fn.lighten(props.game.color, 0.5)} !important`,
                    'svg g path': {
                      fill: `${theme.fn.lighten(props.game.color, 0.5)} !important`,
                    },
                  },
                })}
              >
                REPEAT
              </Button>*/}
          </span>
        }
      >
        <Stack align="center">
          <Text align="center">Please make a deposit in order to place this bet</Text>
          <Anchor component={Link} to="/wallet/deposit">
            <GreenButton> Deposit Now</GreenButton>
          </Anchor>
        </Stack>
      </Popover>
    </Center>
  );
};
