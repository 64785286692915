import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => {
  return {
    anchor: {
      display: 'block',
      color: '#b5d6f4',
      fontWeight: 500,
      paddingLeft: 10,
    },
    glossary: {
      padding: 25,
      gap: '0.5rem',
    },
    paper: {
      padding: 50,
      gap: '1rem',
      lineHeight: 1.35,
    },
    bullets:{
      listStylePosition: 'outside',
    },
    stack: {
      borderBottom: '1px solid #0000002b;',
    },
  };
});

export { useStyles };
