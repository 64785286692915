import { Button, Text, useMantineTheme } from '@mantine/core';
import { Duration, Game, GameThemeEnum } from '../../api/interfaces';
import { formatDurationUnit } from '../../utils/formatters';

export const DurationButton = (props: { duration: Duration; game: Game; selected: boolean; onClick: () => void }) => {
  const theme = useMantineTheme();

  return (
    <Button
      variant="filled"
      radius="md"
      size="lg"
      onClick={props.onClick}
      styles={{
        root: {
          backgroundColor: theme.fn.rgba(props.game.color, props.selected ? 1 : 0.5),
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          height: '45px',
          width: '50px',
          padding: '0',
          '&:hover': {
            backgroundColor: theme.fn.darken(props.game.color, 0.2),
          },
          [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            '&:hover': {
              backgroundColor: props.game.color,
            },
            height: '',
            width: 'auto',
            padding: '0 4px',
          },
          ".mantine-Text-root": {
            color: `${props.game.theme === GameThemeEnum.Light ? theme.white : theme.fn.darken(props.game.color, 0.8)} !important`,
          },
        },
        label: {
          div: {
            lineHeight: 1,
            fontWeight: 'bold',
          },
        },
      }}
    >
      <Text size="xs">
        {props.duration.value}
        <br />
        {formatDurationUnit(props.duration.value, props.duration.unit, true)}
      </Text>
    </Button>
  );
};
