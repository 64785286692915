import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => {
  return {
    container: {
      padding: '16px', 
      backgroundColor: '#000', 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      borderBottom: '3px solid #7C37BD'
    },
    titleAlignment: {
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: { textAlign: 'center' },
    },
    pdfIcon: {
      width: '35px',
      height: '35px',
      fill: '#fff',
    },
    fullHeight: {
      height: '100%',
      width: '100%',
    },
  };
});

export { useStyles };
