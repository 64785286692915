import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => {
  return {
    reasonImage: {
      height: '100%',
      objectFit: 'contain',
      maxHeight: '140px',
      maxWidth: '140px',
    },
    reasonText: {
      maxWidth: '250px',
    },
    blackBar: {
      backgroundColor: '#071924',
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '85%',
      alignSelf: 'center',
      padding: '8px',
    },
    bottomWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    dFlex: {
      display: 'flex',
      alignItems: 'center',
    },
    bottomText: {
      maxWidth: '60%',
      textAlign: 'center',
    },
    TopSpacing: {
      marginTop: '32px',
    },
  };
});
