import { Stack } from '@mantine/core';
import { useEffect } from 'react';
import { getGames } from '../../api/game';
import { useStore } from '../../state/state';
import LandingPageHeader from '../../components/landingPageHeader/landingPageHeader';
import { GameList } from '../games/list';
import LandingPageFooter from '../../components/landingPageFooter/landingPageFooter';

const Landing = () => {
  const games = useStore((state) => state.games);
  const authState = useStore((state) => state.auth);

  const setHeaderMode = useStore((state) => state.setHeaderMode);
  useEffect(() => {
    setHeaderMode('landing');
  }, [setHeaderMode]);

  useEffect(() => {
    if (!games || games.length === 0) {
      getGames();
    }
  }, [games]);

  if (authState.isAuthenticated) {
    if (authState.user?.role?.includes('Sales') || authState.user?.role?.includes('Admin')) {
      window.location.href = '/admin/dashboard';
    } else {
      window.location.href = '/play';
    }
    return null;
  }

  return (
    <Stack spacing={64}>
      <LandingPageHeader />
      <LandingPageFooter />
    </Stack>
  );
};

export { Landing };
