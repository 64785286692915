import { useEffect, useState } from 'react';
import { PlayerAccount, WagerActivityTimeFilter, WeekCount } from '../../../api/interfaces';
import { useStyles } from '../styles';
import { Box, Divider, Grid, Group, Table, Title, Text, Space, Anchor } from '@mantine/core';
import {
  formatCurrency,
  formatCurrencySymbol,
  formatDate,
  formatPlayerFullName,
  getCurrentDate,
} from '../../../utils/formatters';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { HubConnectionState } from '@microsoft/signalr';
import { useStore } from '../../../state/state';
import { useNavigate } from 'react-router-dom';
import ExcelDownload from '../../../components/reporting/ExcelDownload';

const PlayerInsights = ({
  playerAccounts,
  timeSpan,
}: {
  playerAccounts: PlayerAccount[];
  timeSpan: WagerActivityTimeFilter;
}) => {
  const { classes } = useStyles();
  const [totalActiveAccounts, setTotalActiveAccounts] = useState(0);
  const [totalDeactivatedAccounts, setTotalDeactivatedAccounts] = useState(0);
  const [newPlayers, setNewPlayers] = useState<WeekCount[]>();
  const [playersOnline, setPlayersOnline] = useState(0);
  const hub = useStore((state) => state.hub);
  const auth = useStore((state) => state.auth);
  const filename = `Player_Statistics_${getCurrentDate()}`;

  const navigate = useNavigate();

  const countAccountStatus = (playerAccounts: PlayerAccount[]) => {
    const { totalActiveAccounts, totalDeactivatedAccounts } = playerAccounts.reduce(
      (counts, account) => {
        if (!account.isBanned && !account.isClosed) {
          return {
            ...counts,
            totalActiveAccounts: counts.totalActiveAccounts + 1,
          };
        } else {
          return {
            ...counts,
            totalDeactivatedAccounts: counts.totalDeactivatedAccounts + 1,
          };
        }
      },
      { totalActiveAccounts: 0, totalDeactivatedAccounts: 0 }
    );
    setTotalActiveAccounts(totalActiveAccounts);
    setTotalDeactivatedAccounts(totalDeactivatedAccounts);
  };

  const sortByMostLost = (playerAccounts: PlayerAccount[]) => {
    const sortedPlayers = [...playerAccounts];
    sortedPlayers.sort((a, b) => a.profit - b.profit);
    return sortedPlayers;
  };

  const sortByMostWon = (playerAccounts: PlayerAccount[]) => {
    const sortedPlayers = [...playerAccounts];
    sortedPlayers.sort((a, b) => b.profit - a.profit);
    return sortedPlayers;
  };

  const sortPlayersByMinutesTotal = (playerAccounts: PlayerAccount[]) => {
    const sortedPlayers = [...playerAccounts];
    sortedPlayers.sort((a, b) => b.wagerCount - a.wagerCount);
    return sortedPlayers;
  };

  const getWeekCountsFromPlayerAccounts = (playerAccounts: PlayerAccount[]) => {
    const weekCounts: WeekCount[] = [];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentMonthTotalDays = currentDate.getDate();

    for (let day = 1; day <= currentMonthTotalDays; day++) {
      let found = false;
      const week = Math.ceil(day / 7);
      for (const item of weekCounts) {
        if (item.week === week) {
          found = true;
          break;
        }
      }

      if (!found) {
        weekCounts.push({ week, count: 0 });
      }
    }

    for (const item of playerAccounts) {
      const registeredDate = new Date(item.registeredDate);
      const registeredMonth = registeredDate.getMonth();

      if (item.registeredDate && currentMonth === registeredMonth) {
        const week = Math.ceil(registeredDate.getDate() / 7);

        for (const item of weekCounts) {
          if (item.week === week) {
            item.count++;
            break;
          }
        }
      }

      const modifiedData = weekCounts.map((item) => ({
        ...item,
        week: 'W ' + item.week,
      }));
      setNewPlayers(modifiedData);
    }
  };

  useEffect(() => {
    if (playerAccounts) {
      countAccountStatus(playerAccounts);
      getWeekCountsFromPlayerAccounts(playerAccounts);
    }
  }, [playerAccounts, timeSpan]);

  useEffect(() => {
    if (hub?.state === HubConnectionState.Connected) {
      try {
        hub.send('subscribeOnlinePlayerCount');
        hub.on('updateOnlinePlayerCount', (OnlinePlayerCount: number) => {
          setPlayersOnline(OnlinePlayerCount);
        });
      } catch {
        console.log('error calling hub method');
      }
    }
    return () => {
      if (hub?.state === HubConnectionState.Connected) {
        hub.off('updateOnlinePlayerCount');
        hub.send('unsubscribeOnlinePlayerCount');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hub, hub?.state]);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Title align="left" sx={{ flexGrow: 1 }} order={1}>
          Player Quick Insights
        </Title>
        {auth?.user?.role?.includes('SuperAdmin') && (
          <ExcelDownload
            content={playerAccounts}
            defaultFields={[
              'firstName',
              'lastName',
              'email',
              'playtime_lastActive',
              'quickStats_totalPlayed',
              'winCount',
              'won',
              'lostCount',
              'lost',
              'registeredDate',
            ]}
            fileName={filename}
            headers={[
              'First Name',
              'Last Name',
              'Email',
              'Last Active',
              'Number of Games Played',
              'Win Count',
              'Total Won',
              'Loss Count',
              'Total Lost',
              'Registered Date',
            ]}
          />
        )}
      </Box>
      <Divider size={2} sx={{ margin: '24px 0px' }} />
      <Grid>
        <Grid.Col span={12}>
          <Group grow>
            <Box className={classes.container}>
              <Group sx={{ height: '100%' }}>
                <Title order={2}>Total Active Accounts</Title>
                <Title order={2}>{totalActiveAccounts}</Title>
              </Group>
            </Box>
            <Box className={classes.container}>
              <Group sx={{ height: '100%' }}>
                <Title order={2}>Players Currently Online</Title>
                <Title order={2}>{playersOnline}</Title>
              </Group>
            </Box>
            <Box className={classes.container}>
              <Group sx={{ height: '100%' }}>
                <Title order={2}>Total Players Over Time</Title>
                <Title order={2}>{playerAccounts.length}</Title>
              </Group>
            </Box>
            <Box className={classes.container}>
              <Group sx={{ height: '100%' }}>
                <Title order={2}>Deactivated Accounts</Title>
                <Title order={2}>{totalDeactivatedAccounts}</Title>
              </Group>
            </Box>
          </Group>
        </Grid.Col>
        <Grid.Col span={6}>
          <Box className={classes.container} sx={{ width: '100%' }}>
            <Title order={2}>{`New Player over ${timeSpan}`}</Title>
            <Divider size={2} sx={{ margin: '16px 0px' }} />
            <ResponsiveContainer width="100%" height={370}>
              <LineChart data={newPlayers}>
                <CartesianGrid vertical={false} />
                <XAxis dataKey="week" stroke="white" />
                <YAxis dataKey="count" stroke="white" />
                <Tooltip />
                <Line type="linear" dataKey="count" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Grid.Col>
        <Grid.Col span={6}>
          <Box className={classes.container} sx={{ width: '100%' }}>
            <Title sx={{ flexGrow: 1 }} order={2}>
              <Title order={2}>{`Most Active Players Over ${timeSpan}`}</Title>
            </Title>
            <Space h="md" />
            <Table>
              <thead className={classes.tableHeader}>
                <tr>
                  <th>
                    <Text>Rank</Text>
                  </th>
                  <th>
                    <Text>Player</Text>
                  </th>
                  <th>
                    <Text align="center">Player Since</Text>
                  </th>
                  <th>
                    <Text align="center">Last Online</Text>
                  </th>
                  <th>
                    <Text align="center">Total Bets</Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {playerAccounts &&
                  sortPlayersByMinutesTotal(playerAccounts)
                    .slice(0, 10)
                    .map((account, index) => {
                      const formattedLastActive = account.playtime.lastActive
                        ? formatDate(account.playtime.lastActive.toLocaleString(), false)
                        : '';
                      return (
                        <tr>
                          <td>
                            <Text>{index + 1}</Text>
                          </td>
                          <td>
                            <Text lineClamp={1}>
                              <Anchor onClick={() => navigate(`/admin/player-overview/player-account/${account.id}`)}>
                                {formatPlayerFullName(account.firstName, account.lastName)}
                              </Anchor>
                            </Text>
                          </td>
                          <td>
                            <Text align="center">
                              {account.registeredDate ? formatDate(account.registeredDate.toLocaleString(), false) : ''}
                            </Text>
                          </td>
                          <td align="center">
                            <Text>{formattedLastActive}</Text>
                          </td>
                          <td>
                            <Text align="center">{account.wagerCount}</Text>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </Box>
        </Grid.Col>
        <Grid.Col span={6}>
          <Box className={classes.container} sx={{ width: '100%' }}>
            <Title sx={{ flexGrow: 1 }} order={2}>
              <Title order={2}>{`Top Winning Players Over ${timeSpan}`}</Title>
            </Title>
            <Space h="md" />
            <Table>
              <thead className={classes.tableHeader}>
                <tr>
                  <th>
                    <Text>Rank</Text>
                  </th>
                  <th>
                    <Text>Player</Text>
                  </th>
                  <th>
                    <Text align="center">Last Online </Text>
                  </th>
                  <th>
                    <Text align="center">Total Wins</Text>
                  </th>
                  <th>
                    <Text align="center">Total Won</Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {playerAccounts &&
                  sortByMostWon(playerAccounts)
                    .slice(0, 10)
                    .map((account, index) => {
                      const formattedLastActive = account.playtime.lastActive
                        ? formatDate(account.playtime.lastActive.toLocaleString(), false)
                        : '';
                      return (
                        <tr>
                          <td>
                            <Text>{index + 1}</Text>
                          </td>
                          <td>
                            <Text lineClamp={1}>
                              <Anchor onClick={() => navigate(`/admin/player-overview/player-account/${account.id}`)}>
                                {formatPlayerFullName(account.firstName, account.lastName)}
                              </Anchor>
                            </Text>
                          </td>
                          <td align="center">
                            <Text>{formattedLastActive}</Text>
                          </td>
                          <td>
                            <Text align="center">{account.winCount}</Text>
                          </td>
                          <td>
                            <Text align="center" sx={{ color: '#88c32c' }}>
                              {formatCurrencySymbol(account.currency)}
                              {formatCurrency(account.profit)}
                            </Text>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </Box>
        </Grid.Col>
        <Grid.Col span={6}>
          <Box className={classes.container} sx={{ width: '100%' }}>
            <Title sx={{ flexGrow: 1 }} order={2}>
              <Title order={2}>{`Top Losing Players Over ${timeSpan}`}</Title>
            </Title>
            <Space h="md" />
            <Table>
              <thead className={classes.tableHeader}>
                <tr>
                  <th>
                    <Text>Rank</Text>
                  </th>
                  <th>
                    <Text>Player</Text>
                  </th>
                  <th>
                    <Text align="center">Last Online </Text>
                  </th>
                  <th>
                    <Text align="center">Total Losses</Text>
                  </th>
                  <th>
                    <Text align="center">Total Lost</Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {playerAccounts &&
                  sortByMostLost(playerAccounts)
                    .slice(0, 10)
                    .map((account, index) => {
                      const formattedLastActive = account.playtime.lastActive
                        ? formatDate(account.playtime.lastActive.toLocaleString(), false)
                        : '';
                      return (
                        <tr>
                          <td>
                            <Text>{index + 1}</Text>
                          </td>
                          <td>
                            <Text lineClamp={1}>
                              <Anchor onClick={() => navigate(`/admin/player-overview/player-account/${account.id}`)}>
                                {formatPlayerFullName(account.firstName, account.lastName)}
                              </Anchor>
                            </Text>
                          </td>
                          <td align="center">
                            <Text>{formattedLastActive}</Text>
                          </td>
                          <td>
                            <Text align="center">{account.lostCount}</Text>
                          </td>
                          <td>
                            <Text align="center" sx={{ color: '#eb6c35' }}>
                              {formatCurrencySymbol(account.currency)}
                              {formatCurrency(account.profit)}
                            </Text>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </Box>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default PlayerInsights;
