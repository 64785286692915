import { Anchor } from '@mantine/core';
import { useStyles } from './styles';

function Glossary() {
  const { classes } = useStyles();
  return (
    <>
      <Anchor className={classes.anchor} href="#introduction">
        Introduction
      </Anchor>
      <Anchor className={classes.anchor} href="#internal_controls">
        Application of Internal Controls (processes & procedures)
      </Anchor>
      <Anchor className={classes.anchor} href="#risk">
        Risk Based Approach and Risk Assessment Document
      </Anchor>
      <Anchor className={classes.anchor} href="#kyc">
        Customer Verification and Due Diligence
      </Anchor>
      <Anchor className={classes.anchor} href="#mlro">
        Role of the MLRO
      </Anchor>
      <Anchor className={classes.anchor} href="#reporting">
        Suspicious Activity and Transaction Reporting
      </Anchor>
      <Anchor className={classes.anchor} href="#record_keeping">
        Record Keeping
      </Anchor>
      <Anchor className={classes.anchor} href="#conclusion">
        Conclusion
      </Anchor>
      <Anchor className={classes.anchor} href="#glossary">
        Glossary of Abbreviations
      </Anchor>
    </>
  );
}
export default Glossary;
