import React, { useCallback, useEffect, useState } from 'react';
import { Box, Text } from '@mantine/core';

interface ReportDownloadProps {
  content: any[] | any;
  defaultFields: any[];
  headers: string[];
  fileName: string;
}

const ExcelDownload: React.FC<ReportDownloadProps> = ({ content, defaultFields, headers, fileName }) => {
  const [worker, setWorker] = useState<Worker | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const newWorker = new Worker(new URL('./webWorker.ts', import.meta.url));
    setWorker(newWorker);
    newWorker.onmessage = (e) => {
      const blob = e.data;

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setIsDownloading(false);
    };

    return () => {
      newWorker.terminate();
    };
  }, [fileName]);

  const handleDownload = useCallback(() => {
    if (worker && !isDownloading) {
      setIsDownloading(true);
      worker.postMessage({ content, defaultFields, headers });
    }
  }, [worker, content, defaultFields, headers, fileName, isDownloading]);

  if (!content || content == null) return null;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Text
        weight={'bold'}
        underline={!isDownloading}
        onClick={handleDownload}
        sx={{
          cursor: isDownloading ? 'default' : 'pointer',
          ':hover': {
            textDecoration: isDownloading ? 'none' : 'underline',
          },
        }}
      >
        {isDownloading ? 'Downloading...' : 'Download Report (Excel)'}
      </Text>
    </Box>
  );
};

export default ExcelDownload;
