import React, { useEffect, useRef } from 'react';
import { useBatchRendering } from './BatchRenderingContext';

export const BatchRenderer = () => {
  const { componentsToRender } = useBatchRendering();
  const animationFrameId = useRef(0);

  useEffect(() => {
    const animate = () => {
      componentsToRender.forEach((component) => component());
      animationFrameId.current = requestAnimationFrame(animate);
    };

    if (componentsToRender.length > 0) {
      animationFrameId.current = requestAnimationFrame(animate);
    }

    return () => {
      cancelAnimationFrame(animationFrameId.current);
    };
  }, [componentsToRender]);

  return null;
};
