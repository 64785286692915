import { Stack, Text, TextInput, Title } from '@mantine/core';
import { useState } from 'react';
import { operatorWithdrawal } from '../../api/player';
import { EmptyErrorEnum, ModalHandler } from '../../api/interfaces';
import { GreenButton } from '../buttons/GreenButton';

const OperatorWithdrawalModal = ({
  userId,
  currency,
  withdrawalModalHandler,
}: {
  userId: string;
  withdrawalModalHandler: ModalHandler;
  currency?: string;
}) => {
  const [withdrawAmount, setWithdrawAmount] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<EmptyErrorEnum>();

  const handleWithdrawChange = (amount: string) => {
    setWithdrawAmount(parseFloat(amount) || 0);
  };

  const withdrawFunds = async () => {
    const result = await operatorWithdrawal(userId, withdrawAmount);
    if (result?.success) {
      withdrawalModalHandler.close();
    } else {
      setErrorMessage(result?.error);
    }
  };

  return (
    <Stack>
      <TextInput
        label="Amount"
        icon={<Title order={3}>{currency ? currency : 'EUR'}</Title>}
        placeholder="Withdrawl amount"
        radius="md"
        size="lg"
        value={withdrawAmount}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleWithdrawChange(e.target.value)}
      />
      <Text>{errorMessage}</Text>
      <GreenButton
        styles={{
          root: { width: '100%' },
        }}
        type="submit"
        onClick={() => withdrawFunds()}
      >
        Submit Request
      </GreenButton>
    </Stack>
  );
};

export default OperatorWithdrawalModal;
