import { Box, Button, Divider, Group, Stack, Table, Title, Space } from '@mantine/core';
import { MarketStatistics, WagerActivityTimeFilter } from '../../../api/interfaces';
import { ActiveGradient, InActiveGradient } from '../../../utils/consts';
import { Dispatch, SetStateAction } from 'react';
import { useStyles } from '../styles';

const GameStats = ({
  activeFilter,
  marketStatistics,
  setActiveFilter,
}: {
  activeFilter: WagerActivityTimeFilter;
  marketStatistics: MarketStatistics[];
  setActiveFilter: Dispatch<SetStateAction<WagerActivityTimeFilter>>;
}) => {
  const { classes } = useStyles();
  const filterButtons: WagerActivityTimeFilter[] = ['24 Hours', '7 Days', '1 Month', '6 Months'];

  return (
    <>
      <Title>Game Statistics & Insights</Title>
      <Divider size={2} sx={{ margin: '24px 0px' }} />
      <Box sx={{ display: 'flex', margin: '24px 0px' }}>
        <Stack spacing="md">
          <Title align="left" order={2}>
            Choose time span
          </Title>
          {filterButtons.map((button, index) => (
            <Button
              key={index}
              onClick={() => setActiveFilter(button)}
              variant="gradient"
              radius="lg"
              gradient={activeFilter === button ? ActiveGradient : InActiveGradient}
              sx={{ height: '50px', width: '190px' }}
            >
              <Title order={3}>{button}</Title>
            </Button>
          ))}
        </Stack>
        <Box
          sx={{
            flexGrow: 1,
            marginLeft: '16px',
          }}
        >
          <Group grow>
            <Box className={classes.container}>
              <Title order={2} align="center">
                Most Bets Placed
              </Title>
              <Space h={40}></Space>
              <Table>
                <tbody>
                  {[...marketStatistics]
                    .sort((a, b) => b.totalBetsCount - a.totalBetsCount)
                    .splice(0, 5)
                    .map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Title sx={{ fontSize: '18px' }}>{index + 1}</Title>
                        </td>
                        <td>
                          <Title sx={{ fontSize: '18px' }}>{item.gameName}</Title>
                        </td>
                        <td>
                          <Title sx={{ fontSize: '18px' }}>{item.currencyPair}</Title>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Box>
            <Box className={classes.container}>
              <Title order={2} align="center">
                Most Recently Played
              </Title>
              <Space h={40}></Space>
              <Table>
                <tbody>
                  {[...marketStatistics]
                    .sort((a, b) => new Date(b.lastWager).getTime() - new Date(a.lastWager).getTime())
                    .splice(0, 5)
                    .map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Title sx={{ fontSize: '18px' }}>{index + 1}</Title>
                        </td>
                        <td>
                          <Title sx={{ fontSize: '18px' }}>{item.gameName}</Title>
                        </td>
                        <td>
                          <Title sx={{ fontSize: '18px' }}>{item.currencyPair}</Title>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Box>
            <Box className={classes.container}>
              <Title order={2} align="center">
                Most Player Wins
              </Title>
              <Space h={40}></Space>
              <Table>
                <tbody>
                  {[...marketStatistics]
                    .sort((a, b) => b.totalWinsCount - a.totalWinsCount)
                    .splice(0, 5)
                    .map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Title sx={{ fontSize: '18px' }}>{index + 1}</Title>
                        </td>
                        <td>
                          <Title sx={{ fontSize: '18px' }}>{item.gameName}</Title>
                        </td>
                        <td>
                          <Title sx={{ fontSize: '18px' }}>{item.currencyPair}</Title>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Box>
            <Box className={classes.container}>
              <Title order={2} align="center">
                Most Player Losses
              </Title>
              <Space h={40}></Space>
              <Table>
                <tbody>
                  {[...marketStatistics]
                    .sort((a, b) => b.totalLossesCount - a.totalLossesCount)
                    .splice(0, 5)
                    .map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Title sx={{ fontSize: '18px' }}>{index + 1}</Title>
                        </td>
                        <td>
                          <Title sx={{ fontSize: '18px' }}>{item.gameName}</Title>
                        </td>
                        <td>
                          <Title sx={{ fontSize: '18px' }}>{item.currencyPair}</Title>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Box>
          </Group>
        </Box>
      </Box>
    </>
  );
};

export default GameStats;
