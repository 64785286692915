import { Game, Market, Wager } from '../../../api/interfaces';
import { MarketPicker } from './placeWager/marketPicker';
import { WagerSummary } from './watchWager/wagerSummary';

export const PreGraph = (props: { game: Game; market: Market; wager?: Wager; multiview?: boolean | undefined }) => {
  return props.wager ? (
    <WagerSummary game={props.game} market={props.market} wager={props.wager} />
  ) : (
    <MarketPicker game={props.game} market={props.market} multiview={props.multiview} />
  );
};
