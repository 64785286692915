import { Divider, Group, Modal, Stack, Title, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useEffect } from 'react';
import { useStore } from '../../state/state';
import { GreenButton } from '../buttons/GreenButton';
import { PurpleButton } from '../buttons/PurpleButton';

export const ResponsibleGamingModal = () => {
  const [modalState, setModalState] = useDisclosure(false);
  const playerState = useStore((state) => state.player);

  useEffect(() => {
    if (playerState?.selfExclusionCause && playerState?.selfExclusionCause !== 'None') {
      setModalState.open();
    }
  }, [playerState?.selfExclusionCause, playerState?.selfExcludedUntil]);

  const GenericModal = (
    <>
      <Stack align="center" spacing={32}>
        <Stack align="center">
          <Title order={2}>Responsible Gaming - {playerState?.selfExclusionCause?.toUpperCase()}</Title>
          <Divider size={2} />
          {playerState?.selfExclusionCause === 'Losses' && (
            <Text align="center">
              This notification is a reminder that you have reached your responsible gaming losses limit.
            </Text>
          )}
          {playerState?.selfExclusionCause === 'SessionDuration' && (
            <Text align="center">
              This notification is a reminder that you have reached your responsible gaming time limit.
            </Text>
          )}
          {playerState?.selfExclusionCause === 'Wagers' && (
            <Text align="center">
              This notification is a reminder that you have reached your responsible gaming wagers limit.
            </Text>
          )}
          <Text align="center">
            You purposefully created these limitations so that you would receive this reminder.
          </Text>
        </Stack>
        <Group>
          <GreenButton
            onClick={() => setModalState.close()}
            type="submit"
            styles={{
              root: { minWidth: 'fit-content' },
            }}
          >
            <Stack spacing={0}>
              <Text weight="bold">Thank you</Text>
              <Text size="xs">for being responsible</Text>
            </Stack>
          </GreenButton>
        </Group>
      </Stack>
    </>
  );

  const depositModal = (
    <>
      <Stack align="center" spacing={32}>
        <Stack align="center">
          <Title order={2}>Responsible Gaming - DEPOSIT</Title>
          <Divider size={2} />
          <Text align="center">Continuing with this deposit means you will exceed your responsible gaming limits.</Text>
          <Text align="center">You created these limits purposefully, how would you like to proceed?</Text>
        </Stack>
        <Group direction="row" noWrap={true}>
          <PurpleButton
            type="submit"
            styles={{
              root: { minWidth: 'fit-content' },
            }}
          >
            <Stack spacing={0}>
              <Text weight="bold">Cancel</Text>
              <Text size="xs">Do not exceed limits</Text>
            </Stack>
          </PurpleButton>
          <GreenButton
            type="submit"
            styles={{
              root: { minWidth: 'fit-content' },
            }}
          >
            <Stack spacing={0}>
              <Text weight="bold">Proceed</Text>
              <Text size="xs">Exceed responsible limits</Text>
            </Stack>
          </GreenButton>
        </Group>
      </Stack>
    </>
  );

  return (
    <>
      <Modal
        opened={modalState}
        onClose={() => setModalState.open()}
        centered={true}
        padding={32}
        radius={10}
        withCloseButton={false}
        styles={(theme) => ({ modal: { background: theme.other.gradientBlueSolid } })}
      >
        {playerState?.selfExclusionCause !== 'None' && <>{GenericModal}</>}
        {/* not sure what the logic is here when triggering the deposit modal as the enum is ""None" | "Losses" | "Wagers" | "SessionDuration"" which seem to match the generic modal on mockups */}
        {/* {playerState?.selfExclusionCause === "DEPOSIT" && (
            <>
              {GenericModal}
            </>
          )
          } */}
      </Modal>
    </>
  );
};

export default ResponsibleGamingModal;
