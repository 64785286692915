import { createStyles, Stack, Title, Text, Paper } from '@mantine/core';
import { Game, GameThemeEnum, Market, Wager, WagerStateEnum } from '../../../../api/interfaces';
import SVG from 'react-inlinesvg';
import Winner from '../../../../assets/images/winning.svg';
import { formatCurrency, toMaxPrecision } from '../../../../utils/formatters';
import { ReactComponent as IconEuro } from '../../../../assets/icons/icon-currency-euro.svg';
import { SoundManager } from '../../../../utils/SoundManager';

const soundManager = SoundManager.getInstance();

const useStyles = createStyles((theme) => {
  return {
    completionWrapper: {
      position: 'relative',
      width: '100%',
    },
    centerAlign: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
});

export const WagerCompleted = (props: { game: Game; market: Market; wagerState: WagerStateEnum; wager?: Wager }) => {
  const { classes } = useStyles();

  return (
    <Stack align="center">
      <div className={classes.completionWrapper}>
        {props.wagerState === WagerStateEnum.Won && (
          <>
            <SVG src={Winner} />
            <Title sx={{ fontSize: '24px', lineHeight: 1, position: 'absolute', bottom: '16px', width: '100%' }}>
              <div className={classes.centerAlign}>
                <IconEuro style={{ color: '#fff', height: '22px', marginRight: '4px' }} />
                <span> {formatCurrency(props.wager?.won ?? 0)}</span>
              </div>
            </Title>
          </>
        )}
        {props.wagerState === WagerStateEnum.Cancelled && (
                    <Paper
                    radius="md"
                    p={8}
                    sx={(theme) => ({
                      maxWidth: '220px',
                      textAlign: 'center',
                      margin: '32px auto 0 auto',
                    })}
                  >
                    <Stack sx={{
                      gap: '5px' ,
                      padding: '15px 0'
                    }}>
                      <Title sx={{ fontSize: '18px', fontWeight: 700 }} align="center">
                        Wager Cancelled 
                      </Title>
                      <Text sx={{ fontSize: '13px' }}>
                        You have been paid out €{formatCurrency(props.wager?.won ?? 0)}
                      </Text>
                    </Stack>
                  </Paper>
        )}
        {(props.wagerState === WagerStateEnum.Lost || props.wagerState === WagerStateEnum.Draw) && (
          <Paper
            radius="md"
            p={8}
            sx={(theme) => ({
              maxWidth: '220px',
              textAlign: 'center',
              margin: '32px auto 0 auto',
            })}
          >
            <Stack sx={{
              gap: '5px' ,
              padding: '15px 0'
            }}>
              <Title sx={{ fontSize: '18px', fontWeight: 700 }} align="center">
                {props.wagerState === WagerStateEnum.Lost ? 'You lost this round' : 'The game is a DRAW'}
              </Title>
              <Text sx={{ fontSize: '13px' }}>
                {props.wagerState === WagerStateEnum.Lost ? `The feed didn't ${props.wager?.type.toLowerCase()}` : `There is no change in the feed`}
              </Text>
            </Stack>
          </Paper>
        )}
      </div>
    </Stack>
  );
};
