import { Group, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { useStyles } from './styles';
import { useStore } from '../../state/state';
import RoundIcon from '../roundIcon/roundIcon';
import { ReactComponent as HourGlass } from '../../assets/icons/hourglass_empty.svg';
import { ReactComponent as CreditCard } from '../../assets/icons/credit_card.svg';
import { ReactComponent as Graph } from '../../assets/icons/graph.svg';
import { ReactComponent as Trophy } from '../../assets/icons/trophy.svg';
import { useMediaQuery } from '@mantine/hooks';
import { GreenButton } from '../buttons/GreenButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { GameList } from '../../pages/games/list';

const LandingPageHeader = () => {
  const { classes } = useStyles();
  const isAuthenticated = useStore((state) => state.auth.isAuthenticated);
  const mobileView = useMediaQuery(`(max-width: ${useMantineTheme().breakpoints.xs}px)`);
  const location = useLocation();
  const signupUrl = `/signup${location.search}`;
  const navigate = useNavigate();

  return (
    <Stack spacing={56} align="center">
      {/* <Title align="center" sx={{ fontSize: '66px !important' }}>
        Welcome to FXBet
      </Title> */}
      {/* <Title order={1}>So many ways to WIN!</Title> */}
      <GreenButton
        onClick={() => navigate(signupUrl)}
        styles={{
          root: { minWidth: 'fit-content', padding: '0px 64px', margin: '32px 0px' },
        }}
      >
        SIGN UP TO START WINNING
      </GreenButton>
      <GameList />
      <Title align="center" sx={{ fontSize: '33px !important' }}>
        Combining the excitement of betting with the thrill of global currencies
      </Title>
      <Text size="xl" align="center" weight="bold">
        Bet on the rate change between two currency pairs - will the rate go UP or DOWN?
        <br></br>
        Try it out and start playing NOW!
      </Text>
      <Group grow align="center" spacing={!mobileView ? 64 : 32} sx={{ justifyContent: 'center' }}>
        <RoundIcon icon={<HourGlass />} text="Time" subText="Select duration of your bet." step={1} />
        <RoundIcon icon={<CreditCard />} text="Amount" subText="How much you want to bet" step={2} />
        <RoundIcon icon={<Graph />} text="Direction" subText="If your rate will go UP or DOWN" step={3} />
        <RoundIcon icon={<Trophy />} text="Win BIG" subText="Bet on the correct result and win BIG!" color="green" />
      </Group>
    </Stack>
  );
};

export default LandingPageHeader;
