import { Box, Divider } from '@mantine/core';
import { Game, Market, Wager } from '../../../api/interfaces';
import { DurationPicker } from './placeWager/durationPicker';
import { WagerPosition } from './watchWager/wagerPosition';
import { useMarketStore } from '../../../state/state';

export const DurationOrPosition = (props: { game: Game; market: Market; wager?: Wager }) => {
  const wagering = useMarketStore(props.market)((state) => state.wagering);
  const updateWagering = useMarketStore(props.market)((state) => state.updateWagering);

  const key = props.wager ? 'wagerPosition' : 'durationPicker';

  return (
    <Box key={key} sx={{ height: '60px' }}>
      {props.wager ? (
        <WagerPosition game={props.game} market={props.market} wager={props.wager} />
      ) : (
        <DurationPicker
          game={props.game}
          market={props.market}
          unit={wagering.durationUnit}
          value={wagering.durationValue}
          setDuration={(unit, value) =>
            updateWagering((state) => {
              state.durationUnit = unit;
              state.durationValue = value;
            })
          }
        />
      )}
    </Box>
  );
};
