import { Stack, Title, Text, Image, Button, Container } from '@mantine/core';
import { Game, GameThemeEnum, Market, Wager } from '../../../api/interfaces';
import { findMarket, useMarketStore, useStore } from '../../../state/state';
import { WagerSummary } from '../../games/play/watchWager/wagerSummary';
import SVG from 'react-inlinesvg';
import { ReactComponent as SadIcon } from '../../../assets/icons/sad.svg';
import { ReactComponent as QuestionIcon } from '../../../assets/icons/question.svg';

import { useStyles } from './styles';
import { useEffect } from 'react';
import { WagerTimeRemaining } from '../../games/play/watchWager/wagerTimeRemaining';
import { useNavigate } from 'react-router-dom';
import { toMaxPrecision } from '../../../utils/formatters';
import slugify from 'slugify';
import { auto } from '@popperjs/core';

const ActiveBetSummary = ({ wager, game, update }: { wager: Wager; game: Game; update: Function }) => {
  const { classes } = useStyles(game);
  const state = useStore.getState();
  const hub = useStore((state) => state.hub);

  const navigate = useNavigate();

  useEffect(() => {
    if (wager.state === 'Active') {
      hub?.send('subscribe', wager.marketId);
      return () => {
        game.markets.forEach((x) => {
          hub?.send('unsubscribe', wager.marketId);
        });
      };
    }
  }, [game, hub]);

  const navToGame = (game: Game) => {
    navigate(`/play/${slugify(game.name, { lower: true })}/${slugify(game.markets[0].currencyPair, { lower: true })}`);
  };

  const market = findMarket(state.games, wager.marketId);

  const ticks = useMarketStore(market as Market)((state) => {
    return state.ticks;
  });

  if (!wager.marketId) {
    return null;
  }

  return (
    <Stack>
      <div className={classes.flexBetweenWrap}>
        <Title order={2}>{game.name}</Title>
        {wager.state === 'Active' && (
          <SVG src={game.iconGame} style={{ marginLeft: auto, height: '40px' }} className={classes.iconGame} />
        )}
        <Title
          order={2}
          style={{ height: '36px', margin: 'auto 0' }}
          className={classes[wager.state as keyof typeof classes]}
        >
          {wager.state === 'Won' && 'Won'}
          {wager.state === 'Lost' && 'Lost'}
          {wager.state === 'Draw' && 'Draw'}
          {wager.state === 'Active' && 'Live'}
          {wager.state === 'Pending' && 'Pending'}
          {wager.state === 'Cancelled' && 'Cancelled'}
        </Title>
      </div>
      {market && (
        <WagerSummary game={game} wager={wager} market={market} hideDivider={true} spaceBetween={true}></WagerSummary>
      )}
      {(wager.state === 'Won' || wager.state === 'Lost' || wager.state === 'Draw' || wager.state === 'Cancelled') && (
        <div className={classes.resultsWrapper}>
          <div className={classes.resultSection}>
            <Stack>
              <Title align="center">
                {wager.state === 'Won' && 'Well done!'}
                {wager.state === 'Lost' && 'Oh no, you lost this round'}
                {wager.state === 'Draw' && 'This game was a draw'}
                {wager.state === 'Cancelled' && 'This game was cancelled'}
              </Title>
              <Title align="center" order={2}>
                {wager.state === 'Won' && 'You beat the market!'}
                {wager.state === 'Lost' && 'So close, better luck next time...'}
                {wager.state === 'Draw' && 'Your wager has been refunded :)'}
                {wager.state === 'Cancelled' && `You have been credited €${wager.won}`}
              </Title>
              <Stack>
                {wager.state === 'Won' && (
                  <div className={classes.flexBetweenWrap}>
                    <Image fit="contain" src={game.iconAction} sx={{ maxHeight: '50px', maxWidth: '50px' }} />
                    <Stack spacing={0} align="center">
                      <Title align="center" sx={{ color: game.color }}>
                        You Won
                      </Title>
                      <Title align="center">{wager.won}</Title>
                    </Stack>
                    <SVG src={game.iconGame} color={game.color} className={classes.iconGame} />
                  </div>
                )}
                {(wager.state === 'Lost' || wager.state === 'Draw') && (
                  <div className={`${classes.flexBetweenWrap} ${classes.noWrap}`}>
                    <Stack spacing={0} className={`${classes.fullWidth} ${classes.fromToSection}`}>
                      <div className={`${classes.flexBetweenWrap}`}>
                        <Title order={2} sx={{ color: game.color }}>
                          from
                        </Title>
                        <Title order={2}>{wager.openingValue}</Title>
                      </div>
                      <div className={classes.flexBetweenWrap}>
                        <Title order={2} sx={{ color: game.color }}>
                          to
                        </Title>
                        <Title order={2}>{wager.closingValue}</Title>
                      </div>
                    </Stack>
                    {wager.state === 'Lost' && <SadIcon className={classes.fromToIcon} style={{ fill: game.color }} />}
                    {wager.state === 'Draw' && (
                      <QuestionIcon className={classes.fromToIcon} style={{ fill: game.color }} />
                    )}
                  </div>
                )}
              </Stack>
            </Stack>
          </div>
          <Stack align="center" justify={'center'} sx={{ flexGrow: 1 }}>
            <Title order={3} align="center" sx={{ width: '200px' }}>
              Bet will be saved and you can view it in the archive
            </Title>
            <Button
              radius="lg"
              className={classes.GameColourButton}
              sx={{ height: 'fit-content' }}
              onClick={() => navToGame(game)}
            >
              <Stack spacing={0}>
                <Title sx={{ lineHeight: 1.1 }}>
                  PLAY
                  <br />
                  AGAIN
                </Title>
              </Stack>
            </Button>
          </Stack>
        </div>
      )}
      {wager.state === 'Active' && (
        <Stack>
          <div className={`${classes.dFlexSpaceBetween}`}>
            <Stack spacing={0}>
              <Title order={2}>Current market position</Title>
              <Text>The direction the market is currently going</Text>
            </Stack>
            <Container
              className={classes.OnGameColourText}
              sx={(theme) => ({
                marginRight: 0,
                padding: 0,
              })}
            >
              <div
                className={classes.dFlexSpaceBetween}
                style={{
                  gap: '8px',
                  backgroundColor: game.color,
                  padding: '8px',
                  borderRadius: '16px',
                  alignItems: 'center',
                  width: '250px',
                }}
              >
                <div>
                  <Title order={2} align="center">
                    {ticks[ticks.length - 1]?.value
                      ? toMaxPrecision(ticks[ticks.length - 1]?.value, 6)
                      : 'loading value'}
                  </Title>
                </div>
                <Stack spacing={0} align="flex-end">
                  {ticks[ticks.length - 1]?.value && wager.openingValue && (
                    <>
                      <Title order={2} align="right">
                        {ticks[ticks.length - 1]?.value > wager.openingValue && wager.type === 'Rise' && 'Winning'}
                        {ticks[ticks.length - 1]?.value > wager.openingValue && wager.type === 'Fall' && 'Losing'}
                        {ticks[ticks.length - 1]?.value < wager.openingValue && wager.type === 'Rise' && 'Losing'}
                        {ticks[ticks.length - 1]?.value < wager.openingValue && wager.type === 'Fall' && 'Winning'}
                        {ticks[ticks.length - 1]?.value === wager.openingValue && 'Draw'}
                      </Title>
                      <Text align="right">
                        {ticks[ticks.length - 1]?.value > wager.openingValue &&
                          `UP from ${toMaxPrecision(wager.openingValue, 6)}`}
                        {ticks[ticks.length - 1]?.value < wager.openingValue &&
                          `DOWN from ${toMaxPrecision(wager.openingValue, 6)}`}
                        {ticks[ticks.length - 1]?.value === wager.openingValue &&
                          `Staying at ${toMaxPrecision(wager.openingValue, 6)}`}
                      </Text>
                    </>
                  )}
                </Stack>
              </div>
            </Container>
          </div>
          <div className={classes.flexBetweenWrap}>
            <Stack spacing={0}>
              <Title order={2}>Time remaining</Title>
              <Text>Total time left before this bet is complete</Text>
            </Stack>
            <div>
              {market && (
                <WagerTimeRemaining
                  game={game}
                  market={market}
                  wager={wager}
                  hideText={true}
                  updateCallback={() => update()}
                />
              )}
            </div>
          </div>
        </Stack>
      )}
    </Stack>
  );
};

export default ActiveBetSummary;
