import { Title, Stack, createStyles, keyframes } from '@mantine/core';

const loader = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

const useStyles = createStyles((theme) => ({
  chartLoaderWrapper: {
    background: theme.other.darkBackground,
    margin: '15px 0',
    padding: '15px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.lg,
  },
  chartLoader: {
    border: '4px solid #3498db ',
    borderTop: '4px solid #f3f3f3',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    animation: `${loader} 2s linear infinite`,
  },
}));

export const InfoBubble = (props: {
  title: string;
  loader?: boolean;
}) => {
  const { classes } = useStyles();

  return (
    <Stack className={`${classes.chartLoaderWrapper} `}>
      {props.loader && <div className={classes.chartLoader}></div>}
      <Title order={2} >{props.title}</Title>
    </Stack>
  );
};
