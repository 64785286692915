
import { Stack, Title } from '@mantine/core';

function Help() {
  return (
    <Stack align="center" spacing={32}>
      <Title>How To Play</Title>
        <iframe
          title="how to play"
          width="854"
          height="480"
          src="https://www.youtube.com/embed/AiaVtDLQQoo?autoplay=1&mute=0"
          allowFullScreen
        ></iframe>
    </Stack>
  );
};

export default Help;
