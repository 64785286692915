import { Divider, Title, Text, Stack } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Game, Wager, WagerStateEnum } from '../../api/interfaces';
import { getRecentWagers } from '../../api/player';
import { useStore } from '../../state/state';
import ActiveBetSummary from './active-bet-summary/activeBetSummary';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import { getPlayerActiveWagers } from '../../api/backoffice';
import React from 'react';
import { InfoBubble } from './InfoBubble';
import { GreenButton } from '../../components/buttons/GreenButton';

interface WagerSummary extends Wager {
  wager: Wager;
  game: Game;
}

const ActiveBets = ({ isAdmin, id }: { isAdmin?: boolean; id?: string }) => {
  const games = useStore((state) => state.games);
  const [wagers, setWagers] = useState<Wager[]>([]);
  const [completedWagers, setCompletedWagers] = useState<WagerSummary[]>([]);
  const [activeWagers, setActiveWagers] = useState<WagerSummary[]>([]);
  const [stillLoading, setStillLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    fetchBets().then(() => {
      setStillLoading(false);
    });
  }, [games]);

  const fetchBets = async () => {
    const wagers = isAdmin && id ? await getPlayerActiveWagers(id) : await getRecentWagers();
    if (wagers) {
      setWagers(wagers);

      const completedWagersList = wagers
        .filter((wager) => isCompleted(wager))
        .sort((a, b) => b.closingTimestamp! - a.closingTimestamp!)
        .slice(0, 10)
        .map((wager) => {
          const game = games.find((game) => {
            return game.markets.find((field) => {
              return field.id === wager.marketId;
            });
          });

          return { wager: wager, game: game };
        }) as WagerSummary[];
      setCompletedWagers(completedWagersList);

      const activeWagersList = wagers
        .filter((wager) => wager.state === 'Active')
        .sort((a, b) => b.openingTimestamp! - a.openingTimestamp!)
        .map((wager) => {
          const game = games.find((game) => {
            return game.markets.find((field) => {
              return field.id === wager.marketId;
            });
          });

          return { wager: wager, game: game };
        }) as WagerSummary[];
      setActiveWagers(activeWagersList);
    }
  };
  const { classes } = useStyles();

  const isCompleted = (wager: Wager) => {
    return (
      wager.state === WagerStateEnum.Lost ||
      wager.state === WagerStateEnum.Won ||
      wager.state === WagerStateEnum.Draw ||
      wager.state === WagerStateEnum.Cancelled
    );
  };

  const showList = wagers && games && !stillLoading;

  return (
    <div>
      {!isAdmin && (
        <Title order={1} align="center" mb={16}>
          All Active Bets
        </Title>
      )}

      <div className={classes.sectionWrapper}>
        <div className={isAdmin ? classes.isAdminSection : classes.section}>
          <Title>OPEN Wagers</Title>
          {stillLoading && <InfoBubble title="Loading Active Wagers" loader></InfoBubble>}
          {showList && activeWagers.length === 0 && (
            <InfoBubble title="Currently there are no open wagers"></InfoBubble>
          )}
          {showList &&
            activeWagers.map((activeWager) => {
              if (activeWager.game) {
                return (
                  <div key={`${activeWager.wager.openingTimestamp}-active-${activeWager.wager.marketId}`}>
                    <Divider size={3} mt={16} mb={16} sx={{ borderTopColor: '#34B9F5', width: '100%' }} />
                    <ActiveBetSummary
                      wager={activeWager.wager}
                      game={activeWager.game}
                      update={fetchBets}
                    ></ActiveBetSummary>
                  </div>
                );
              } else {
                setStillLoading(true);
                return <></>;
              }
            })}
        </div>
        {!isAdmin && (
          <div className={classes.section}>
            <Title>CLOSED Wagers</Title>
            {stillLoading && <InfoBubble title="Loading Closed Wagers" loader></InfoBubble>}
            {showList && completedWagers.length === 0 && (
              <InfoBubble title="There are no recent closed wagers"></InfoBubble>
            )}
            {showList &&
              completedWagers.map((completedWager) => {
                if (completedWager.game) {
                  return (
                    <div key={`${completedWager.wager.openingTimestamp}-closed-${completedWager.wager.marketId}`}>
                      <Divider size={3} mt={16} mb={16} sx={{ borderTopColor: '#34B9F5', width: '100%' }} />
                      <ActiveBetSummary
                        wager={completedWager.wager}
                        game={completedWager.game}
                        update={fetchBets}
                      ></ActiveBetSummary>
                    </div>
                  );
                } else {
                  setStillLoading(true);
                  return <React.Fragment key={`fragment-${completedWager.wager.openingTimestamp}`} />;
                }
              })}
            <GreenButton
              onClick={() => navigate('/wallet')}
              styles={{
                root: { minWidth: '100%', marginTop: '16px', marginBottom: '16px' },
              }}
            >
              <Stack spacing={0}>
                <Title order={2}>View betting history in your wallet</Title>
                <Text>Full history of all wagers and transactions</Text>
              </Stack>
            </GreenButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActiveBets;
