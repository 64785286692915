import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => {
  return {
    header: {
      background: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,90,146,1) 100%);',
    },
    headerNoAuth: {
      background: 'transparent',
      border: '0px',
    },
    navbar: {
      background: 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,90,146,1) 100%);',
      padding: '16px 0px',
      height: 'calc(100vh - 59px)',
    },
    linkHover: {
      '&:hover': {
        transform: 'scale(1.1)',
        cursor: 'pointer',
      },
    },
    navIcons: {
      height: '40px',
      width: '40px',
    },
    menuIcon: {
      height: '20px',
      width: '20px',
    },
    icon: {
      height: '30px',
      width: '30px',
    },
    iconWithImage: {
      height: '30px',
      width: '30px',
      borderRadius: '50%',
      border: '2px solid #fff',
      objectFit: 'cover',
    },
    linearBorder: {
      width: '100%', 
      height: '5px',
      background: 'linear-gradient(90deg, rgba(8,132,213,1) 0%, rgba(255,255,255,1) 100%)',
      marginBottom: '8px',
    },
    disabledContent: {
      filter: 'blur(3px)',
      cursor: 'default',
    },
    legalLinks: {
      '& > *:not(:last-child)': {
        borderRight: '1px solid #fff',
        paddingRight: '12px',
      },
      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        '& > *': {
          paddingRight: '0px',
        },
      },
    },
    footer: {
      borderTop: '5px solid transparent',
      borderImage: 'linear-gradient(90deg, rgba(8,132,213,1) 0%, rgba(255,255,255,1) 100%)',
      borderImageSlice: 1,
      width: '100%',
    },
  };
});

export { useStyles };
