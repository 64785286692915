import { Space, Title } from '@mantine/core';
import PlayerStats from './player-stats/PlayerStats';
import PlayerActivity from './player-activity/PlayerActivity';
import {
  PlayerAccount,
  PlayerSearchParams,
  IPlayerStats,
  WagerActivityTimeFilter,
  PlayerSearchFilterEnum,
} from '../../api/interfaces';
import { useEffect, useState } from 'react';
import { getPlayerStats, searchAccounts } from '../../api/backoffice';
import { calculateDateFromToday, financePeriodFormatter } from '../../utils/formatters';
import Reports from '../bo-reports/Report';
import { useStore } from '../../state/state';
import ManageSalesUserAllocation from './unallocated-players/ManageSalesUserAllocation';

const PlayerOverview = () => {
  const [activeFilterStats, setActiveFilterStats] = useState<WagerActivityTimeFilter>('24 Hours');
  const [activeFilterActivity, setActiveFilterActivity] = useState<WagerActivityTimeFilter>('24 Hours');
  const [playerAccounts, setPlayerAccounts] = useState<PlayerAccount[]>([]);
  const [sortByFilter, setSortByFilter] = useState<PlayerSearchFilterEnum>(PlayerSearchFilterEnum.LastOnline);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [playerStats, setPlayerStats] = useState<IPlayerStats>();
  const auth = useStore((state) => state.auth);

  const getPlayers = async () => {
    const date = new Date(await calculateDateFromToday(activeFilterActivity));
    let params: PlayerSearchParams = {
      duration: date,
      filter: sortByFilter,
      isDesc: true,
    };
    if (searchTerm !== '') {
      params.searchTerm = searchTerm;
    }
    const result = await searchAccounts(params);
    if (result) {
      setPlayerAccounts(result);
    }
  };

  const getStats = async () => {
    const result = await getPlayerStats(financePeriodFormatter(activeFilterStats));
    if (result) {
      setPlayerStats(result);
    }
  };

  useEffect(() => {
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilterStats]);

  useEffect(() => {
    getPlayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilterActivity, sortByFilter, searchTerm]);

  return (
    <>
      <Title align="center" order={1}>
        PLAYER Reports
      </Title>
      <Space h={32} />
      <PlayerStats
        activeFilterStats={activeFilterStats}
        setActiveFilterStats={setActiveFilterStats}
        playerStats={playerStats}
      />
      <Space h={32} />
      <PlayerActivity
        activeFilterActivity={activeFilterActivity}
        setActiveFilterActivity={setActiveFilterActivity}
        playerAccounts={playerAccounts}
        getPlayers={getPlayers}
        setSortByFilter={setSortByFilter}
        sortByFilter={sortByFilter}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
      />
      <Space h={32} />
      {auth?.user?.role?.includes('SuperAdmin') && (
        <>
          <ManageSalesUserAllocation />
          <Space h={32} />
          <Reports />
          <Space h={32} />
        </>
      )}
    </>
  );
};

export default PlayerOverview;
