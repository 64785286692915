import { Game, GameCategoryEnum, GameVolatilityEnum, MarketEnabledEnum } from '../../../api/interfaces';
import { CategoryButton } from '../../../components/game/categoryButton';
import { VolatilityButton } from '../../../components/game/volatilityButton';
import { useStore } from '../../../state/state';
import { Box, Group, createStyles, Title, Text, Paper, Skeleton, Modal } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import slugify from 'slugify';
import { GameCard } from './gameCard';
import jwtDecode from 'jwt-decode';
import { Payload } from '../../wallet/wallet';

interface GameFilters {
  category?: GameCategoryEnum;
  volatility?: GameVolatilityEnum;
}

const useGroupStyles = createStyles((theme) => ({
  controlGroup: {
    flexDirection: 'column',
    marginRight: '20px',
    marginBottom: '15px',
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: { flexDirection: 'row', marginRight: 0 },
    [`@media (max-width: 390px)`]: { justifyContent: 'space-between' },
  },
  layoutGroup: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: { flexDirection: 'column', alignItems: 'stretch' },
  },
  gameGroup: {
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: { justifyContent: 'center' },
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: { gap: '2.5vw' },
  },
  categorySkeleton: {
    width: '190px',
    height: '50px',
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: { height: '40px', width: 'auto', flexGrow: 1 },
  },
  cardLink: {
    textDecoration: 'none',
    ":hover": {
      transform: 'scale(1.05)',
    }
  }
}));

const GameList = () => {
  const games = useStore((state) => state.games);
  const [filters, setFilters] = useState<GameFilters>({});
  const { classes } = useGroupStyles();
  const [loading, setLoading] = useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  const params = urlParams.get('payload');
  const [transactionModalContent, setTransactionModalContent] = useState({ title: '', body: '', reference: '' });
  const [transactionModalOpened, setTransactionModalOpened] = useState(false);

  useEffect(() => {
    if (params) {
      displayDepositModal(params);
    }
  }, [params]);

  const displayDepositModal = (params: string) => {
    var payload = jwtDecode(params) as Payload;
    setTransactionModalContent(
      payload.status === 'ok'
        ? {
          title: 'Payment Successful',
          body: `Thank you for your deposit of ${payload.total_settled.amount} ${payload.total_settled.currency}. 
      This will reflect in your account balance shortly.`,
          reference: payload.order_ref.toString(),
        }
        : {
          title: 'There was a problem with your payment',
          body: `Your deposit of ${payload.total_requested.amount} ${payload.total_requested.currency} was not successful, 
      please try again or contact support`,
          reference: payload.order_ref.toString(),
        }
    );
    setTransactionModalOpened(true);
  };

  const handleCloseModal = () => {
    urlParams.delete('payload');
    const newUrl = `${window.location.pathname}`;
    window.history.replaceState(null, '', newUrl);
    setTransactionModalOpened(false);
  };

  useEffect(() => {
    if (games.length > 0) {
      setLoading(false);
    }
  }, [games]);

  const filteredGames = games?.filter(
    (x) =>
      (filters.category === undefined || x.category === filters.category) &&
      (filters.volatility === undefined || x.volatility === filters.volatility)
  );

  const skeletonArray = Array.from({ length: 10 });

  return (
    <>
      <Modal
        title={<Title order={3}>{transactionModalContent.title}</Title>}
        overlayOpacity={0.5}
        centered
        size="lg"
        opened={transactionModalOpened}
        radius="lg"
        onClose={handleCloseModal}
      >
        <Text sx={{ maxWidth: '400px' }}>{transactionModalContent.body}</Text>
        <Text size="xs" mt="md">
          {' '}
          Transaction reference: {transactionModalContent.reference}
        </Text>
      </Modal>
      <Group className={classes.layoutGroup} spacing={0} position="left" align="flex-start" noWrap>
        <GameFilterControls games={games as Game[]} filters={filters} setFilters={setFilters} loading={loading} />
        <Group className={classes.gameGroup} position="left" spacing="xs">
          {loading && skeletonArray.map((x, i) => <Skeleton key={`skeleton${i}`} width={190} height={281} mt={6} radius="md" />)}
          {!loading && filteredGames?.length === 0 && <Paper radius={'lg'} sx={{
            margin: '15px 0',
            padding: '15px',
          }}>
            <Title order={2} align='center'>Coming soon</Title>
            <Text>There are currently no games that meet your selection,
              <br />but we're always adding more, so watch this space!</Text>
          </Paper>}
          {!loading && filteredGames?.map((x) => (
            canClick(x) ?
              <Link
                key={x.name}
                className={classes.cardLink}
                to={`/play/${slugify(x.name, { lower: true })}/${slugify(x.markets[0].currencyPair, { lower: true })}`}
              >
                <GameCard game={x as Game} />
              </Link>
              :
              <GameCard game={x as Game} key={x.name} />
          ))}
        </Group>
      </Group>
    </>
  );
};

export { GameList };

const canClick = (game: Game) => {
  return game?.markets?.some((x) => x.enabled === MarketEnabledEnum.Enabled);
}


const GameFilterControls = (props: {
  games: Game[];
  filters: GameFilters;
  loading: boolean;
  setFilters: (filters: GameFilters) => void;
}) => {
  const { games, filters, setFilters } = props;
  const { classes } = useGroupStyles();

  const categories = [...new Set(games?.map((x) => x.category))];
  const volatilities = [...new Set(games?.map((x) => x.volatility))];

  return (
    <Box>
      <Title order={2} align="center" mr={20} mb={8}>
        Choose an arena
      </Title>
      <Group spacing="xs" className={classes.controlGroup}>
        {props.loading && [1, 2, 3].map((_, i) => <Skeleton key={`skeletonArena${i}`} className={classes.categorySkeleton} mt={6} radius="lg" />)}
        {!props.loading && categories.map((x) => (
          <CategoryButton
            key={x}
            category={x}
            selected={filters.category === x}
            onClick={() => {
              setFilters({ ...filters, category: filters.category === x ? undefined : x });
            }}
          />
        ))}
      </Group>

      <Title order={2} align="center" mr={20} mb={8}>
        Choose a game
      </Title>
      <Group spacing="xs" className={classes.controlGroup}>
        {props.loading && [1, 2].map((_, i) => <Skeleton key={`skeletonVolatility${i}`} className={classes.categorySkeleton} mt={6} radius="lg" />)}
        {!props.loading && volatilities.map((x) => (
          <VolatilityButton
            key={x}
            volatility={x}
            selected={filters.volatility === x}
            onClick={() => {
              setFilters({ ...filters, volatility: filters.volatility === x ? undefined : x });
            }}
          />
        ))}
      </Group>
    </Box>
  );
};
