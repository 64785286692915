import { Container, Divider, Grid, Stack, Text, Group, TextInput, useMantineTheme, Title, Box } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BalanceSummary, PlayTime, PlayerData } from '../../../api/interfaces';
import { getBalanceSummary, getPlayTime } from '../../../api/player';
import { GreenButton } from '../../../components/buttons/GreenButton';
import { minutesToDhms } from '../../../utils/formatters';
import { useStore } from '../../../state/state';

export const GamingStatistics = ({ isAdmin, player }: { isAdmin: boolean; player?: PlayerData }) => {
  const mobileView = useMediaQuery(`(max-width: ${useMantineTheme().breakpoints.xs}px)`);
  const [gameStats, setGameStats] = useState<PlayTime>();
  const [balanceSummary, setBalanceSummary] = useState<BalanceSummary>();
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const getStats = async () => {
    const gameStats = await getPlayTime();
    setGameStats(gameStats);
  };

  const getBalance = async () => {
    const balanceSummary = await getBalanceSummary();
    setBalanceSummary(balanceSummary);
  };

  useEffect(() => {
    if (isAdmin && player) {
      setGameStats(player.playtime);
    } else {
      getStats();
      getBalance();
    }
  }, [isAdmin, player]);

  return (
    <>
      {isAdmin && (
        <Group grow>
          <Box
            sx={{
              backgroundColor: theme.other.tabBackgroundColor,
              borderRadius: '20px',
              padding: '16px',
              height: '100%',
            }}
          >
            <Stack align="center" sx={{ height: '100%' }}>
              <Title align="center">{minutesToDhms(gameStats?.minutesLast24Hours)}</Title>
              <Text align="center" size="sm">
                Last 24 hours
              </Text>
            </Stack>
          </Box>
          <Box
            sx={{
              backgroundColor: theme.other.tabBackgroundColor,
              borderRadius: '20px',
              padding: '16px',
              height: '100%',
            }}
          >
            <Stack align="center" sx={{ height: '100%' }}>
              <Title align="center">{minutesToDhms(gameStats?.minutesLast7Days)}</Title>
              <Text align="center" size="sm">
                Last 7 days
              </Text>
            </Stack>
          </Box>
          <Box
            sx={{
              backgroundColor: theme.other.tabBackgroundColor,
              borderRadius: '20px',
              padding: '16px',
              height: '100%',
            }}
          >
            <Stack align="center" sx={{ height: '100%' }}>
              <Title align="center">{minutesToDhms(gameStats?.minutesLast30Days)}</Title>
              <Text align="center" size="sm">
                Last 30 days
              </Text>
            </Stack>
          </Box>
          <Box
            sx={{
              backgroundColor: theme.other.tabBackgroundColor,
              borderRadius: '20px',
              padding: '16px',
              height: '100%',
            }}
          >
            <Stack align="center" sx={{ height: '100%' }}>
              <Title align="center">{minutesToDhms(gameStats?.minutesTotal)}</Title>
              <Text align="center" size="sm">
                Ever
              </Text>
            </Stack>
          </Box>
        </Group>
      )}
      {!isAdmin && (
        <Stack>
          <Title order={2} align="center">
            Time spent on FXBet
          </Title>
          <Container fluid sx={{ width: '100%' }}>
            <Grid>
              <Grid.Col span={6}>
                <Box
                  sx={{
                    backgroundColor: theme.other.tabBackgroundColor,
                    borderRadius: '20px',
                    padding: '16px',
                    height: '100%',
                  }}
                >
                  {' '}
                  <Title align="center">{minutesToDhms(gameStats?.minutesLast24Hours)}</Title>
                  <Text align="center" size="sm">
                    Last 24 hours
                  </Text>
                </Box>
              </Grid.Col>
              <Grid.Col span={6}>
                <Box
                  sx={{
                    backgroundColor: theme.other.tabBackgroundColor,
                    borderRadius: '20px',
                    padding: '16px',
                    height: '100%',
                  }}
                >
                  <Title align="center">{minutesToDhms(gameStats?.minutesLast7Days)}</Title>
                  <Text align="center" size="sm">
                    Last 7 days
                  </Text>
                </Box>
              </Grid.Col>
              <Grid.Col span={6}>
                <Box
                  sx={{
                    backgroundColor: theme.other.tabBackgroundColor,
                    borderRadius: '20px',
                    padding: '16px',
                    height: '100%',
                  }}
                >
                  <Title align="center">{minutesToDhms(gameStats?.minutesLast30Days)}</Title>
                  <Text align="center" size="sm">
                    Last 30 days
                  </Text>
                </Box>
              </Grid.Col>
              <Grid.Col span={6}>
                <Box
                  sx={{
                    backgroundColor: theme.other.tabBackgroundColor,
                    borderRadius: '20px',
                    padding: '16px',
                    height: '100%',
                  }}
                >
                  {' '}
                  <Title align="center">{minutesToDhms(gameStats?.minutesTotal)}</Title>
                  <Text align="center" size="sm">
                    Ever
                  </Text>
                </Box>
              </Grid.Col>
            </Grid>
          </Container>
          <Divider size={2} />
          <Title order={2} align="center">
            Balance changes on FXBet
          </Title>
          <Container fluid sx={{ width: '100%' }}>
            <Grid>
              <Grid.Col span={6}>
                <Box
                  sx={{
                    backgroundColor: theme.other.tabBackgroundColor,
                    borderRadius: '20px',
                    padding: '16px',
                    height: '100%',
                  }}
                >
                  {' '}
                  <Title align="center">{`€ ${balanceSummary?.deposits}`}</Title>
                  <Text align="center" size="sm">
                    Total deposits
                  </Text>
                </Box>
              </Grid.Col>
              <Grid.Col span={6}>
                <Box
                  sx={{
                    backgroundColor: theme.other.tabBackgroundColor,
                    borderRadius: '20px',
                    padding: '16px',
                    height: '100%',
                  }}
                >
                  <Title align="center">{`€ ${balanceSummary?.withdrawals}`}</Title>
                  <Text align="center" size="sm">
                    Total withdrawals
                  </Text>
                </Box>
              </Grid.Col>
              <Grid.Col span={6}>
                <Box
                  sx={{
                    backgroundColor: theme.other.tabBackgroundColor,
                    borderRadius: '20px',
                    padding: '16px',
                    height: '100%',
                  }}
                >
                  <Title align="center">{`€ ${balanceSummary?.wins}`}</Title>
                  <Text align="center" size="sm">
                    Total wins
                  </Text>
                </Box>
              </Grid.Col>
              <Grid.Col span={6}>
                <Box
                  sx={{
                    backgroundColor: theme.other.tabBackgroundColor,
                    borderRadius: '20px',
                    padding: '16px',
                    height: '100%',
                  }}
                >
                  <Title align="center">{`€ ${balanceSummary?.losses}`}</Title>
                  <Text align="center" size="sm">
                    Total losses
                  </Text>
                </Box>
              </Grid.Col>
            </Grid>
          </Container>
          <Divider size={2} />
          <Group align="center" grow={true}>
            <GreenButton
              type="submit"
              onClick={() => navigate('/wallet')}
              styles={{
                root: { minWidth: 'fit-content' },
              }}
            >
              <Stack spacing={0}>
                <Title order={2}>View betting history in your wallet</Title>
                {!mobileView && <Text>Full history of all wagers, results, deposits, withdrawals</Text>}
              </Stack>
            </GreenButton>
          </Group>
          <Group align="center" grow={true}>
            <GreenButton
              onClick={() => navigate('/active-bets')}
              type="submit"
              styles={{
                root: { minWidth: 'fit-content' },
              }}
            >
              <Stack spacing={0}>
                <Title order={2}>View currently active bets</Title>
                {!mobileView && <Text>Manage all your bets that are currently active and in play</Text>}
              </Stack>
            </GreenButton>
          </Group>
        </Stack>
      )}
    </>
  );
};

export default GamingStatistics;
