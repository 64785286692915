import { Checkbox, Divider, Group, Select, Space, Stack, Switch, Text, TextInput } from '@mantine/core';
import { useForm, formList } from '@mantine/form';
import { useEffect, useState } from 'react';
import { Limits, Player, PlayerData } from '../../../api/interfaces';
import { getPlayer, updatePlayerLimits } from '../../../api/player';
import { updatePlayerLimitsAdmin } from '../../../api/backoffice';
import { GreenButton } from '../../../components/buttons/GreenButton';

export const GamingLimits = ({ isAdmin, player }: { isAdmin: boolean; player?: PlayerData }) => {
  const [loading, setLoading] = useState(true);

  const selectData = [
    { value: '12', label: '12h cool-off' },
    { value: '24', label: '24h cool-off' },
    { value: '72', label: '3d cool-off' },
    { value: '168', label: '7d cool-off' },
    { value: '336', label: '14d cool-off' },
    { value: '720', label: '30d cool-off' },
  ];

  const playerLimitForm = useForm({
    initialValues: {
      playerLimits: formList([
        {
          value: 0,
          checked: false,
          key: 'selfExclusionPeriodHours',
        },
        {
          value: 0,
          checked: false,
          key: 'depositLimitDaily',
        },
        {
          value: 0,
          checked: false,
          key: 'depositLimitWeekly',
        },
        {
          value: 0,
          checked: false,
          key: 'depositLimitMonthly',
        },
        {
          value: 0,
          checked: false,
          key: 'lossLimitDaily',
        },
        {
          value: 0,
          checked: false,
          key: 'lossLimitWeekly',
        },
        {
          value: 0,
          checked: false,
          key: 'lossLimitMonthly',
        },
        {
          value: 0,
          checked: false,
          key: 'wagerLimitDaily',
        },
        {
          value: 0,
          checked: false,
          key: 'wagerLimitWeekly',
        },
        {
          value: 0,
          checked: false,
          key: 'wagerLimitMonthly',
        },
        {
          value: 0,
          checked: false,
          key: 'wagerLimit',
        },
        {
          mainLabel: 'Session time',
          value: 0,
          checked: false,
          key: 'sessionLimitHoursDaily',
        },
        {
          mainLabel: 'Session time',
          value: 0,
          checked: false,
          key: 'sessionLimitHoursWeekly',
        },
        {
          mainLabel: 'Session time',
          value: 0,
          checked: false,
          key: 'sessionLimitDaysMonthly',
        },
      ]),
    },

    validate: {
      playerLimits: {
        value: (value) => (value < 0 || isNaN(value) ? 'invalid input' : null),
      },
    },
  });

  const playerItem = playerLimitForm.values.playerLimits;

  const changeGamingLimits = () => {
    let updateObject: Limits = { selfExclusionPeriodHours: 0 };
    playerLimitForm.values.playerLimits.forEach((item) => {
      if (item.checked) {
        updateObject[item.key as keyof Limits] = Number(item.value);
      }
    });
    if (isAdmin && player) {
      updatePlayerLimits(updateObject, player.id);
    } else {
      updatePlayerLimits(updateObject);
    }
  };

  const getGamingLimits = async (player: Player | PlayerData) => {
    if (player) {
      for (let [key, value] of Object.entries(player.limits)) {
        let index = playerLimitForm.values.playerLimits.findIndex((x) => x.key.toString() === key.toString());
        if (index !== -1) {
          playerLimitForm.values.playerLimits[index].value = value;
          if (value !== 0) {
            playerLimitForm.values.playerLimits[index].checked = true;
          }
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const playerInfo = isAdmin ? player : await getPlayer();
      if (playerInfo) {
        await getGamingLimits(playerInfo);
      }
    };
    fetchData();
  }, [isAdmin, player]);

  const userTextInputMarkup = (index: number) => {
    return (
      <Group noWrap={true}>
        <Switch
          size="xl"
          checked={playerItem[index].checked}
          {...playerLimitForm.getListInputProps('playerLimits', index, 'checked')}
        />
        <TextInput
          size="lg"
          disabled={!playerItem[index].checked}
          {...playerLimitForm.getListInputProps('playerLimits', index, 'value')}
        />
      </Group>
    );
  };

  return (
    <>
      {isAdmin && (
        <>
          <Group grow>
            <Group noWrap grow>
              <Stack spacing={0}>
                <Text weight="bold">Self-exclusion</Text>
                <Text>Time-out</Text>
              </Stack>
              <Group>
                <Switch
                  size="xl"
                  checked={playerItem[0].checked}
                  {...playerLimitForm.getListInputProps('playerLimits', 0, 'checked')}
                />
                <Select
                  size="lg"
                  disabled={!playerItem[0].checked}
                  {...playerLimitForm.getListInputProps('playerLimits', 0, 'value')}
                  value={playerItem[0].value.toString()}
                  data={selectData}
                />
              </Group>
            </Group>
            <Stack>
              <Text size="sm">
                Cooling off allows you to take a responsible break from gaming for a determined amount of time.
              </Text>
              <Text size="sm">
                By clicking SAVE you will be logged out of your account. You will NOT be able to log in until the
                timeframe is expired. This can not be undone!
              </Text>
            </Stack>
          </Group>
          <Divider size={2} sx={{ margin: '24px 0px' }} />
          <Group grow>
            <Stack>
              <Group grow>
                <Stack spacing={0}>
                  <Text weight="bold">Deposit</Text>
                  <Text>DAILY limit</Text>
                </Stack>
                <Group noWrap={true}>{userTextInputMarkup(1)}</Group>
              </Group>
              <Group noWrap={true} grow={true}>
                <Stack spacing={0}>
                  <Text weight="bold">Deposit</Text>
                  <Text>WEEKLY limit</Text>
                </Stack>
                <Group noWrap={true}>{userTextInputMarkup(2)}</Group>
              </Group>
              <Group noWrap={true} grow={true}>
                <Stack spacing={0}>
                  <Text weight="bold">Deposit</Text>
                  <Text>Monthly limit</Text>
                </Stack>
                <Group noWrap={true}>{userTextInputMarkup(3)}</Group>
              </Group>
              <Text size="sm">
                This setting will notify you when your deposits over a set timeframe exceed the set responsible gaming
                limits.
              </Text>
            </Stack>
            <Stack>
              <Group noWrap={true} grow={true}>
                <Stack spacing={0}>
                  <Text weight="bold">Losses</Text>
                  <Text>DAILY limit</Text>
                </Stack>
                <Group noWrap={true}>{userTextInputMarkup(4)}</Group>
              </Group>
              <Group noWrap={true} grow={true}>
                <Stack spacing={0}>
                  <Text weight="bold">Losses</Text>
                  <Text>WEEKLY limit</Text>
                </Stack>
                <Group noWrap={true}>{userTextInputMarkup(5)}</Group>
              </Group>
              <Group noWrap={true} grow={true}>
                <Stack spacing={0}>
                  <Text weight="bold">Losses</Text>
                  <Text>MONTLY limit</Text>
                </Stack>
                <Group noWrap={true}>{userTextInputMarkup(6)}</Group>
              </Group>
              <Text size="sm">
                This setting will notify you when your deposits over a set timeframe exceed the set responsible gaming
                limits.
              </Text>
            </Stack>
          </Group>
          <Divider size={2} sx={{ margin: '24px 0px' }} />
          <Group grow>
            <Stack>
              <Group noWrap={true} grow={true}>
                <Stack spacing={0}>
                  <Text weight="bold">Wagers</Text>
                  <Text>Daily limit</Text>
                </Stack>
                <Group noWrap={true}>{userTextInputMarkup(7)}</Group>
              </Group>
              <Group noWrap={true} grow={true}>
                <Stack spacing={0}>
                  <Text weight="bold">Wagers</Text>
                  <Text>WEEKLY limit</Text>
                </Stack>
                <Group noWrap={true}>{userTextInputMarkup(8)}</Group>
              </Group>
              <Group noWrap={true} grow={true}>
                <Stack spacing={0}>
                  <Text weight="bold">Wagers</Text>
                  <Text>MONTLY limit</Text>
                </Stack>
                <Group noWrap={true}>{userTextInputMarkup(9)}</Group>
              </Group>
              <Group noWrap={true} grow={true}>
                <Stack spacing={0}>
                  <Text weight="bold">Maximum Bet</Text>
                  <Text>Max wager limit</Text>
                </Stack>
                <Group noWrap={true}>{userTextInputMarkup(10)}</Group>
              </Group>
              <Text size="sm">
                This setting will notify you when your wager losses over a set timeframe exceed the set responsible
                gaming limits.
              </Text>
            </Stack>
            <Stack>
              <Group noWrap={true} grow={true}>
                <Stack spacing={0}>
                  <Text weight="bold">Session time</Text>
                  <Text>DAILY limit</Text>
                </Stack>
                <Group noWrap={true}>{userTextInputMarkup(11)}</Group>
              </Group>
              <Group noWrap={true} grow={true}>
                <Stack spacing={0}>
                  <Text weight="bold">Session time</Text>
                  <Text>WEEKLY limit</Text>
                </Stack>
                <Group noWrap={true}>{userTextInputMarkup(12)}</Group>
              </Group>
              <Group noWrap={true} grow={true}>
                <Stack spacing={0}>
                  <Text weight="bold">Session time</Text>
                  <Text>MONTLY limit</Text>
                </Stack>
                <Group noWrap={true}>{userTextInputMarkup(13)}</Group>
              </Group>
              <Text size="sm">
                This setting will notify you when you play longer than a set timeframe, exceeding your responsible
                gaming limits.
              </Text>
              <Group grow={true}>
                <GreenButton
                  type="submit"
                  styles={{
                    root: { minWidth: 'fit-content' },
                  }}
                  onClick={() => changeGamingLimits()}
                >
                  Update Gaming Limits
                </GreenButton>
              </Group>
            </Stack>
          </Group>
          <Space h={64} />
        </>
      )}
      {!isAdmin && (
        <Stack style={isAdmin ? { width: '50%', margin: 'auto' } : {}}>
          <Divider size={2} />
          <Stack>
            <Group noWrap={true} grow={true}>
              <Stack spacing={0}>
                <Text weight="bold">Self-exclusion</Text>
                <Text>Time-out</Text>
              </Stack>
              <Group noWrap={true}>
                <Switch
                  size="xl"
                  checked={playerItem[0].checked}
                  {...playerLimitForm.getListInputProps('playerLimits', 0, 'checked')}
                />
                <Select
                  size="lg"
                  disabled={!playerItem[0].checked}
                  {...playerLimitForm.getListInputProps('playerLimits', 0, 'value')}
                  value={playerItem[0].value.toString()}
                  data={selectData}
                />
              </Group>
            </Group>
            <Text size="sm">
              Cooling off allows you to take a responsible break from gaming for a determined amount of time.
            </Text>
            <Text size="sm">
              By clicking SAVE you will be logged out of your account. You will NOT be able to log in until the
              timeframe is expired. This can not be undone!
            </Text>
            <Divider size={2} />
            <Group noWrap={true} grow={true}>
              <Stack spacing={0}>
                <Text weight="bold">Deposit</Text>
                <Text>DAILY limit</Text>
              </Stack>
              <Group noWrap={true}>{userTextInputMarkup(1)}</Group>
            </Group>
            <Group noWrap={true} grow={true}>
              <Stack spacing={0}>
                <Text weight="bold">Deposit</Text>
                <Text>WEEKLY limit</Text>
              </Stack>
              <Group noWrap={true}>{userTextInputMarkup(2)}</Group>
            </Group>
            <Group noWrap={true} grow={true}>
              <Stack spacing={0}>
                <Text weight="bold">Deposit</Text>
                <Text>Monthly limit</Text>
              </Stack>
              <Group noWrap={true}>{userTextInputMarkup(3)}</Group>
            </Group>
            <Text size="sm">
              This setting will notify you when your deposits over a set timeframe exceed the set responsible gaming
              limits.
            </Text>
            <Divider size={2} />
            <Group noWrap={true} grow={true}>
              <Stack spacing={0}>
                <Text weight="bold">Losses</Text>
                <Text>DAILY limit</Text>
              </Stack>
              <Group noWrap={true}>{userTextInputMarkup(4)}</Group>
            </Group>
            <Group noWrap={true} grow={true}>
              <Stack spacing={0}>
                <Text weight="bold">Losses</Text>
                <Text>WEEKLY limit</Text>
              </Stack>
              <Group noWrap={true}>{userTextInputMarkup(5)}</Group>
            </Group>
            <Group noWrap={true} grow={true}>
              <Stack spacing={0}>
                <Text weight="bold">Losses</Text>
                <Text>MONTLY limit</Text>
              </Stack>
              <Group noWrap={true}>{userTextInputMarkup(6)}</Group>
            </Group>
            <Text size="sm">
              This setting will notify you when your deposits over a set timeframe exceed the set responsible gaming
              limits.
            </Text>
            <Divider size={2} />
            <Group noWrap={true} grow={true}>
              <Stack spacing={0}>
                <Text weight="bold">Wagers</Text>
                <Text>Daily limit</Text>
              </Stack>
              <Group noWrap={true}>{userTextInputMarkup(7)}</Group>
            </Group>
            <Group noWrap={true} grow={true}>
              <Stack spacing={0}>
                <Text weight="bold">Wagers</Text>
                <Text>WEEKLY limit</Text>
              </Stack>
              <Group noWrap={true}>{userTextInputMarkup(8)}</Group>
            </Group>
            <Group noWrap={true} grow={true}>
              <Stack spacing={0}>
                <Text weight="bold">Wagers</Text>
                <Text>MONTLY limit</Text>
              </Stack>
              <Group noWrap={true}>{userTextInputMarkup(9)}</Group>
            </Group>
            <Group noWrap={true} grow={true}>
              <Stack spacing={0}>
                <Text weight="bold">Maximum Bet</Text>
                <Text>Max wager limit</Text>
              </Stack>
              <Group noWrap={true}>{userTextInputMarkup(10)}</Group>
            </Group>
            <Text size="sm">
              This setting will notify you when your wager losses over a set timeframe exceed the set responsible gaming
              limits.
            </Text>
            <Divider size={2} />
            <Group noWrap={true} grow={true}>
              <Stack spacing={0}>
                <Text weight="bold">Session time</Text>
                <Text>DAILY limit</Text>
              </Stack>
              <Group noWrap={true}>{userTextInputMarkup(11)}</Group>
            </Group>
            <Group noWrap={true} grow={true}>
              <Stack spacing={0}>
                <Text weight="bold">Session time</Text>
                <Text>WEEKLY limit</Text>
              </Stack>
              <Group noWrap={true}>{userTextInputMarkup(12)}</Group>
            </Group>
            <Group noWrap={true} grow={true}>
              <Stack spacing={0}>
                <Text weight="bold">Session time</Text>
                <Text>MONTLY limit</Text>
              </Stack>
              <Group noWrap={true}>{userTextInputMarkup(13)}</Group>
            </Group>
            <Text size="sm">
              This setting will notify you when you play longer than a set timeframe, exceeding your responsible gaming
              limits.
            </Text>
            <Divider size={2} />
          </Stack>
          <Group grow={true}>
            <GreenButton
              type="submit"
              styles={{
                root: { minWidth: 'fit-content' },
              }}
              onClick={() => changeGamingLimits()}
            >
              Update Gaming Limits
            </GreenButton>
          </Group>
        </Stack>
      )}
    </>
  );
};

export default GamingLimits;
